import moment from 'moment';
import { VehicleInfo } from 'types/vehicleInfo';

export const sortActiveAuctionVehiclesArray = (
  vehicles: VehicleInfo[],
  order: string,
  searchValue: string,
  userId: string,
  page: number,
  rowsPerPage: number,
  pinned: boolean,
  isFilteredList?: boolean
) => {
  let slicedVehicles;
  if (isFilteredList) {
    slicedVehicles = vehicles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  } else {
    slicedVehicles = vehicles;
  }
  // const slicedVehicles = vehicles; //.slice(); //page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  const sortedVehicles = slicedVehicles.sort((a, b) => {
    const currentDate = new Date(); // Get the current date

    // Convert Firebase Timestamps to JavaScript Date objects
    // const launchDateA = a.auction.endDate.toDate();
    // const launchTimeA = a.auction.endTime.toDate();

    const launchDateA = moment(a.auction.endDate.toDate()).format('YYYY/MM/DD');
    const launchTimeA = moment(a.auction.endTime.toDate()).format('hh:mm:ss a');
    const finalLaunchDateA = new Date(launchDateA + ' ' + launchTimeA);

    const launchDateB = moment(b.auction.endDate.toDate()).format('YYYY/MM/DD');
    const launchTimeB = moment(b.auction.endTime.toDate()).format('hh:mm:ss a');
    const finalLaunchDateB = new Date(launchDateB + ' ' + launchTimeB);

    // const launchDateB = b.auction.endDate.toDate();

    if (order === 'timeDesc') {
      return finalLaunchDateA.valueOf() - currentDate.valueOf() >
        finalLaunchDateB.valueOf() - currentDate.valueOf()
        ? -1
        : 1;
    } else if (order === 'timeAsc') {
      return finalLaunchDateA.valueOf() - currentDate.valueOf() >
        finalLaunchDateB.valueOf() - currentDate.valueOf()
        ? 1
        : -1;
    } else {
      return a.details.make > b.details.make ? 1 : -1;
    }
  });

  const filteredVehicles = sortedVehicles.filter((v: VehicleInfo) =>
    pinned ? v.auction.isFavourite.includes(userId) : !v.auction.isFavourite.includes(userId)
  );

  if (searchValue === '' || filteredVehicles.length < 1) {
    return filteredVehicles;
  }

  const retFilteredVehicles = sortedVehicles.filter(
    (v: VehicleInfo) =>
      v.details.make.toLowerCase().includes(searchValue.toLowerCase()) ||
      v.details.model.toLowerCase().includes(searchValue.toLowerCase()) ||
      v.details.year?.toString().toLowerCase().includes(searchValue.toLowerCase()) ||
      v.details.trim.toLowerCase().includes(searchValue.toLowerCase())
  );

  return retFilteredVehicles;
};

export const sortLiveAuctionVehiclesArray = (vehicles: VehicleInfo[]) => {
  const slicedVehicles = vehicles;
  const sortedVehicles = slicedVehicles.sort((a, b) => {
    if (a.liveAuctionIndex > b.liveAuctionIndex) {
      return 1;
    }
    if (a.liveAuctionIndex < b.liveAuctionIndex) {
      return -1;
    }
    return 0;
  });
  return sortedVehicles;
};

export const sortPreAuctionVehiclesArray = (
  vehicles: VehicleInfo[],
  order: string,
  searchValue: string,
  page: number,
  rowsPerPage: number,
  isSellBuy: 'sell' | 'buy'
) => {
  const slicedVehicles = vehicles; // .slice(); //page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  // Buy
  if (isSellBuy === 'buy') {
    const sortedVehicles = slicedVehicles;
    // const sortedVehicles = slicedVehicles.sort((a, b) => {
    //   const currentDate = new Date();

    //   const launchDateA = moment(a.auction.launchDate.toDate()).format('YYYY/MM/DD');
    //   const launchTimeA = moment(a.auction.launchDate.toDate()).format('hh:mm:ss a');
    //   const finalLaunchDateA = new Date(launchDateA + ' ' + launchTimeA);

    //   const launchDateB = moment(b.auction.launchDate.toDate()).format('YYYY/MM/DD');
    //   const launchTimeB = moment(b.auction.launchDate.toDate()).format('hh:mm:ss a');
    //   const finalLaunchDateB = new Date(launchDateB + ' ' + launchTimeB);

    //   if (order === 'timeAsc') {
    //     return finalLaunchDateA.valueOf() - currentDate.valueOf() >
    //       finalLaunchDateB.getTime() - currentDate.getTime()
    //       ? 1
    //       : -1;
    //   } else if (order === 'timeDesc') {
    //     return finalLaunchDateA.valueOf() - currentDate.valueOf() >
    //       finalLaunchDateB.valueOf() - currentDate.getTime()
    //       ? -1
    //       : 1;
    //   } else {
    //     return a.details.make > b.details.make ? 1 : -1;
    //   }
    // });
    const retFilteredVehicles = sortedVehicles.filter(
      (v: VehicleInfo) =>
        v.details.make.toLowerCase().includes(searchValue.toLowerCase()) ||
        v.details.model.toLowerCase().includes(searchValue.toLowerCase()) ||
        v.details.year?.toString().toLowerCase().includes(searchValue.toLowerCase()) ||
        v.details.trim.toLowerCase().includes(searchValue.toLowerCase())
    );
    if (searchValue === '') {
      return sortedVehicles;
    }
    return retFilteredVehicles;
  } else {
    // Sell
    const sortedVehicles = slicedVehicles.sort((a, b) => {
      if (order === 'timeDesc') {
        if (a.auction.auctionStatus === 'launching soon') {
          if (b.auction.auctionStatus === 'launching soon') {
            return a.auction.launchDate > b.auction.launchDate ? 1 : -1;
          } else {
            return 1;
          }
        } else if (b.auction.auctionStatus === 'launching soon') {
          return -1;
        } else {
          return a.auction.captureDate > b.auction.captureDate ? 1 : -1;
        }
      } else if (order === 'timeAsc') {
        if (a.auction.auctionStatus === 'launching soon') {
          if (b.auction.auctionStatus === 'launching soon') {
            return a.auction.launchDate > b.auction.launchDate ? -1 : 1;
          } else {
            return -1;
          }
        } else if (b.auction.auctionStatus === 'launching soon') {
          return 1;
        } else {
          return a.auction.captureDate > b.auction.captureDate ? -1 : 1;
        }
      } else if (order === 'vehiclePrice') {
        return a.auction.floorPrice > b.auction.floorPrice ? -1 : 1;
      } else if (order === 'createdDate') {
        return a.createdDate > b.createdDate ? -1 : 1;
      } else {
        return a.details.make > b.details.make ? 1 : -1;
      }
    });

    const retFilteredVehicles = sortedVehicles.filter(
      (v: VehicleInfo) =>
        v.details.make.toLowerCase().includes(searchValue.toLowerCase()) ||
        v.details.model.toLowerCase().includes(searchValue.toLowerCase()) ||
        v.details.year?.toString().toLowerCase().includes(searchValue.toLowerCase()) ||
        v.details.trim.toLowerCase().includes(searchValue.toLowerCase()) ||
        /* fDateTimeSuffix(v.auction.captureDate.toDate())
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase()) || */
        v.auction.floorPrice.toString().toLowerCase().includes(searchValue.toLowerCase()) ||
        v.auction.captureRepName.toLowerCase().includes(searchValue.toLowerCase())
    );
    if (searchValue === '') {
      return sortedVehicles;
    }
    return retFilteredVehicles;
  }
};

export const sortNegotiationVehiclesArray = (
  vehicles: VehicleInfo[],
  order: string,
  searchValue: string,
  page: number,
  rowsPerPage: number
) => {
  const slicedVehicles = vehicles; //.slice(); //page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  const sortedVehicles = slicedVehicles.sort((a, b) => {
    const negHistoryA =
      a.auction.negotiationHistory && a.auction.negotiationHistory.length > 1
        ? a.auction.negotiationHistory[a.auction.negotiationHistory.length - 1].dateTime
        : 0;
    const negHistoryB =
      b.auction.negotiationHistory && b.auction.negotiationHistory.length > 1
        ? b.auction.negotiationHistory[b.auction.negotiationHistory?.length - 1].dateTime
        : 0;
    if (order === 'oldest') {
      return negHistoryA > negHistoryB ? 1 : -1;
    } else if (order === 'newest') {
      return negHistoryA > negHistoryB ? -1 : 1;
    } else {
      return a.details.make > b.details.make ? 1 : -1;
    }
  });

  const retFilteredVehicles = sortedVehicles.filter(
    (v: VehicleInfo) =>
      v.details.make.toLowerCase().includes(searchValue.toLowerCase()) ||
      v.details.model.toLowerCase().includes(searchValue.toLowerCase()) ||
      /* fDateTimeSuffix(v.auction.captureDate.toDate())
        .toString()
        .toLowerCase()
        .includes(searchValue.toLowerCase()) || */
      v.auction.floorPrice.toString().toLowerCase().includes(searchValue.toLowerCase()) ||
      v.auction.captureRepName.toLowerCase().includes(searchValue.toLowerCase()) ||
      v.details.year?.toString().toLowerCase().includes(searchValue.toLowerCase()) ||
      v.details.trim.toLowerCase().includes(searchValue.toLowerCase())
  );

  if (searchValue === '') {
    return sortedVehicles;
  }
  return retFilteredVehicles;
};
