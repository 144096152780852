import { Link as RouterLink } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Typography } from '@mui/material';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
//
import { MHidden } from '../../components/@material-extend';
import AccountPopover from './AccountPopover';
import NotificationsPopover from './NotificationsPopover';
import Image from '../../assets/LogoText-FullColour.png';
import DarkImage from '../../assets/LogoText-White.png';
import { useTheme } from '@mui/material/styles';
import LearnPopover from './LearnPopover';
import useAuth from 'hooks/useAuth';
import { useFirestore } from 'reactfire';
import { doc, getDoc } from 'firebase/firestore';
import { Dealership } from 'types/dealership';
import { useEffect, useState } from 'react';
import VideoPopover from './VideoPopover';
// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 83;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.background.paper
      : alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: '100%',
    borderBottom: `solid 1px ${alpha(theme.palette.grey[500], 0.24)}`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

const LogoMenuIconStyle = styled(Box)(({ theme }) => ({
  width: 240,
  minHeight: APPBAR_DESKTOP,
  display: 'inline-flex',
  alignItems: 'center'
}));

// ----------------------------------------------------------------------
type IconCollapseProps = {
  onToggleCollapse: VoidFunction;
};

function IconCollapse({ onToggleCollapse }: IconCollapseProps) {
  const theme = useTheme();
  return (
    <LogoMenuIconStyle>
      <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
        {/* <LogoText /> */}
        <Box
          component="img"
          src={theme.palette.mode === 'dark' ? DarkImage : Image}
          sx={{ maxWidth: '80%' }}
        />
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <IconButton
        onClick={onToggleCollapse}
        sx={{ mr: 1, color: 'text.primary' }}
        id="activeAuction10"
      >
        <MenuIcon />
      </IconButton>
    </LogoMenuIconStyle>
  );
}

type DashboardNavbarProps = {
  onOpenSidebar: VoidFunction;
};

export default function DashboardNavbar({ onOpenSidebar }: DashboardNavbarProps) {
  const { onToggleCollapse } = useCollapseDrawer();
  const { currentUser } = useAuth();
  const firestore = useFirestore();
  const [currentDealership, setCurrentDealership] = useState<Dealership>();

  const getCurrentDealership = async () => {
    const docRef = doc(firestore, `dealerships/${currentUser.currentDealershipId}`);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const currentDealership = { id: docSnap.id, ...docSnap.data() } as Dealership;
      setCurrentDealership(currentDealership);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (currentUser && !currentDealership) {
        getCurrentDealership();
      }
    }
    return () => {
      isMounted = false;
    };
    // if (currentUser && !currentDealership) {
    //   getCurrentDealership();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return (
    <RootStyle sx={{ width: { lg: '100%' }, zIndex: '1198' }}>
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <MenuIcon />
          </IconButton>
        </MHidden>

        <MHidden width="lgDown">
          <IconCollapse onToggleCollapse={onToggleCollapse} />
        </MHidden>

        <Box sx={{ flexGrow: 1 }} />
        {currentDealership && <Typography>{currentDealership.dealershipName}</Typography>}
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          sx={{ mr: '30px' }}
          spacing={{ xs: 0.5, sm: 1.5 }}
          id="dashboard2"
        >
          {/* <VideoPopover /> */}
          <LearnPopover />
          <NotificationsPopover />
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
