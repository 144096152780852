import {
  Box,
  useTheme,
  InputBase,
  Typography,
  MenuItem,
  Select,
  SelectChangeEvent,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Accordion,
  Grid,
  TablePagination
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { FC, useEffect, useState } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { VehicleInfo } from 'types/vehicleInfo';
import { arrayRemove, arrayUnion, doc, updateDoc } from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import useAuth from 'hooks/useAuth';
import { sortActiveAuctionVehiclesArray, sortLiveAuctionVehiclesArray } from 'utils/searchHelper';
import VehicleRowCommonSell from './VehicleRowCommonSell';
import { ViewportList } from 'react-viewport-list';

type Props = {
  vehicles?: VehicleInfo[];
  collectionSize: number;
  searchValue: string;
  handleOpenDrawer: (value: number, v: VehicleInfo) => void;
  mediaQuery: number;
  order: string;
  page: number;
  rowsPerPage: number;
  expandedPinned: boolean;
  expandedAll: boolean;
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  handleChangeRowsPerPage: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleOrderByChange: (event: SelectChangeEvent) => void;
  handleExpandedPinnedChange: () => void;
  handleExpandedAllChange: () => void;
  pinnedRef: any;
  // handleNextVehicles: () => void;
  // vehiclesLoading: boolean;
};

const LiveAuctionSell: FC<Props> = React.memo(function LiveAuctionSell({
  pinnedRef,
  vehicles,
  collectionSize,
  searchValue,
  handleOpenDrawer,
  mediaQuery,
  order,
  page,
  rowsPerPage,
  expandedPinned,
  expandedAll,
  handleChangePage,
  handleChangeRowsPerPage,
  handleOrderByChange,
  handleExpandedPinnedChange,
  handleExpandedAllChange
}) {
  const theme = useTheme();
  const { user } = useAuth();
  const firestore = useFirestore();
  const [pinnedHeight, setPinnedHeight] = useState<number>(0);
  const [unpinnedHeight, setUnpinnedHeight] = useState<number>(0);

  const handleExpand = async (id: string, isExpanded: boolean) => {
    const docRef = doc(firestore, `vehicles/${id}`);
    if (isExpanded) {
      await updateDoc(docRef, { 'auction.isExpanded': arrayRemove(user?.uid) });
    } else {
      await updateDoc(docRef, { 'auction.isExpanded': arrayUnion(user?.uid) });
    }
  };

  const handleFavourite = async (id: string, isFavourite: boolean) => {
    const docRef = doc(firestore, `vehicles/${id}`);
    if (isFavourite) {
      await updateDoc(docRef, { 'auction.isFavourite': arrayRemove(user?.uid) });
    } else {
      await updateDoc(docRef, { 'auction.isFavourite': arrayUnion(user?.uid) });
    }
  };

  const handleWatchlist = async (id: string, isWatchlist: boolean) => {
    const docRef = doc(firestore, `vehicles/${id}`);
    if (isWatchlist) {
      await updateDoc(docRef, { 'auction.isWatchlist': arrayRemove(user?.uid) });
    } else {
      await updateDoc(docRef, { 'auction.isWatchlist': arrayUnion(user?.uid) });
    }
  };

  const checkPinnedVehicles = () => {
    let counterPinned = 0;
    let counterUnpinned = 0;
    vehicles?.forEach((vehicle: VehicleInfo) => {
      if (vehicle.auction.isFavourite.includes(user?.uid)) {
        counterPinned++;
      } else {
        counterUnpinned++;
      }
    });
    if (counterPinned === 0) {
      setPinnedHeight(0);
    } else if (counterPinned === 1) {
      setPinnedHeight(325);
    } else if (counterPinned === 2) {
      setPinnedHeight(450);
    } else if (counterPinned > 2) {
      setPinnedHeight(600);
    }
    if (counterUnpinned === 0) {
      setUnpinnedHeight(0);
    } else if (counterUnpinned === 1) {
      setUnpinnedHeight(325);
    } else if (counterUnpinned === 2) {
      setUnpinnedHeight(450);
    } else if (counterUnpinned > 2) {
      setUnpinnedHeight(800);
    }
  };

  useEffect(() => {
    checkPinnedVehicles();
    console.log('vehicles length', vehicles?.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicles]);

  const useStyles = makeStyles({
    headerTextContainer: {
      width: '100%',
      height: 'auto',
      display: 'flex',
      minHeight: '65px',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    controlsContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'flex-end'
      // [theme.breakpoints.down(675)]: {
      //   marginTop: '25px',
      //   justifyContent: 'center'
      // }
    },
    headerText: {
      fontSize: '14px',
      marginRight: '18px',
      marginLeft: '0px'
    },
    inputText: {
      fontSize: '14px'
    },
    vehicleContainer: {
      display: 'flex',
      width: '100%',
      height: 'auto',
      marginBottom: '20px',
      minWidth: '1050px',
      overflowX: 'auto'
      // [theme.breakpoints.down(mediaQuery)]: {
      //   display: 'flex',
      //   flexDirection: 'column',
      //   justifyContent: 'center',
      //   alignItems: 'center',
      //   width: 'auto'
      // }
    },
    accordion: {
      boxShadow: 'none !important',
      // backgroundColor: theme.palette.common.white,
      backgroundColor: 'transparent !important',
      marginTop: '0px !important',
      marginBottom: '0px !important',
      border: 'none',
      '&:not(:last-child)': {
        borderBottom: 0
      },
      '&:before': {
        display: 'none'
      }
    },
    pagination: {
      borderTopStyle: 'none',
      fontWeight: '700',
      borderTop: 'none'
    },
    dividerContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%'
    },
    divider: {
      flexGrow: '3',
      margin: '0px 10px',
      borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
    },
    selectDealer: {
      width: '200px'
    },
    dealerLogo: {
      width: 'auto',
      height: '30px',
      marginRight: '5px'
    },
    dealerLogoContainer: {
      display: 'flex',
      alignItems: 'center'
    }
  });

  const styles = useStyles();

  return (
    <>
      <Box className={styles.headerTextContainer}>
        {vehicles && (
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Typography
              component="p"
              className={styles.headerText}
              sx={{
                textTransform: 'uppercase',
                fontWeight: '700',
                color: theme.palette.info.light
              }}
            >
              {collectionSize} total vehicles found
            </Typography>
            <Box className={styles.controlsContainer}>
              {/* <Typography component="p" className={styles.headerText}>
                Sort By:
              </Typography>
              <Select
                label="Sort By"
                value={order}
                onChange={handleOrderByChange}
                className={styles.inputText}
                inputProps={{ 'aria-label': 'order vehicles' }}
                input={<InputBase />}
              >
                <MenuItem value={'timeDesc'}>Ending Last</MenuItem>
                <MenuItem value={'timeAsc'}>Ending First</MenuItem>
                <MenuItem value={'vehicleName'}>Name</MenuItem>
              </Select> */}
              <TablePagination
                className={styles.pagination}
                count={collectionSize}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
              />
            </Box>
          </Box>
        )}
      </Box>
      {vehicles && (
        <ViewportList
          viewportRef={pinnedRef}
          initialPrerender={5}
          items={sortLiveAuctionVehiclesArray(vehicles)}
        >
          {(vehicle: VehicleInfo, index: number) => {
            let isFirstVehicle = false;
            if (index === 0) {
              isFirstVehicle = true;
            }
            return (
              <Box className={styles.vehicleContainer} key={vehicle.id} id="activeAuctionSell5">
                <VehicleRowCommonSell
                  isFirstVehicle={isFirstVehicle}
                  isLiveAuction={true}
                  vehicle={vehicle}
                  handleExpand={handleExpand}
                  handleFavourite={handleFavourite}
                  handleWatchlist={handleWatchlist}
                  handleOpenDrawer={handleOpenDrawer}
                  mediaQuery={mediaQuery}
                />
              </Box>
            );
          }}
        </ViewportList>
      )}
    </>
  );
});

export default LiveAuctionSell;
