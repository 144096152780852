import { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import TuneIcon from '@mui/icons-material/Tune';
// material
import { Box, Backdrop, Paper, Tooltip, Divider, Typography, Stack } from '@mui/material';
//
import Scrollbar from '../Scrollbar';
import { MIconButton } from '../@material-extend';
import SettingMode from './SettingMode';
// import SettingColor from './SettingColor';
// import SettingStretch from './SettingStretch';
// import SettingDirection from './SettingDirection';
import SettingFullscreen from './SettingFullscreen';
import { useTheme } from '@mui/material/styles';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 260;

export default function Settings() {
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [open]);

  const handleToggle = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Backdrop sx={{ zIndex: 1199 }} open={open} onClick={handleClose} />
      <Box
        sx={{
          top: 12,
          bottom: 12,
          right: 0,
          position: 'fixed',
          zIndex: 1199,
          ...(open && { right: 12 })
        }}
      >
        <Box
          sx={{
            p: 0.5,
            px: '4px',
            mt: -3,
            left: -44,
            // top: '50%',
            top: '27px',
            color: 'grey.800',
            position: 'absolute',
            bgcolor: 'common.white',
            borderRadius: '24px 0 16px 24px',
            boxShadow: (theme) => theme.customShadows.z12
          }}
        >
          <Tooltip title="Settings">
            <MIconButton
              color="inherit"
              onClick={handleToggle}
              sx={{
                p: 0,
                width: 40,
                height: 40,
                transition: (theme) => theme.transitions.create('all'),
                '&:hover': { color: 'primary.main', bgcolor: 'transparent !important' }
              }}
            >
              {open ? <CloseIcon sx={{ fontSize: 20 }} /> : <TuneIcon sx={{ fontSize: 20 }} />}
            </MIconButton>
          </Tooltip>
        </Box>

        <Paper
          sx={{
            height: 1,
            width: '0px',
            overflow: 'hidden',
            boxShadow: (theme) => theme.customShadows.z24,
            transition: (theme) => theme.transitions.create('width'),
            ...(open && { width: DRAWER_WIDTH })
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ py: 2, pr: 1, pl: 2.5 }}
          >
            <Typography variant="subtitle1">Settings</Typography>
            <MIconButton onClick={handleClose}>
              <CloseIcon sx={{ fontSize: 20 }} />
            </MIconButton>
          </Stack>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          <Scrollbar sx={{ height: 1 }}>
            <Stack spacing={4} sx={{ pt: 3, px: 3, pb: 15 }}>
              <Stack spacing={1.5}>
                <Typography variant="subtitle2">Mode</Typography>
                {/* prettier-ignore */}
                <Typography sx={{ fontSize: '12px' }}>
                  This setting will <span style={{ fontWeight: 'bolder' }}>refresh the app</span> in your desired theme
                </Typography>
                <SettingMode />
              </Stack>

              <Stack spacing={1.5}>
                <Typography variant="subtitle2">Screen Size</Typography>
                {/* prettier-ignore */}
                <Typography sx={{ fontSize: '12px' }}>
                  This setting will set the app to fullscreen mode.  Hit escape to leave fullscreen mode at any time
                </Typography>
                <SettingFullscreen />
              </Stack>
              {/* <Stack spacing={1.5}>
                <Typography variant="subtitle2">Direction</Typography>
                <SettingDirection />
              </Stack> */}
              {/* <Stack spacing={1.5}>
                <Typography variant="subtitle2">Color</Typography>
                <SettingColor />
              </Stack> */}
              {/* <Stack spacing={1.5}>
                <Typography variant="subtitle2">Stretch</Typography>
                <SettingStretch />
              </Stack> */}
            </Stack>
          </Scrollbar>
        </Paper>
      </Box>
    </>
  );
}
