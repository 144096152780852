import { Box, Button, Divider, FormHelperText, TextField, Typography } from '@mui/material';
import { VehicleAuctionType } from '../../../../types/vehicleInfo';
import { useEffect, useState } from 'react';
import { GridElement, VehicleDropBox, VehicleCalendar, VehicleTime } from './CommonComponents';
import { collection, CollectionReference, query, Timestamp, where } from 'firebase/firestore';
import { Dealership } from 'types/dealership';
import useAuth from 'hooks/useAuth';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { NumericFormat } from 'react-number-format';
import { useTheme } from '@mui/material/styles';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import moment from 'moment';
import { getMilliSeconds, getSeconds, subtractSeconds } from 'utils/formatAuctionEndTime';
import { User } from 'constants/user';

type teamMember = {
  id: string;
  email: string;
  phone: string;
  name: string;
  signatureUrl?: string;
};

type Location = {
  locationName: string;
  locationAddress: string;
  locationCity: string;
  locationProvince: string;
  locationPostalCode: string;
  locationCountry: string;
  locationPhone?: string;
  locationNotes?: string;
};

type Props = {
  auction: VehicleAuctionType;
  dealership: Dealership;
  openConfirmModal: (isOpen: boolean) => void;
  updVehicleAuc: (equipment: VehicleAuctionType, flag: number) => void;
  curUser?: User;
};

export function VehicleAuction({
  auction,
  updVehicleAuc,
  dealership,
  openConfirmModal,
  curUser
}: Props) {
  const { user, currentUser } = useAuth();
  const theme = useTheme();

  const boxStyle = {
    textAlign: 'start',
    width: 400,
    height: 60,
    backgroundColor: theme.palette.mode !== 'dark' ? theme.palette.common.white : 'unset',
    border: `1px ${theme.palette.divider} solid`,
    borderRadius: '10px',
    fontWeight: 300,
    padding: 0,
    mb: 1,
    overflow: 'hidden'
  };

  const statusArray = ['Launching Soon', 'Active', 'Parked'];
  const captureRepStatusArray = ['Parked', 'Draft'];

  const [auctionInfo, setAuctionInfo] = useState(auction ? auction : ({} as VehicleAuctionType));

  const [locationNames, setLocationNames] = useState<string[]>();
  const [inStockVal, setInStockVal] = useState<boolean>(auction.isInStock === false ? false : true);
  const [currentLocation, setCurrentLocation] = useState<Location | null>();
  const [dateVal, setDateVal] = useState<any>(auction?.dateExpected ? auction.dateExpected : '');
  const [statusVal, setStatusVal] = useState<string>(
    auctionInfo.auctionStatus === 'pre draft'
      ? ''
      : auctionInfo.auctionStatus.toLowerCase() === 'draft'
      ? ''
      : auctionInfo.auctionStatus
  );
  const [dealerRepVal, setDealerRepVal] = useState<any>(
    auction.dealerRep && auction.dealerRep.email !== '' ? auction.dealerRep : ''
  );
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [showValidateError, setShowValidateError] = useState<boolean>(false);

  const [launchDateVal, setLaunchDateVal] = useState<Timestamp | undefined>(
    auctionInfo.launchDate ? auctionInfo.launchDate : undefined
  );
  const [launchTimeVal, setLaunchTimeVal] = useState<Timestamp | undefined>(
    auctionInfo.launchTime ? auctionInfo.launchTime : undefined
  );
  const [endDateVal, setEndDateVal] = useState<Timestamp | undefined>(
    auctionInfo.endDate ? auctionInfo.endDate : undefined
  );
  const [endTimeVal, setEndTimeVal] = useState<Timestamp | undefined>(
    auctionInfo.endTime ? auctionInfo.endTime : undefined
  );

  const [launchDateValid, setLaunchDateValid] = useState<boolean>(
    auctionInfo.launchDate ? true : false
  );
  const [launchTimeValid, setLaunchTimeValid] = useState<boolean>(
    auctionInfo.launchTime ? true : false
  );

  const [endDateValid, setEndDateValid] = useState<boolean>(auctionInfo.endDate ? true : false);
  const [endTimeValid, setEndTimeValid] = useState<boolean>(auctionInfo.endTime ? true : false);

  const [endMinTime, setEndMinTime] = useState<Date>();
  const [launchMinTime, setLaunchMinTime] = useState<Date>();

  const [hasDateChanged, setHasDateChanged] = useState<boolean>(false);

  const [floorPriceFloat, setFloorPriceFloat] = useState<number | undefined>(
    auction.floorPrice ? auction.floorPrice : undefined
  );

  const firestore = useFirestore();
  const usersRef = collection(firestore, 'users') as CollectionReference<teamMember>;
  const userQuery = query(
    usersRef,
    where('currentDealershipId', '==', currentUser.currentDealershipId)
  );
  const { data: users } = useFirestoreCollectionData(userQuery, { idField: 'id' });

  const [teamMembers, setTeamMembers] = useState<teamMember[]>();
  const [teamNames, setTeamNames] = useState<string[]>();
  const [priceVal, setPriceVal] = useState(auction?.floorPrice ? auction.floorPrice : '');
  const [paymentMethods, setPaymentMethods] = useState<any[]>();

  const getDealershipInfo = () => {
    const tempLocations: string[] = [];
    if (dealership.pickupLocations && dealership.pickupLocations.length > 0) {
      dealership.pickupLocations.forEach((location) => {
        tempLocations.push(location.locationName);
      });
    }
    setLocationNames(tempLocations);
    setCurrentLocation(dealership.pickupLocations[0]);
    if (dealership.paymentMethods && dealership.paymentMethods.length > 0) {
      const tempPaymentMethods: any[] = [];
      dealership.paymentMethods.forEach((paymentMethod: any) => {
        tempPaymentMethods.push(paymentMethod);
      });
      setPaymentMethods(tempPaymentMethods);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getDealershipInfo();
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateInputs = () => {
    if (
      auctionInfo.auctionStatus.toLowerCase() === 'draft' ||
      auctionInfo.auctionStatus.toLowerCase() === 'parked'
    ) {
      if (dateVal && statusVal && currentLocation && dealerRepVal && priceVal) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    } else if (auctionInfo.auctionStatus.toLowerCase() === 'launching soon') {
      if (
        dateVal &&
        statusVal &&
        currentLocation &&
        dealerRepVal &&
        priceVal &&
        launchTimeVal &&
        launchDateVal &&
        endTimeVal &&
        endDateVal
      ) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    } else {
      if (
        dateVal &&
        statusVal &&
        currentLocation &&
        dealerRepVal &&
        priceVal &&
        endTimeVal &&
        endDateVal
      ) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }
  };

  useEffect(() => {
    validateInputs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dateVal,
    statusVal,
    currentLocation,
    priceVal,
    dealerRepVal,
    launchTimeVal,
    launchDateVal,
    endDateVal,
    endTimeVal
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
    validateInputs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (users) {
      const tempTeamMembers: teamMember[] = [];
      const tempTeamNames: string[] = [];
      users.forEach((user: any) => {
        const tempTeamMember = {
          name: user.firstName + ' ' + user.lastName,
          id: user.id,
          email: user.email,
          phone: user.phone,
          signatureUrl: user.signatureUrl,
          registration: user.registration ? user.registration : 'No Registration'
        };
        tempTeamMembers.push(tempTeamMember);
        tempTeamNames.push(user.email);
      });
      setTeamMembers(tempTeamMembers);
      setTeamNames(tempTeamNames);
    }
  }, [users]);

  useEffect(() => {
    console.log('is in stock =>', auction.isInStock);
    const tmp = { ...auctionInfo };
    if (auction.isInStock === false) {
      tmp.isInStock = false;
      setInStockVal(false);
    } else {
      tmp.isInStock = true;
      setInStockVal(true);
    }
  }, []);

  useEffect(() => {
    const tmp = { ...auctionInfo };
    if (auction.dateExpected) {
      tmp.dateExpected = auction.dateExpected;
      setDateVal(auction.dateExpected);
    }
    if (!auctionInfo.dealerRep && user) {
      const selectedTeamMember = teamMembers?.find((t: teamMember) => t.id === user?.uid);
      tmp.dealerRep = selectedTeamMember as teamMember;
      tmp.sellerName = selectedTeamMember?.name as string;
      setDealerRepVal(selectedTeamMember);
    }
    if (!auctionInfo.sellerName && user) {
      const selectedTeamMember = teamMembers?.find((t: teamMember) => t.id === user?.uid);
      tmp.sellerName = selectedTeamMember?.name as string;
    }
    // prettier-ignore
    if (dealership && dealership.pickupLocations.length > 0 && !auctionInfo.pickUpLocation && user) {
      const location = dealership.pickupLocations[0];
      const selectedTeamMember = teamMembers?.find((t: teamMember) => t.id === user?.uid);
      tmp.sellerName = selectedTeamMember?.name as string;
      setCurrentLocation(location as Location);
      tmp.pickUpLocation = location as Location;
    }
    setAuctionInfo(tmp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamMembers, user]);

  const handleValidDate = (value: any) => {
    return !isNaN(value) && value instanceof Date && !moment(value).isBefore(new Date(), 'day');
  };

  const handleValidStartDate = (value: any) => {
    if (auctionInfo.endDate) {
      // prettier-ignore
      return !isNaN(value) && value instanceof Date && moment(value).isSameOrBefore(auctionInfo.endDate.toDate(), 'day');
    } else {
      return !isNaN(value) && value instanceof Date && !moment(value).isBefore(new Date(), 'day');
    }
  };

  const handleValidEndDate = (value: any) => {
    if (launchDateVal) {
      // prettier-ignore
      return !isNaN(value) && value instanceof Date && moment(value).isSameOrAfter(launchDateVal.toDate(), 'day');
    } else {
      return !isNaN(value) && value instanceof Date && !moment(value).isBefore(new Date(), 'day');
    }
  };

  const isToday = (date: Date) => {
    const today = new Date();
    /* prettier-ignore */
    return date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();
  };

  const sameDay = (date: Date, nextDate: Date) => {
    /* prettier-ignore */
    return date.getDate() === nextDate.getDate() &&
      date.getMonth() === nextDate.getMonth() &&
      date.getFullYear() === nextDate.getFullYear();
  };

  useEffect(() => {
    if (hasDateChanged) {
      if (launchTimeVal || endTimeVal) {
        setLaunchTimeVal(undefined);
        setEndTimeVal(undefined);
        setLaunchTimeValid(false);
        setEndTimeValid(false);
      }
      if (
        launchDateVal &&
        endDateVal &&
        sameDay(launchDateVal.toDate(), endDateVal.toDate()) &&
        sameDay(launchDateVal.toDate(), new Date())
      ) {
        const launchMinTime = new Date();
        launchMinTime.setTime(launchMinTime.getTime() + 5 * 60 * 1000);
        setLaunchMinTime(launchMinTime);
      }
    }
  }, [endDateVal, launchDateVal]);

  useEffect(() => {
    if (endTimeVal) {
      setEndTimeVal(undefined);
      setEndTimeValid(false);
    }
    if (
      launchDateVal &&
      endDateVal &&
      launchTimeVal &&
      sameDay(launchDateVal.toDate(), endDateVal.toDate()) &&
      sameDay(launchDateVal.toDate(), new Date())
    ) {
      const endMinTime = new Date(launchTimeVal.toDate().getTime() + 20 * 60 * 1000);
      setEndMinTime(endMinTime);
    } else if (
      launchDateVal &&
      endDateVal &&
      launchTimeVal &&
      sameDay(launchDateVal.toDate(), endDateVal.toDate()) &&
      !sameDay(launchDateVal.toDate(), new Date())
    ) {
      const endMinTime = new Date(launchTimeVal.toDate().getTime() + 20 * 60 * 1000);
      setEndMinTime(endMinTime);
    } else if (
      launchDateVal &&
      endDateVal &&
      !sameDay(launchDateVal.toDate(), endDateVal.toDate())
    ) {
      setEndMinTime(undefined);
    }
  }, [launchTimeVal]);

  useEffect(() => {
    if (
      auctionInfo.auctionStatus.toLowerCase() === 'active' ||
      statusVal.toLowerCase() === 'active'
    ) {
      if (endDateVal && sameDay(endDateVal.toDate(), new Date())) {
        setEndMinTime(new Date(new Date().getTime() + 20 * 60 * 1000));
      } else {
        setEndMinTime(undefined);
      }
    }
  }, [endDateVal]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          backgroundColor:
            theme.palette.mode === 'dark'
              ? theme.palette.background.neutral
              : theme.palette.grey[50],
          borderRadius: '10px',
          maxWidth: '850px',
          width: '100%',
          border: '1px solid rgba(145, 158, 171, 0.24)',
          margin: 'auto',
          marginTop: '56px',
          paddingBottom: '20px'
        }}
      >
        <Box sx={{ mt: 3, width: 400 }}>
          <Typography variant="h3" sx={{ textAlign: 'center', color: theme.palette.text.primary }}>
            Auction Details
          </Typography>

          <Box sx={boxStyle}>
            <NumericFormat
              sx={{
                mt: 1,
                ml: 2,
                color: 'black',
                width: '85%'
              }}
              inputProps={{ style: { fontSize: '12px', fontWeight: '600' } }}
              InputLabelProps={{ style: { fontSize: '12px', color: 'grey' } }}
              variant="standard"
              id="outlined-required"
              label="Floor Price (must end with 00)"
              defaultValue={priceVal}
              prefix="$"
              decimalScale={0}
              allowNegative={false}
              thousandSeparator={true}
              fullWidth
              customInput={TextField}
              onValueChange={(values) => {
                if (values.floatValue?.toString().endsWith('00')) {
                  setFloorPriceFloat(values.floatValue);
                  setPriceVal(values.value);
                } else {
                  setPriceVal('');
                  setFloorPriceFloat(undefined);
                }
              }}
            />
          </Box>

          {(!priceVal || priceVal === '') && showValidateError && (
            <FormHelperText sx={{ ml: 1, mb: 2 }} error={Boolean(!priceVal || priceVal === '')}>
              You must enter a floor price and it must end with 00.
            </FormHelperText>
          )}
          <Box sx={{ mt: 3, width: 400 }}>
            <GridElement
              isDateExpected={true}
              caption={'Is this vehicle In-Stock?'}
              dropBoxValue={inStockVal ? 'Yes' : 'No'}
              arrayOfItems={['Yes', 'No']}
              updateDropValue={(value) => {
                const tmp = { ...auctionInfo };
                tmp.isInStock = value === 'Yes' ? true : false;
                setInStockVal(tmp.isInStock);
                if (value === 'Yes') {
                  tmp.dateExpected = Timestamp.now();
                  setDateVal(tmp.dateExpected);
                } else {
                  setDateVal('');
                }
                setAuctionInfo(tmp);
              }}
            />
          </Box>
          {auctionInfo.isInStock === false && (
            <>
              <Box sx={{ ...boxStyle, mt: 1 }}>
                <VehicleCalendar
                  dropBoxValue={auctionInfo?.dateExpected?.toDate()}
                  caption={'Date Expected'}
                  updateDropValue={(v) => {
                    if (!v) return;
                    const tmp = { ...auctionInfo };
                    tmp.dateExpected = Timestamp.fromDate(v);
                    setAuctionInfo(tmp);
                    setDateVal(tmp.dateExpected);
                  }}
                />
              </Box>
              {(!dateVal || dateVal === '') && showValidateError && (
                <FormHelperText sx={{ ml: 1, mb: 2 }} error={Boolean(!dateVal || dateVal === '')}>
                  You must enter an expected date.
                </FormHelperText>
              )}
            </>
          )}
          <Divider
            sx={{
              mt: 0.5,
              mb: 0.5,
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          <Box sx={{ mt: 3, mb: 1, width: 400 }}>
            <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
              DEALER REPRESENTATIVE
            </Typography>
          </Box>
          {teamNames && (
            <>
              <Box sx={boxStyle}>
                {auctionInfo && (
                  <VehicleDropBox
                    dropBoxValue={
                      auctionInfo.dealerRep && auctionInfo.dealerRep.email !== ''
                        ? auctionInfo.dealerRep.email
                        : dealerRepVal
                        ? dealerRepVal.email
                        : ''
                    }
                    caption={'Representative'}
                    arrayOfItems={teamNames as string[]}
                    updateDropValue={(v) => {
                      if (!v) return;
                      const selectedTeamMember = teamMembers?.find(
                        (t: teamMember) => t.email === v
                      );
                      const tmp = { ...auctionInfo };
                      tmp.dealerRep = selectedTeamMember as teamMember;
                      tmp.sellerName = selectedTeamMember?.name as string;
                      setAuctionInfo(tmp);
                      setDealerRepVal(selectedTeamMember);
                    }}
                  />
                )}
              </Box>
              {(!dealerRepVal || dealerRepVal === '') && showValidateError && (
                <FormHelperText
                  sx={{ ml: 1, mb: 2 }}
                  error={Boolean(!dealerRepVal || dealerRepVal === '')}
                >
                  You must enter a dealer representative.
                </FormHelperText>
              )}
            </>
          )}
          <Box sx={{ mt: 3, mb: 1, width: 400 }}>
            <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
              PICK UP LOCATION
            </Typography>
          </Box>
          <Box sx={boxStyle}>
            {locationNames && (
              <VehicleDropBox
                dropBoxValue={currentLocation ? currentLocation?.locationName : ''}
                caption={'Pick up Location'}
                arrayOfItems={locationNames as string[]}
                updateDropValue={(v) => {
                  if (!v) return;
                  const tmp = { ...auctionInfo };
                  const location = dealership.pickupLocations.find(
                    (loc) => loc.locationName.toLowerCase().trim() === v.toLowerCase().trim()
                  );
                  setCurrentLocation(location as Location);
                  tmp.pickUpLocation = location as Location;
                  setAuctionInfo(tmp);
                }}
              />
            )}
          </Box>
          {!currentLocation && showValidateError && (
            <FormHelperText sx={{ ml: 1, mb: 2 }} error={Boolean(!currentLocation)}>
              You must enter a pickup location.
            </FormHelperText>
          )}
          {currentLocation?.locationName && (
            <Typography
              sx={{
                fontSize: '12px',
                color: 'grey',
                fontWeight: 500,
                whiteSpace: 'pre-line',
                lineHeight: '10px'
              }}
            >
              {`${currentLocation.locationName}\n
            ${currentLocation.locationAddress}\n 
            ${currentLocation.locationCity}\n
            ${currentLocation.locationPostalCode}\n
            ${currentLocation.locationCountry}`}
            </Typography>
          )}
          <Box sx={{ mt: 3, mb: 1, width: 400 }}>
            <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
              AUCTION STATUS
            </Typography>
          </Box>
          <Box sx={boxStyle}>
            <VehicleDropBox
              dropBoxValue={statusVal}
              caption={'Status'}
              arrayOfItems={['Parked']}
              // arrayOfItems={
              //   curUser && curUser.currentDealershipRole.toLowerCase() === 'capturerep'
              //     ? captureRepStatusArray
              //     : statusArray
              // }
              updateDropValue={(v) => {
                if (!v) return;
                const tmp = { ...auctionInfo };
                tmp.auctionStatus = v.toLowerCase() as string;
                setAuctionInfo(tmp);
                setStatusVal(tmp.auctionStatus);
                setLaunchDateVal(undefined);
                setLaunchDateValid(false);
                setEndDateVal(undefined);
                setEndDateValid(false);
                setLaunchTimeVal(undefined);
                setLaunchTimeValid(false);
                setEndTimeValid(false);
                setEndTimeVal(undefined);
              }}
            />
          </Box>
          {(!statusVal || statusVal === '') && showValidateError && (
            <FormHelperText sx={{ ml: 1, mb: 2 }} error={Boolean(!statusVal || statusVal === '')}>
              You must enter a status.
            </FormHelperText>
          )}
          {(auctionInfo.auctionStatus.toLowerCase() === 'launching soon' ||
            auctionInfo.auctionStatus.toLowerCase() === 'active') && (
            <>
              <Box sx={{ mt: 3, mb: 1, width: 400 }}>
                <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
                  {auctionInfo.auctionStatus.toLowerCase() === 'launching soon'
                    ? 'Active Auction Date (Set Dates Before Times)'
                    : 'Active Auction Date (Set Date First)'}
                </Typography>
              </Box>
              {auctionInfo.auctionStatus.toLowerCase() === 'launching soon' && (
                <>
                  <Box sx={{ mb: 2 }}>
                    <VehicleCalendar
                      auctionMinDate={new Date()}
                      auctionMaxDate={endDateVal ? endDateVal.toDate() : undefined}
                      dropBoxValue={launchDateVal?.toDate() || ''}
                      caption={'Auction Start Date'}
                      updateDropValue={(v) => {
                        if (!v) {
                          setLaunchDateVal(undefined);
                          setLaunchDateValid(false);
                        } else {
                          const tmp = { ...auctionInfo };
                          const isValid = handleValidStartDate(v);
                          if (isValid) {
                            setHasDateChanged(true);
                            tmp.launchDate = Timestamp.fromDate(v);
                            setAuctionInfo(tmp);
                            setLaunchDateVal(tmp.launchDate);
                            setLaunchDateValid(true);
                          } else {
                            setLaunchDateVal(undefined);
                            setLaunchDateValid(false);
                          }
                        }
                      }}
                    />
                  </Box>
                  {!launchDateValid && showValidateError && (
                    <FormHelperText sx={{ ml: 1, mb: 2 }} error={Boolean(!launchDateValid)}>
                      Enter a valid date format of mm/dd/yyyy and cannot be in the past or after
                      &nbsp;end date.
                    </FormHelperText>
                  )}
                </>
              )}
              <Box sx={{ mb: 2 }}>
                <VehicleCalendar
                  dropBoxValue={endDateVal?.toDate() || ''}
                  auctionMinDate={launchDateVal ? launchDateVal.toDate() : undefined}
                  caption={'Auction End Date'}
                  updateDropValue={(v) => {
                    if (!v) {
                      setEndDateVal(undefined);
                      setEndDateValid(false);
                    } else {
                      const tmp = { ...auctionInfo };
                      const isValid = handleValidEndDate(v);
                      if (isValid) {
                        setHasDateChanged(true);
                        tmp.endDate = Timestamp.fromDate(v);
                        setAuctionInfo(tmp);
                        setEndDateVal(tmp.endDate);
                        setEndDateValid(true);
                      } else {
                        setEndDateVal(undefined);
                        setEndDateValid(false);
                      }
                    }
                  }}
                />
              </Box>
              {!endDateValid && showValidateError && (
                <FormHelperText sx={{ ml: 1, mb: 2 }} error={Boolean(!endDateValid)}>
                  {/* prettier-ignore */}
                  Enter a valid date format of mm/dd/yyyy and cannot be in the past or before&nbsp;
                  start date.
                </FormHelperText>
              )}
            </>
          )}
          {(auctionInfo.auctionStatus.toLowerCase() === 'launching soon' ||
            auctionInfo.auctionStatus.toLowerCase() === 'active') && (
            <>
              <Box sx={{ mt: 3, mb: 1, width: 400 }}>
                <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
                  {auctionInfo.auctionStatus.toLowerCase() === 'launching soon'
                    ? 'Active Auction Time (Set Start Time First)'
                    : 'Active Auction Time'}
                </Typography>
              </Box>
              {auctionInfo.auctionStatus.toLowerCase() === 'launching soon' && (
                <>
                  <Box sx={{ mb: 2 }}>
                    {/* prettier-ignore */}
                    <VehicleTime
                      // auctionMinTime={launchMinTime ? launchMinTime as Date : undefined}
                      // dropBoxValue={auctionInfo?.launchTime?.toDate() || ''}
                      auctionMinTime={launchDateVal && launchDateVal > Timestamp.now() ? undefined :launchMinTime as Date }
                      dropBoxValue={launchTimeVal?.toDate() || ''}
                      isDisabled={!launchDateVal || !endDateVal}
                      caption={'Auction Start Time'}
                      updateDropValue={(v) => {
                        if (!v) {
                          setLaunchTimeVal(undefined);
                          setLaunchTimeValid(false);
                        } else {
                          const tmp = { ...auctionInfo };
                          const isValid = endDateVal && endDateVal > Timestamp.now() ? true : handleValidDate(v);
                          if (isValid) {
                            setLaunchTimeValid(true);
                            tmp.launchTime = Timestamp.fromDate(v);
                            setAuctionInfo(tmp);
                            setLaunchTimeVal(tmp.launchTime);
                          } else {
                            setLaunchTimeVal(undefined);
                            setLaunchTimeValid(false);
                          }
                        }
                      }}
                    />
                  </Box>
                  {!launchTimeValid && showValidateError && (
                    <FormHelperText sx={{ ml: 1, mb: 2 }} error={Boolean(!launchTimeValid)}>
                      Enter a valid time format is hh:mm (a/p)m.
                    </FormHelperText>
                  )}
                </>
              )}
              <Box sx={{ mb: 2 }}>
                {/* prettier-ignore */}
                <VehicleTime
                  auctionMinTime={endMinTime ? endMinTime as Date : undefined}
                  // dropBoxValue={auctionInfo?.endTime?.toDate() || ''}
                  dropBoxValue={endTimeVal?.toDate() || ''}
                  isDisabled={
                    auctionInfo.auctionStatus.toLowerCase() === 'launching soon'
                      ? (!launchDateVal || !endDateVal || !launchTimeVal)
                      : !endDateVal}
                  caption={'Auction End Time'}
                  updateDropValue={(v) => {
                    if (!v) {
                      setEndTimeValid(false);
                      setEndTimeVal(undefined);
                    } else {
                      const tmp = { ...auctionInfo };
                      const isValid = handleValidDate(v);
                      if (isValid) {
                        setEndTimeValid(true);
                        tmp.endTime = Timestamp.fromDate(v);
                        setAuctionInfo(tmp);
                        setEndTimeVal(tmp.endTime);
                      } else {
                        setEndTimeValid(false);
                        setEndTimeVal(undefined);
                      }
                    }
                  }}
                />
              </Box>
              {!endTimeValid && showValidateError && (
                <FormHelperText sx={{ ml: 1, mb: 2 }} error={Boolean(!endTimeValid)}>
                  Enter a valid time format of hh:mm (a/p)m
                </FormHelperText>
              )}
            </>
          )}
        </Box>
      </Box>
      {(!priceVal || priceVal === '') && showValidateError && (
        <Box
          sx={{
            background: theme.palette.error.lighter,
            padding: '5px 10px',
            width: '100%',
            maxWidth: '850px',
            margin: 'auto',
            borderRadius: '10px',
            border: `1px solid ${theme.palette.divider}`,
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <WarningAmberOutlinedIcon sx={{ color: theme.palette.error.main }} />
          <FormHelperText
            sx={{ ml: 1, mb: 1, color: `${theme.palette.common.black} !important` }}
            error={Boolean(!priceVal || priceVal === '')}
          >
            You must enter a floor price and it must end with 00.
          </FormHelperText>
        </Box>
      )}
      {(!paymentMethods || paymentMethods.length < 1) && showValidateError && (
        <Box
          sx={{
            background: theme.palette.error.lighter,
            padding: '5px 10px',
            width: '100%',
            maxWidth: '850px',
            margin: 'auto',
            borderRadius: '10px',
            border: `1px solid ${theme.palette.divider}`,
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <WarningAmberOutlinedIcon sx={{ color: theme.palette.error.main }} />
          {/* prettier-ignore */}
          <FormHelperText
            sx={{ ml: 1, mb: 1, color: `${theme.palette.common.black} !important` }}
            error={Boolean(!paymentMethods)}
          >
            No payment method available. Add a payment method in Account Management under Billing Profile to continue.
          </FormHelperText>
        </Box>
      )}
      <Divider
        sx={{
          mt: 2,
          borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
        }}
      />
      <Box sx={{ m: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Button variant="text" color="inherit" onClick={() => updVehicleAuc(auctionInfo, 5)}>
          Back
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            const tmp = { ...auctionInfo };
            console.log('tmp vehicle =>', tmp);
            tmp.floorPrice = floorPriceFloat as number;
            tmp.liveAuctionStatus = 'Inactive';
            tmp.isLiveAuctionActive = false;
            if (auctionInfo.auctionStatus.toLowerCase() === 'active') {
              if (isDisabled || !endTimeValid) {
                setShowValidateError(true);
              } else {
                if (paymentMethods && paymentMethods.length > 0) {
                  const modifiedEndTime = subtractSeconds(
                    tmp.endTime.toDate(),
                    getSeconds(tmp.endTime.toDate()),
                    getMilliSeconds(tmp.endTime.toDate())
                  );
                  tmp.endTime = Timestamp.fromDate(modifiedEndTime);
                  updVehicleAuc(tmp, 6);
                  openConfirmModal(true);
                } else {
                  setShowValidateError(true);
                }
              }
            } else if (auctionInfo.auctionStatus.toLowerCase() === 'launching soon') {
              if (isDisabled || !endTimeValid || !launchTimeValid) {
                setShowValidateError(true);
              } else {
                if (paymentMethods && paymentMethods.length > 0) {
                  const modifiedLaunchTime = subtractSeconds(
                    tmp.launchTime.toDate(),
                    getSeconds(tmp.launchTime.toDate()),
                    getMilliSeconds(tmp.launchTime.toDate())
                  );
                  const modifiedEndTime = subtractSeconds(
                    tmp.endTime.toDate(),
                    getSeconds(tmp.endTime.toDate()),
                    getMilliSeconds(tmp.endTime.toDate())
                  );
                  tmp.launchTime = Timestamp.fromDate(modifiedLaunchTime);
                  tmp.endTime = Timestamp.fromDate(modifiedEndTime);
                  updVehicleAuc(tmp, 6);
                  openConfirmModal(true);
                } else {
                  setShowValidateError(true);
                }
              }
            } else {
              console.log('is disabled =>', isDisabled);
              if (isDisabled) {
                setShowValidateError(true);
              } else {
                updVehicleAuc(tmp, 6);
                openConfirmModal(true);
              }
            }
          }}
        >
          Complete Vehicle Capture
        </Button>
      </Box>
    </>
  );
}
