import {
  Container,
  Box,
  useTheme,
  InputBase,
  Paper,
  Typography,
  MenuItem,
  Select,
  SelectChangeEvent,
  TablePagination,
  Tabs,
  Tab,
  Divider,
  Alert,
  AlertTitle,
  Grid,
  Button
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from '@mui/styles';
import Page from '../../../components/Page';
import React, { useEffect, useRef, useState } from 'react';
import VehicleGallery from 'components/shared/VehicleGallery';
import VehicleActionsBuy from 'components/buyer/auctions/VehicleActionsBuy';
import VehicleDetails from 'components/shared/VehicleDetails';
import useSettings from 'hooks/useSettings';
import VehicleSummary from 'components/shared/VehicleSummary';
import { DebounceInput } from 'react-debounce-input';
import AuctionDrawer from 'components/auction/drawer/AuctionDrawer';
import { styled } from '@mui/material/styles';
import { VehicleInfo } from 'types/vehicleInfo';
import {
  doc,
  DocumentReference,
  getDoc,
  onSnapshot,
  orderBy,
  limit,
  updateDoc,
  where,
  arrayRemove,
  arrayUnion,
  CollectionReference,
  collection,
  query,
  OrderByDirection
} from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import useAuth from 'hooks/useAuth';
import Tour from 'reactour';
import { User } from 'constants/user';
import { useFirestoreByPath } from 'hooks/useFirestoreByPath';
import EmptyStateMessage from 'components/shared/EmptyStateMessage';
import { Dealership } from 'types/dealership';
import { sortPreAuctionVehiclesArray } from 'utils/searchHelper';
import { ViewportList } from 'react-viewport-list';
import { useNavigate } from 'react-router-dom';
import { useVehicleBatch } from 'utils/vehicleQuery';
import { useMemo } from 'react';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface StyledTabsProps {
  id?: string;
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`activeAuction-tabpanel-${index}`}
      aria-labelledby={`activeAuction-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `activeAuction-tab-${index}`,
    'aria-controls': `activeAuction-tabpanel-${index}`
  };
};

export default function PreAuctionPageBuy() {
  const theme = useTheme();
  const firestore = useFirestore();
  const { currentUser, user, logout } = useAuth();
  const { themeStretch } = useSettings();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [drawerTab, setDrawerTab] = useState<number>(0);
  const [vehicle, setVehicle] = useState<VehicleInfo>();
  const [tabValue, setTabValue] = useState(0);
  const [mediaQuery] = useState(1450);

  const numberOfRows = 25;

  const [order, setOrder] = useState('timeAsc');
  const [queryOrder, setQueryOrder] = useState<OrderByDirection>('asc');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(numberOfRows);
  const [collectionSize, setCollectionSize] = useState<number>(0);

  const countVehicleCollection = () => {
    const vehicleRef = collection(firestore, 'vehicles') as CollectionReference;
    const vehicleQuery = query(
      vehicleRef,
      where('networkDealerships', 'array-contains', currentUser.currentDealershipId),
      where('auction.auctionStatus', 'in', ['launching soon', 'Launching Soon'])
    );
    const unsubsribe = onSnapshot(vehicleQuery, (querySnapshot) => {
      setCollectionSize(querySnapshot.size);
    });
    return unsubsribe;
  };

  useEffect(() => {
    const unsubscribe = countVehicleCollection();
    return () => {
      unsubscribe();
    };
  }, []);

  const queryConstraints = [
    where('networkDealerships', 'array-contains', currentUser.currentDealershipId),
    where('auction.auctionStatus', 'in', ['launching soon', 'Launching Soon']),
    orderBy('auction.launchDate', queryOrder),
    limit(rowsPerPage)
  ];

  const [lastDocArray, setLastDocArray] = useState<any[]>([]);
  const [firstDocArray, setFirstDocArray] = useState<any[]>([]);
  const [backPage, setBackPage] = useState<boolean>(false);
  const [lastDataVehicleRef, setLastDataVehicleRef] = useState<DocumentReference>();

  const { data } = useVehicleBatch(
    page,
    queryConstraints,
    lastDocArray[page - 1],
    backPage,
    firstDocArray[page]
  );

  const vehicles = useMemo(() => {
    const lastDoc = data?.docs[data?.docs?.length - 1];
    if (lastDoc) {
      const lastDocRef = data?.docs[data?.docs?.length - 1].ref as DocumentReference;
      setLastDataVehicleRef(lastDocRef);
    }
    // prettier-ignore
    return data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id }));
  }, [data]);

  const [vehicleEmptyState, setVehicleEmptyState] = useState<boolean>(false);
  const [autobidEmptyState, setAutobidEmptyState] = useState<boolean>(false);
  const [watchlistEmptyState, setWatchlistEmptyState] = useState<boolean>(false);
  const [liveAuctionEmptyState, setLiveAuctionEmptyState] = useState<boolean>(false);

  const { update: updateUser } = useFirestoreByPath<User>('users');

  const [currentDealership, setCurrentDealership] = useState<Dealership>();
  const [currentVehicle, setCurrentVehicle] = useState<VehicleInfo>();

  const getCurrentDealership = async () => {
    const userDealershipRef = doc(firestore, `dealerships/${currentUser.currentDealershipId}`);
    const userDealerSnap = await getDoc(userDealershipRef);
    if (userDealerSnap.exists()) {
      const data = userDealerSnap.data();
      setCurrentDealership({ ...data, id: userDealerSnap.id } as Dealership);
    }
  };

  const [userDeleted, setUserDeleted] = useState<boolean>(false);
  const [curUser, setCurUser] = useState<User>();

  const vehiclesViewRef = useRef(null);
  const autobidViewRef = useRef(null);
  const watchlistViewRef = useRef(null);
  const liveAuctionViewRef = useRef(null);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      snapshotUser();
      getCurrentDealership();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const handleCloseTour = async () => {
    await updateUser(user?.uid as string, { showPreAuctionBuyTour: false });
    setTourOpen(false);
  };

  const [tourOpen, setTourOpen] = useState(false);

  const steps = [
    {
      selector: '',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Buy: Pre-Auction</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              Pre Auction displays all vehicles that are in a 'Launching Soon' status.  This is where cars will be displayed before they run in Active Auction. These vehicles belong to only those networks you have requested to buy from, or been invited to buy from.
            </Typography>
          </Box>
        </Box>
      )
    },
    {
      selector: '#preAuctionBuy2',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Buy: Pre-Auction</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              Tabs provide quick sort options if the buyer would like to follow only units where they have placed Autobids or in watchlist.
            </Typography>
          </Box>
        </Box>
      )
    },
    {
      selector: '#preAuctionBuy3',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Buy: Pre-Auction</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              Click here to add vehicles to your watchlist and follow them through Pre-Auction to Active Auctions.
            </Typography>
          </Box>
        </Box>
      )
    },
    {
      selector: '#preAuctionBuy4',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Buy: Pre-Auction</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              Click here to set an Autobid. Autobids are editable at any time in both Pre Auction and Active Auction.
            </Typography>
          </Box>
        </Box>
      )
    },
    {
      selector: '#preAuctionBuy5',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Buy: Pre-Auction</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              Click these individual icons to access information drawers for all car details. Important vehicle information such as equipment, damages, and disclosure will be found here.
            </Typography>
          </Box>
        </Box>
      )
    }
  ];

  const handleWatchlist = async (id: string, isWatchlist: boolean) => {
    const docRef = doc(firestore, `vehicles/${id}`);
    if (isWatchlist) {
      await updateDoc(docRef, { 'auction.isWatchlist': arrayRemove(user?.uid) });
    } else {
      await updateDoc(docRef, { 'auction.isWatchlist': arrayUnion(user?.uid) });
    }
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleToggleDrawer = (value: number, vehicle: VehicleInfo) => {
    setCurrentVehicle(vehicle);
    setDrawerTab(value);
    setVehicle(vehicle);
    setOpenDrawer(!openDrawer);
  };

  // ALL
  //---------------------------------------------------------------------------------------
  const handleChangePage = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      const firstDoc = data?.docs[0];
      const firstDocRef = firstDoc.ref as DocumentReference;
      const docRef = doc(firestore, `vehicles/${firstDocRef.id}`);
      const docSnap = await getDoc(docRef);
      const tempFirstDocs = firstDocArray;
      if (docSnap.exists()) {
        tempFirstDocs.push(docSnap);
        setFirstDocArray(tempFirstDocs);
      }
      if (lastDataVehicleRef) {
        const docRef = doc(firestore, `vehicles/${lastDataVehicleRef.id}`);
        const docSnap = await getDoc(docRef);
        const tempLastDocs = lastDocArray;
        if (docSnap.exists()) {
          tempLastDocs.push(docSnap);
          setLastDocArray(tempLastDocs);
          setPage(newPage);
          setBackPage(false);
        }
      }
    } else {
      setPage(newPage);
      setBackPage(true);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFirstDocArray([]);
    setLastDocArray([]);
    setBackPage(false);
  };

  const handleOrderByChange = (event: SelectChangeEvent) => {
    setOrder(event.target.value);
    if (event.target.value === 'timeAsc') {
      setQueryOrder('asc');
    } else {
      setQueryOrder('desc');
    }
    setPage(0);
    setFirstDocArray([]);
    setLastDocArray([]);
    setBackPage(false);
  };

  // AUTOBID
  //---------------------------------------------------------------------------------------
  const [orderAutobid, setOrderAutobid] = useState('timeAsc');
  const [pageAutobid, setPageAutobid] = useState(0);
  const [rowsPerPageAutobid, setRowsPerPageAutobid] = useState(numberOfRows);
  const [collectionSizeAutobid, setCollectionSizeAutobid] = useState<number>(0);
  const [queryOrderAutobid, setQueryOrderAutobid] = useState<OrderByDirection>('asc');

  const countVehicleCollectionAutobid = () => {
    const vehicleRef = collection(firestore, 'vehicles') as CollectionReference;
    const vehicleQuery = query(
      vehicleRef,
      where('auction.auctionStatus', 'in', ['launching soon', 'Launching Soon']),
      where('auction.isAutobid', 'array-contains', user?.uid)
    );
    const unsubsribe = onSnapshot(vehicleQuery, (querySnapshot) => {
      setCollectionSizeAutobid(querySnapshot.size);
    });
    return unsubsribe;
  };

  useEffect(() => {
    const unsubscribe = countVehicleCollectionAutobid();
    return () => {
      unsubscribe();
    };
  }, []);

  const queryConstraintsAutobid = [
    where('auction.isAutobid', 'array-contains', user?.uid),
    where('auction.auctionStatus', 'in', ['launching soon', 'Launching Soon']),
    orderBy('auction.launchDate', queryOrderAutobid),
    limit(rowsPerPageAutobid)
  ];

  const [lastDocArrayAutobid, setLastDocArrayAutobid] = useState<any[]>([]);
  const [firstDocArrayAutobid, setFirstDocArrayAutobid] = useState<any[]>([]);
  const [backPageAutobid, setBackPageAutobid] = useState<boolean>(false);
  const [lastDataVehicleRefAutobid, setLastDataVehicleRefAutobid] = useState<DocumentReference>();

  const autobidData = useVehicleBatch(
    pageAutobid,
    queryConstraintsAutobid,
    lastDocArrayAutobid[pageAutobid - 1],
    backPageAutobid,
    firstDocArrayAutobid[pageAutobid]
  );

  const autobidVehicles = useMemo(() => {
    const lastDoc = autobidData?.data?.docs[autobidData?.data?.docs?.length - 1];
    if (lastDoc) {
      // prettier-ignore
      const lastDocRef = autobidData?.data?.docs[autobidData?.data?.docs?.length - 1].ref as DocumentReference;
      setLastDataVehicleRefAutobid(lastDocRef);
    }
    // prettier-ignore
    return autobidData?.data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id }));
  }, [autobidData.data]);

  const handleChangePageAutobid = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > pageAutobid) {
      const firstDoc = autobidData?.data?.docs[0];
      const firstDocRef = firstDoc.ref as DocumentReference;
      const docRef = doc(firestore, `vehicles/${firstDocRef.id}`);
      const docSnap = await getDoc(docRef);
      const tempFirstDocs = firstDocArrayAutobid;
      if (docSnap.exists()) {
        tempFirstDocs.push(docSnap);
        setFirstDocArrayAutobid(tempFirstDocs);
      }
      if (lastDataVehicleRefAutobid) {
        const docRef = doc(firestore, `vehicles/${lastDataVehicleRefAutobid.id}`);
        const docSnap = await getDoc(docRef);
        const tempLastDocs = lastDocArrayAutobid;
        if (docSnap.exists()) {
          tempLastDocs.push(docSnap);
          setLastDocArrayAutobid(tempLastDocs);
          setPageAutobid(newPage);
          setBackPageAutobid(false);
        }
      }
    } else {
      setPageAutobid(newPage);
      setBackPageAutobid(true);
    }
  };

  const handleChangeRowsPerPageAutobid = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPageAutobid(parseInt(event.target.value, 10));
    setPageAutobid(0);
    setFirstDocArrayAutobid([]);
    setLastDocArrayAutobid([]);
    setBackPageAutobid(false);
  };

  const handleOrderByChangeAutobid = (event: SelectChangeEvent) => {
    setOrderAutobid(event.target.value);
    if (event.target.value === 'timeAsc') {
      setQueryOrderAutobid('asc');
    } else {
      setQueryOrderAutobid('desc');
    }
    setPageAutobid(0);
    setFirstDocArrayAutobid([]);
    setLastDocArrayAutobid([]);
    setBackPageAutobid(false);
  };

  // WATCHLIST
  //---------------------------------------------------------------------------------------
  const [orderWatchlist, setOrderWatchlist] = useState('timeAsc');
  const [pageWatchlist, setPageWatchlist] = useState(0);
  const [rowsPerPageWatchlist, setRowsPerPageWatchlist] = useState(numberOfRows);
  const [lastDocArrayWatchlist, setLastDocArrayWatchlist] = useState<any[]>([]);
  const [firstDocArrayWatchlist, setFirstDocArrayWatchlist] = useState<any[]>([]);
  const [backPageWatchlist, setBackPageWatchlist] = useState<boolean>(false);
  // prettier-ignore
  const [lastDataVehicleRefWatchlist, setLastDataVehicleRefWatchlist] = useState<DocumentReference>();
  const [collectionSizeWatchlist, setCollectionSizeWatchlist] = useState<number>(0);
  const [queryOrderWatchlist, setQueryOrderWatchlist] = useState<OrderByDirection>('asc');

  const queryConstraintsWatchlist = [
    where('auction.auctionStatus', 'in', ['launching soon', 'Launching Soon']),
    where('auction.isWatchlist', 'array-contains', user?.uid),
    orderBy('auction.launchDate', queryOrderWatchlist),
    limit(rowsPerPageWatchlist)
  ];

  const watchlistData = useVehicleBatch(
    pageWatchlist,
    queryConstraintsWatchlist,
    lastDocArrayWatchlist[pageWatchlist - 1],
    backPageWatchlist,
    firstDocArrayWatchlist[pageWatchlist]
  );

  const watchlistVehicles = useMemo(() => {
    const lastDoc = watchlistData?.data?.docs[watchlistData?.data?.docs?.length - 1];
    if (lastDoc) {
      // prettier-ignore
      const lastDocRef = watchlistData?.data?.docs[watchlistData?.data?.docs?.length - 1].ref as DocumentReference;
      setLastDataVehicleRefWatchlist(lastDocRef);
    }
    // prettier-ignore
    return watchlistData?.data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id }));
  }, [watchlistData.data]);

  const countVehicleCollectionWatchlist = () => {
    const vehicleRef = collection(firestore, 'vehicles') as CollectionReference;
    const vehicleQuery = query(
      vehicleRef,
      where('auction.auctionStatus', 'in', ['launching soon', 'Launching Soon']),
      where('auction.isWatchlist', 'array-contains', user?.uid)
    );
    const unsubsribe = onSnapshot(vehicleQuery, (querySnapshot) => {
      setCollectionSizeWatchlist(querySnapshot.size);
    });
    return unsubsribe;
  };

  useEffect(() => {
    const unsubscribe = countVehicleCollectionWatchlist();
    return () => {
      unsubscribe();
    };
  }, []);

  const handleChangePageWatchlist = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > pageWatchlist) {
      const firstDoc = watchlistData?.data?.docs[0];
      const firstDocRef = firstDoc.ref as DocumentReference;
      const docRef = doc(firestore, `vehicles/${firstDocRef.id}`);
      const docSnap = await getDoc(docRef);
      const tempFirstDocs = firstDocArrayWatchlist;
      if (docSnap.exists()) {
        tempFirstDocs.push(docSnap);
        setFirstDocArrayWatchlist(tempFirstDocs);
      }
      if (lastDataVehicleRefWatchlist) {
        const docRef = doc(firestore, `vehicles/${lastDataVehicleRefWatchlist.id}`);
        const docSnap = await getDoc(docRef);
        const tempLastDocs = lastDocArrayWatchlist;
        if (docSnap.exists()) {
          tempLastDocs.push(docSnap);
          setLastDocArrayWatchlist(tempLastDocs);
          setPageWatchlist(newPage);
          setBackPageWatchlist(false);
        }
      }
    } else {
      setPageWatchlist(newPage);
      setBackPageWatchlist(true);
    }
  };

  const handleChangeRowsPerPageWatchlist = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPageWatchlist(parseInt(event.target.value, 10));
    setPageWatchlist(0);
    setFirstDocArrayWatchlist([]);
    setLastDocArrayWatchlist([]);
    setBackPageWatchlist(false);
  };

  const handleOrderByChangeWatchlist = (event: SelectChangeEvent) => {
    setOrderWatchlist(event.target.value);
    if (event.target.value === 'timeAsc') {
      setQueryOrderWatchlist('asc');
    } else {
      setQueryOrderWatchlist('desc');
    }
    setPageWatchlist(0);
    setFirstDocArrayWatchlist([]);
    setLastDocArrayWatchlist([]);
    setBackPageWatchlist(false);
  };

  useEffect(() => {
    if (!vehicles || vehicles.length < 1) {
      setVehicleEmptyState(true);
    } else {
      setVehicleEmptyState(false);
    }
    if (!autobidVehicles || autobidVehicles.length < 1) {
      setAutobidEmptyState(true);
    } else {
      setAutobidEmptyState(false);
    }
    if (!watchlistVehicles || watchlistVehicles.length < 1) {
      setWatchlistEmptyState(true);
    } else {
      setWatchlistEmptyState(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicles, autobidVehicles, watchlistVehicles]);

  const StyledTabs = styled((props: StyledTabsProps) => (
    <Tabs
      {...props}
      variant="scrollable"
      sx={{
        minHeight: 'unset !important'
      }}
      // scrollButtons
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />
      }}
    />
  ))(({ theme }) => ({
    '& .MuiTab-root': {
      color:
        theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
      backgroundColor:
        theme.palette.mode === 'dark'
          ? theme.palette.background.neutral
          : theme.palette.background.paper
    },
    '& .MuiTab-root:not(:last-of-type)': {
      marginRight: '10px'
    },
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent !important',
      padding: '5px'
    },
    '& .Mui-selected': {
      backgroundColor: theme.palette.primary.lighter,
      color: `${theme.palette.primary.main} !important`
    },
    '& .MuiTabs-indicatorSpan': {
      display: 'none',
      maxWidth: 40,
      width: '100%',
      backgroundColor: theme.palette.chart.violet[2]
    }
  }));

  const useStyles = makeStyles({
    tourContainer: {
      maxWidth: '500px !important',
      borderRadius: '10px !important'
    },
    page: {
      width: '100%',
      display: 'flex',
      [theme.breakpoints.down(1200)]: {
        paddingRight: '40px'
      }
    },
    container: {
      display: 'flex',
      paddingRight: '0 !important'
    },
    collapseContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    innerContainer: {
      display: 'flex',
      width: '100%',
      height: 'auto',
      [theme.breakpoints.down(mediaQuery)]: {
        padding: '0px 40px'
      },
      [theme.breakpoints.down('md')]: {
        padding: '0px 0px'
      }
    },
    expandIcon: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      borderRadius: '100%',
      width: '25px',
      height: '25px',
      padding: '5px'
    },
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: 'auto',
      // paddingTop: '12px'
      paddingTop: '0px'
    },
    searchContainer: {
      width: '100%',
      maxWidth: '400px',
      height: 'auto',
      padding: '5px 0px',
      // marginBottom: '23px',
      marginBottom: '0px',
      // marginRight: '40px'
      marginRight: '20px'
    },
    search: {
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.background.neutral : 'transparent !important',
      border: theme.palette.mode === 'dark' ? 'unset' : `1px solid ${theme.palette.divider}`,
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: '100%'
    },
    searchInput: {
      fontSize: '14px',
      width: '100%',
      height: '34px',
      margin: '0 10px',
      border: 'none',
      padding: '5px 10px',
      outline: 'none !important',
      marginLeft: 2,
      flex: 1,
      backgroundColor: 'transparent !important',
      color:
        theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
      '&::placeholder': {
        color:
          theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
        fontSize: '14px'
      }
    },
    searchIcon: {
      fill: theme.palette.primary.main,
      marginRight: '5px'
    },
    headerTextContainer: {
      width: '100%',
      minHeight: '65px',
      height: 'auto',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    controlsContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'flex-end'
      // [theme.breakpoints.down(675)]: {
      //   marginTop: '25px',
      //   justifyContent: 'center'
      // }
    },
    headerText: {
      fontSize: '14px',
      marginRight: '18px',
      marginLeft: '5px'
    },
    inputText: {
      fontSize: '14px'
    },
    vehicleContainer: {
      display: 'flex',
      width: '100%',
      height: 'auto',
      marginBottom: '10px',
      minWidth: '1050px',
      overflowX: 'auto',
      paddingRight: '20px'
      // [theme.breakpoints.down(mediaQuery)]: {
      //   display: 'flex',
      //   flexDirection: 'column',
      //   justifyContent: 'center',
      //   alignItems: 'center',
      //   width: 'auto'
      // }
    },
    accordion: {
      boxShadow: 'none !important',
      backgroundColor: theme.palette.common.white,
      border: 'none',
      '&:not(:last-child)': {
        borderBottom: 0
      },
      '&:before': {
        display: 'none'
      }
    },
    pagination: {
      borderTopStyle: 'none',
      fontWeight: '700',
      borderTop: 'none !important'
    },
    dividerContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%'
    },
    divider: {
      flexGrow: '3',
      margin: '0px 10px'
    },
    selectDealer: {
      width: '200px'
    },
    dealerLogo: {
      width: 'auto',
      height: '30px',
      marginRight: '5px'
    },
    dealerLogoContainer: {
      display: 'flex',
      alignItems: 'center'
    }
  });

  const styles = useStyles();

  const snapshotUser = async () => {
    const userDoc = doc(firestore, `users/${user?.uid}`) as DocumentReference<User>;
    onSnapshot(userDoc, (doc: any) => {
      const userObj = { ...doc.data(), id: doc.id } as User;
      setCurUser(userObj);
      if (!userObj.email) {
        logout?.().then(() => {
          navigate('.');
          window.location.reload();
        });
      }
      if (userObj.showPreAuctionBuyTour && !tourOpen) {
        setTourOpen(true);
      }
      if (userObj && userObj.status.toLowerCase() === 'suspended') {
        setUserSuspended(true);
      } else {
        setUserSuspended(false);
      }
      if (userObj && userObj.status.toLowerCase() === 'disabled') {
        setUserDisabled(true);
      } else {
        setUserDisabled(false);
      }
    });
  };

  const [userDisabled, setUserDisabled] = useState<boolean>(false);
  const [userSuspended, setUserSuspended] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/dashboard');
  };

  return (
    <>
      {!userDeleted && !userSuspended && !userDisabled ? (
        <Page title="Pre Auction | Buyer" className={styles.page}>
          {vehicle && (
            <AuctionDrawer
              tabIndex={drawerTab}
              vehicle={vehicle as VehicleInfo}
              isOpenSidebar={openDrawer}
              onCloseSidebar={() => setOpenDrawer(!openDrawer)}
            />
          )}
          <Container
            maxWidth={themeStretch ? false : 'xl'}
            sx={{
              width: '100%',
              marginRight: 'auto'
            }}
            className={styles.container}
          >
            <Box className={styles.innerContainer}>
              <Box className={styles.bodyContainer}>
                <Box className={styles.headerTextContainer}>
                  <Box>
                    <Typography
                      variant="h4"
                      component="h1"
                      sx={{
                        fontSize: '1rem !important',
                        color:
                          theme.palette.mode === 'dark'
                            ? theme.palette.common.white
                            : theme.palette.text.primary
                      }}
                    >
                      Pre Auction: Launching Soon
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexWrap: 'wrap'
                  }}
                >
                  <StyledTabs
                    id="preAuctionBuy2"
                    value={tabValue}
                    onChange={handleChangeTab}
                    aria-label="Post Auction Tabs"
                  >
                    <Tab
                      sx={{
                        padding: '0px 10px',
                        borderRadius: '5px',
                        minHeight: '40px',
                        height: '40px'
                      }}
                      label={
                        <Typography sx={{ fontSize: '12px' }}>
                          LAUNCHING SOON ({collectionSize})
                        </Typography>
                      }
                      {...a11yProps(0)}
                    />
                    <Tab
                      sx={{
                        padding: '0px 10px',
                        borderRadius: '5px',
                        minHeight: '40px',
                        height: '40px'
                      }}
                      label={
                        <Typography sx={{ fontSize: '12px' }}>
                          AUTOBID ({collectionSizeAutobid})
                        </Typography>
                      }
                      {...a11yProps(1)}
                    />
                    <Tab
                      sx={{
                        padding: '0px 10px',
                        borderRadius: '5px',
                        minHeight: '40px',
                        height: '40px'
                      }}
                      label={
                        <Typography sx={{ fontSize: '12px' }}>
                          WATCHLIST ({collectionSizeWatchlist})
                        </Typography>
                      }
                      {...a11yProps(2)}
                    />
                  </StyledTabs>
                  {vehicles && (
                    <Box className={styles.searchContainer}>
                      <Box className={styles.controlsContainer}>
                        <Paper component="form" className={styles.search}>
                          <DebounceInput
                            className={styles.searchInput}
                            placeholder="Search by Make, Model, Year..."
                            minLength={2}
                            debounceTimeout={300}
                            onChange={(e) => setSearchValue(e.target.value)}
                          />
                          <SearchIcon className={styles.searchIcon} sx={{ width: '20px' }} />
                        </Paper>
                      </Box>
                    </Box>
                  )}
                </Box>
                {/* ALL VEHICLES */}
                <TabPanel value={tabValue} index={0}>
                  <Box className={styles.headerTextContainer}>
                    {vehicles && (
                      <Typography
                        component="p"
                        className={styles.headerText}
                        sx={{
                          textTransform: 'uppercase',
                          fontWeight: '700',
                          color: theme.palette.info.light
                        }}
                      >
                        {collectionSize} total vehicles found
                      </Typography>
                    )}
                    {vehicles && (
                      <Box className={styles.controlsContainer}>
                        <Typography component="p" className={styles.headerText}>
                          Sort By:
                        </Typography>
                        <Select
                          label="Sort By"
                          value={order}
                          onChange={handleOrderByChange}
                          className={styles.inputText}
                          inputProps={{ 'aria-label': 'order vehicles' }}
                          input={<InputBase />}
                        >
                          <MenuItem value={'timeDesc'}>Ending Last</MenuItem>
                          <MenuItem value={'timeAsc'}>Ending First</MenuItem>
                          {/* <MenuItem value={'vehicleName'}>Name</MenuItem> */}
                        </Select>
                        <TablePagination
                          className={styles.pagination}
                          count={collectionSize}
                          page={page}
                          onPageChange={handleChangePage}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          rowsPerPageOptions={[5, 10, 25]}
                        />
                      </Box>
                    )}
                  </Box>
                  <Grid
                    ref={vehiclesViewRef}
                    sx={{
                      mt: 1,
                      overflowX: 'auto',
                      height: vehicles && vehicles.length > 0 ? '800px' : '',
                      overflowY: 'auto',
                      willChange: 'transform'
                    }}
                  >
                    {vehicles && vehicles.length > 0 && (
                      <ViewportList
                        initialPrerender={10}
                        viewportRef={vehiclesViewRef}
                        // items={vehicles}
                        items={sortPreAuctionVehiclesArray(
                          vehicles,
                          order,
                          searchValue,
                          page,
                          rowsPerPage,
                          'buy'
                        )}
                      >
                        {(vehicle: VehicleInfo) => (
                          <Box className={styles.vehicleContainer} key={vehicle.id}>
                            {vehicles && (
                              <>
                                <VehicleGallery
                                  isPreAuction={true}
                                  vehicle={vehicle}
                                  mediaQuery={mediaQuery}
                                  width={'20%'}
                                  handleExpand={() => null}
                                  handleFavourite={() => null}
                                  sendRunTimeToParent={() => null}
                                  sendHideVehicleToParent={() => null}
                                />
                                <VehicleSummary
                                  vehicle={vehicle}
                                  isPreAuction={true}
                                  mediaQuery={mediaQuery}
                                  width={'30%'}
                                  handleWatchlist={handleWatchlist}
                                />
                                {currentDealership && (
                                  <VehicleActionsBuy
                                    vehicle={vehicle}
                                    curUser={curUser}
                                    dealership={currentDealership}
                                    isPreAuction={true}
                                    mediaQuery={mediaQuery}
                                    handleWatchlist={handleWatchlist}
                                    sendSubmitBidToParent={() => null}
                                    width={'20%'}
                                  />
                                )}
                                <VehicleDetails
                                  mediaQuery={mediaQuery}
                                  isPreAuction={true}
                                  isExpanded={false}
                                  handleOpenDrawer={handleToggleDrawer}
                                  vehicle={vehicle}
                                  width={'30%'}
                                />
                              </>
                            )}
                          </Box>
                        )}
                      </ViewportList>
                    )}
                  </Grid>
                  {vehicleEmptyState && (
                    <EmptyStateMessage
                      boldMessage="There are no vehicles launching soon."
                      message="You may need to expend your Buying Network through Find a Dealership to have access to more inventory."
                    />
                  )}
                </TabPanel>
                {/* AUTOBID */}
                <TabPanel value={tabValue} index={1}>
                  <Box className={styles.headerTextContainer}>
                    {autobidVehicles && (
                      <Typography
                        component="p"
                        className={styles.headerText}
                        sx={{
                          textTransform: 'uppercase',
                          fontWeight: '700',
                          color: theme.palette.info.light
                        }}
                      >
                        {collectionSizeAutobid} total vehicles found
                      </Typography>
                    )}
                    {autobidVehicles && (
                      <Box className={styles.controlsContainer}>
                        <Typography component="p" className={styles.headerText}>
                          Sort By:
                        </Typography>
                        <Select
                          label="Sort By"
                          value={orderAutobid}
                          onChange={handleOrderByChangeAutobid}
                          className={styles.inputText}
                          inputProps={{ 'aria-label': 'order vehicles' }}
                          input={<InputBase />}
                        >
                          <MenuItem value={'timeDesc'}>Ending Last</MenuItem>
                          <MenuItem value={'timeAsc'}>Ending First</MenuItem>
                          {/* <MenuItem value={'vehicleName'}>Name</MenuItem> */}
                        </Select>
                        {autobidVehicles && (
                          <TablePagination
                            className={styles.pagination}
                            count={collectionSizeAutobid}
                            page={pageAutobid}
                            onPageChange={handleChangePageAutobid}
                            rowsPerPage={rowsPerPageAutobid}
                            onRowsPerPageChange={handleChangeRowsPerPageAutobid}
                            rowsPerPageOptions={[5, 10, 25]}
                          />
                        )}
                      </Box>
                    )}
                  </Box>
                  <Grid
                    ref={autobidViewRef}
                    sx={{
                      mt: 1,
                      overflowX: 'auto',
                      height: autobidVehicles && autobidVehicles.length > 0 ? '800px' : '',
                      overflowY: 'auto',
                      willChange: 'transform'
                    }}
                  >
                    {autobidVehicles && autobidVehicles.length > 0 && (
                      <ViewportList
                        initialPrerender={10}
                        viewportRef={autobidViewRef}
                        items={sortPreAuctionVehiclesArray(
                          autobidVehicles,
                          orderAutobid,
                          searchValue,
                          pageAutobid,
                          rowsPerPageAutobid,
                          'buy'
                        )}
                      >
                        {(vehicle: VehicleInfo) => (
                          <Box className={styles.vehicleContainer} key={vehicle.id}>
                            {autobidVehicles && (
                              <>
                                <VehicleGallery
                                  isPreAuction={true}
                                  vehicle={vehicle}
                                  mediaQuery={mediaQuery}
                                  width={'20%'}
                                  handleExpand={() => null}
                                  handleFavourite={() => null}
                                  sendRunTimeToParent={() => null}
                                  sendHideVehicleToParent={() => null}
                                />
                                <VehicleSummary
                                  vehicle={vehicle}
                                  isPreAuction={true}
                                  mediaQuery={mediaQuery}
                                  width={'30%'}
                                  handleWatchlist={handleWatchlist}
                                />
                                {currentDealership && (
                                  <VehicleActionsBuy
                                    vehicle={vehicle}
                                    curUser={curUser}
                                    dealership={currentDealership}
                                    isPreAuction={true}
                                    mediaQuery={mediaQuery}
                                    handleWatchlist={handleWatchlist}
                                    sendSubmitBidToParent={() => null}
                                    width={'20%'}
                                  />
                                )}
                                <VehicleDetails
                                  mediaQuery={mediaQuery}
                                  isPreAuction={true}
                                  isExpanded={false}
                                  handleOpenDrawer={handleToggleDrawer}
                                  vehicle={vehicle}
                                  width={'30%'}
                                />
                              </>
                            )}
                          </Box>
                        )}
                      </ViewportList>
                    )}
                  </Grid>
                  {autobidEmptyState && (
                    <EmptyStateMessage
                      boldMessage="There are no vehicles launching soon."
                      message="You may need to expend your Buying Network through Find a Dealership to have access to more inventory."
                    />
                  )}
                </TabPanel>
                {/* WATCHLIST */}
                <TabPanel value={tabValue} index={2}>
                  <Box className={styles.headerTextContainer}>
                    {watchlistVehicles && (
                      <Typography
                        component="p"
                        className={styles.headerText}
                        sx={{
                          textTransform: 'uppercase',
                          fontWeight: '700',
                          color: theme.palette.info.light
                        }}
                      >
                        {collectionSizeWatchlist} total vehicles found
                      </Typography>
                    )}
                    {watchlistVehicles && (
                      <Box className={styles.controlsContainer}>
                        <Typography component="p" className={styles.headerText}>
                          Sort By:
                        </Typography>
                        <Select
                          label="Sort By"
                          value={orderWatchlist}
                          onChange={handleOrderByChangeWatchlist}
                          className={styles.inputText}
                          inputProps={{ 'aria-label': 'order vehicles' }}
                          input={<InputBase />}
                        >
                          <MenuItem value={'timeDesc'}>Ending Last</MenuItem>
                          <MenuItem value={'timeAsc'}>Ending First</MenuItem>
                          {/* <MenuItem value={'vehicleName'}>Name</MenuItem> */}
                        </Select>
                        {watchlistVehicles && (
                          <TablePagination
                            className={styles.pagination}
                            count={collectionSizeWatchlist}
                            page={pageWatchlist}
                            onPageChange={handleChangePageWatchlist}
                            rowsPerPage={rowsPerPageWatchlist}
                            onRowsPerPageChange={handleChangeRowsPerPageWatchlist}
                            rowsPerPageOptions={[5, 10, 25]}
                          />
                        )}
                      </Box>
                    )}
                  </Box>
                  <Grid
                    ref={watchlistViewRef}
                    sx={{
                      mt: 1,
                      overflowX: 'auto',
                      height: watchlistVehicles && watchlistVehicles.length > 0 ? '800px' : '',
                      overflowY: 'auto',
                      willChange: 'transform'
                    }}
                  >
                    {watchlistVehicles && watchlistVehicles.length > 0 && (
                      <ViewportList
                        initialPrerender={10}
                        viewportRef={watchlistViewRef}
                        items={sortPreAuctionVehiclesArray(
                          watchlistVehicles,
                          orderWatchlist,
                          searchValue,
                          pageWatchlist,
                          rowsPerPageWatchlist,
                          'buy'
                        )}
                      >
                        {(vehicle: VehicleInfo) => (
                          <Box className={styles.vehicleContainer} key={vehicle.id}>
                            {watchlistVehicles && (
                              <>
                                <VehicleGallery
                                  isPreAuction={true}
                                  vehicle={vehicle}
                                  mediaQuery={mediaQuery}
                                  width={'20%'}
                                  handleExpand={() => null}
                                  handleFavourite={() => null}
                                  sendRunTimeToParent={() => null}
                                  sendHideVehicleToParent={() => null}
                                />
                                <VehicleSummary
                                  vehicle={vehicle}
                                  isPreAuction={true}
                                  mediaQuery={mediaQuery}
                                  width={'30%'}
                                  handleWatchlist={handleWatchlist}
                                />
                                {currentDealership && (
                                  <VehicleActionsBuy
                                    dealership={currentDealership}
                                    vehicle={vehicle}
                                    curUser={curUser}
                                    isPreAuction={true}
                                    mediaQuery={mediaQuery}
                                    handleWatchlist={handleWatchlist}
                                    sendSubmitBidToParent={() => null}
                                    width={'20%'}
                                  />
                                )}
                                <VehicleDetails
                                  mediaQuery={mediaQuery}
                                  isPreAuction={true}
                                  isExpanded={false}
                                  handleOpenDrawer={handleToggleDrawer}
                                  vehicle={vehicle}
                                  width={'30%'}
                                />
                              </>
                            )}
                          </Box>
                        )}
                      </ViewportList>
                    )}
                  </Grid>
                  {watchlistEmptyState && (
                    <EmptyStateMessage
                      boldMessage="There are no vehicles launching soon."
                      message="You may need to expend your Buying Network through Find a Dealership to have access to more inventory."
                    />
                  )}
                </TabPanel>
              </Box>
            </Box>
          </Container>
          {currentVehicle && (
            <AuctionDrawer
              vehicle={currentVehicle as VehicleInfo}
              tabIndex={drawerTab}
              isOpenSidebar={openDrawer}
              onCloseSidebar={() => setOpenDrawer(!openDrawer)}
            />
          )}
          {vehicles && vehicles.length > 0 && (
            <Tour
              steps={steps}
              isOpen={tourOpen}
              onRequestClose={() => handleCloseTour()}
              className={styles.tourContainer}
            />
          )}
        </Page>
      ) : (
        <>
          {userDeleted && (
            <Alert severity="error">
              <AlertTitle>Permission Denied</AlertTitle>
              Your account has been disabled.
            </Alert>
          )}
          {userSuspended && (
            <>
              <Alert severity="error">
                <AlertTitle>Thank you for joining DealerGavel</AlertTitle>
                Your account information is being reviewed by DealerGavel team, and will be
                &nbsp;authorized shortly. For now, you can return to the dashboard and await
                &nbsp;authorization.
              </Alert>
              <Box sx={{ mt: '20px' }}>
                <Button onClick={handleNavigate} variant="contained">
                  Back to Dashboard
                </Button>
              </Box>
            </>
          )}
          {userDisabled && (
            <>
              <Alert severity="error">
                <AlertTitle>Permission Denied</AlertTitle>
                Your account has been disabled.
              </Alert>
              <Box sx={{ mt: '20px' }}>
                <Button onClick={handleNavigate} variant="contained">
                  Back to Dashboard
                </Button>
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
}
