import VehicleGallery from 'components/shared/VehicleGallery';
import VehicleDetails from 'components/shared/VehicleDetails';
import VehicleSummary from 'components/shared/VehicleSummary';
import VehicleActionsSell from 'components/seller/auctions/VehicleAuctionsSell';
import { VehicleInfo } from 'types/vehicleInfo';
import useAuth from 'hooks/useAuth';
import React, { FC, useState } from 'react';

interface Props {
  vehicle: VehicleInfo;
  handleExpand: (id: string, isExpanded: boolean) => void;
  handleFavourite: (id: string, isFavourite: boolean) => void;
  handleWatchlist: (id: string, isWatchlist: boolean) => void;
  handleOpenDrawer: (value: number, v: VehicleInfo) => void;
  mediaQuery: number;
  isLiveAuction?: boolean;
  isFirstVehicle?: boolean;
}

const VehicleRowCommonSell: FC<Props> = React.memo(function VehicleRowCommonSell({
  vehicle,
  handleExpand,
  handleFavourite,
  handleWatchlist,
  handleOpenDrawer,
  mediaQuery,
  isLiveAuction,
  isFirstVehicle
}) {
  const { user } = useAuth();
  const [hideVehicle, setHideVehicle] = useState<boolean>(false);

  const getHideVehicle = (hideVehicle: boolean) => {
    setHideVehicle(hideVehicle);
  };

  const getRuntime = (runtime: any) => {
    if (runtime.months) {
      if (
        runtime.months === 0 &&
        runtime.days === 0 &&
        runtime.hours === 0 &&
        runtime.minutes === 0 &&
        runtime.seconds === 0 &&
        !vehicle.bidExtension
      ) {
        setHideVehicle(true);
      }
    } else {
      if (
        runtime.days === 0 &&
        runtime.hours === 0 &&
        runtime.minutes === 0 &&
        runtime.seconds === 0 &&
        !vehicle.bidExtension
      ) {
        setHideVehicle(true);
      }
    }
  };

  return (
    <>
      {!hideVehicle && (
        <>
          {(vehicle && vehicle.auction.isExpanded.includes(user?.uid)) ||
          isFirstVehicle === true ? (
            <>
              <VehicleGallery
                isPreAuction={false}
                isLiveAuction={isLiveAuction}
                isFirstVehicle={isFirstVehicle}
                mediaQuery={mediaQuery}
                vehicle={vehicle}
                width={'33.3%'}
                handleExpand={handleExpand}
                handleFavourite={handleFavourite}
                sendRunTimeToParent={getRuntime}
                sendHideVehicleToParent={getHideVehicle}
              />
              <VehicleActionsSell
                vehicle={vehicle}
                isFirstVehicle={isFirstVehicle}
                isLiveAuction={isLiveAuction}
                mediaQuery={mediaQuery}
                handleWatchlist={handleWatchlist}
                width={'33.3%'}
              />
              <VehicleDetails
                mediaQuery={mediaQuery}
                isFirstVehicle={isFirstVehicle}
                isPreAuction={false}
                isExpanded={true}
                handleOpenDrawer={handleOpenDrawer}
                vehicle={vehicle}
                width={'33.3%'}
              />
            </>
          ) : (
            <>
              <VehicleGallery
                isPreAuction={false}
                isLiveAuction={isLiveAuction}
                isFirstVehicle={isFirstVehicle}
                vehicle={vehicle}
                mediaQuery={mediaQuery}
                width={'22.5%'}
                handleExpand={handleExpand}
                handleFavourite={handleFavourite}
                sendRunTimeToParent={getRuntime}
                sendHideVehicleToParent={getHideVehicle}
              />
              <VehicleSummary
                vehicle={vehicle}
                mediaQuery={mediaQuery}
                width={'27.5%'}
                handleWatchlist={handleWatchlist}
              />
              <VehicleActionsSell
                vehicle={vehicle}
                isFirstVehicle={isFirstVehicle}
                isLiveAuction={isLiveAuction}
                mediaQuery={mediaQuery}
                handleWatchlist={handleWatchlist}
                width={'22.5%'}
              />
              <VehicleDetails
                mediaQuery={mediaQuery}
                isFirstVehicle={isFirstVehicle}
                isPreAuction={false}
                isExpanded={false}
                handleOpenDrawer={handleOpenDrawer}
                vehicle={vehicle}
                width={'27.5%'}
              />
            </>
          )}
        </>
      )}
    </>
  );
});

export default VehicleRowCommonSell;
