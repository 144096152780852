import {
  Container,
  Box,
  useTheme,
  InputBase,
  Paper,
  Typography,
  MenuItem,
  Select,
  SelectChangeEvent,
  TablePagination,
  Tabs,
  Tab,
  Divider,
  Alert,
  AlertTitle,
  Grid,
  Button,
  DialogContent,
  FormControl,
  InputLabel,
  Dialog,
  DialogTitle
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from '@mui/styles';
import Page from '../../../components/Page';
import React, { useEffect, useRef, useState } from 'react';
import VehicleGallery from 'components/shared/VehicleGallery';
import VehicleActionsBuy from 'components/buyer/auctions/VehicleActionsBuy';
import VehicleDetails from 'components/shared/VehicleDetails';
import useSettings from 'hooks/useSettings';
import VehicleSummary from 'components/shared/VehicleSummary';
import { DebounceInput } from 'react-debounce-input';
import AuctionDrawer from 'components/auction/drawer/AuctionDrawer';
import { styled } from '@mui/material/styles';
import { VehicleInfo } from 'types/vehicleInfo';
import {
  doc,
  DocumentReference,
  getDoc,
  onSnapshot,
  orderBy,
  limit,
  updateDoc,
  where,
  arrayRemove,
  arrayUnion,
  CollectionReference,
  collection,
  query,
  OrderByDirection,
  Timestamp
} from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import useAuth from 'hooks/useAuth';
import Tour from 'reactour';
import { User } from 'constants/user';
import { useFirestoreByPath } from 'hooks/useFirestoreByPath';
import EmptyStateMessage from 'components/shared/EmptyStateMessage';
import { Dealership } from 'types/dealership';
import { sortPreAuctionVehiclesArray } from 'utils/searchHelper';
import { ViewportList } from 'react-viewport-list';
import { useNavigate } from 'react-router-dom';
import { useVehicleBatch } from 'utils/vehicleQuery';
import { useMemo } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { batchUpdateAddFront } from 'utils/batchSet';
import CloseIcon from '@mui/icons-material/Close';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface StyledTabsProps {
  id?: string;
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`activeAuction-tabpanel-${index}`}
      aria-labelledby={`activeAuction-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `activeAuction-tab-${index}`,
    'aria-controls': `activeAuction-tabpanel-${index}`
  };
};

export default function RunListBuy() {
  const theme = useTheme();
  const firestore = useFirestore();
  const { currentUser, user, logout } = useAuth();
  const { themeStretch } = useSettings();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [drawerTab, setDrawerTab] = useState<number>(0);
  const [vehicle, setVehicle] = useState<VehicleInfo>();
  const [tabValue, setTabValue] = useState(0);
  const [mediaQuery] = useState(1450);

  const numberOfRows = 25;

  const [autobidEmptyState, setAutobidEmptyState] = useState<boolean>(false);
  const [watchlistEmptyState, setWatchlistEmptyState] = useState<boolean>(false);
  const [liveAuctionEmptyState, setLiveAuctionEmptyState] = useState<boolean>(false);

  const { update: updateUser } = useFirestoreByPath<User>('users');

  const [currentDealership, setCurrentDealership] = useState<Dealership>();
  const [currentVehicle, setCurrentVehicle] = useState<VehicleInfo>();

  const getCurrentDealership = async () => {
    const userDealershipRef = doc(firestore, `dealerships/${currentUser.currentDealershipId}`);
    const userDealerSnap = await getDoc(userDealershipRef);
    if (userDealerSnap.exists()) {
      const data = userDealerSnap.data();
      setCurrentDealership({ ...data, id: userDealerSnap.id } as Dealership);
    }
  };

  const [userDeleted, setUserDeleted] = useState<boolean>(false);
  const [curUser, setCurUser] = useState<User>();

  const autobidViewRef = useRef(null);
  const watchlistViewRef = useRef(null);
  const liveAuctionViewRef = useRef(null);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      snapshotUser();
      getCurrentDealership();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const handleCloseTour = async () => {
    await updateUser(user?.uid as string, { showPreAuctionBuyTour: false });
    setTourOpen(false);
  };

  const [tourOpen, setTourOpen] = useState(false);

  const steps = [
    {
      selector: '',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Buy: Pre-Auction</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              Pre Auction displays all vehicles that are in a 'Launching Soon' status.  This is where cars will be displayed before they run in Active Auction. These vehicles belong to only those networks you have requested to buy from, or been invited to buy from.
            </Typography>
          </Box>
        </Box>
      )
    },
    {
      selector: '#preAuctionBuy2',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Buy: Pre-Auction</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              Tabs provide quick sort options if the buyer would like to follow only units where they have placed Autobids or in watchlist.
            </Typography>
          </Box>
        </Box>
      )
    },
    {
      selector: '#preAuctionBuy3',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Buy: Pre-Auction</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              Click here to add vehicles to your watchlist and follow them through Pre-Auction to Active Auctions.
            </Typography>
          </Box>
        </Box>
      )
    },
    {
      selector: '#preAuctionBuy4',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Buy: Pre-Auction</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              Click here to set an Autobid. Autobids are editable at any time in both Pre Auction and Active Auction.
            </Typography>
          </Box>
        </Box>
      )
    },
    {
      selector: '#preAuctionBuy5',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Buy: Pre-Auction</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              Click these individual icons to access information drawers for all car details. Important vehicle information such as equipment, damages, and disclosure will be found here.
            </Typography>
          </Box>
        </Box>
      )
    }
  ];

  const handleWatchlist = async (id: string, isWatchlist: boolean) => {
    const docRef = doc(firestore, `vehicles/${id}`);
    if (isWatchlist) {
      await updateDoc(docRef, { 'auction.isWatchlist': arrayRemove(user?.uid) });
    } else {
      await updateDoc(docRef, { 'auction.isWatchlist': arrayUnion(user?.uid) });
    }
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleToggleDrawer = (value: number, vehicle: VehicleInfo) => {
    setCurrentVehicle(vehicle);
    setDrawerTab(value);
    setVehicle(vehicle);
    setOpenDrawer(!openDrawer);
  };

  // ALL
  //---------------------------------------------------------------------------------------
  const [collectionSizeAllRunlist, setCollectionSizeAllRunlist] = useState<number>(0);

  const countAllRunlistVehicles = () => {
    const allRunlistVehicleRef = collection(firestore, 'vehicles') as CollectionReference;
    const allRunlistVehicleQuery = query(
      allRunlistVehicleRef,
      where('auction.auctionStatus', '==', 'Live Auction'),
      where('auction.liveAuctionStatus', '==', 'Inactive')
    );
    const unsubsribe = onSnapshot(allRunlistVehicleQuery, (querySnapshot) => {
      setCollectionSizeAllRunlist(querySnapshot.size);
    });
    return unsubsribe;
  };

  useEffect(() => {
    const unsubscribe = countAllRunlistVehicles();
    return () => {
      unsubscribe();
    };
  }, []);

  const queryConstraintsAllRunlist = [
    where('auction.auctionStatus', '==', 'Live Auction'),
    where('auction.liveAuctionStatus', '==', 'Inactive'),
    orderBy('liveAuctionIndex', 'asc')
  ];

  const allRunlistData = useVehicleBatch(0, queryConstraintsAllRunlist);

  const allRunlistVehicles = useMemo(() => {
    // prettier-ignore
    return allRunlistData?.data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id }));
  }, [allRunlistData.data]);

  useEffect(() => {
    if (allRunlistVehicles) {
      setItems(allRunlistVehicles);
    }
  }, [allRunlistVehicles]);

  // AUTOBID
  //---------------------------------------------------------------------------------------
  const [orderAutobid, setOrderAutobid] = useState('timeAsc');
  const [pageAutobid, setPageAutobid] = useState(0);
  const [rowsPerPageAutobid, setRowsPerPageAutobid] = useState(numberOfRows);
  const [collectionSizeAutobid, setCollectionSizeAutobid] = useState<number>(0);
  const [queryOrderAutobid, setQueryOrderAutobid] = useState<OrderByDirection>('asc');

  const countVehicleCollectionAutobid = () => {
    const vehicleRef = collection(firestore, 'vehicles') as CollectionReference;
    const vehicleQuery = query(
      vehicleRef,
      where('auction.auctionStatus', '==', 'Live Auction'),
      where('auction.liveAuctionStatus', '==', 'Inactive'),
      where('auction.isAutobid', 'array-contains', user?.uid)
    );
    const unsubsribe = onSnapshot(vehicleQuery, (querySnapshot) => {
      setCollectionSizeAutobid(querySnapshot.size);
    });
    return unsubsribe;
  };

  useEffect(() => {
    const unsubscribe = countVehicleCollectionAutobid();
    return () => {
      unsubscribe();
    };
  }, []);

  const queryConstraintsAutobid = [
    where('auction.isAutobid', 'array-contains', user?.uid),
    where('auction.auctionStatus', '==', 'Live Auction'),
    where('auction.liveAuctionStatus', '==', 'Inactive'),
    orderBy('liveAuctionIndex', queryOrderAutobid),
    limit(rowsPerPageAutobid)
  ];

  const [lastDocArrayAutobid, setLastDocArrayAutobid] = useState<any[]>([]);
  const [firstDocArrayAutobid, setFirstDocArrayAutobid] = useState<any[]>([]);
  const [backPageAutobid, setBackPageAutobid] = useState<boolean>(false);
  const [lastDataVehicleRefAutobid, setLastDataVehicleRefAutobid] = useState<DocumentReference>();

  const autobidData = useVehicleBatch(
    pageAutobid,
    queryConstraintsAutobid,
    lastDocArrayAutobid[pageAutobid - 1],
    backPageAutobid,
    firstDocArrayAutobid[pageAutobid]
  );

  const autobidVehicles = useMemo(() => {
    const lastDoc = autobidData?.data?.docs[autobidData?.data?.docs?.length - 1];
    if (lastDoc) {
      // prettier-ignore
      const lastDocRef = autobidData?.data?.docs[autobidData?.data?.docs?.length - 1].ref as DocumentReference;
      setLastDataVehicleRefAutobid(lastDocRef);
    }
    // prettier-ignore
    return autobidData?.data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id }));
  }, [autobidData.data]);

  const handleChangePageAutobid = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > pageAutobid) {
      const firstDoc = autobidData?.data?.docs[0];
      const firstDocRef = firstDoc.ref as DocumentReference;
      const docRef = doc(firestore, `vehicles/${firstDocRef.id}`);
      const docSnap = await getDoc(docRef);
      const tempFirstDocs = firstDocArrayAutobid;
      if (docSnap.exists()) {
        tempFirstDocs.push(docSnap);
        setFirstDocArrayAutobid(tempFirstDocs);
      }
      if (lastDataVehicleRefAutobid) {
        const docRef = doc(firestore, `vehicles/${lastDataVehicleRefAutobid.id}`);
        const docSnap = await getDoc(docRef);
        const tempLastDocs = lastDocArrayAutobid;
        if (docSnap.exists()) {
          tempLastDocs.push(docSnap);
          setLastDocArrayAutobid(tempLastDocs);
          setPageAutobid(newPage);
          setBackPageAutobid(false);
        }
      }
    } else {
      setPageAutobid(newPage);
      setBackPageAutobid(true);
    }
  };

  const handleChangeRowsPerPageAutobid = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPageAutobid(parseInt(event.target.value, 10));
    setPageAutobid(0);
    setFirstDocArrayAutobid([]);
    setLastDocArrayAutobid([]);
    setBackPageAutobid(false);
  };

  const handleOrderByChangeAutobid = (event: SelectChangeEvent) => {
    setOrderAutobid(event.target.value);
    if (event.target.value === 'timeAsc') {
      setQueryOrderAutobid('asc');
    } else {
      setQueryOrderAutobid('desc');
    }
    setPageAutobid(0);
    setFirstDocArrayAutobid([]);
    setLastDocArrayAutobid([]);
    setBackPageAutobid(false);
  };

  // WATCHLIST
  //---------------------------------------------------------------------------------------
  const [orderWatchlist, setOrderWatchlist] = useState('timeAsc');
  const [pageWatchlist, setPageWatchlist] = useState(0);
  const [rowsPerPageWatchlist, setRowsPerPageWatchlist] = useState(numberOfRows);
  const [lastDocArrayWatchlist, setLastDocArrayWatchlist] = useState<any[]>([]);
  const [firstDocArrayWatchlist, setFirstDocArrayWatchlist] = useState<any[]>([]);
  const [backPageWatchlist, setBackPageWatchlist] = useState<boolean>(false);
  // prettier-ignore
  const [lastDataVehicleRefWatchlist, setLastDataVehicleRefWatchlist] = useState<DocumentReference>();
  const [collectionSizeWatchlist, setCollectionSizeWatchlist] = useState<number>(0);
  const [queryOrderWatchlist, setQueryOrderWatchlist] = useState<OrderByDirection>('asc');

  const queryConstraintsWatchlist = [
    where('auction.auctionStatus', '==', 'Live Auction'),
    where('auction.isWatchlist', 'array-contains', user?.uid),
    where('auction.liveAuctionStatus', '==', 'Inactive'),
    orderBy('liveAuctionIndex', queryOrderAutobid),
    limit(rowsPerPageWatchlist)
  ];

  const watchlistData = useVehicleBatch(
    pageWatchlist,
    queryConstraintsWatchlist,
    lastDocArrayWatchlist[pageWatchlist - 1],
    backPageWatchlist,
    firstDocArrayWatchlist[pageWatchlist]
  );

  const watchlistVehicles = useMemo(() => {
    const lastDoc = watchlistData?.data?.docs[watchlistData?.data?.docs?.length - 1];
    if (lastDoc) {
      // prettier-ignore
      const lastDocRef = watchlistData?.data?.docs[watchlistData?.data?.docs?.length - 1].ref as DocumentReference;
      setLastDataVehicleRefWatchlist(lastDocRef);
    }
    // prettier-ignore
    return watchlistData?.data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id }));
  }, [watchlistData.data]);

  const countVehicleCollectionWatchlist = () => {
    const vehicleRef = collection(firestore, 'vehicles') as CollectionReference;
    const vehicleQuery = query(
      vehicleRef,
      where('auction.auctionStatus', '==', 'Live Auction'),
      where('auction.liveAuctionStatus', '==', 'Inactive'),
      where('auction.isWatchlist', 'array-contains', user?.uid)
    );
    const unsubsribe = onSnapshot(vehicleQuery, (querySnapshot) => {
      setCollectionSizeWatchlist(querySnapshot.size);
    });
    return unsubsribe;
  };

  useEffect(() => {
    const unsubscribe = countVehicleCollectionWatchlist();
    return () => {
      unsubscribe();
    };
  }, []);

  const handleChangePageWatchlist = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > pageWatchlist) {
      const firstDoc = watchlistData?.data?.docs[0];
      const firstDocRef = firstDoc.ref as DocumentReference;
      const docRef = doc(firestore, `vehicles/${firstDocRef.id}`);
      const docSnap = await getDoc(docRef);
      const tempFirstDocs = firstDocArrayWatchlist;
      if (docSnap.exists()) {
        tempFirstDocs.push(docSnap);
        setFirstDocArrayWatchlist(tempFirstDocs);
      }
      if (lastDataVehicleRefWatchlist) {
        const docRef = doc(firestore, `vehicles/${lastDataVehicleRefWatchlist.id}`);
        const docSnap = await getDoc(docRef);
        const tempLastDocs = lastDocArrayWatchlist;
        if (docSnap.exists()) {
          tempLastDocs.push(docSnap);
          setLastDocArrayWatchlist(tempLastDocs);
          setPageWatchlist(newPage);
          setBackPageWatchlist(false);
        }
      }
    } else {
      setPageWatchlist(newPage);
      setBackPageWatchlist(true);
    }
  };

  const handleChangeRowsPerPageWatchlist = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPageWatchlist(parseInt(event.target.value, 10));
    setPageWatchlist(0);
    setFirstDocArrayWatchlist([]);
    setLastDocArrayWatchlist([]);
    setBackPageWatchlist(false);
  };

  const handleOrderByChangeWatchlist = (event: SelectChangeEvent) => {
    setOrderWatchlist(event.target.value);
    if (event.target.value === 'timeAsc') {
      setQueryOrderWatchlist('asc');
    } else {
      setQueryOrderWatchlist('desc');
    }
    setPageWatchlist(0);
    setFirstDocArrayWatchlist([]);
    setLastDocArrayWatchlist([]);
    setBackPageWatchlist(false);
  };

  // LIVE AUCTION
  //---------------------------------------------------------------------------------------
  const [orderLiveAuction, setOrderLiveAuction] = useState('timeAsc');
  const [pageLiveAuction, setPageLiveAuction] = useState(0);
  const [rowsPerPageLiveAuction, setRowsPerPageLiveAuction] = useState(numberOfRows);
  const [lastDocArrayLiveAuction, setLastDocArrayLiveAuction] = useState<any[]>([]);
  const [firstDocArrayLiveAuction, setFirstDocArrayLiveAuction] = useState<any[]>([]);
  const [backPageLiveAuction, setBackPageLiveAuction] = useState<boolean>(false);
  // prettier-ignore
  const [lastDataVehicleRefLiveAuction, setLastDataVehicleRefLiveAuction] = useState<DocumentReference>();
  const [collectionSizeLiveAuction, setCollectionSizeLiveAuction] = useState<number>(0);
  const [queryOrderLiveAuction, setQueryOrderLiveAuction] = useState<OrderByDirection>('asc');

  const queryConstraintsLiveAuction = [
    where('auction.auctionStatus', '==', 'Live Auction'),
    where('auction.liveAuctionStatus', '==', 'Inactive'),
    orderBy('liveAuctionIndex', 'asc'),
    limit(rowsPerPageLiveAuction)
  ];

  const liveAuctionData = useVehicleBatch(
    pageLiveAuction,
    queryConstraintsLiveAuction,
    lastDocArrayLiveAuction[pageLiveAuction - 1],
    backPageLiveAuction,
    firstDocArrayLiveAuction[pageLiveAuction]
  );

  const liveAuctionVehicles = useMemo(() => {
    const lastDoc = liveAuctionData?.data?.docs[liveAuctionData?.data?.docs?.length - 1];
    if (lastDoc) {
      // prettier-ignore
      const lastDocRef = liveAuctionData?.data?.docs[liveAuctionData?.data?.docs?.length - 1].ref as DocumentReference;
      setLastDataVehicleRefLiveAuction(lastDocRef);
    }
    // prettier-ignore
    return liveAuctionData?.data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id }));
  }, [liveAuctionData.data]);

  const countVehicleCollectionLiveAuction = () => {
    const vehicleRef = collection(firestore, 'vehicles') as CollectionReference;
    const vehicleQuery = query(
      vehicleRef,
      where('auction.auctionStatus', '==', 'Live Auction'),
      where('auction.liveAuctionStatus', '==', 'Inactive')
    );
    const unsubsribe = onSnapshot(vehicleQuery, (querySnapshot) => {
      setCollectionSizeLiveAuction(querySnapshot.size);
    });
    return unsubsribe;
  };

  useEffect(() => {
    const unsubscribe = countVehicleCollectionLiveAuction();
    return () => {
      unsubscribe();
    };
  }, []);

  const handleChangePageLiveAuction = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > pageLiveAuction) {
      const firstDoc = liveAuctionData?.data?.docs[0];
      const firstDocRef = firstDoc.ref as DocumentReference;
      const docRef = doc(firestore, `vehicles/${firstDocRef.id}`);
      const docSnap = await getDoc(docRef);
      const tempFirstDocs = firstDocArrayLiveAuction;
      if (docSnap.exists()) {
        tempFirstDocs.push(docSnap);
        setFirstDocArrayLiveAuction(tempFirstDocs);
      }
      if (lastDataVehicleRefLiveAuction) {
        const docRef = doc(firestore, `vehicles/${lastDataVehicleRefLiveAuction.id}`);
        const docSnap = await getDoc(docRef);
        const tempLastDocs = lastDocArrayLiveAuction;
        if (docSnap.exists()) {
          tempLastDocs.push(docSnap);
          setLastDocArrayLiveAuction(tempLastDocs);
          setPageLiveAuction(newPage);
          setBackPageLiveAuction(false);
        }
      }
    } else {
      setPageLiveAuction(newPage);
      setBackPageLiveAuction(true);
    }
  };

  const handleChangeRowsPerPageLiveAuction = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPageLiveAuction(parseInt(event.target.value, 10));
    setPageLiveAuction(0);
    setFirstDocArrayLiveAuction([]);
    setLastDocArrayLiveAuction([]);
    setBackPageLiveAuction(false);
  };

  const handleOrderByChangeLiveAuction = (event: SelectChangeEvent) => {
    setOrderLiveAuction(event.target.value);
    if (event.target.value === 'timeAsc') {
      setQueryOrderLiveAuction('asc');
    } else {
      setQueryOrderLiveAuction('desc');
    }
    setPageLiveAuction(0);
    setFirstDocArrayLiveAuction([]);
    setLastDocArrayLiveAuction([]);
    setBackPageLiveAuction(false);
  };

  useEffect(() => {
    // if (!vehicles || vehicles.length < 1) {
    //   setVehicleEmptyState(true);
    // } else {
    //   setVehicleEmptyState(false);
    // }
    if (!autobidVehicles || autobidVehicles.length < 1) {
      setAutobidEmptyState(true);
    } else {
      setAutobidEmptyState(false);
    }
    if (!watchlistVehicles || watchlistVehicles.length < 1) {
      setWatchlistEmptyState(true);
    } else {
      setWatchlistEmptyState(false);
    }
    if (!liveAuctionVehicles || liveAuctionVehicles.length < 1) {
      setLiveAuctionEmptyState(true);
    } else {
      setLiveAuctionEmptyState(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autobidVehicles, watchlistVehicles, liveAuctionVehicles]);

  const StyledTabs = styled((props: StyledTabsProps) => (
    <Tabs
      {...props}
      variant="scrollable"
      sx={{
        minHeight: 'unset !important'
      }}
      // scrollButtons
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />
      }}
    />
  ))(({ theme }) => ({
    '& .MuiTab-root': {
      color:
        theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
      backgroundColor:
        theme.palette.mode === 'dark'
          ? theme.palette.background.neutral
          : theme.palette.background.paper
    },
    '& .MuiTab-root:not(:last-of-type)': {
      marginRight: '10px'
    },
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent !important',
      padding: '5px'
    },
    '& .Mui-selected': {
      backgroundColor: theme.palette.primary.lighter,
      color: `${theme.palette.primary.main} !important`
    },
    '& .MuiTabs-indicatorSpan': {
      display: 'none',
      maxWidth: 40,
      width: '100%',
      backgroundColor: theme.palette.chart.violet[2]
    }
  }));

  const useStyles = makeStyles({
    inviteButton: {
      backgroundColor: theme.palette.primary.main
    },
    tourContainer: {
      maxWidth: '500px !important',
      borderRadius: '10px !important'
    },
    page: {
      width: '100%',
      display: 'flex',
      [theme.breakpoints.down(1200)]: {
        paddingRight: '40px'
      }
    },
    container: {
      display: 'flex',
      paddingRight: '0 !important'
    },
    collapseContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    innerContainer: {
      display: 'flex',
      width: '100%',
      height: 'auto',
      [theme.breakpoints.down(mediaQuery)]: {
        padding: '0px 40px'
      },
      [theme.breakpoints.down('md')]: {
        padding: '0px 0px'
      }
    },
    expandIcon: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      borderRadius: '100%',
      width: '25px',
      height: '25px',
      padding: '5px'
    },
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: 'auto',
      // paddingTop: '12px'
      paddingTop: '0px'
    },
    searchContainer: {
      width: '100%',
      maxWidth: '400px',
      height: 'auto',
      padding: '5px 0px',
      marginBottom: '0px',
      marginRight: '20px'
    },
    search: {
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.background.neutral : 'transparent !important',
      border: theme.palette.mode === 'dark' ? 'unset' : `1px solid ${theme.palette.divider}`,
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: '100%'
    },
    searchInput: {
      fontSize: '14px',
      width: '100%',
      height: '34px',
      margin: '0 10px',
      border: 'none',
      padding: '5px 10px',
      outline: 'none !important',
      marginLeft: 2,
      flex: 1,
      backgroundColor: 'transparent !important',
      color:
        theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
      '&::placeholder': {
        color:
          theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
        fontSize: '14px'
      }
    },
    searchIcon: {
      fill: theme.palette.primary.main,
      marginRight: '5px'
    },
    headerTextContainer: {
      width: '100%',
      minHeight: '65px',
      height: 'auto',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    controlsContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'flex-end'
      // [theme.breakpoints.down(675)]: {
      //   marginTop: '25px',
      //   justifyContent: 'center'
      // }
    },
    headerText: {
      fontSize: '14px',
      marginRight: '18px',
      marginLeft: '5px'
    },
    inputText: {
      fontSize: '14px'
    },
    vehicleContainer: {
      display: 'flex',
      width: '100%',
      height: 'auto',
      marginBottom: '10px',
      minWidth: '1050px',
      overflowX: 'auto',
      paddingRight: '20px'
      // [theme.breakpoints.down(mediaQuery)]: {
      //   display: 'flex',
      //   flexDirection: 'column',
      //   justifyContent: 'center',
      //   alignItems: 'center',
      //   width: 'auto'
      // }
    },
    accordion: {
      boxShadow: 'none !important',
      backgroundColor: theme.palette.common.white,
      border: 'none',
      '&:not(:last-child)': {
        borderBottom: 0
      },
      '&:before': {
        display: 'none'
      }
    },
    pagination: {
      borderTopStyle: 'none',
      fontWeight: '700',
      borderTop: 'none !important'
    },
    dividerContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%'
    },
    divider: {
      flexGrow: '3',
      margin: '0px 10px'
    },
    selectDealer: {
      width: '200px'
    },
    dealerLogo: {
      width: 'auto',
      height: '30px',
      marginRight: '5px'
    },
    dealerLogoContainer: {
      display: 'flex',
      alignItems: 'center'
    }
  });

  const styles = useStyles();

  const snapshotUser = async () => {
    const userDoc = doc(firestore, `users/${user?.uid}`) as DocumentReference<User>;
    onSnapshot(userDoc, (doc: any) => {
      const userObj = { ...doc.data(), id: doc.id } as User;
      setCurUser(userObj);
      if (!userObj.email) {
        logout?.().then(() => {
          navigate('.');
          window.location.reload();
        });
      }
      if (userObj.showPreAuctionBuyTour && !tourOpen) {
        setTourOpen(true);
      }
      if (userObj && userObj.status.toLowerCase() === 'suspended') {
        setUserSuspended(true);
      } else {
        setUserSuspended(false);
      }
      if (userObj && userObj.status.toLowerCase() === 'disabled') {
        setUserDisabled(true);
      } else {
        setUserDisabled(false);
      }
    });
  };

  const [lane, setLane] = useState<string>();

  const handleLaneChange = (event: SelectChangeEvent) => {
    setLane(event.target.value as string);
    console.log('all run list', allRunlistVehicles);
    setItems(allRunlistVehicles);
  };

  const reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const grid = 8;
  const [items, setItems] = useState<VehicleInfo[]>();

  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    userSelect: 'none',
    border: `1px solid ${theme.palette.divider}`,
    height: '80px',
    borderRadius: '5px',
    padding: grid,
    margin: `0 0 ${grid}px 0`,
    background: isDragging ? theme.palette.primary.main : 'transparent',
    color: theme.palette.common.white,
    ...draggableStyle
  });

  const getListStyle = (isDraggingOver: any) => ({
    padding: grid,
    width: '100%'
  });

  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }
    const newItems = reorder(items, result.source.index, result.destination.index) as any;
    setItems(newItems);
  };

  const [dateTimeValue, setDateTimeValue] = useState<Date | null>();

  const handleRunOrderChange = async () => {
    if (items) {
      const vehicleIds: string[] = [];
      const vehicleIndexes: any[] = [];
      items.forEach((item: any, index: number) => {
        vehicleIds.push(item.id);
        vehicleIndexes.push(index);
      });
      const now = Timestamp.now();
      const vehiclesToUpdate = vehicleIndexes.map((index) => ({
        liveAuctionIndex: index,
        auction: {
          // liveAuctionStatus: 'Active',
          isLiveAuctionActive: false,
          // endDate: now,
          // endTime: now,
          startDateTime: index === 0 ? dateTimeValue : null
        }
      }));
      // console.log('vehicles to update', vehiclesToUpdate);
      await batchUpdateAddFront('vehicles', vehicleIds, vehiclesToUpdate);
      handleToggleOrderModal();
    }
  };

  const handleSaveRunOrder = async () => {
    if (items) {
      const vehicleIds: string[] = [];
      const vehicleIndexes: any[] = [];
      items.forEach((item: any, index: number) => {
        vehicleIds.push(item.id);
        vehicleIndexes.push(index);
      });
      // const now = Timestamp.now();
      const vehiclesToUpdate = vehicleIndexes.map((index) => ({
        liveAuctionIndex: index
      }));
      await batchUpdateAddFront('vehicles', vehicleIds, vehiclesToUpdate);
      handleToggleOrderModal();
    }
  };

  const [orderModalToggle, setOrderModalToggle] = useState<boolean>(false);
  const handleToggleOrderModal = () => {
    setOrderModalToggle(!orderModalToggle);
  };

  const [userDisabled, setUserDisabled] = useState<boolean>(false);
  const [userSuspended, setUserSuspended] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/dashboard');
  };

  return (
    <>
      {!userDeleted && !userSuspended && !userDisabled ? (
        <Page title="Pre Auction | Buyer" className={styles.page}>
          {vehicle && (
            <AuctionDrawer
              tabIndex={drawerTab}
              vehicle={vehicle as VehicleInfo}
              isOpenSidebar={openDrawer}
              onCloseSidebar={() => setOpenDrawer(!openDrawer)}
            />
          )}
          <Container
            maxWidth={themeStretch ? false : 'xl'}
            sx={{
              width: '100%',
              marginRight: 'auto'
            }}
            className={styles.container}
          >
            <Box className={styles.innerContainer}>
              <Box className={styles.bodyContainer}>
                <Box className={styles.headerTextContainer}>
                  <Box>
                    <Typography
                      variant="h4"
                      component="h1"
                      sx={{
                        fontSize: '1rem !important',
                        color:
                          theme.palette.mode === 'dark'
                            ? theme.palette.common.white
                            : theme.palette.text.primary
                      }}
                    >
                      Pre Auction: Run List
                    </Typography>
                    {/* {curUser && curUser.currentDealershipRole === 'superAdmin' && (
                      <Button
                        sx={{ marginTop: '10px', marginBottom: '10px' }}
                        onClick={handleToggleOrderModal}
                        className={styles.inviteButton}
                        variant="contained"
                        size="large"
                      >
                        Order Vehicles
                      </Button>
                    )} */}
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexWrap: 'wrap'
                  }}
                >
                  <StyledTabs
                    id="preAuctionBuy2"
                    value={tabValue}
                    onChange={handleChangeTab}
                    aria-label="Post Auction Tabs"
                  >
                    {/* <Tab
                      sx={{
                        padding: '0px 10px',
                        borderRadius: '5px',
                        minHeight: '40px',
                        height: '40px'
                      }}
                      label={
                        <Typography sx={{ fontSize: '12px' }}>
                          DEALERSHIP AUCTIONS ({collectionSize})
                        </Typography>
                      }
                      {...a11yProps(0)}
                    /> */}
                    <Tab
                      sx={{
                        padding: '0px 10px',
                        borderRadius: '5px',
                        minHeight: '40px',
                        height: '40px'
                      }}
                      label={
                        <Typography sx={{ fontSize: '12px' }}>
                          RUN LIST ({collectionSizeLiveAuction})
                        </Typography>
                      }
                      {...a11yProps(0)}
                    />
                    <Tab
                      sx={{
                        padding: '0px 10px',
                        borderRadius: '5px',
                        minHeight: '40px',
                        height: '40px'
                      }}
                      label={
                        <Typography sx={{ fontSize: '12px' }}>
                          AUTOBID ({collectionSizeAutobid})
                        </Typography>
                      }
                      {...a11yProps(1)}
                    />
                    <Tab
                      sx={{
                        padding: '0px 10px',
                        borderRadius: '5px',
                        minHeight: '40px',
                        height: '40px'
                      }}
                      label={
                        <Typography sx={{ fontSize: '12px' }}>
                          WATCHLIST ({collectionSizeWatchlist})
                        </Typography>
                      }
                      {...a11yProps(2)}
                    />
                  </StyledTabs>
                  {liveAuctionVehicles && (
                    <Box className={styles.searchContainer}>
                      <Box className={styles.controlsContainer}>
                        <Paper component="form" className={styles.search}>
                          <DebounceInput
                            className={styles.searchInput}
                            placeholder="Search by Make, Model, Year..."
                            minLength={2}
                            debounceTimeout={300}
                            onChange={(e) => setSearchValue(e.target.value)}
                          />
                          <SearchIcon className={styles.searchIcon} sx={{ width: '20px' }} />
                        </Paper>
                      </Box>
                    </Box>
                  )}
                </Box>
                {/* AUTOBID */}
                <TabPanel value={tabValue} index={1}>
                  <Box className={styles.headerTextContainer}>
                    {autobidVehicles && (
                      <Typography
                        component="p"
                        className={styles.headerText}
                        sx={{
                          textTransform: 'uppercase',
                          fontWeight: '700',
                          color: theme.palette.info.light
                        }}
                      >
                        {collectionSizeAutobid} total vehicles found
                      </Typography>
                    )}
                    {autobidVehicles && (
                      <Box className={styles.controlsContainer}>
                        {/* <Typography component="p" className={styles.headerText}>
                          Sort By:
                        </Typography>
                        <Select
                          label="Sort By"
                          value={orderAutobid}
                          onChange={handleOrderByChangeAutobid}
                          className={styles.inputText}
                          inputProps={{ 'aria-label': 'order vehicles' }}
                          input={<InputBase />}
                        >
                          <MenuItem value={'timeDesc'}>Ending Last</MenuItem>
                          <MenuItem value={'timeAsc'}>Ending First</MenuItem>
                        </Select> */}
                        {autobidVehicles && (
                          <TablePagination
                            className={styles.pagination}
                            count={collectionSizeAutobid}
                            page={pageAutobid}
                            onPageChange={handleChangePageAutobid}
                            rowsPerPage={rowsPerPageAutobid}
                            onRowsPerPageChange={handleChangeRowsPerPageAutobid}
                            rowsPerPageOptions={[5, 10, 25]}
                          />
                        )}
                      </Box>
                    )}
                  </Box>
                  <Grid
                    ref={autobidViewRef}
                    sx={{
                      mt: 1,
                      overflowX: 'auto',
                      height: autobidVehicles && autobidVehicles.length > 0 ? '800px' : '',
                      overflowY: 'auto',
                      willChange: 'transform'
                    }}
                  >
                    {autobidVehicles && autobidVehicles.length > 0 && (
                      <ViewportList
                        initialPrerender={10}
                        viewportRef={autobidViewRef}
                        items={sortPreAuctionVehiclesArray(
                          autobidVehicles,
                          orderAutobid,
                          searchValue,
                          pageAutobid,
                          rowsPerPageAutobid,
                          'buy'
                        )}
                      >
                        {(vehicle: VehicleInfo) => (
                          <Box className={styles.vehicleContainer} key={vehicle.id}>
                            {autobidVehicles && (
                              <>
                                <VehicleGallery
                                  isRunlist={true}
                                  isPreAuction={true}
                                  vehicle={vehicle}
                                  mediaQuery={mediaQuery}
                                  width={'20%'}
                                  handleExpand={() => null}
                                  handleFavourite={() => null}
                                  sendRunTimeToParent={() => null}
                                  sendHideVehicleToParent={() => null}
                                />
                                <VehicleSummary
                                  vehicle={vehicle}
                                  isPreAuction={true}
                                  mediaQuery={mediaQuery}
                                  width={'30%'}
                                  handleWatchlist={handleWatchlist}
                                />
                                {currentDealership && (
                                  <VehicleActionsBuy
                                    vehicle={vehicle}
                                    curUser={curUser}
                                    dealership={currentDealership}
                                    isPreAuction={true}
                                    mediaQuery={mediaQuery}
                                    handleWatchlist={handleWatchlist}
                                    sendSubmitBidToParent={() => null}
                                    width={'20%'}
                                  />
                                )}
                                <VehicleDetails
                                  mediaQuery={mediaQuery}
                                  isPreAuction={true}
                                  isExpanded={false}
                                  handleOpenDrawer={handleToggleDrawer}
                                  vehicle={vehicle}
                                  width={'30%'}
                                />
                              </>
                            )}
                          </Box>
                        )}
                      </ViewportList>
                    )}
                  </Grid>
                  {autobidEmptyState && (
                    <EmptyStateMessage
                      boldMessage="There are no vehicles launching soon."
                      message="You may need to expend your Buying Network through Find a Dealership to have access to more inventory."
                    />
                  )}
                </TabPanel>
                {/* WATCHLIST */}
                <TabPanel value={tabValue} index={2}>
                  <Box className={styles.headerTextContainer}>
                    {watchlistVehicles && (
                      <Typography
                        component="p"
                        className={styles.headerText}
                        sx={{
                          textTransform: 'uppercase',
                          fontWeight: '700',
                          color: theme.palette.info.light
                        }}
                      >
                        {collectionSizeWatchlist} total vehicles found
                      </Typography>
                    )}
                    {watchlistVehicles && (
                      <Box className={styles.controlsContainer}>
                        {/* <Typography component="p" className={styles.headerText}>
                          Sort By:
                        </Typography>
                        <Select
                          label="Sort By"
                          value={orderWatchlist}
                          onChange={handleOrderByChangeWatchlist}
                          className={styles.inputText}
                          inputProps={{ 'aria-label': 'order vehicles' }}
                          input={<InputBase />}
                        >
                          <MenuItem value={'timeDesc'}>Ending Last</MenuItem>
                          <MenuItem value={'timeAsc'}>Ending First</MenuItem>
                        </Select> */}
                        {watchlistVehicles && (
                          <TablePagination
                            className={styles.pagination}
                            count={collectionSizeWatchlist}
                            page={pageWatchlist}
                            onPageChange={handleChangePageWatchlist}
                            rowsPerPage={rowsPerPageWatchlist}
                            onRowsPerPageChange={handleChangeRowsPerPageWatchlist}
                            rowsPerPageOptions={[5, 10, 25]}
                          />
                        )}
                      </Box>
                    )}
                  </Box>
                  <Grid
                    ref={watchlistViewRef}
                    sx={{
                      mt: 1,
                      overflowX: 'auto',
                      height: watchlistVehicles && watchlistVehicles.length > 0 ? '800px' : '',
                      overflowY: 'auto',
                      willChange: 'transform'
                    }}
                  >
                    {watchlistVehicles && watchlistVehicles.length > 0 && (
                      <ViewportList
                        initialPrerender={10}
                        viewportRef={watchlistViewRef}
                        items={sortPreAuctionVehiclesArray(
                          watchlistVehicles,
                          orderWatchlist,
                          searchValue,
                          pageWatchlist,
                          rowsPerPageWatchlist,
                          'buy'
                        )}
                      >
                        {(vehicle: VehicleInfo) => (
                          <Box className={styles.vehicleContainer} key={vehicle.id}>
                            {watchlistVehicles && (
                              <>
                                <VehicleGallery
                                  isRunlist={true}
                                  isPreAuction={true}
                                  vehicle={vehicle}
                                  mediaQuery={mediaQuery}
                                  width={'20%'}
                                  handleExpand={() => null}
                                  handleFavourite={() => null}
                                  sendRunTimeToParent={() => null}
                                  sendHideVehicleToParent={() => null}
                                />
                                <VehicleSummary
                                  vehicle={vehicle}
                                  isPreAuction={true}
                                  mediaQuery={mediaQuery}
                                  width={'30%'}
                                  handleWatchlist={handleWatchlist}
                                />
                                {currentDealership && (
                                  <VehicleActionsBuy
                                    dealership={currentDealership}
                                    vehicle={vehicle}
                                    curUser={curUser}
                                    isPreAuction={true}
                                    mediaQuery={mediaQuery}
                                    handleWatchlist={handleWatchlist}
                                    sendSubmitBidToParent={() => null}
                                    width={'20%'}
                                  />
                                )}
                                <VehicleDetails
                                  mediaQuery={mediaQuery}
                                  isPreAuction={true}
                                  isExpanded={false}
                                  handleOpenDrawer={handleToggleDrawer}
                                  vehicle={vehicle}
                                  width={'30%'}
                                />
                              </>
                            )}
                          </Box>
                        )}
                      </ViewportList>
                    )}
                  </Grid>
                  {watchlistEmptyState && (
                    <EmptyStateMessage
                      boldMessage="There are no vehicles launching soon."
                      message="You may need to expend your Buying Network through Find a Dealership to have access to more inventory."
                    />
                  )}
                </TabPanel>
                {/* LIVE AUCTION */}
                <TabPanel value={tabValue} index={0}>
                  <Box className={styles.headerTextContainer}>
                    {liveAuctionVehicles && (
                      <Typography
                        component="p"
                        className={styles.headerText}
                        sx={{
                          textTransform: 'uppercase',
                          fontWeight: '700',
                          color: theme.palette.info.light
                        }}
                      >
                        {collectionSizeLiveAuction} total vehicles found
                      </Typography>
                    )}
                    {liveAuctionVehicles && (
                      <Box className={styles.controlsContainer}>
                        {/* <Typography component="p" className={styles.headerText}>
                          Sort By:
                        </Typography>
                        <Select
                          label="Sort By"
                          value={orderLiveAuction}
                          onChange={handleOrderByChangeLiveAuction}
                          className={styles.inputText}
                          inputProps={{ 'aria-label': 'order vehicles' }}
                          input={<InputBase />}
                        >
                          <MenuItem value={'timeDesc'}>Ending Last</MenuItem>
                          <MenuItem value={'timeAsc'}>Ending First</MenuItem>
                        </Select> */}
                        {liveAuctionVehicles && (
                          <TablePagination
                            className={styles.pagination}
                            count={collectionSizeLiveAuction}
                            page={pageLiveAuction}
                            onPageChange={handleChangePageLiveAuction}
                            rowsPerPage={rowsPerPageLiveAuction}
                            onRowsPerPageChange={handleChangeRowsPerPageLiveAuction}
                            rowsPerPageOptions={[5, 10, 25]}
                          />
                        )}
                      </Box>
                    )}
                  </Box>
                  <Grid
                    ref={liveAuctionViewRef}
                    sx={{
                      mt: 1,
                      overflowX: 'auto',
                      height: liveAuctionVehicles && liveAuctionVehicles.length > 0 ? '800px' : '',
                      overflowY: 'auto',
                      willChange: 'transform'
                    }}
                  >
                    {liveAuctionVehicles && liveAuctionVehicles.length > 0 && (
                      <ViewportList
                        initialPrerender={10}
                        viewportRef={liveAuctionViewRef}
                        items={sortPreAuctionVehiclesArray(
                          liveAuctionVehicles,
                          orderLiveAuction,
                          searchValue,
                          pageLiveAuction,
                          rowsPerPageLiveAuction,
                          'buy'
                        )}
                      >
                        {(vehicle: VehicleInfo) => (
                          <Box className={styles.vehicleContainer} key={vehicle.id}>
                            {liveAuctionVehicles && (
                              <>
                                <VehicleGallery
                                  isRunlist={true}
                                  isPreAuction={true}
                                  vehicle={vehicle}
                                  mediaQuery={mediaQuery}
                                  width={'20%'}
                                  handleExpand={() => null}
                                  handleFavourite={() => null}
                                  sendRunTimeToParent={() => null}
                                  sendHideVehicleToParent={() => null}
                                />
                                <VehicleSummary
                                  vehicle={vehicle}
                                  isPreAuction={true}
                                  mediaQuery={mediaQuery}
                                  width={'30%'}
                                  handleWatchlist={handleWatchlist}
                                />
                                {currentDealership && (
                                  <VehicleActionsBuy
                                    dealership={currentDealership}
                                    vehicle={vehicle}
                                    curUser={curUser}
                                    isPreAuction={true}
                                    mediaQuery={mediaQuery}
                                    handleWatchlist={handleWatchlist}
                                    sendSubmitBidToParent={() => null}
                                    width={'20%'}
                                  />
                                )}
                                <VehicleDetails
                                  mediaQuery={mediaQuery}
                                  isPreAuction={true}
                                  isExpanded={false}
                                  handleOpenDrawer={handleToggleDrawer}
                                  vehicle={vehicle}
                                  width={'30%'}
                                />
                              </>
                            )}
                          </Box>
                        )}
                      </ViewportList>
                    )}
                  </Grid>
                  {liveAuctionEmptyState && (
                    <EmptyStateMessage
                      boldMessage="There are no live auction vehicles."
                      message="You will see any vehicles set for live auction here."
                    />
                  )}
                </TabPanel>
              </Box>
            </Box>
          </Container>
          <Dialog open={orderModalToggle} onClose={handleToggleOrderModal}>
            <Box sx={{ minWidth: '450px' }}>
              <DialogTitle>Order Vehicles for Lane</DialogTitle>
              <CloseIcon
                onClick={handleToggleOrderModal}
                sx={{
                  cursor: 'pointer',
                  position: 'absolute',
                  right: '15px',
                  top: '24px'
                }}
              />
            </Box>
            <DialogContent>
              {/* <FormControl fullWidth>
                <InputLabel id="select-label">Select Lane</InputLabel>
                <Select
                  labelId="select-label"
                  id="select"
                  value={lane}
                  label="Lane Number"
                  onChange={handleLaneChange}
                >
                  <MenuItem value={1}>One</MenuItem>
                </Select>
              </FormControl> */}
              {items && items.length > 0 && (
                <Box>
                  <Typography sx={{ my: 3, width: '100%', ml: 1 }}>
                    Set the date and time for the next Live Auction.
                  </Typography>
                  <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-start', mb: 1 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        disablePast
                        label="Live Auction Date & Time"
                        value={dateTimeValue}
                        onChange={(newValue: React.SetStateAction<Date | null | undefined>) =>
                          setDateTimeValue(newValue)
                        }
                      />
                    </LocalizationProvider>
                  </Box>
                  <Typography sx={{ my: 3, width: '100%', ml: 1 }}>
                    Next, set the run order for the following vehicles and apply your changes.
                  </Typography>
                  <Typography
                    component="p"
                    className={styles.headerText}
                    sx={{
                      textTransform: 'uppercase',
                      fontWeight: '700',
                      color: theme.palette.info.light,
                      mt: 2
                    }}
                  >
                    {collectionSizeAllRunlist} total vehicles found
                  </Typography>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                        >
                          {items &&
                            items.map((item, index) => {
                              const image = item.photos.find((image: any) =>
                                image.location.toLowerCase().includes('3/4 shot')
                              );
                              return (
                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <Box sx={{ display: 'flex' }}>
                                        {/* prettier-ignore */}
                                        <img src={image?.url} style={{ width: 'auto', height: '64px', borderRadius: '5px'}} />
                                        <div style={{ marginLeft: '10px' }}>
                                          {/* prettier-ignore */}
                                          <p>{item.details.year} {item.details.make} {item.details.model} {item.details.trim}</p>
                                          <p style={{ fontSize: '12px' }}>{item.details.vin}</p>
                                        </div>
                                      </Box>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                    <Button onClick={handleSaveRunOrder} variant="contained" sx={{ mr: 1 }}>
                      Save Run List
                    </Button>
                    <Button
                      onClick={handleRunOrderChange}
                      variant="contained"
                      disabled={!dateTimeValue}
                    >
                      Save & Start Auction
                    </Button>
                  </Box>
                </Box>
              )}
            </DialogContent>
          </Dialog>
          {currentVehicle && (
            <AuctionDrawer
              vehicle={currentVehicle as VehicleInfo}
              tabIndex={drawerTab}
              isOpenSidebar={openDrawer}
              onCloseSidebar={() => setOpenDrawer(!openDrawer)}
            />
          )}
          {liveAuctionVehicles && liveAuctionVehicles.length > 0 && (
            <Tour
              steps={steps}
              isOpen={tourOpen}
              onRequestClose={() => handleCloseTour()}
              className={styles.tourContainer}
            />
          )}
        </Page>
      ) : (
        <>
          {userDeleted && (
            <Alert severity="error">
              <AlertTitle>Permission Denied</AlertTitle>
              Your account has been disabled.
            </Alert>
          )}
          {userSuspended && (
            <>
              <Alert severity="error">
                <AlertTitle>Thank you for joining DealerGavel</AlertTitle>
                Your account information is being reviewed by DealerGavel team, and will be
                &nbsp;authorized shortly. For now, you can return to the dashboard and await
                &nbsp;authorization.
              </Alert>
              <Box sx={{ mt: '20px' }}>
                <Button onClick={handleNavigate} variant="contained">
                  Back to Dashboard
                </Button>
              </Box>
            </>
          )}
          {userDisabled && (
            <>
              <Alert severity="error">
                <AlertTitle>Permission Denied</AlertTitle>
                Your account has been disabled.
              </Alert>
              <Box sx={{ mt: '20px' }}>
                <Button onClick={handleNavigate} variant="contained">
                  Back to Dashboard
                </Button>
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
}
