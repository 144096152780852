import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  TextField,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC, useEffect, useState } from 'react';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { useFirestoreByPath } from 'hooks/useFirestoreByPath';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { VehicleInfo } from 'types/vehicleInfo';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import { NumericFormat } from 'react-number-format';
import { Timestamp, doc, getDoc } from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import React from 'react';

interface Props {
  vehicle: VehicleInfo;
  width: string;
  handleWatchlist: any;
  mediaQuery: number;
  isFirstVehicle?: boolean;
  isLiveAuction?: boolean;
}

const VehicleActionsSell: FC<Props> = React.memo(function VehicleActionsSell({
  vehicle,
  width,
  handleWatchlist,
  mediaQuery,
  isFirstVehicle,
  isLiveAuction
}) {
  const theme = useTheme();
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  let [bidValue, setBidValue] = useState(vehicle.auction.floorPrice);
  const { update } = useFirestoreByPath('vehicles');
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [buyingUser, setBuyingUser] = useState<any>(null);
  const { currentUser } = useAuth();
  const firestore = useFirestore();
  const [floorPriceDisabled, setFloorPriceDisabled] = useState<boolean>(
    !bidValue?.toString().endsWith('00')
  );

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });

  const submitPriceChange = async (id: string) => {
    await update(id, { 'auction.floorPrice': parseInt(bidValue.toString(), 10) });
    enqueueSnackbar('Floor Price Updated', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      }
    });
  };

  const setMarkAsSold = async (id: string) => {
    await update(id, { sellerMarkAsSold: true });
    enqueueSnackbar('Vehicle Marked As Sold, moves directly to sold when auction completes.', {
      variant: 'success',
      autoHideDuration: 7000,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      }
    });
  };

  const useStyles = makeStyles({
    container: {
      borderWidth: '1px',
      borderTopStyle: 'solid',
      borderBottomStyle: 'solid',
      borderColor:
        theme.palette.mode === 'dark' ? theme.palette.text.secondary : theme.palette.divider,
      minWidth: '255px',
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '314px'
    },
    summaryContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '10px 10px 0px 10px',
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      borderBottomColor:
        theme.palette.mode === 'dark' ? theme.palette.text.secondary : theme.palette.divider,
      position: 'relative'
    },
    iconButton: {
      position: 'absolute',
      right: '5px',
      top: '5px',
      width: '30px',
      height: '30px',
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.primary.main,
      borderRadius: '3px'
    },
    icon: {
      width: '20px',
      height: '20px'
    },
    vehicleName: {
      lineHeight: 1,
      width: 'calc(100% - 30px)'
    },
    vinNumber: {
      lineHeight: 1.5,
      color: theme.palette.primary.main,
      fontSize: '13px',
      fontWeight: '700',
      textTransform: 'uppercase'
    },
    vehicleStats: {
      // lineHeight: 2,
      fontSize: '14px'
    },
    priceContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      padding: '0px'
    },
    currentPriceContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '6.5px 5px'
    },
    currentPriceText: {
      lineHeight: 1,
      fontWeight: 700,
      fontSize: '1.75rem',
      color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.primary
    },
    bidsText: {
      lineHeight: 1.5,
      marginLeft: '3px',
      color: theme.palette.info.lighter
    },
    selectDealer: {
      flexGrow: '1'
    },
    smallSelectDealer: {
      width: '100px'
    },
    inputContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '5px'
    },
    bidListContainer: {
      borderTopWidth: '1px',
      borderTopStyle: 'solid',
      borderTopColor:
        theme.palette.mode === 'dark' ? theme.palette.text.secondary : theme.palette.divider,
      // minHeight: '50px',
      // maxHeight: '70px',
      // height: '100%',
      // minHeight: '45px',
      flex: 1,
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        '-webkit-appearance': 'none'
      },
      '&::-webkit-scrollbar:vertical': {
        width: '9px'
      },
      '&::-webkit-scrollbar:horizontal': {
        height: '9px'
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '8px',
        backgroundColor: 'rgba(0, 0, 0, .5)',
        border: '1px solid white'
      }
    },
    bidListItem: {
      lineHeight: '1',
      fontSize: '13px',
      paddingTop: '2px',
      paddingBottom: '2px'
    },
    dealerLogo: {
      width: 'auto',
      height: '30px',
      marginRight: '5px'
    },
    gavelIcon: {
      width: '100%'
    },
    inputDivider: {
      height: 28,
      m: 0.5
    },
    dealerLogoContainer: {
      display: 'flex',
      alignItems: 'center'
    }
  });

  const styles = useStyles();

  const winningColor =
    vehicle.auction.previousBids && vehicle.auction.previousBids.length > 0
      ? //vehicle.auction.previousBids[vehicle.auction.previousBids.length - 1].bidderId === user?.uid
        theme.palette.success.main
      : theme.palette.text.secondary;

  return (
    <Box className={styles.container} sx={{ width: width }}>
      {(vehicle && vehicle.auction.isExpanded.includes(user?.uid)) || isFirstVehicle ? (
        <Box className={styles.summaryContainer}>
          <Tooltip title="Watchlist">
            <IconButton
              className={styles.iconButton}
              onClick={() => {
                handleWatchlist(vehicle.id, vehicle.auction.isWatchlist.includes(user?.uid));
              }}
            >
              {vehicle && vehicle.auction.isWatchlist.includes(user?.uid) ? (
                <BookmarkIcon className={styles.icon} />
              ) : (
                <BookmarkBorderIcon className={styles.icon} />
              )}
            </IconButton>
          </Tooltip>
          <Typography
            className={styles.vehicleName}
            component="p"
            // variant="h5"
            sx={{
              fontSize: '17px !important',
              fontWeight: 'bold',
              color:
                theme.palette.mode === 'dark'
                  ? theme.palette.common.white
                  : theme.palette.text.primary
            }}
          >
            {vehicle.details.year} {vehicle.details.make}&nbsp;
            {vehicle.details.model} {vehicle.details.trim}
          </Typography>
          <Typography className={styles.vinNumber} component="p">
            VIN: {vehicle.details.vin ? vehicle.details.vin : 'No VIN'}
          </Typography>
          <Typography className={styles.vinNumber} component="p">
            COLOUR: {vehicle.details.extColor}
          </Typography>
          {vehicle.disclosures.odometerStatus.toLowerCase() === 'broken' ? (
            // prettier-ignore
            <Typography component="p" className={styles.vehicleStats}>
              {vehicle.disclosures.odometerReading} {vehicle.details.mileageType} | {vehicle.dealershipName ? vehicle.dealershipName : vehicle.auction.sellerName} | {vehicle.auction.pickUpLocation.locationProvince}
            </Typography>
          ) : (
            // prettier-ignore
            <Typography component="p" className={styles.vehicleStats}>
              {vehicle.details.mileage} {vehicle.details.mileageType} | {vehicle.dealershipName ? vehicle.dealershipName : vehicle.auction.sellerName} | {vehicle.auction.pickUpLocation.locationProvince}
            </Typography>
          )}
        </Box>
      ) : (
        <Box></Box>
      )}
      <Box className={styles.priceContainer} sx={{ width: '100%' }}>
        {vehicle && vehicle.auction.isExpanded.includes(user?.uid) ? (
          <Box
            className={styles.currentPriceContainer}
            sx={{
              width: '100%',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              {vehicle.auction.currentPrice ? (
                <span
                  style={{
                    color:
                      vehicle.auction.currentPrice >= vehicle.auction.floorPrice ? winningColor : ''
                  }}
                  className={styles.currentPriceText}
                >
                  <NumericFormat
                    displayType="text"
                    allowNegative={false}
                    value={`$${vehicle.auction.currentPrice}`}
                    prefix="$"
                    decimalScale={0}
                    thousandSeparator={true}
                    // className={styles.currentPriceText}
                  />
                </span>
              ) : (
                <Typography component="p" variant="h4" className={styles.currentPriceText}>
                  {vehicle.auction.currentPrice ? `$${vehicle.auction.currentPrice}` : 'No Bids'}
                </Typography>
              )}
              {vehicle.auction.previousBids &&
                vehicle.auction.previousBids !== null &&
                vehicle.auction.previousBids.length > 0 && (
                  <Typography className={styles.bidsText} component="p">
                    {vehicle.auction.previousBids.length} bids
                  </Typography>
                )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                color: theme.palette.text.secondary
              }}
            >
              <Typography
                component="p"
                sx={{
                  color:
                    vehicle.auction.currentPrice &&
                    vehicle.auction.currentPrice >= vehicle.auction.floorPrice
                      ? winningColor
                      : ''
                }}
              >
                {vehicle.auction.previousBids && vehicle.auction.previousBids.length > 0
                  ? vehicle.auction.previousBids[0].bidderName
                  : 'No Winner'}
              </Typography>
              <Typography
                component="p"
                sx={{
                  fontSize: '12px',
                  color:
                    vehicle.auction.currentPrice &&
                    vehicle.auction.currentPrice >= vehicle.auction.floorPrice
                      ? winningColor
                      : ''
                }}
              >
                CURRENTLY WINNING
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box
            className={styles.currentPriceContainer}
            sx={{
              width: '100%',
              flexDirection: 'row',
              alignItems: 'center',
              padding: '6px !important'
            }}
          >
            {vehicle.auction.currentPrice ? (
              <NumericFormat
                displayType="text"
                allowNegative={false}
                value={`$${vehicle.auction.currentPrice}`}
                prefix="$"
                decimalScale={0}
                thousandSeparator={true}
                className={styles.currentPriceText}
              />
            ) : (
              <Typography component="p" variant="h4" className={styles.currentPriceText}>
                {vehicle.auction.currentPrice ? `$${vehicle.auction.currentPrice}` : 'No Bids'}
              </Typography>
            )}
            {vehicle.auction.previousBids &&
              vehicle.auction.previousBids !== null &&
              vehicle.auction.previousBids.length > 0 && (
                <Typography className={styles.bidsText} component="p">
                  {vehicle.auction.previousBids.length} bids
                </Typography>
              )}
          </Box>
        )}
      </Box>
      <Box className={styles.inputContainer}>
        <Box sx={{ display: 'flex', width: '100%' }}>
          <NumericFormat
            prefix="$"
            decimalScale={0}
            allowNegative={false}
            thousandSeparator={true}
            customInput={TextField}
            label="Floor Price (must end with 00)"
            fullWidth
            value={bidValue}
            onValueChange={(values) => {
              if (values.floatValue?.toString().endsWith('00')) {
                setFloorPriceDisabled(false);
              } else {
                setFloorPriceDisabled(true);
              }
              if (values.floatValue) {
                setBidValue(values.floatValue);
              }
            }}
          />
          <Tooltip title="Click To Set Floor Price (must end with 00)">
            <Button
              sx={{ ml: '5px' }}
              variant="contained"
              disabled={
                isLiveAuction ? isFirstVehicle : floorPriceDisabled || vehicle.sellerMarkAsSold
              }
              onClick={() => submitPriceChange(vehicle.id)}
            >
              Submit
            </Button>
          </Tooltip>
        </Box>
        <Button
          fullWidth
          disabled={
            !vehicle.auction.bidLeaderId ||
            vehicle.sellerMarkAsSold ||
            (isLiveAuction && !isFirstVehicle)
          }
          variant="contained"
          size="large"
          sx={{ marginTop: '5px', backgroundColor: theme.palette.primary.darker }}
          onClick={() => setMarkAsSold(vehicle.id)}
        >
          {vehicle.sellerMarkAsSold ? 'Sold: Highest Bid' : 'Sell: Highest Bid'}
        </Button>
      </Box>
      {(vehicle && vehicle.auction.isExpanded.includes(user?.uid)) || isFirstVehicle ? (
        <Box className={styles.bidListContainer} sx={{ position: 'relative' }}>
          <List sx={{ paddingTop: '5px', paddingBottom: '5px' }}>
            {vehicle.auction.previousBids &&
              vehicle.auction.previousBids !== null &&
              vehicle.auction.previousBids
                .sort((a, b) => +b.price - +a.price)
                .map((bid: any, index: number) => (
                  <ListItem
                    className={styles.bidListItem}
                    key={index}
                    sx={{
                      color: index === 0 ? theme.palette.success.main : theme.palette.text.primary
                    }}
                  >
                    {currencyFormat.format(bid.price as number)}: {bid.bidderName}
                  </ListItem>
                ))}
          </List>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
});

export default VehicleActionsSell;
