import {
  Container,
  Box,
  useTheme,
  IconButton,
  Paper,
  Typography,
  SelectChangeEvent,
  Divider,
  Tabs,
  Tab,
  Alert,
  AlertTitle,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from '@mui/styles';
import Page from '../../../components/Page';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import useSettings from 'hooks/useSettings';
import { DebounceInput } from 'react-debounce-input';
import AuctionDrawer from 'components/auction/drawer/AuctionDrawer';
import { styled } from '@mui/material/styles';
import { VehicleInfo } from 'types/vehicleInfo';
import {
  doc,
  DocumentReference,
  getDoc,
  onSnapshot,
  orderBy,
  where,
  limit,
  collection,
  CollectionReference,
  query
} from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import useAuth from 'hooks/useAuth';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Tour from 'reactour';
import { User } from 'constants/user';
import { useFirestoreByPath } from 'hooks/useFirestoreByPath';
import EmptyStateMessage from 'components/shared/EmptyStateMessage';
import { Dealership } from 'types/dealership';
import { isMobile } from 'react-device-detect';
import TabsPageBuy from './components/TabsPagesBuy';
import { useNavigate } from 'react-router-dom';
import { useVehicleBatch } from 'utils/vehicleQuery';
import LiveAuction from './components/LiveAuction';
import CloseIcon from '@mui/icons-material/Close';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { batchUpdateAddFront } from 'utils/batchSet';

export default function LiveAuctionPageBuy() {
  const theme = useTheme();
  const firestore = useFirestore();
  const { currentUser, user, logout } = useAuth();
  const { themeStretch } = useSettings();
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [drawerTab, setDrawerTab] = useState<number>(0);
  const [vehicle, setVehicle] = useState<VehicleInfo>();
  const [mediaQuery, setMediaQuery] = useState(1450);
  const [tourOpen, setTourOpen] = useState(false);
  const { update: updateUser } = useFirestoreByPath<User>('users');
  const [currentDealership, setCurrentDealership] = useState<Dealership>();

  const [vehicles, setVehicles] = useState<VehicleInfo[]>();

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      snapshotUser();
      getCurrentDealership();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  // Refs to tabs
  const vehiclesViewRef = useRef(null);
  const vehiclesPinViewRef = useRef(null);

  const getCurrentDealership = async () => {
    const userDealershipRef = doc(firestore, `dealerships/${currentUser.currentDealershipId}`);
    const userDealerSnap = await getDoc(userDealershipRef);
    if (userDealerSnap.exists()) {
      const data = userDealerSnap.data();
      setCurrentDealership({ ...data, id: userDealerSnap.id } as Dealership);
    }
  };

  const [userDeleted, setUserDeleted] = useState<boolean>(false);
  const [userSuspended, setUserSuspended] = useState<boolean>(false);
  const [userDisabled, setUserDisabled] = useState<boolean>(false);
  const [curUser, setCurUser] = useState<User>();

  const snapshotUser = () => {
    const userDoc = doc(firestore, `users/${user?.uid}`) as DocumentReference<User>;
    onSnapshot(userDoc, (doc: any) => {
      const userObj = { ...doc.data(), id: doc.id } as User;
      setCurUser(userObj);
      if (!userObj.email) {
        logout?.().then(() => {
          navigate('.');
          window.location.reload();
        });
      }
      if (userObj.showActiveAuctionBuyTour && !tourOpen) {
        setTourOpen(true);
      }
      if (userObj && userObj.status.toLowerCase() === 'suspended') {
        setUserSuspended(true);
      } else {
        setUserSuspended(false);
      }
      if (userObj && userObj.status.toLowerCase() === 'disabled') {
        setUserDisabled(true);
      } else {
        setUserDisabled(false);
      }
    });
  };

  const handleCloseTour = async () => {
    await updateUser(user?.uid as string, { showActiveAuctionBuyTour: false });
    setTourOpen(false);
  };

  const steps = [
    {
      selector: '',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Buy: Active Auction</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
                Active Auctions is where you will bid on live vehicles.
              </Typography>
          </Box>
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
                Remember, only vehicles from sellers in your 'buying network' will appear in your active auctions. 
              </Typography>
          </Box>
        </Box>
      )
    },
    {
      selector: '#activeAuction2',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Buy: Active Auction</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
                <span style={{ fontWeight: '900' }}>FILTERS</span>
              </Typography>
          </Box>
          {/* prettier-ignore */}
          <Typography sx={{ marginTop: '10px', color: theme.palette.text.secondary }}>
              Filter the list of vehicles to find exactly what you are looking for.
            </Typography>
        </Box>
      )
    },
    {
      selector: '#activeAuctionBuy3',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Buy: Active Auction</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
                <span style={{ fontWeight: '900' }}>AUCTION TABS</span>
              </Typography>
          </Box>
          {/* prettier-ignore */}
          <Typography sx={{ marginTop: '10px', color: theme.palette.text.secondary }}>
              The auction tabs keep the vehicles you have access to organized into helpful categories.
            </Typography>
        </Box>
      )
    },
    {
      selector: '#activeAuctionBuy4',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Buy: Active Auction</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
                <span style={{ fontWeight: '900' }}>SEARCH</span>
              </Typography>
          </Box>
          {/* prettier-ignore */}
          <Typography sx={{ marginTop: '10px', color: theme.palette.text.secondary }}>
              Use the versatile search feature to quickly find the vehicle you need.
            </Typography>
        </Box>
      )
    },
    {
      selector: '#activeAuctionBuy9',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Buy: Active Auction</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          {/* prettier-ignore */}
          <Typography sx={{ marginTop: '10px', color: theme.palette.text.secondary }}>
              Not enough room? You can close the filter menu using this button.
            </Typography>
        </Box>
      )
    },
    {
      selector: '#activeAuctionBuy7',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Buy: Active Auction</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
                <span style={{ fontWeight: '900' }}>VEHICLES</span>
              </Typography>
          </Box>
          {/* prettier-ignore */}
          <Typography sx={{ marginTop: '10px', color: theme.palette.text.secondary }}>
              The Bid Box is extremely versatile. 
            </Typography>
          {/* prettier-ignore */}
          <Typography sx={{ marginTop: '10px', color: theme.palette.text.secondary }}>
              Enter a starting bid.
            </Typography>
          {/* prettier-ignore */}
          <Typography sx={{ marginTop: '10px', color: theme.palette.text.secondary }}>
              Use our Bid button  which is defaulted at 200$ increments.
            </Typography>
          <Typography sx={{ marginTop: '10px', color: theme.palette.text.secondary }}>
            Or enter manual bids by clicking on the + icon.
          </Typography>
          <Typography sx={{ marginTop: '10px', color: theme.palette.text.secondary }}>
            Autobids can be set, edited and deleted during both Pre-Auction and Active Auction.
          </Typography>
        </Box>
      )
    },
    {
      selector: '#activeAuction6',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Buy: Active Auction</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          {/* prettier-ignore */}
          <Typography sx={{ marginTop: '10px', color: theme.palette.text.secondary }}>
              Expand and condense individual vehicle boxes using the arrows.
            </Typography>
          {/* prettier-ignore */}
          <Typography sx={{ marginTop: '10px', color: theme.palette.text.secondary }}>
              You can also pin priority vehicles during an auction so you don't have to scroll the entire list to find them. The unpinned vehicles will remain scrollable in the unpinned section underneath.
            </Typography>
        </Box>
      )
    },
    // {
    //   selector: '#activeAuctionBuy7',
    //   content: () => (
    //     <Box>
    //       <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
    //         <Typography variant="h5">Buy: Active Auction</Typography>
    //       </Box>
    //       <Divider
    //         sx={{
    //           borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
    //         }}
    //       />
    //       {/* prettier-ignore */}
    //       <Typography sx={{ marginTop: '10px', color: theme.palette.text.secondary }}>
    //         The bidding box is extremely versatile. It has starting bid functionality which defaults to $200.00 over the last bid, manual bids can alos be submitted by clicking on the + icon.
    //       </Typography>
    //       {/* prettier-ignore */}
    //       <Typography sx={{ marginTop: '10px', color: theme.palette.text.secondary }}>
    //         Autobids can be set on the fly as well as deleted in order to move to manual bid capability for a higher level of control.
    //       </Typography>
    //     </Box>
    //   )
    // },
    {
      selector: '#activeAuction8',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Buy: Active Auction</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          {/* prettier-ignore */}
          <Typography sx={{ marginTop: '10px', color: theme.palette.text.secondary }}>
              Click these individual icons to access information drawers for all car details. Important vehicle information such as equipment, damages, and disclosure will be found here. 
            </Typography>
        </Box>
      )
    }
    // {
    //   selector: '#activeAuction10',
    //   content: () => (
    //     <Box>
    //       <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
    //         <Typography variant="h5">Buy: Active Auction</Typography>
    //       </Box>
    //       <Divider
    //         sx={{
    //           borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
    //         }}
    //       />
    //       {/* prettier-ignore */}
    //       <Typography sx={{ marginTop: '10px', color: theme.palette.text.secondary }}>
    //         You can also collapse and expand this menu to create additional space to work.
    //       </Typography>
    //     </Box>
    //   )
    // }
  ];

  const handleToggleDrawer = (value: number, vehicle: VehicleInfo) => {
    setDrawerTab(value);
    setVehicle(vehicle);
    setOpenDrawer(!openDrawer);
  };

  // ALL ACTIVE
  //---------------------------------------------------------------------------------------
  const [order, setOrder] = useState('timeAsc');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [expandedPinned, setExpandedPinned] = useState(true);
  const [expandedAll, setExpandedAll] = useState(true);
  const [lastDocArray, setLastDocArray] = useState<any[]>([]);
  const [firstDocArray, setFirstDocArray] = useState<any[]>([]);
  const [backPage, setBackPage] = useState<boolean>(false);
  const [lastDataVehicleRef, setLastDataVehicleRef] = useState<DocumentReference>();
  const [collectionSize, setCollectionSize] = useState<number>(0);

  const queryConstraints = [
    where('auction.auctionStatus', '==', 'Live Auction'),
    where('auction.liveAuctionStatus', '==', 'Active'),
    // where('networkDealerships', 'array-contains', currentUser.currentDealershipId),
    // where('auction.auctionStatus', '==', 'active'),
    // orderBy('auction.endDate', 'asc'),
    // orderBy('auction.endTime', 'asc'),
    limit(rowsPerPage)
  ];

  const { data } = useVehicleBatch(
    page,
    queryConstraints,
    lastDocArray[page - 1],
    backPage,
    firstDocArray[page]
  );

  const allVehicles = useMemo(() => {
    const lastDoc = data?.docs[data?.docs?.length - 1];
    if (lastDoc) {
      const lastDocRef = data?.docs[data?.docs?.length - 1].ref as DocumentReference;
      setLastDataVehicleRef(lastDocRef);
    }
    // prettier-ignore
    return data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id }));
  }, [data]);

  const countVehicleCollection = () => {
    const vehicleRef = collection(firestore, 'vehicles') as CollectionReference;
    const vehicleQuery = query(
      vehicleRef,
      where('auction.auctionStatus', '==', 'Live Auction'),
      where('auction.liveAuctionStatus', '==', 'Active')
    );
    const unsubsribe = onSnapshot(vehicleQuery, (querySnapshot) => {
      setCollectionSize(querySnapshot.size);
    });
    return unsubsribe;
  };

  useEffect(() => {
    const unsubscribe = countVehicleCollection();
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    setVehicles(allVehicles);
  }, [allVehicles]);

  const handleChangePage = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      const firstDoc = data?.docs[0];
      const firstDocRef = firstDoc.ref as DocumentReference;
      const docRef = doc(firestore, `vehicles/${firstDocRef.id}`);
      const docSnap = await getDoc(docRef);
      const tempFirstDocs = firstDocArray;
      if (docSnap.exists()) {
        tempFirstDocs.push(docSnap);
        setFirstDocArray(tempFirstDocs);
      }
      if (lastDataVehicleRef) {
        const docRef = doc(firestore, `vehicles/${lastDataVehicleRef.id}`);
        const docSnap = await getDoc(docRef);
        const tempLastDocs = lastDocArray;
        if (docSnap.exists()) {
          tempLastDocs.push(docSnap);
          setLastDocArray(tempLastDocs);
          setPage(newPage);
          setBackPage(false);
        }
      }
    } else {
      setPage(newPage);
      setBackPage(true);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFirstDocArray([]);
    setLastDocArray([]);
    setBackPage(false);
  };

  const handleOrderByChange = (event: SelectChangeEvent) => {
    setOrder(event.target.value);
  };

  const handleExpandedPinnedChange = () => {
    setExpandedPinned(!expandedPinned);
  };

  const handleExpandedAllChange = () => {
    setExpandedAll(!expandedAll);
  };

  const [vehicleEmptyState, setVehicleEmptyState] = useState<boolean>(false);

  useEffect(() => {
    if (allVehicles && (!vehicles || vehicles.length < 1)) {
      setVehicleEmptyState(true);
    } else {
      setVehicleEmptyState(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicles]);

  const useStyles = makeStyles({
    headerText: {
      fontSize: '14px',
      marginRight: '18px'
    },
    inviteButton: {
      backgroundColor: theme.palette.primary.main
    },
    pagination: {
      borderTopStyle: 'none',
      fontWeight: '700',
      borderTop: 'none'
    },
    tourContainer: {
      maxWidth: '500px !important',
      borderRadius: '10px !important'
    },
    page: {
      width: '100%',
      display: 'flex',
      position: 'relative'
    },
    container: {
      display: 'flex',
      paddingRight: '0 !important'
    },
    collapseContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    innerContainer: {
      display: 'flex',
      width: '100%',
      height: 'auto',
      [theme.breakpoints.down(mediaQuery)]: {
        padding: '0px 40px'
      },
      [theme.breakpoints.down('md')]: {
        padding: '0px 0px'
      }
    },
    expandIcon: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      borderRadius: '100%',
      width: '25px',
      height: '25px',
      padding: '5px'
    },
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: 'auto',
      paddingTop: '0px'
    },
    searchContainer: {
      width: '100%',
      maxWidth: '400px',
      height: 'auto',
      padding: '5px 0px',
      marginBottom: '0px',
      marginRight: '20px'
    },
    search: {
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.background.neutral : 'transparent !important',
      border: theme.palette.mode === 'dark' ? 'unset' : `1px solid ${theme.palette.divider}`,
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: '100%'
    },
    searchInput: {
      fontSize: '14px',
      width: '100%',
      height: '34px',
      margin: '0 10px',
      border: 'none',
      padding: '5px 10px',
      outline: 'none !important',
      marginLeft: 2,
      flex: 1,
      backgroundColor: 'transparent !important',
      color:
        theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
      '&::placeholder': {
        color:
          theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
        fontSize: '14px'
      }
    },
    searchIcon: {
      fill: theme.palette.primary.main,
      marginRight: '5px'
    }
  });

  const styles = useStyles();

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate('/dashboard');
  };

  const [orderModalToggle, setOrderModalToggle] = useState<boolean>(false);
  const handleToggleOrderModal = () => {
    setOrderModalToggle(!orderModalToggle);
  };

  const [lane, setLane] = useState<string>();

  const handleLaneChange = (event: SelectChangeEvent) => {
    setLane(event.target.value as string);
    setItems(vehicles);
  };

  const reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const grid = 8;
  const [items, setItems] = useState<VehicleInfo[]>();

  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    userSelect: 'none',
    border: `1px solid ${theme.palette.divider}`,
    height: '80px',
    borderRadius: '5px',
    padding: grid,
    margin: `0 0 ${grid}px 0`,
    background: isDragging ? theme.palette.primary.main : 'transparent',
    color: theme.palette.common.white,
    ...draggableStyle
  });

  const getListStyle = (isDraggingOver: any) => ({
    padding: grid,
    width: '100%'
  });

  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }
    const newItems = reorder(items, result.source.index, result.destination.index) as any;
    setItems(newItems);
  };

  const handleRunOrderChange = async () => {
    if (items) {
      const vehicleIds: string[] = [];
      const vehicleIndexes: any[] = [];
      items.forEach((item: any, index: number) => {
        vehicleIds.push(item.id);
        vehicleIndexes.push(index);
      });
      const vehiclesToUpdate = vehicleIndexes.map((index) => ({
        liveAuctionIndex: index
      }));
      await batchUpdateAddFront('vehicles', vehicleIds, vehiclesToUpdate);
      handleToggleOrderModal();
    }
  };

  return (
    <>
      {!userDeleted && !userSuspended && !userDisabled ? (
        <Page title="Live Auction | Buyer" className={styles.page}>
          <Box
            sx={{
              position: 'absolute',
              zIndex: '99999',
              top: isMobile ? '-20px' : '-17px',
              left: isMobile ? '10px' : ''
            }}
          >
            {/* <Tooltip title="Hide/Show">
              <IconButton onClick={() => handleToggleSidebar(openSidebar)} id="activeAuctionBuy9">
                <MenuOpenIcon className={styles.expandIcon} />
              </IconButton>
            </Tooltip> */}
          </Box>
          {/* <AuctionFilters
            handleFilterChange={handleFilterChange}
            handleFormReset={handleFormReset}
            isOpenSidebar={openSidebar}
            onCloseSidebar={() => handleToggleSidebar(openSidebar)}
          /> */}
          {vehicle && (
            <AuctionDrawer
              tabIndex={drawerTab}
              vehicle={vehicle as VehicleInfo}
              isOpenSidebar={openDrawer}
              onCloseSidebar={() => setOpenDrawer(!openDrawer)}
            />
          )}
          <Container
            maxWidth={themeStretch ? false : 'xl'}
            sx={{
              width: openSidebar ? `calc(100% - 250px)` : '100%',
              marginRight: openSidebar ? 0 : 'auto',
              marginTop: isMobile ? '20px' : ''
            }}
            className={styles.container}
          >
            <Box className={styles.innerContainer}>
              <Box className={styles.bodyContainer}>
                <Box className={styles.collapseContainer}>
                  <Box>
                    <Typography
                      variant="h4"
                      component="h1"
                      sx={{
                        ml: 1,
                        mb: 1,
                        fontSize: '1rem !important',
                        color:
                          theme.palette.mode === 'dark'
                            ? theme.palette.common.white
                            : theme.palette.text.primary
                      }}
                    >
                      Live Auction: Buying
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexWrap: 'wrap'
                  }}
                >
                  {/* <StyledTabs
                    id="activeAuctionBuy3"
                    value={tabValue}
                    onChange={handleChangeTab}
                    aria-label="Post Auction Tabs"
                  >
                    <Tab
                      sx={{
                        padding: '0px 10px',
                        borderRadius: '5px',
                        minHeight: '40px',
                        height: '40px'
                      }}
                      label={
                        <Typography sx={{ fontSize: '12px' }}>
                          ALL ACTIVE ({collectionSize})
                        </Typography>
                      }
                      {...a11yProps(0)}
                    />
                    <Tab
                      sx={{
                        padding: '0px 10px',
                        borderRadius: '5px',
                        minHeight: '40px',
                        height: '40px'
                      }}
                      label={
                        <Typography sx={{ fontSize: '12px' }}>
                          IN-STOCK ({collectionSizeInStock})
                        </Typography>
                      }
                      {...a11yProps(1)}
                    />
                    <Tab
                      sx={{
                        padding: '0px 10px',
                        borderRadius: '5px',
                        minHeight: '40px',
                        height: '40px'
                      }}
                      label={
                        <Typography sx={{ fontSize: '12px' }}>
                          APPRAISALS ({collectionSizeAppraisals})
                        </Typography>
                      }
                      {...a11yProps(2)}
                    />
                    <Tab
                      sx={{
                        padding: '0px 10px',
                        borderRadius: '5px',
                        minHeight: '40px',
                        height: '40px'
                      }}
                      label={
                        <Typography sx={{ fontSize: '12px' }}>
                          WINNING ({collectionSizeWinning})
                        </Typography>
                      }
                      {...a11yProps(3)}
                    />
                    <Tab
                      sx={{
                        padding: '0px 10px',
                        borderRadius: '5px',
                        minHeight: '40px',
                        height: '40px'
                      }}
                      label={
                        <Typography sx={{ fontSize: '12px' }}>
                          LOSING ({collectionSizeLosing})
                        </Typography>
                      }
                      {...a11yProps(4)}
                    />
                    <Tab
                      sx={{
                        padding: '0px 10px',
                        borderRadius: '5px',
                        minHeight: '40px',
                        height: '40px'
                      }}
                      label={
                        <Typography sx={{ fontSize: '12px' }}>
                          AUTOBID ({collectionSizeAutobid})
                        </Typography>
                      }
                      {...a11yProps(5)}
                    />
                    <Tab
                      sx={{
                        padding: '0px 10px',
                        borderRadius: '5px',
                        minHeight: '40px',
                        height: '40px'
                      }}
                      label={
                        <Typography sx={{ fontSize: '12px' }}>
                          WATCHLIST ({collectionSizeWatchlist})
                        </Typography>
                      }
                      {...a11yProps(6)}
                    />
                  </StyledTabs> */}
                  <Box className={styles.searchContainer}>
                    {/* <Button
                      onClick={handleToggleOrderModal}
                      className={styles.inviteButton}
                      variant="contained"
                      size="large"
                    >
                      Order Vehicle List
                    </Button> */}
                    {/* <Paper component="form" className={styles.search}>
                      <DebounceInput
                        className={styles.searchInput}
                        placeholder="Search Vehicles by Make, Model, Year, etc."
                        minLength={2}
                        debounceTimeout={300}
                        onChange={(e) => setSearchValue(e.target.value)}
                      />
                      <SearchIcon className={styles.searchIcon} sx={{ width: '20px' }} />
                    </Paper> */}
                  </Box>
                </Box>
                <LiveAuction
                  vehicles={vehicles}
                  curUser={curUser}
                  collectionSize={collectionSize}
                  searchValue={searchValue}
                  handleToggleDrawer={handleToggleDrawer}
                  mediaQuery={mediaQuery}
                  currentDealership={currentDealership}
                  order={order}
                  expandedPinned={expandedPinned}
                  expandedAll={expandedAll}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  handleOrderByChange={handleOrderByChange}
                  handleExpandedPinnedChange={handleExpandedPinnedChange}
                  handleExpandedAllChange={handleExpandedAllChange}
                  ref2GridUnpin={vehiclesViewRef}
                  ref2GridPin={vehiclesPinViewRef}
                />
                {vehicleEmptyState && (
                  <EmptyStateMessage
                    boldMessage="There are no vehicles to bid on."
                    message="Build your Buying Network through the DealerGavel Community or see what vehicles are launching soon"
                  />
                )}
              </Box>
            </Box>
          </Container>
          <Dialog open={orderModalToggle} onClose={handleToggleOrderModal}>
            <Box sx={{ minWidth: '450px' }}>
              <DialogTitle>Order Vehicles for Lane</DialogTitle>
              <CloseIcon
                onClick={handleToggleOrderModal}
                sx={{
                  cursor: 'pointer',
                  position: 'absolute',
                  right: '15px',
                  top: '24px'
                }}
              />
            </Box>
            <DialogContent>
              <FormControl fullWidth>
                <InputLabel id="select-label">Select Lane</InputLabel>
                <Select
                  labelId="select-label"
                  id="select"
                  value={lane}
                  label="Lane Number"
                  onChange={handleLaneChange}
                >
                  <MenuItem value={1}>One</MenuItem>
                </Select>
              </FormControl>
              {items && items.length > 0 && (
                <Box>
                  <Typography sx={{ my: 3, width: '75%', ml: 1 }}>
                    Set the run order for the following vehicles and apply your changes.
                  </Typography>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                        >
                          {items &&
                            items.map((item, index) => {
                              const image = item.photos.find((image: any) =>
                                image.location.toLowerCase().includes('3/4 shot')
                              );
                              return (
                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <Box sx={{ display: 'flex' }}>
                                        {/* prettier-ignore */}
                                        <img src={image?.url} style={{ width: 'auto', height: '64px', borderRadius: '5px'}} />
                                        <div style={{ marginLeft: '10px' }}>
                                          {/* prettier-ignore */}
                                          <p>{item.details.year} {item.details.make} {item.details.model} {item.details.trim}</p>
                                          <p style={{ fontSize: '12px' }}>{item.details.vin}</p>
                                        </div>
                                      </Box>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                    <Button onClick={handleRunOrderChange} variant="contained">
                      Apply Changes
                    </Button>
                  </Box>
                </Box>
              )}
            </DialogContent>
          </Dialog>
          {vehicles && vehicles.length > 0 && (
            <Tour
              steps={steps}
              isOpen={tourOpen}
              onRequestClose={() => handleCloseTour()}
              className={styles.tourContainer}
            />
          )}
        </Page>
      ) : (
        <>
          {userDeleted && (
            <Alert severity="error">
              <AlertTitle>Permission Denied</AlertTitle>
              Your account has been disabled.
            </Alert>
          )}
          {userSuspended && (
            <>
              <Alert severity="error">
                <AlertTitle>Thank you for joining DealerGavel</AlertTitle>
                Your account information is being reviewed by DealerGavel team, and will be
                &nbsp;authorized shortly. For now, you can return to the dashboard and await
                &nbsp;authorization.
              </Alert>
              <Box sx={{ mt: '20px' }}>
                <Button onClick={handleNavigate} variant="contained">
                  Back to Dashboard
                </Button>
              </Box>
            </>
          )}
          {userDisabled && (
            <>
              <Alert severity="error">
                <AlertTitle>Permission Denied</AlertTitle>
                Your account has been disabled.
              </Alert>
              <Box sx={{ mt: '20px' }}>
                <Button onClick={handleNavigate} variant="contained">
                  Back to Dashboard
                </Button>
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
}
