import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  TextField,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC, useEffect, useRef, useState } from 'react';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { VehicleInfo } from 'types/vehicleInfo';
import {
  doc,
  Timestamp,
  updateDoc,
  runTransaction,
  arrayUnion,
  arrayRemove
} from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import { MIconButton } from 'components/@material-extend';
import closeFill from '@iconify/icons-eva/close-fill';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import { NumericFormat } from 'react-number-format';
import { Dealership } from 'types/dealership';
import { LoadingButton } from '@mui/lab';
import React from 'react';
import { User } from 'constants/user';
interface Props {
  vehicle: VehicleInfo;
  width: string;
  handleWatchlist: any;
  mediaQuery: number;
  isPreAuction: boolean;
  submitAutobidChange?: any;
  dealership: Dealership;
  curUser?: User;
  isFirstVehicle?: boolean;
  isLiveAuction?: boolean;
  sendSubmitBidToParent: (hasBid: boolean) => void;
}
const VehicleActionsBuy: FC<Props> = React.memo(function VehicleActionsBuy({
  vehicle,
  width,
  handleWatchlist,
  dealership,
  mediaQuery,
  curUser,
  isPreAuction,
  isFirstVehicle,
  isLiveAuction,
  sendSubmitBidToParent
}) {
  const theme = useTheme();
  const firestore = useFirestore();
  const { user, currentUser } = useAuth();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [, setCurrentVehicle] = useState<VehicleInfo>();
  const [dialogBidOpen, setDialogBidOpen] = useState(false);
  const [currentDealership, setCurrentDealership] = useState<Dealership>(dealership);
  const [dialogConfirmOpen, setDialogConfirmOpen] = useState<boolean>(false);
  const [dialogCustomOpen, setDialogCustomOpen] = useState<boolean>(false);
  const [dialogConfirmCustomOpen, setDialogConfirmCustomOpen] = useState<boolean>(false);
  const [adminOmvicDialogOpen, setAdminOmvicDialogOpen] = useState<boolean>(false);
  const [isAutobid, setIsAutobid] = useState<boolean>(false);
  const autobid = vehicle.auction.autobids.find(
    (bid: { autobidPrice: number; autobidTime: Timestamp; autobidId: string }) =>
      bid.autobidId === user?.uid
  );

  const sendSubmitBid = (hasBid: boolean) => {
    sendSubmitBidToParent(hasBid);
  };

  let [bidValue, setBidValue] = useState<number | string>();
  let [customBidValue, setCustomBidValue] = useState<number | string>();
  const [autobidValue, setAutobidValue] = useState<number | string>(
    autobid ? autobid.autobidPrice : ''
  );

  let [formattedBidValue, setFormattedBidValue] = useState<string>();
  let [formattedCustomBidValue, setFormattedCustomBidValue] = useState<string>();
  let [formattedAutobidValue, setFormattedAutobidValue] = useState<string>();

  const [bidIncrement, setBidIncrement] = useState<number>(200);
  const [isBidLoading, setIsBidLoading] = useState<boolean>(false);
  const [isAutobidLoading, setIsAutobidLoading] = useState<boolean>(false);

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
  });

  // const [buyFeeSaving, setBuyFeeSaving] = useState<string>(currencyFormat.format(426 - 99));
  const [buyFeeSaving, setBuyFeeSaving] = useState<string>(currencyFormat.format(426));
  const [fees, setFees] = useState<string>(currencyFormat.format(0));

  useEffect(() => {
    if (vehicle.auction.currentPrice) {
      let buyerFee = 0;
      let fee = 0;
      if (vehicle.auction.currentPrice < 10000) {
        buyerFee = 426;
        fee = 99;
      } else if (vehicle.auction.currentPrice >= 10000 && vehicle.auction.currentPrice < 20000) {
        buyerFee = 481;
        fee = 139;
      } else if (vehicle.auction.currentPrice >= 20000 && vehicle.auction.currentPrice < 30000) {
        buyerFee = 516;
        fee = 179;
      } else if (vehicle.auction.currentPrice >= 30000 && vehicle.auction.currentPrice < 40000) {
        buyerFee = 551;
        fee = 209;
      } else if (vehicle.auction.currentPrice >= 40000 && vehicle.auction.currentPrice < 50000) {
        buyerFee = 609;
        fee = 239;
      } else if (vehicle.auction.currentPrice >= 50000 && vehicle.auction.currentPrice < 60000) {
        buyerFee = 709;
        fee = 269;
      } else if (vehicle.auction.currentPrice >= 60000 && vehicle.auction.currentPrice < 70000) {
        buyerFee = 801;
        fee = 299;
      } else if (vehicle.auction.currentPrice >= 70000 && vehicle.auction.currentPrice < 80000) {
        buyerFee = 921;
        fee = 329;
      } else if (vehicle.auction.currentPrice >= 80000 && vehicle.auction.currentPrice < 90000) {
        buyerFee = 1031;
        fee = 359;
      } else if (vehicle.auction.currentPrice >= 90000 && vehicle.auction.currentPrice < 100000) {
        buyerFee = 1141;
        fee = 389;
      } else if (vehicle.auction.currentPrice >= 100000 && vehicle.auction.currentPrice < 110000) {
        buyerFee = 1306;
        fee = 389;
      } else if (vehicle.auction.currentPrice >= 110000 && vehicle.auction.currentPrice < 120000) {
        buyerFee = 1371;
        fee = 389;
      } else if (vehicle.auction.currentPrice >= 120000 && vehicle.auction.currentPrice < 130000) {
        buyerFee = 1461;
        fee = 389;
      } else if (vehicle.auction.currentPrice >= 130000 && vehicle.auction.currentPrice < 140000) {
        buyerFee = 1494;
        fee = 389;
      } else if (vehicle.auction.currentPrice >= 140000) {
        buyerFee = 1526;
        fee = 389;
      }
      setBuyFeeSaving(currencyFormat.format(buyerFee));
      setFees(currencyFormat.format(fee));
      const slicedPrice = parseInt(vehicle.auction.currentPrice.toString().slice(-3));
      console.log('sliced price => ', slicedPrice);
      if (
        slicedPrice === 100 ||
        slicedPrice === 300 ||
        slicedPrice === 500 ||
        slicedPrice === 700 ||
        slicedPrice === 900
      ) {
        setBidIncrement(100);
      } else {
        setBidIncrement(200);
      }
    }
  }, [vehicle.auction.currentPrice]);

  const [vehicleStartingPrice, setVehicleStartingPrice] = useState<any>();
  useEffect(() => {
    if (vehicle) {
      let price = vehicle.auction.floorPrice - 10000 - bidIncrement;
      if (price <= 10000) {
        price = 10000;
      }
      setVehicleStartingPrice(price);
    }
  }, [vehicle]);

  const handleBidDialogChange = (value?: boolean) => {
    setDialogBidOpen(!dialogBidOpen);
    if (value !== undefined) {
      setIsAutobid(value);
    }
  };

  const handleCustomDialogChange = () => {
    setDialogCustomOpen(!dialogCustomOpen);
  };

  const handleConfirmDialogChange = () => {
    setDialogBidOpen(!dialogBidOpen);
    setDialogConfirmOpen(!dialogConfirmOpen);
  };

  const handleConfirmCustomDialogChange = () => {
    setDialogCustomOpen(!dialogCustomOpen);
    setDialogConfirmCustomOpen(!dialogConfirmCustomOpen);
  };

  const handleCloseAdminOmvicDialog = () => {
    setAdminOmvicDialogOpen(!adminOmvicDialogOpen);
  };

  const submitStartingBid = async (id: string) => {
    setIsBidLoading(true);
    const vehicleDocRef = doc(firestore, `vehicles/${id}`);
    const timezoneOffset = new Date().getTimezoneOffset();
    // prettier-ignore
    const UTCEndDateMoment = moment(vehicle.auction.endDate.toDate()).clone().add(timezoneOffset, 'minutes');
    // prettier-ignore
    const UTCEndTimeMoment = moment(vehicle.auction.endTime.toDate()).clone().add(timezoneOffset, 'minutes');
    const UTCendDate = UTCEndDateMoment.toDate();
    const UTCendTime = UTCEndTimeMoment.toDate();
    UTCendDate.setHours(UTCendTime.getHours());
    UTCendDate.setMinutes(UTCendTime.getMinutes());
    UTCendDate.setSeconds(UTCendTime.getSeconds());
    UTCendDate.setMilliseconds(UTCendTime.getMilliseconds());
    const now = moment().clone().add(timezoneOffset, 'minutes');
    const secondsRemaining = moment(UTCendDate).diff(now, 'seconds');
    const bidExtensionInterval = 15; // 15 seconds before close

    if (bidValue) {
      try {
        await runTransaction(firestore, async (transaction) => {
          const vehicleDoc = await transaction.get(vehicleDocRef);
          if (vehicleDoc.exists()) {
            const vehicle = { ...vehicleDoc.data(), id: vehicleDoc.id } as VehicleInfo;
            if (!vehicle.auction.currentPrice) {
              transaction.update(vehicleDocRef, {
                bidExtension: secondsRemaining <= bidExtensionInterval ? true : false,
                bidExtensionTime: secondsRemaining <= bidExtensionInterval ? 15 : null,
                'auction.currentPrice': bidValue ? parseInt(bidValue.toString(), 10) : null,
                'auction.floorPriceMet':
                  bidValue && parseInt(bidValue.toString(), 10) >= vehicle.auction.floorPrice
                    ? true
                    : false,
                'auction.isAutobidding': vehicle.auction.autobids.length > 0 ? true : false,
                'auction.bidLeaderId': user?.uid,
                'auction.buyerName': `${currentUser.firstName} ${currentUser.lastName}`,
                'auction.previousBidders': arrayUnion(user?.uid),
                'auction.previousBids': arrayUnion({
                  price: bidValue,
                  bidderName: currentDealership?.dealershipName,
                  dateTime: Timestamp.now(),
                  bidderId: user?.uid
                })
              });
              enqueueSnackbar('Your new bid has been submitted', {
                variant: 'success',
                autoHideDuration: 3000,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right'
                },
                action: (key) => (
                  <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                    <Icon icon={closeFill} />
                  </MIconButton>
                )
              });
              setBidValue('');
            } else {
              enqueueSnackbar('Someone else placed Starting Bid first!', {
                variant: 'error',
                autoHideDuration: 3000,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right'
                },
                action: (key) => (
                  <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                    <Icon icon={closeFill} />
                  </MIconButton>
                )
              });
            }
          }
        });
      } catch (e) {
        enqueueSnackbar('Bid Not Successful, please try again', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          },
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
      }
    } else {
      enqueueSnackbar('Enter a proper Bid Value', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        },
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
    setDialogConfirmOpen(!dialogConfirmOpen);
    setIsBidLoading(false);
  };

  const submitBidChange = async (vehicle: VehicleInfo, value: string) => {
    sendSubmitBid(true);
    setIsBidLoading(true);
    const vehicleDocRef = doc(firestore, `vehicles/${vehicle.id}`);
    setCurrentVehicle(vehicle);
    const timezoneOffset = new Date().getTimezoneOffset();
    // prettier-ignore
    const UTCEndDateMoment = moment(vehicle.auction.endDate.toDate()).clone().add(timezoneOffset, 'minutes');
    // prettier-ignore
    const UTCEndTimeMoment = moment(vehicle.auction.endTime.toDate()).clone().add(timezoneOffset, 'minutes');
    const UTCendDate = UTCEndDateMoment.toDate();
    const UTCendTime = UTCEndTimeMoment.toDate();
    UTCendDate.setHours(UTCendTime.getHours());
    UTCendDate.setMinutes(UTCendTime.getMinutes());
    UTCendDate.setSeconds(UTCendTime.getSeconds());
    UTCendDate.setMilliseconds(UTCendTime.getMilliseconds());
    const now = moment().clone().add(timezoneOffset, 'minutes');
    const secondsRemaining = moment(UTCendDate).diff(now, 'seconds');
    const bidExtensionInterval = 15; // 15 seconds before close

    if (value) {
      if (customBidValue) {
        if (
          parseInt(customBidValue.toString(), 10) >
          parseInt(vehicle.auction.currentPrice.toString(), 10)
        ) {
          try {
            await runTransaction(firestore, async (transaction) => {
              const vehicleDoc = await transaction.get(vehicleDocRef);
              if (vehicleDoc.exists() && customBidValue) {
                const vehicle = { ...vehicleDoc.data(), id: vehicleDoc.id } as VehicleInfo;
                const bidValue = parseInt(customBidValue.toString(), 10);
                if (bidValue <= parseInt(vehicle.auction.currentPrice.toString(), 10)) {
                  enqueueSnackbar('Someone else Bid First!', {
                    autoHideDuration: 3000,
                    variant: 'error',
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'right'
                    },
                    action: (key) => (
                      <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                        <Icon icon={closeFill} />
                      </MIconButton>
                    )
                  });
                } else if (vehicle.auction.isAutobidding) {
                  enqueueSnackbar('Autobidding In Progress!', {
                    autoHideDuration: 3000,
                    variant: 'error',
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'right'
                    },
                    action: (key) => (
                      <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                        <Icon icon={closeFill} />
                      </MIconButton>
                    )
                  });
                } else {
                  transaction.update(vehicleDocRef, {
                    bidExtension: secondsRemaining <= bidExtensionInterval ? true : false,
                    bidExtensionTime: secondsRemaining <= bidExtensionInterval ? 15 : null,
                    // bidExtension: minutesRemaining < bidExtensionInterval ? true : false,
                    // bidExtensionTime: minutesRemaining < bidExtensionInterval ? secondsRemaining : null,
                    'auction.isAutobidding': vehicle.auction.autobids.length > 0 ? true : false,
                    'auction.currentPrice': value ? parseInt(value.toString(), 10) : null,
                    'auction.floorPriceMet':
                      value && parseInt(value.toString(), 10) >= vehicle.auction.floorPrice
                        ? true
                        : false,
                    'auction.bidLeaderId': user?.uid,
                    'auction.buyerName': `${currentUser.firstName} ${currentUser.lastName}`,
                    'auction.previousBidders': arrayUnion(user?.uid),
                    'auction.previousBids': arrayUnion({
                      price: value,
                      bidderName: currentDealership?.dealershipName,
                      dateTime: Timestamp.now(),
                      bidderId: user?.uid
                    })
                  });
                  enqueueSnackbar('Your new bid has been submitted', {
                    autoHideDuration: 3000,
                    variant: 'success',
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'right'
                    },
                    action: (key) => (
                      <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                        <Icon icon={closeFill} />
                      </MIconButton>
                    )
                  });
                }
              }
            });
          } catch (e) {
            enqueueSnackbar('Bid Not Successful, please try again', {
              autoHideDuration: 3000,
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right'
              },
              action: (key) => (
                <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                  <Icon icon={closeFill} />
                </MIconButton>
              )
            });
          }
        } else {
          enqueueSnackbar('Custom bid value must exceed current price.', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right'
            },
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            )
          });
        }
        setCustomBidValue(undefined);
        setFormattedCustomBidValue(undefined);
      } else {
        try {
          await runTransaction(firestore, async (transaction) => {
            const vehicleDoc = await transaction.get(vehicleDocRef);
            if (vehicleDoc.exists()) {
              const vehicle = { ...vehicleDoc.data(), id: vehicleDoc.id } as VehicleInfo;
              const bidValue = parseInt(value.toString(), 10);
              if (bidValue <= vehicle.auction.currentPrice) {
                enqueueSnackbar('Someone else Bid First!', {
                  autoHideDuration: 3000,
                  variant: 'error',
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                  },
                  action: (key) => (
                    <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                      <Icon icon={closeFill} />
                    </MIconButton>
                  )
                });
              } else if (vehicle.auction.isAutobidding) {
                enqueueSnackbar('Autobidding In Progress!', {
                  autoHideDuration: 3000,
                  variant: 'error',
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                  },
                  action: (key) => (
                    <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                      <Icon icon={closeFill} />
                    </MIconButton>
                  )
                });
              } else {
                transaction.update(vehicleDocRef, {
                  bidExtension: secondsRemaining <= bidExtensionInterval ? true : false,
                  bidExtensionTime: secondsRemaining <= bidExtensionInterval ? 15 : null,
                  // bidExtension: minutesRemaining < bidExtensionInterval ? true : false,
                  // bidExtensionTime: minutesRemaining < bidExtensionInterval ? secondsRemaining : null,
                  'auction.isAutobidding': vehicle.auction.autobids.length > 0 ? true : false,
                  'auction.currentPrice': value ? parseInt(value.toString(), 10) : null,
                  'auction.floorPriceMet':
                    value && parseInt(value.toString(), 10) >= vehicle.auction.floorPrice
                      ? true
                      : false,
                  'auction.bidLeaderId': user?.uid,
                  'auction.buyerName': `${currentUser.firstName} ${currentUser.lastName}`,
                  'auction.previousBidders': arrayUnion(user?.uid),
                  'auction.previousBids': arrayUnion({
                    price: value,
                    bidderName: currentDealership?.dealershipName,
                    dateTime: Timestamp.now(),
                    bidderId: user?.uid
                  })
                });
                enqueueSnackbar('Your new bid has been submitted', {
                  autoHideDuration: 3000,
                  variant: 'success',
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                  },
                  action: (key) => (
                    <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                      <Icon icon={closeFill} />
                    </MIconButton>
                  )
                });
              }
            }
          });
        } catch (e) {
          enqueueSnackbar('Bid Not Successful, please try again', {
            autoHideDuration: 3000,
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right'
            },
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            )
          });
        }
      }
    }
    setDialogConfirmCustomOpen(false);
    setIsBidLoading(false);
    sendSubmitBid(false);
  };

  const deleteAutobidValue = async (id: string) => {
    if (autobidValue) {
      const docRef = doc(firestore, `vehicles/${id}`);
      // const vehicle = await getDoc(docRef).then((v) => ({ ...v.data(), id: v.id } as VehicleInfo));
      const filteredAutobids = vehicle.auction.autobids.filter(
        (autobid: {
          autobidPrice: number;
          autobidTime: Timestamp;
          autobidId: string;
          autobidName: string;
        }) => autobid.autobidId !== user?.uid
      );
      await updateDoc(docRef, {
        'auction.isAutobid': arrayRemove(user?.uid),
        'auction.autobids': filteredAutobids
      });
      setAutobidValue('');
      enqueueSnackbar('Autobid Deleted', {
        autoHideDuration: 3000,
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        },
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    } else {
      enqueueSnackbar('No Autobid to delete', {
        autoHideDuration: 3000,
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        },
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
  };

  const submitAutobidChange = async (id: string) => {
    setIsAutobidLoading(true);
    if (autobidValue) {
      // const userRef = doc(firestore, `users/${user?.uid}`);
      const docRef = doc(firestore, `vehicles/${id}`);
      if (vehicle.auction.currentPrice) {
        // prettier-ignore
        if (parseInt(autobidValue.toString(), 10) >= parseInt(vehicle.auction.currentPrice.toString(), 10) + bidIncrement) {
          const filteredAutobids = vehicle.auction.autobids.filter(
            (autobid: {
              autobidPrice: number;
              autobidTime: Timestamp;
              autobidId: string;
              autobidName: string;
            }) => autobid.autobidId !== user?.uid
          );
          await runTransaction(firestore, async (transaction) => {
            transaction.update(docRef, {
              'auction.isAutobid': arrayRemove(user?.uid),
              'auction.autobids': filteredAutobids
            });
            transaction.update(docRef, {
              'auction.isAutobidding': true,
              'auction.isAutobid': arrayUnion(user?.uid),
              'auction.previousBidders': arrayUnion(user?.uid),
              'auction.autobids': arrayUnion({
                autobidTime: Timestamp.now(),
                autobidId: user?.uid,
                autobidPrice: autobidValue,
                autobidName: currentDealership?.dealershipName
              })
            });
          });
          setAutobidValue(autobidValue);
          enqueueSnackbar('Autobid Updated', {
            autoHideDuration: 3000,
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right'
            },
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            )
          });
        } else {
          setAutobidValue('');
          enqueueSnackbar(`Autobid Value must exceed current price by $${bidIncrement} or more`, {
            autoHideDuration: 3000,
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right'
            },
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            )
          });
        }
      } else {
        const filteredAutobids = vehicle.auction.autobids.filter(
          (autobid: {
            autobidPrice: number;
            autobidTime: Timestamp;
            autobidId: string;
            autobidName: string;
          }) => autobid.autobidId !== user?.uid
        );
        await runTransaction(firestore, async (transaction) => {
          transaction.update(docRef, {
            'auction.isAutobid': arrayRemove(user?.uid),
            'auction.autobids': filteredAutobids
          });
          transaction.update(docRef, {
            'auction.isAutobidding': true,
            'auction.isAutobid': arrayUnion(user?.uid),
            'auction.previousBidders': arrayUnion(user?.uid),
            'auction.autobids': arrayUnion({
              autobidTime: Timestamp.now(),
              autobidId: user?.uid,
              autobidPrice: autobidValue,
              autobidName: currentDealership?.dealershipName
            })
          });
        });
        setAutobidValue(autobidValue);
        enqueueSnackbar('Autobid Updated', {
          autoHideDuration: 3000,
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          },
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
      }
    } else {
      enqueueSnackbar('Enter an Autobid Value', {
        autoHideDuration: 3000,
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        },
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
    setDialogConfirmOpen(!dialogConfirmOpen);
    setIsAutobidLoading(false);
  };

  const useStyles = makeStyles({
    container: {
      borderWidth: '1px',
      borderTopStyle: 'solid',
      borderBottomStyle: 'solid',
      borderColor:
        theme.palette.mode === 'dark' ? theme.palette.text.secondary : theme.palette.divider,
      minWidth: isPreAuction ? '255px' : '275px',
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '314px'
    },
    summaryContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '10px 10px 0px 10px',
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      borderBottomColor:
        theme.palette.mode === 'dark' ? theme.palette.text.secondary : theme.palette.divider,
      position: 'relative'
    },
    iconButton: {
      position: 'absolute',
      right: '5px',
      top: '5px',
      width: '30px',
      height: '30px',
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.primary.main,
      borderRadius: '3px'
    },
    icon: {
      width: '20px',
      height: '20px'
    },
    vehicleName: {
      lineHeight: 1,
      width: 'calc(100% - 30px)'
    },
    vinNumber: {
      lineHeight: 1.5,
      color: theme.palette.primary.main,
      fontSize: '13px',
      fontWeight: '700',
      textTransform: 'uppercase'
    },
    vehicleStats: {
      fontSize: '14px'
    },
    priceContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      padding: '5px 5px 0px 5px'
    },
    currentPriceContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0px 5px'
    },
    currentPriceText: {
      lineHeight: 1,
      fontWeight: 700,
      fontSize: '1.25rem',
      color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.primary
    },
    preAuctionPriceText: {
      lineHeight: 1,
      fontWeight: 700,
      fontSize: '1.75rem',
      color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.primary
    },
    bidsText: {
      lineHeight: 1.5,
      marginLeft: '3px',
      color: theme.palette.info.lighter
    },
    selectDealer: {
      flexGrow: '1'
    },
    smallSelectDealer: {
      width: '100px'
    },
    inputContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '5px',
      paddingTop: '0px'
    },
    bidListContainer: {
      borderTopWidth: '1px',
      borderTopStyle: 'solid',
      borderTopColor:
        theme.palette.mode === 'dark' ? theme.palette.text.secondary : theme.palette.divider,
      flex: 1,
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        '-webkit-appearance': 'none'
      },
      '&::-webkit-scrollbar:vertical': {
        width: '9px'
      },
      '&::-webkit-scrollbar:horizontal': {
        height: '9px'
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '8px',
        backgroundColor: 'rgba(0, 0, 0, .5)',
        border: '1px solid white'
      }
    },
    bidListItem: {
      lineHeight: '1',
      fontSize: '13px',
      paddingTop: '2px',
      paddingBottom: '2px'
    },
    dealerLogo: {
      width: 'auto',
      height: '30px',
      marginRight: '5px'
    },
    inputAdornment: {
      marginRight: '23px'
    },
    gavelIcon: {
      width: '100%'
    },
    inputDivider: {
      height: 28,
      m: 0.5
    },
    dealerLogoContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    menuPopover: {
      boxShadow: '0 0 2px 0 rgba(139, 148, 156, 0.24),0 10px 20px -4px rgba(139, 148, 156, 0.24)',
      padding: '20px',
      '@global': {
        '.MuiMenuItem-root': {
          paddingBottom: '6px'
        }
      }
    },
    cancelButton: {
      backgroundColor: theme.palette.divider,
      color: theme.palette.info.light,
      '&:hover': {
        color: theme.palette.common.white
      }
    }
  });

  const styles = useStyles();

  const winningColor =
    vehicle.auction.previousBids &&
    vehicle.auction.previousBids.length > 0 &&
    vehicle.auction.previousBids[vehicle.auction.previousBids.length - 1].bidderId === user?.uid
      ? theme.palette.success.main
      : theme.palette.text.secondary;

  return (
    <Box className={styles.container} sx={{ width: width }} id="activeAuctionBuy7">
      {(vehicle && vehicle.auction.isExpanded.includes(user?.uid) && !isPreAuction) ||
      isFirstVehicle ? (
        <Box className={styles.summaryContainer}>
          <Tooltip title="Watchlist">
            <IconButton
              className={styles.iconButton}
              onClick={() => {
                handleWatchlist(vehicle.id, vehicle.auction.isWatchlist.includes(user?.uid));
              }}
            >
              {vehicle && vehicle.auction.isWatchlist.includes(user?.uid) ? (
                <BookmarkIcon className={styles.icon} />
              ) : (
                <BookmarkBorderIcon className={styles.icon} />
              )}
            </IconButton>
          </Tooltip>
          <Typography
            className={styles.vehicleName}
            component="p"
            variant="h5"
            sx={{
              fontSize: '17px !important',
              color:
                theme.palette.mode === 'dark'
                  ? theme.palette.common.white
                  : theme.palette.text.primary
            }}
          >
            {vehicle.details.year} {vehicle.details.make}&nbsp;
            {vehicle.details.model} {vehicle.details.trim}
          </Typography>
          <Typography className={styles.vinNumber} component="p">
            VIN: {vehicle.details.vin ? vehicle.details.vin : 'No VIN'}
          </Typography>
          <Typography className={styles.vinNumber} component="p">
            COLOUR: {vehicle.details.extColor}
          </Typography>
          {vehicle.disclosures.odometerStatus.toLowerCase() === 'broken' ? (
            // prettier-ignore
            <Typography component="p" className={styles.vehicleStats}>
              {vehicle.disclosures.odometerReading} {vehicle.details.mileageType} | {vehicle.dealershipName ? vehicle.dealershipName : vehicle.auction.sellerName} | {vehicle.auction.pickUpLocation.locationProvince}
            </Typography>
          ) : (
            // prettier-ignore
            <Typography component="p" className={styles.vehicleStats}>
              {vehicle.details.mileage} {vehicle.details.mileageType} | {vehicle.dealershipName ? vehicle.dealershipName : vehicle.auction.sellerName} | {vehicle.auction.pickUpLocation.locationProvince}
            </Typography>
          )}
        </Box>
      ) : (
        <Box></Box>
      )}
      {!isPreAuction ? (
        <Box className={styles.priceContainer} sx={{ width: '100%' }}>
          {(vehicle && vehicle.auction.isExpanded.includes(user?.uid)) || isFirstVehicle ? (
            <>
              <Box
                className={styles.currentPriceContainer}
                sx={{
                  width: '100%',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start'
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  {vehicle.auction.currentPrice ? (
                    <span
                      style={{
                        color: winningColor
                      }}
                      className={styles.currentPriceText}
                    >
                      <NumericFormat
                        allowNegative={false}
                        displayType="text"
                        value={`$${vehicle.auction.currentPrice}`}
                        prefix="$"
                        decimalScale={0}
                        thousandSeparator={true}
                      />
                    </span>
                  ) : (
                    <Typography component="p" variant="h4" className={styles.currentPriceText}>
                      No Bids
                    </Typography>
                  )}
                  <Typography className={styles.bidsText} component="p" sx={{ fontSize: '13px' }}>
                    {vehicle.auction.previousBids &&
                    vehicle.auction.previousBids !== null &&
                    vehicle.auction.previousBids.length > 0
                      ? `${vehicle.auction.previousBids.length} Bids`
                      : '0 Bids'}
                  </Typography>
                  <Typography
                    component="p"
                    sx={{
                      fontSize: '10px',
                      color: winningColor,
                      marginTop: '3px'
                    }}
                  >
                    CURRENTLY WINNING
                  </Typography>
                  <Typography
                    component="p"
                    noWrap
                    sx={{
                      color: winningColor,
                      maxWidth: '190px',
                      fontSize: '11px !important',
                      lineHeight: 1.1,
                      fontWeight: '900'
                    }}
                  >
                    {vehicle.auction.previousBids && vehicle.auction.previousBids.length > 0
                      ? vehicle.auction.previousBids[vehicle.auction.previousBids.length - 1]
                          .bidderName
                      : 'No Winner'}
                  </Typography>
                </Box>

                <Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-end',
                      alignSelf: 'flex-end'
                    }}
                  >
                    <Chip
                      label={fees}
                      sx={{
                        backgroundColor: '#FD7E14',
                        color: theme.palette.common.white,
                        height: '15px',
                        borderRadius: '10px',
                        fontSize: '10px',
                        fontWeight: '900'
                      }}
                    ></Chip>
                    <Typography
                      sx={{
                        fontSize: '10px',
                        color:
                          theme.palette.mode === 'dark'
                            ? theme.palette.common.white
                            : theme.palette.text.primary
                      }}
                    >
                      Current Buy Fee
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-end',
                      alignSelf: 'flex-end',
                      textAlign: 'end',
                      justifyContent: 'space-between',
                      color: theme.palette.text.secondary
                    }}
                  >
                    <Box>
                      <Chip
                        label={buyFeeSaving}
                        sx={{
                          backgroundColor: '#FD7E14',
                          color: theme.palette.common.white,
                          height: '15px',
                          fontSize: '10px',
                          borderRadius: '10px',
                          fontWeight: '900'
                        }}
                      ></Chip>
                      <Typography
                        sx={{
                          fontSize: '10px',
                          lineHeight: 1.25,
                          color:
                            theme.palette.mode === 'dark'
                              ? theme.palette.common.white
                              : theme.palette.text.primary
                        }}
                      >
                        Assumed Fee Savings
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Box
                className={styles.currentPriceContainer}
                sx={{
                  width: '100%',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between'
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  {vehicle.auction.currentPrice ? (
                    <NumericFormat
                      displayType="text"
                      allowNegative={false}
                      value={`$${vehicle.auction.currentPrice}`}
                      prefix="$"
                      decimalScale={0}
                      thousandSeparator={true}
                      className={styles.currentPriceText}
                      style={{
                        color:
                          vehicle.auction.previousBids &&
                          vehicle.auction.previousBids.length > 0 &&
                          vehicle.auction.previousBids[vehicle.auction.previousBids.length - 1]
                            .bidderId === user?.uid
                            ? theme.palette.success.main
                            : theme.palette.text.secondary
                      }}
                    />
                  ) : (
                    <Typography component="p" variant="h4" className={styles.currentPriceText}>
                      No Bids
                    </Typography>
                  )}
                  <Typography className={styles.bidsText} component="p" sx={{ fontSize: '13px' }}>
                    {vehicle.auction.previousBids &&
                    vehicle.auction.previousBids !== null &&
                    vehicle.auction.previousBids.length > 0
                      ? `${vehicle.auction.previousBids.length} Bids`
                      : '0 Bids'}
                  </Typography>
                  <Typography
                    component="p"
                    sx={{
                      fontSize: '10px',
                      color: winningColor,
                      marginTop: '0px'
                    }}
                  >
                    CURRENTLY WINNING
                  </Typography>
                  <Typography
                    component="p"
                    noWrap
                    sx={{
                      color: winningColor,
                      maxWidth: '140px',
                      fontSize: '11px !important',
                      lineHeight: 1.1,
                      fontWeight: '900'
                    }}
                  >
                    {vehicle.auction.previousBids && vehicle.auction.previousBids.length > 0
                      ? vehicle.auction.previousBids[vehicle.auction.previousBids.length - 1]
                          .bidderName
                      : 'No Winner'}
                  </Typography>
                </Box>

                <Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-end',
                      alignSelf: 'flex-end'
                    }}
                  >
                    <Chip
                      label={fees}
                      sx={{
                        backgroundColor: '#FD7E14',
                        color: theme.palette.common.white,
                        height: '15px',
                        borderRadius: '10px',
                        fontSize: '10px',
                        fontWeight: '900'
                      }}
                    ></Chip>
                    <Typography
                      sx={{
                        fontSize: '10px',
                        color:
                          theme.palette.mode === 'dark'
                            ? theme.palette.common.white
                            : theme.palette.text.primary
                      }}
                    >
                      Current Buy Fee
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-end',
                      alignSelf: 'flex-end',
                      textAlign: 'end',
                      justifyContent: 'space-between',
                      color: theme.palette.text.secondary
                    }}
                  >
                    <Box>
                      <Chip
                        label={buyFeeSaving}
                        sx={{
                          backgroundColor: '#FD7E14',
                          color: theme.palette.common.white,
                          height: '15px',
                          borderRadius: '10px',
                          fontSize: '10px',
                          fontWeight: '900'
                        }}
                      ></Chip>
                      <Typography
                        sx={{
                          fontSize: '10px',
                          lineHeight: '1.25',
                          color:
                            theme.palette.mode === 'dark'
                              ? theme.palette.common.white
                              : theme.palette.text.primary
                        }}
                      >
                        Assumed Fee Savings
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </Box>
      ) : (
        <></>
      )}
      <Box className={styles.inputContainer}>
        {!isPreAuction && !isLiveAuction && (
          <>
            {vehicle.auction.currentPrice ? (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <LoadingButton
                  loading={isBidLoading}
                  fullWidth
                  variant="contained"
                  disabled={
                    vehicle.auction.previousBids && vehicle.auction.previousBids.length > 0
                      ? vehicle.auction.previousBids[vehicle.auction.previousBids.length - 1]
                          .bidderId === user?.uid || vehicle.auction.isAutobidding
                      : false
                  }
                  size="large"
                  sx={{
                    marginTop: '5px',
                    backgroundColor: theme.palette.primary.main,
                    height: '40px'
                  }}
                  onClick={() =>
                    submitBidChange(
                      vehicle,
                      (
                        parseInt(vehicle.auction.currentPrice.toString(), 10) + bidIncrement
                      ).toString()
                    )
                  }
                >
                  Bid:
                  {currencyFormat.format(
                    parseInt(vehicle.auction.currentPrice.toString(), 10) + bidIncrement
                  )}
                </LoadingButton>
                <Tooltip title="Manual Bid">
                  <IconButton
                    disabled={
                      vehicle.auction.previousBids && vehicle.auction.previousBids.length > 0
                        ? vehicle.auction.previousBids[vehicle.auction.previousBids.length - 1]
                            .bidderId === user?.uid || vehicle.auction.isAutobidding
                        : false
                    }
                    sx={{
                      marginTop: '5px',
                      marginLeft: '5px',
                      height: '40px',
                      width: '40px'
                    }}
                    onClick={handleCustomDialogChange}
                  >
                    <AddCircleOutlineIcon sx={{ color: theme.palette.secondary.main }} />
                  </IconButton>
                </Tooltip>
              </Box>
            ) : (
              <Button
                fullWidth
                variant="contained"
                size="large"
                sx={{
                  marginTop: '5px',
                  height: '40px',
                  backgroundColor: theme.palette.primary.main
                }}
                onClick={() => handleBidDialogChange(false)}
              >
                Enter Starting Bid
              </Button>
            )}
          </>
        )}
        {!isPreAuction && isLiveAuction && vehicleStartingPrice && (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <LoadingButton
              loading={isBidLoading}
              fullWidth
              variant="contained"
              disabled={
                vehicle.auction.previousBids && vehicle.auction.previousBids.length > 0
                  ? vehicle.auction.previousBids[vehicle.auction.previousBids.length - 1]
                      .bidderId === user?.uid || vehicle.auction.isAutobidding
                  : vehicle.dealershipId === curUser?.currentDealershipId
                  ? true
                  : !isFirstVehicle
                  ? true
                  : false
              }
              size="large"
              sx={{
                marginTop: '5px',
                backgroundColor: theme.palette.primary.main,
                height: '40px'
              }}
              onClick={() =>
                submitBidChange(
                  vehicle,
                  (
                    parseInt(
                      vehicle.auction.currentPrice
                        ? vehicle.auction.currentPrice.toString()
                        : vehicleStartingPrice.toString(),
                      10
                    ) + bidIncrement
                  ).toString()
                )
              }
            >
              Bid Now:&nbsp;
              {currencyFormat.format(
                parseInt(
                  vehicle.auction.currentPrice
                    ? vehicle.auction.currentPrice.toString()
                    : vehicleStartingPrice.toString(),
                  10
                ) + bidIncrement
              )}
            </LoadingButton>
            <Tooltip title="Manual Bid">
              <IconButton
                disabled={
                  vehicle.auction.previousBids && vehicle.auction.previousBids.length > 0
                    ? vehicle.auction.previousBids[vehicle.auction.previousBids.length - 1]
                        .bidderId === user?.uid || vehicle.auction.isAutobidding
                    : vehicle.dealershipId === curUser?.currentDealershipId
                    ? true
                    : !isFirstVehicle
                    ? true
                    : false
                }
                sx={{
                  marginTop: '5px',
                  marginLeft: '5px',
                  height: '40px',
                  width: '40px'
                }}
                onClick={handleCustomDialogChange}
              >
                <AddCircleOutlineIcon sx={{ color: theme.palette.secondary.main }} />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        {vehicle.auction.isAutobid.includes(user?.uid) ? (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Tooltip title="New Autobid">
              <Button
                fullWidth
                variant="contained"
                size="large"
                sx={{
                  marginTop: '5px',
                  backgroundColor: theme.palette.secondary.main,
                  height: '40px'
                }}
                onClick={() => handleBidDialogChange(true)}
              >
                Autobid: {currencyFormat.format(autobidValue as number)}
              </Button>
            </Tooltip>
            <Tooltip title="Trash">
              <IconButton
                onClick={() => deleteAutobidValue(vehicle.id)}
                sx={{
                  ml: '5px',
                  height: '40px',
                  width: '40px'
                }}
              >
                <DeleteIcon sx={{ color: theme.palette.secondary.main }} />
              </IconButton>
            </Tooltip>
          </Box>
        ) : (
          <Button
            fullWidth
            id="preAuctionBuy4"
            variant="contained"
            disabled={curUser && curUser.currentDealershipId === vehicle.dealershipId}
            size="large"
            sx={{
              marginTop: '5px',
              backgroundColor: theme.palette.secondary.main,
              height: '40px'
            }}
            onClick={() => handleBidDialogChange(true)}
          >
            Set An Autobid
          </Button>
        )}
      </Box>
      {(vehicle &&
        vehicle.auction.isExpanded.includes(user?.uid) &&
        !isPreAuction &&
        vehicle.auction.previousBids &&
        vehicle.auction.previousBids !== null) ||
      (isFirstVehicle &&
        vehicle &&
        vehicle.auction.previousBids &&
        vehicle.auction.previousBids !== null) ? (
        <Box className={styles.bidListContainer} sx={{ position: 'relative' }}>
          <List sx={{ paddingTop: '5px !important', paddingBottom: '5px !important' }}>
            {vehicle.auction.previousBids &&
              vehicle.auction.previousBids !== null &&
              vehicle.auction.previousBids
                .map((bid: any, index: number) => (
                  <ListItem
                    className={styles.bidListItem}
                    key={index}
                    sx={{
                      color:
                        bid.bidderId === user?.uid
                          ? theme.palette.success.main
                          : theme.palette.text.primary
                    }}
                  >
                    ${bid.price}: {bid.bidderName}
                  </ListItem>
                ))
                .reverse()}
          </List>
        </Box>
      ) : (
        <Box></Box>
      )}
      <Dialog open={dialogBidOpen} onClose={() => handleBidDialogChange()}>
        <Box sx={{ textAlign: 'center' }}>
          <DialogTitle>{isAutobid ? 'Autobid' : 'Starting Bid'}</DialogTitle>
          <CloseIcon
            onClick={() => handleBidDialogChange()}
            sx={{
              cursor: 'pointer',
              position: 'absolute',
              right: '15px',
              top: '24px'
            }}
          />
        </Box>
        <DialogContent sx={{ width: '450px' }}>
          <Typography variant="h6" align="center" sx={{ mb: '10px' }}>
            {isAutobid
              ? 'Enter an Autobid Amount (must end with 00)'
              : 'Enter a starting bid amount to begin bidding on this vehicle. (must end with 00)'}
          </Typography>
          {isAutobid ? (
            <NumericFormat
              value={formattedAutobidValue}
              allowNegative={false}
              prefix="$"
              decimalScale={0}
              thousandSeparator={true}
              label="Autobid Amount"
              fullWidth
              customInput={TextField}
              onValueChange={(values) => {
                const { floatValue } = values;
                if (floatValue?.toString().endsWith('00')) {
                  setAutobidValue(values.value);
                  setFormattedAutobidValue(values.formattedValue);
                } else {
                  setAutobidValue('');
                  setFormattedAutobidValue(undefined);
                }
              }}
            />
          ) : (
            <NumericFormat
              value={formattedBidValue}
              allowNegative={false}
              prefix="$"
              decimalScale={0}
              thousandSeparator={true}
              label="Starting Bid Amount"
              fullWidth
              customInput={TextField}
              onValueChange={(values) => {
                const { floatValue } = values;
                if (floatValue?.toString().endsWith('00')) {
                  setBidValue(values.value);
                  setFormattedBidValue(values.formattedValue);
                } else {
                  setBidValue(undefined);
                  setFormattedBidValue(undefined);
                }
              }}
            />
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
              mt: '15px'
            }}
          >
            <Button
              size="large"
              variant="contained"
              className={styles.cancelButton}
              onClick={() => handleBidDialogChange()}
            >
              Cancel
            </Button>
            <Button
              size="large"
              variant="contained"
              disabled={isAutobid ? !autobidValue : !bidValue}
              onClick={handleConfirmDialogChange}
            >
              Submit Bid
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog open={dialogCustomOpen} onClose={handleCustomDialogChange}>
        <Box sx={{ textAlign: 'center' }}>
          <DialogTitle>Custom Bid</DialogTitle>
          <CloseIcon
            onClick={handleCustomDialogChange}
            sx={{
              cursor: 'pointer',
              position: 'absolute',
              right: '15px',
              top: '24px'
            }}
          />
        </Box>
        <DialogContent sx={{ width: '450px' }}>
          <Typography variant="h6" align="center" sx={{ mb: '10px' }}>
            Enter a custom bid amount.
          </Typography>
          <NumericFormat
            value={formattedCustomBidValue}
            allowNegative={false}
            prefix="$"
            decimalScale={0}
            thousandSeparator={true}
            label="Custom Bid Amount (must end with 00)"
            fullWidth
            customInput={TextField}
            onValueChange={(values) => {
              const { floatValue } = values;
              if (floatValue?.toString().endsWith('00')) {
                setCustomBidValue(values.value);
                setFormattedCustomBidValue(values.formattedValue);
              } else {
                setCustomBidValue(undefined);
                setFormattedCustomBidValue(undefined);
              }
            }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
              mt: '15px'
            }}
          >
            <Button
              size="large"
              variant="contained"
              className={styles.cancelButton}
              onClick={handleCustomDialogChange}
            >
              Cancel
            </Button>
            <Button
              size="large"
              variant="contained"
              disabled={!customBidValue}
              onClick={handleConfirmCustomDialogChange}
            >
              Submit Bid
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog open={dialogConfirmOpen} onClose={handleConfirmDialogChange}>
        <Box sx={{ textAlign: 'center' }}>
          <DialogTitle>Starting Bid</DialogTitle>
          <CloseIcon
            onClick={handleConfirmDialogChange}
            sx={{
              cursor: 'pointer',
              position: 'absolute',
              right: '15px',
              top: '24px'
            }}
          />
        </Box>
        <DialogContent sx={{ width: '450px' }}>
          <Typography variant="h6" align="center" sx={{ mb: '10px' }}>
            Are you sure you want to submit {isAutobid ? 'an autobid' : 'a starting bid'} of&nbsp;
            {isAutobid
              ? currencyFormat.format(autobidValue as number)
              : currencyFormat.format(bidValue as number)}
            ?
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
              mt: '15px'
            }}
          >
            <Button
              size="large"
              variant="contained"
              className={styles.cancelButton}
              onClick={handleConfirmDialogChange}
            >
              No, Cancel
            </Button>
            {isAutobid ? (
              <LoadingButton
                loading={isAutobidLoading}
                size="large"
                variant="contained"
                onClick={() => submitAutobidChange(vehicle.id)}
              >
                Yes, Submit
              </LoadingButton>
            ) : (
              <LoadingButton
                loading={isBidLoading}
                size="large"
                variant="contained"
                onClick={() => submitStartingBid(vehicle.id)}
              >
                Yes, Submit
              </LoadingButton>
            )}
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog open={adminOmvicDialogOpen} onClose={handleCloseAdminOmvicDialog}>
        <Box sx={{ textAlign: 'center' }}>
          <DialogTitle>Permission Error</DialogTitle>
          <CloseIcon
            onClick={handleCloseAdminOmvicDialog}
            sx={{
              cursor: 'pointer',
              position: 'absolute',
              right: '15px',
              top: '24px'
            }}
          />
        </Box>
        <DialogContent>
          {/* prettier-ignore */}
          <Typography>
            Your account is missing an Individual License/Registration Number (ex. OMVIC/MVDA/SAAQ etc).
            Go to Manage Account and select Account Information to update this.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'flex-end',
              mt: '15px'
            }}
          >
            <Button size="large" variant="contained" onClick={handleCloseAdminOmvicDialog}>
              Ok, Got it!
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog open={dialogConfirmCustomOpen} onClose={handleConfirmCustomDialogChange}>
        <Box sx={{ textAlign: 'center' }}>
          <DialogTitle>Custom Bid</DialogTitle>
          <CloseIcon
            onClick={handleConfirmCustomDialogChange}
            sx={{
              cursor: 'pointer',
              position: 'absolute',
              right: '15px',
              top: '24px'
            }}
          />
        </Box>
        <DialogContent sx={{ width: '450px' }}>
          <Typography variant="h6" align="center" sx={{ mb: '10px' }}>
            Are you sure you want to submit a custom bid of&nbsp;
            {currencyFormat.format(customBidValue as number)}?
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
              mt: '15px'
            }}
          >
            <Button
              size="large"
              variant="contained"
              className={styles.cancelButton}
              onClick={handleConfirmCustomDialogChange}
            >
              No, Cancel
            </Button>
            <LoadingButton
              loading={isBidLoading}
              size="large"
              variant="contained"
              onClick={() => submitBidChange(vehicle, customBidValue as string)}
            >
              Yes, Submit
            </LoadingButton>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
});

export default VehicleActionsBuy;
