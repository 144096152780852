import { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Box, Drawer, Typography, IconButton, Chip, Tab, Tabs, Button } from '@mui/material';
import { MHidden } from 'components/@material-extend';
import Scrollbar from 'components/Scrollbar';
import CloseIcon from '@mui/icons-material/Close';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import DeclarationsTab from './DeclarationsTab';
import DamagesTab from './DamagesTab';
import EquipmentTab from './EquipmentTab';
import OverviewTab from './OverviewTab';
import SellerTab from './SellerTab';
import { VehicleInfo } from 'types/vehicleInfo';
import { Dealership } from 'types/dealership';
import { useFirestore } from 'reactfire';
import { doc, getDoc } from 'firebase/firestore';
import { ImageCarousel } from 'components/shared/ImageCarousel';
import moment from 'moment';
import RunTimeCalc from 'utils/RuntimeCalc';

const imageFilters = [
  {
    label: 'Damages',
    isSelected: false
  },
  {
    label: 'Exterior',
    isSelected: false
  },
  {
    label: 'Interior',
    isSelected: false
  },
  {
    label: 'All',
    isSelected: true
  }
];

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

type AuctionDrawerProps = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
  tabIndex: number;
  vehicle: VehicleInfo;
};

export default function AuctionDrawer({
  isOpenSidebar,
  onCloseSidebar,
  tabIndex,
  vehicle
}: AuctionDrawerProps) {
  const theme = useTheme();
  const indexRef = useRef<number>(0);
  // prettier-ignore
  let allImages = vehicle.photos && vehicle.photos.length > 0 ? vehicle.photos.filter((photo) => Object.keys(photo).includes('url') && photo.url !== '') : null;
  if (allImages && allImages.length > 0) {
    allImages?.forEach((image: any, index: number) => {
      if (image.categoryName.toLowerCase() === 'damages') {
        allImages?.splice(index, 1);
        allImages?.unshift(image);
      }
    });
    // prettier-ignore
    let initialIndex = allImages.findIndex((image: any) => image.location.toLowerCase().includes('3/4 shot'));
    if (initialIndex && initialIndex >= 0) {
      indexRef.current = initialIndex;
    } else {
      indexRef.current = 0;
    }
  }

  let filteredImages: any[] = [];
  let [filters, setFilters] = useState(imageFilters);
  let [images, setImages] = useState<any[]>(allImages as any[]);
  const [tabValue, setTabValue] = useState(0);
  const firestore = useFirestore();
  const [dealership, setDealership] = useState<Dealership>();

  const getRuntime = (runtime: any) => {
    // console.log('runtime =>', runtime);
  };

  const getDealership = async () => {
    const docRef = doc(firestore, `dealerships/${vehicle.dealershipId}`);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const currentDealership = { id: docSnap.id, ...docSnap.data() } as Dealership;
      setDealership(currentDealership);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getDealership();
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const a11yProps = (index: number) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`
    };
  };

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const selectFilter = (filter: any) => {
    const updatedFilters: any[] = [];
    filters.forEach((f: any) => {
      if (f.label === filter.label) {
        f.isSelected = true;
      } else {
        f.isSelected = false;
      }
      updatedFilters.push(f);
    });

    setFilters(filters);
    filteredImages = [];
    filters.forEach((updatedFilter: any) => {
      if (updatedFilter.isSelected) {
        allImages?.forEach((image: any) => {
          if (
            image.categoryName.toLowerCase() === updatedFilter.label.toLowerCase() &&
            !filteredImages.includes(image)
          ) {
            filteredImages.push(image);
          }
        });
      }
    });
    if (filteredImages.length > 0) {
      setImages(filteredImages);
    } else {
      setImages(allImages as any[]);
    }
  };

  const useStyles = makeStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.palette.divider,
      [theme.breakpoints.down(1450)]: {
        width: '100%'
      }
    },
    buttonHeaderContainer: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 10px',
      height: '40px',
      backgroundColor: theme.palette.primary.main
    },
    buttonHeaderRight: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
      marginRight: '10px'
    },
    iconButtonContainer: {
      // marginLeft: '2px'
    },
    iconButton: {
      width: '25px',
      height: '25px',
      color: 'white'
    },
    timeRemaining: {
      lineHeight: 1,
      marginRight: '5px',
      color: 'white',
      fontSize: '14px',
      fontWeight: '700'
    },
    stockChip: {
      position: 'absolute',
      top: '50px',
      right: '10px',
      fontWeight: '700',
      color: 'white',
      zIndex: '999'
    },
    imageBox: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start'
    },
    filterChipContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '10px 0px',
      width: '100%'
    },
    filterChip: {
      margin: '5px 10px',
      color:
        theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.info.lighter,
      fontWeight: '700',
      '&.active': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.lighter
      }
    },
    slider: {
      display: 'flex',
      flexDirection: 'row',
      position: 'absolute',
      bottom: '-45px',
      width: 'calc(100% - 80px)',
      overflowX: 'scroll',
      scrollBehavior: 'smooth'
    }
  });

  let dmgNum: number;
  let intNum: number;
  let extNum: number;
  let allNum: number;

  if (allImages) {
    const intImages = allImages.filter((img: any) => img.categoryName === 'Interior');
    const extImages = allImages.filter((img: any) => img.categoryName === 'Exterior');
    const dmgImages = allImages.filter(
      (img: any) => img.categoryName === 'Damages' && img.fileName
    );
    dmgNum = dmgImages.length;
    intNum = intImages.length;
    extNum = extImages.length;
    allNum = dmgImages.length + intImages.length + extImages.length;
  }

  useEffect(() => {
    setTabValue(tabIndex);
    selectFilter({ label: 'All', isSelected: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenSidebar]);

  const timerRef = useRef<any>();
  const [extRunTime, setExtRunTime] = useState<string>();

  useEffect(() => {
    if (vehicle.bidExtension) {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
      let secondsRemaining = 15;
      timerRef.current = setInterval(() => {
        secondsRemaining = secondsRemaining - 1;
        const extRunTime = `${secondsRemaining}S Left!`;
        setExtRunTime(extRunTime);
        if (secondsRemaining <= 0) {
          clearInterval(timerRef.current);
        }
      }, 1000);
      return () => {
        if (secondsRemaining <= 0) {
          clearInterval(timerRef.current);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const styles = useStyles();

  type ChipsShowType = {
    filter: any;
    selectFilter: (filter: any) => void;
  };

  const ChipShow = (props: ChipsShowType) => {
    const { filter } = props;
    return (
      <Button
        onClick={() => {
          filter.isSelected = true;
          selectFilter(filter);
        }}
        sx={{
          padding: '0'
        }}
      >
        <Chip
          label={`${filter.label} (${
            filter.label === 'Damages'
              ? dmgNum
              : filter.label === 'Interior'
              ? intNum
              : filter.label === 'Exterior'
              ? extNum
              : allNum
          })`}
          className={styles.filterChip}
          sx={{
            color: filter.isSelected ? theme.palette.primary.main : '',
            backgroundColor: filter.isSelected ? theme.palette.primary.lighter : '',
            cursor: 'pointer'
          }}
        ></Chip>
      </Button>
    );
  };

  const content = (
    <Scrollbar
      sx={{
        height: '100%',
        backgroundColor: 'transparent' // theme.palette.common.white
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: 'auto'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            padding: '10px'
          }}
        >
          <Typography component="p" variant="h5">
            {vehicle?.details.year} {vehicle?.details.make}&nbsp;
            {vehicle?.details.model} {vehicle?.details.trim}
          </Typography>
          <IconButton onClick={onCloseSidebar} sx={{ display: 'flex' }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ position: 'relative', width: '100%' }}>
          {/* ------------- Show countdown --------------------*/}
          {vehicle.auction.auctionStatus === 'active' && (
            <Box
              className={styles.buttonHeaderContainer}
              sx={{
                marginLeft: '0px',
                fontSize: '13px',
                fontWeight: '700',
                color: theme.palette.common.white
              }}
            >
              <Box className={styles.buttonHeaderRight}>
                <AccessTimeIcon className={styles.iconButton} />
                {vehicle.bidExtension ? (
                  <Typography className={styles.timeRemaining} component="p">
                    {extRunTime}
                  </Typography>
                ) : (
                  <RunTimeCalc vehicle={vehicle} sendRunTimeToParent={getRuntime} />
                )}
              </Box>
            </Box>
          )}
          {/* -------------------------------------------- */}
          <Box sx={{ position: 'relative' }}>
            {allImages && (
              <ImageCarousel
                images={images ?? []}
                isVDP={true}
                isMarkedAsSold={vehicle.sellerMarkAsSold}
                initialIndex={indexRef.current}
              />
            )}
            <Box className={styles.filterChipContainer}>
              {filters &&
                filters.map((filter: any) => (
                  <ChipShow key={filter.label} filter={filter} selectFilter={selectFilter} />
                ))}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: '100%',
            marginTop: '40px'
          }}
        >
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons
            indicatorColor="secondary"
            textColor="inherit"
            aria-label="vehicle details tabs"
            sx={{
              borderTop:
                theme.palette.mode === 'dark'
                  ? `1px ${theme.palette.text.secondary} solid`
                  : `1px ${theme.palette.divider} solid`,
              borderBottom:
                theme.palette.mode === 'dark'
                  ? `1px ${theme.palette.text.secondary} solid`
                  : `1px ${theme.palette.divider} solid`,
              backgroundColor: theme.palette.background.paper,
              '& .MuiTabs-indicator': {
                display: 'none'
              },
              '& .Mui-selected': {
                backgroundColor: theme.palette.secondary.main,
                color: `${theme.palette.common.white} !important`
              }
            }}
          >
            <Tab
              title="Announcements/Disclosures"
              sx={{
                borderRadius: '0px',
                marginRight: '0px !important'
              }}
              {...a11yProps(0)}
              icon={<AssignmentLateIcon />}
            />

            <Tab
              title="Damages/Conditions"
              sx={{
                borderRadius: '0px',
                marginRight: '0px !important'
              }}
              {...a11yProps(1)}
              icon={<AssignmentIcon />}
            />

            <Tab
              title="Equipment"
              sx={{
                borderRadius: '0px',
                marginRight: '0px !important'
              }}
              {...a11yProps(2)}
              icon={<AssignmentTurnedInIcon />}
            />

            <Tab
              title="Vehicle Overview"
              sx={{
                borderRadius: '0px',
                marginRight: '0px !important'
              }}
              {...a11yProps(1)}
              icon={<DirectionsCarIcon />}
            />

            <Tab
              title="Seller’s Contact"
              sx={{
                borderRadius: '0px',
                marginRight: '0px !important'
              }}
              {...a11yProps(2)}
              icon={<AssignmentIndIcon />}
            />
          </Tabs>
          <TabPanel value={tabValue} index={0}>
            {vehicle && <DeclarationsTab vehicle={vehicle as VehicleInfo} />}
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            {vehicle && <DamagesTab vehicle={vehicle as VehicleInfo} />}
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            {vehicle && <EquipmentTab vehicle={vehicle as VehicleInfo} />}
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            {vehicle && <OverviewTab vehicle={vehicle as VehicleInfo} />}
          </TabPanel>
          <TabPanel value={tabValue} index={4}>
            {vehicle && dealership && <SellerTab vehicle={vehicle as VehicleInfo} />}
          </TabPanel>
        </Box>
      </Box>
    </Scrollbar>
  );

  return (
    <Box sx={{ backgroundColor: theme.palette.common.white }}>
      <MHidden width="mdUp">
        <Drawer
          sx={{ zIndex: 9999 }}
          variant="persistent"
          anchor={'right'}
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          ModalProps={{ keepMounted: true }}
          PaperProps={{ sx: { width: '80%', border: 'none' } }}
        >
          {content}
        </Drawer>
      </MHidden>
      <MHidden width="mdDown">
        <Drawer
          sx={{ zIndex: 9999 }}
          variant="persistent"
          anchor={'right'}
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          ModalProps={{ keepMounted: true }}
          PaperProps={{ sx: { width: 500, border: 'none' } }}
        >
          {content}
        </Drawer>
      </MHidden>
    </Box>
  );
}
