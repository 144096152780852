import { FC, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Box, Typography, IconButton, Divider, Button, Tabs, Tab } from '@mui/material';
import Scrollbar from 'components/Scrollbar';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import GavelIcon from '@mui/icons-material/Gavel';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { VehicleInfo } from 'types/vehicleInfo';
import { format } from 'date-fns';
import { Dealership } from 'types/dealership';
import { useFirestore } from 'reactfire';
import { doc, getDoc } from 'firebase/firestore';

import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  timelineOppositeContentClasses
} from '@mui/lab';
import moment from 'moment';

type PickupInfoType = {
  vehicle: VehicleInfo;
  styles: any;
  theme: any;
  isSeller: boolean;
};

const PickupInformation = (props: PickupInfoType) => {
  const { vehicle, styles, theme, isSeller } = props;
  return (
    <>
      <Box>
        <Typography className={styles.headerText} sx={{ marginTop: '10px' }}>
          Pickup Information
        </Typography>
        <Box className={styles.infoRow}>
          <Typography className={styles.rowText}>Dealership Name:</Typography>
          <Typography className={styles.rowText} sx={{ color: theme.palette.text.secondary }}>
            {vehicle.sellerDealership?.dealershipName}
          </Typography>
        </Box>
        <Box className={styles.infoRow}>
          <Typography className={styles.rowText}>Name of Seller:</Typography>
          {vehicle.auction.dealerRep && vehicle.auction.dealerRep.name && (
            <Typography className={styles.rowText} sx={{ color: theme.palette.text.secondary }}>
              {vehicle.auction.dealerRep.name}
            </Typography>
          )}
        </Box>
        <Box className={styles.infoRow}>
          <Typography className={styles.rowText}>Phone Number:</Typography>
          {vehicle.auction.dealerRep && vehicle.auction.dealerRep.phone && (
            <Typography className={styles.rowText} sx={{ color: theme.palette.text.secondary }}>
              {vehicle.auction.dealerRep.phone}
            </Typography>
          )}
        </Box>
        <Box className={styles.infoRow}>
          <Typography className={styles.rowText}>Date of Sale:</Typography>
          {vehicle.auction.dateSold ? (
            <Typography className={styles.rowText} sx={{ color: theme.palette.text.secondary }}>
              {format(vehicle.auction.dateSold.toDate(), 'dd MMM yyyy')}
            </Typography>
          ) : (
            <Typography className={styles.rowText} sx={{ color: theme.palette.text.secondary }}>
              Date not set
            </Typography>
          )}
        </Box>
        <Box className={styles.infoRow}>
          <Typography className={styles.rowText}>Date of Arrival:</Typography>
          {vehicle.auction.dateArrived ? (
            <Typography className={styles.rowText} sx={{ color: theme.palette.text.secondary }}>
              {format(vehicle.auction.dateArrived.toDate(), 'dd MMM yyyy')}
            </Typography>
          ) : (
            <Typography className={styles.rowText} sx={{ color: theme.palette.text.secondary }}>
              Date not set
            </Typography>
          )}
        </Box>
        <Box className={styles.infoRow} sx={{ mb: '0px !important' }}>
          <Typography className={styles.rowText}>Pickup Location:</Typography>
          {vehicle.auction.pickUpLocation && vehicle.auction.pickUpLocation.locationName && (
            <Typography
              className={styles.rowText}
              sx={{
                color: theme.palette.text.secondary,
                maxWidth: '150px',
                textAlign: 'right'
              }}
            >
              {vehicle.auction.pickUpLocation.locationName}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end'
          }}
        >
          {vehicle.auction.pickUpLocation && vehicle.auction.pickUpLocation.locationAddress && (
            <Typography
              className={styles.rowText}
              sx={{
                color: theme.palette.text.secondary,
                maxWidth: '150px',
                textAlign: 'right'
              }}
            >
              {vehicle.auction.pickUpLocation.locationAddress}
            </Typography>
          )}
          {vehicle.auction.pickUpLocation && vehicle.auction.pickUpLocation.locationCity && (
            <Typography
              className={styles.rowText}
              sx={{
                color: theme.palette.text.secondary,
                maxWidth: '150px',
                textAlign: 'right'
              }}
            >
              {vehicle.auction.pickUpLocation.locationCity}
            </Typography>
          )}
          {vehicle.auction.pickUpLocation && vehicle.auction.pickUpLocation.locationProvince && (
            <Typography
              className={styles.rowText}
              sx={{
                color: theme.palette.text.secondary,
                maxWidth: '150px',
                textAlign: 'right'
              }}
            >
              {vehicle.auction.pickUpLocation.locationProvince}
            </Typography>
          )}
          {vehicle.auction.pickUpLocation && vehicle.auction.pickUpLocation.locationCountry && (
            <Typography
              className={styles.rowText}
              sx={{
                color: theme.palette.text.secondary,
                maxWidth: '150px',
                textAlign: 'right'
              }}
            >
              {vehicle.auction.pickUpLocation.locationCountry}
            </Typography>
          )}
          {vehicle.auction.pickUpLocation && vehicle.auction.pickUpLocation.locationPostalCode && (
            <Typography
              className={styles.rowText}
              sx={{
                color: theme.palette.text.secondary,
                maxWidth: '150px',
                textAlign: 'right'
              }}
            >
              {vehicle.auction.pickUpLocation.locationPostalCode}
            </Typography>
          )}
          {vehicle.auction.pickUpLocation && vehicle.auction.pickUpLocation.locationPhone && (
            <Typography
              className={styles.rowText}
              sx={{
                color: theme.palette.text.secondary,
                maxWidth: '150px',
                textAlign: 'right'
              }}
            >
              {vehicle.auction.pickUpLocation.locationPhone}
            </Typography>
          )}
          {vehicle.auction.pickUpLocation && vehicle.auction.pickUpLocation.locationNotes && (
            <Typography
              className={styles.rowText}
              sx={{
                color: theme.palette.text.secondary,
                maxWidth: '150px',
                textAlign: 'right'
              }}
            >
              {vehicle.auction.pickUpLocation.locationNotes}
            </Typography>
          )}
        </Box>
      </Box>
      <Divider
        sx={{
          marginTop: '10px',
          marginBottom: '10px',
          borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
        }}
      />
      {isSeller ? (
        <>
          {vehicle.vehicleHistory && (
            <Box>
              <Typography className={styles.headerText} sx={{ marginTop: '10px' }}>
                Post auction status
              </Typography>
              <Timeline
                sx={{
                  [`& .${timelineOppositeContentClasses.root}`]: {
                    flex: 0.8
                  }
                }}
              >
                {vehicle.vehicleHistory.length > 0 &&
                  vehicle.vehicleHistory
                    .sort((a, b) => b.date.toMillis() - a.date.toMillis())
                    .map((item, index) => {
                      return (
                        <TimelineItem key={index}>
                          <TimelineOppositeContent
                            sx={{
                              fontSize: 12,
                              margin: 'auto 0',
                              color: theme.palette.text.secondary
                            }}
                            align="right"
                          >
                            {format(item.date.toDate(), 'dd MMM yyyy hh:mm a')}
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography
                              sx={{
                                fontSize: '12px',
                                backgroundColor:
                                  item.status === 'delivered'
                                    ? '#21BA45'
                                    : item.status === 'sold'
                                    ? 'blue'
                                    : item.status === 'no sale'
                                    ? 'red'
                                    : theme.palette.text.secondary,
                                padding: '0px 15px',
                                width: 'fit-content',
                                borderRadius: '15px',
                                color: theme.palette.common.white,
                                fontWeight: '700',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginRight: '3px',
                                textTransform: 'capitalize'
                              }}
                            >
                              {item.status}
                            </Typography>
                          </TimelineContent>
                        </TimelineItem>
                      );
                    })}
              </Timeline>
            </Box>
          )}
        </>
      ) : (
        <>
          {vehicle.buyerVehicleHistory && (
            <Box>
              <Typography className={styles.headerText} sx={{ marginTop: '10px' }}>
                Post auction status
              </Typography>
              <Timeline
                sx={{
                  [`& .${timelineOppositeContentClasses.root}`]: {
                    flex: 0.8
                  }
                }}
              >
                {vehicle.buyerVehicleHistory.length > 0 &&
                  vehicle.buyerVehicleHistory
                    .sort((a, b) => b.date.toMillis() - a.date.toMillis())
                    .map((item, index) => {
                      return (
                        <TimelineItem key={index}>
                          <TimelineOppositeContent
                            sx={{
                              fontSize: 12,
                              margin: 'auto 0',
                              color: theme.palette.text.secondary
                            }}
                            align="right"
                          >
                            {format(item.date.toDate(), 'dd MMM yyyy hh:mm a')}
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography
                              sx={{
                                fontSize: '12px',
                                backgroundColor:
                                  item.status === 'delivered'
                                    ? '#21BA45'
                                    : item.status === 'sold'
                                    ? 'blue'
                                    : item.status === 'no sale'
                                    ? 'red'
                                    : theme.palette.text.secondary,
                                padding: '0px 15px',
                                width: 'fit-content',
                                borderRadius: '15px',
                                color: theme.palette.common.white,
                                fontWeight: '700',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginRight: '3px',
                                textTransform: 'capitalize'
                              }}
                            >
                              {item.status}
                            </Typography>
                          </TimelineContent>
                        </TimelineItem>
                      );
                    })}
              </Timeline>
            </Box>
          )}
        </>
      )}
    </>
  );
};

type Props = {
  onClose: any;
  vehicle: VehicleInfo;
  isSeller: boolean;
  index?: number;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const HistoryDrawerContent: FC<Props> = (props: Props) => {
  const theme = useTheme();
  const firestore = useFirestore();
  const { onClose, vehicle, isSeller } = props;
  const [tabValue, setTabValue] = useState(0);
  const [dealership, setDealership] = useState<Dealership>();
  const photos = vehicle.photos.filter(
    (v: any) => v.url && v.location && v.location.toLowerCase().includes('3/4 shot')
  );

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });

  let vehiclePhoto;
  if (photos[0]) {
    vehiclePhoto = photos[0];
  }

  useEffect(() => {
    if (props.index) {
      setTabValue(props.index);
    }
  }, [props.index]);

  const onCloseSelf = () => {
    setTabValue(0);
    onClose();
  };

  const a11yProps = (index: number) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`
    };
  };

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  const getDealership = async () => {
    const docRef = doc(firestore, `dealerships/${vehicle.dealershipId}`);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const currentDealership = { id: docSnap.id, ...docSnap.data() } as Dealership;
      setDealership(currentDealership);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getDealership();
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const useStyles = makeStyles({
    scrollBar: {
      height: '100%',
      backgroundColor: theme.palette.common.white
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: 'auto'
    },
    innerContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      padding: '10px'
    },
    imageContainer: {
      width: '100%',
      borderRadius: '5px',
      margin: '20px auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: '15px 5px',
      backgroundColor: theme.palette.background.neutral
    },
    saleContainer: {
      width: '100%',
      borderRadius: '5px',
      margin: '20px auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: '15px 5px'
    },
    titleText: {
      fontWeight: '700',
      fontSize: '20px',
      marginBottom: '15px',
      color: theme.palette.text.secondary
    },
    headerText: {
      fontWeight: '700',
      fontSize: '16px',
      marginBottom: '20px',
      color: theme.palette.text.secondary,
      textTransform: 'uppercase'
    },
    infoRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      margin: '5px 0px'
    },
    rowText: {
      textTransform: 'capitalize'
    },
    carfaxContainer: {
      margin: '15px',
      // padding: '10px 10px',
      // borderWidth: '1px',
      // borderStyle: 'solid',
      // borderColor: theme.palette.divider,
      // borderRadius: '5px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start'
    },
    bidsText: {
      lineHeight: 1.5,
      marginLeft: '3px',
      fontSize: '14px',
      color: theme.palette.info.lighter
    }
  });

  const styles = useStyles();

  return (
    <Scrollbar className={styles.scrollBar}>
      <Box className={styles.container}>
        <Box className={styles.innerContainer}>
          <Typography component="p" variant="h5">
            {vehicle.details.year} {vehicle.details.make}&nbsp;
            {vehicle.details.model} {vehicle.details.trim}
          </Typography>
          <IconButton onClick={onCloseSelf} sx={{ display: 'flex' }}>
            <CloseIcon />
          </IconButton>
        </Box>
        {vehiclePhoto && vehiclePhoto.url && (
          <Box component="img" alt="vehicle photo" src={vehiclePhoto?.url as string} />
        )}
        <Box sx={{ padding: '0 20px' }}>
          {/* <Box
            className={styles.saleContainer}
            sx={{
              backgroundColor:
                vehicle.auction.postAuctionStatus.toLowerCase() === 'sold' ||
                vehicle.auction.postAuctionStatus.toLowerCase() === 'arrived' ||
                vehicle.auction.postAuctionStatus.toLowerCase() === 'delivered'
                  ? theme.palette.success.lighter
                  : theme.palette.background.neutral
            }}
          >
            <InfoOutlinedIcon sx={{ marginLeft: '10px' }} />
            {isSeller ? (
              <Box>
                {vehicle.auction.postAuctionStatus.toLowerCase() === 'sold' ||
                vehicle.auction.postAuctionStatus.toLowerCase() === 'arrived' ||
                vehicle.auction.postAuctionStatus.toLowerCase() === 'delivered' ? (
                  <Box>
                    {vehicle.auction.dateSold ? (
                      <Typography sx={{ marginLeft: '10px', textTransform: 'capitalize' }}>
                        Sale: Complete {format(vehicle.auction.dateSold.toDate(), 'dd MMM yyyy')}
                      </Typography>
                    ) : (
                      <Typography sx={{ marginLeft: '10px', textTransform: 'capitalize' }}>
                        Sale: {vehicle.auction.negotiationStatusSeller}
                      </Typography>
                    )}
                  </Box>
                ) : (
                  <Typography sx={{ marginLeft: '10px', textTransform: 'capitalize' }}>
                    Sale: {vehicle.auction.negotiationStatusSeller}
                  </Typography>
                )}
              </Box>
            ) : (
              <Box>
                {vehicle.auction.dateSold &&
                (vehicle.auction.postAuctionStatus.toLowerCase() === 'sold' ||
                  vehicle.auction.postAuctionStatus.toLowerCase() === 'arrived' ||
                  vehicle.auction.postAuctionStatus.toLowerCase() === 'delivered') ? (
                  <Typography sx={{ marginLeft: '10px' }}>
                    Sale: Complete {format(vehicle.auction.dateSold.toDate(), 'dd MMM yyyy')}
                  </Typography>
                ) : (
                  <Typography sx={{ marginLeft: '10px' }}>
                    Sale: {vehicle.auction.negotiationStatusBuyer}
                  </Typography>
                )}
              </Box>
            )}
          </Box> */}
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons
            indicatorColor="secondary"
            textColor="inherit"
            aria-label="vehicle details tabs"
            sx={{
              marginTop: '40px',
              backgroundColor: theme.palette.background.paper,
              '& .MuiTabs-indicator': {
                display: 'none'
              },
              '& .Mui-selected': {
                backgroundColor: theme.palette.secondary.main,
                color: `${theme.palette.common.white} !important`
              }
            }}
          >
            <Tab
              sx={{
                borderRadius: '0px',
                marginRight: '0px !important'
              }}
              {...a11yProps(0)}
              icon={<ReceiptIcon />}
            />
            <Tab
              sx={{
                borderRadius: '0px',
                marginRight: '0px !important'
              }}
              {...a11yProps(1)}
              icon={<GavelIcon />}
            />
            {isSeller && (
              <Tab
                sx={{
                  borderRadius: '0px',
                  marginRight: '0px !important'
                }}
                {...a11yProps(2)}
                icon={<CameraAltIcon />}
              />
            )}
          </Tabs>
          <Box>
            {isSeller ? (
              <TabPanel value={tabValue} index={0}>
                <Typography className={styles.titleText}>Contact & History</Typography>
                <Box>
                  <Typography className={styles.headerText} sx={{ marginTop: '10px' }}>
                    Buyer Information
                  </Typography>
                  {vehicle.buyerDealership?.dealershipName ? (
                    <>
                      <Typography className={styles.rowText} style={{ fontWeight: 700 }}>
                        {vehicle.buyerDealership?.dealershipName}
                      </Typography>
                      <Typography
                        className={styles.rowText}
                        sx={{ color: theme.palette.text.secondary }}
                      >
                        {vehicle.buyerUser?.firstName} {vehicle.buyerUser?.lastName}
                      </Typography>
                      <Box className={styles.infoRow}>
                        {/* prettier-ignore */}
                        <Typography
                          className={styles.rowText}
                          sx={{ color: theme.palette.text.secondary }}
                        >
                          {`${vehicle.buyerUser?.email || 'No email'} | ${vehicle.buyerUser?.phone}`}
                        </Typography>
                      </Box>
                    </>
                  ) : (
                    <Typography className={styles.rowText} style={{ fontWeight: 700 }}>
                      No Information
                    </Typography>
                  )}
                </Box>
                <Divider
                  sx={{
                    marginTop: '10px',
                    marginBottom: '10px',
                    borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
                  }}
                />
                <PickupInformation
                  vehicle={vehicle}
                  styles={styles}
                  theme={theme}
                  isSeller={isSeller}
                />
              </TabPanel>
            ) : (
              <TabPanel value={tabValue} index={0}>
                <Typography className={styles.titleText}>Contact & History</Typography>
                <Box>
                  <Typography className={styles.headerText} sx={{ marginTop: '10px' }}>
                    Seller Information
                  </Typography>
                  {vehicle.sellerDealership?.dealershipName && vehicle.auction.dealerRep ? (
                    <>
                      <Typography className={styles.rowText} style={{ fontWeight: 700 }}>
                        {vehicle.sellerDealership?.dealershipName}
                      </Typography>
                      <Typography
                        className={styles.rowText}
                        sx={{ color: theme.palette.text.secondary }}
                      >
                        {vehicle.auction.dealerRep.name}
                      </Typography>
                      <Box className={styles.infoRow}>
                        {/* prettier-ignore */}
                        <Typography
                          className={styles.rowText}
                          sx={{ color: theme.palette.text.secondary }}
                        >
                          {vehicle.auction.dealerRep.email} | {vehicle.auction.dealerRep.phone}
                        </Typography>
                      </Box>
                    </>
                  ) : (
                    <Typography className={styles.rowText} style={{ fontWeight: 700 }}>
                      No Information
                    </Typography>
                  )}
                </Box>
                <Divider
                  sx={{
                    marginTop: '10px',
                    marginBottom: '10px',
                    borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
                  }}
                />
                <PickupInformation
                  vehicle={vehicle}
                  styles={styles}
                  theme={theme}
                  isSeller={isSeller}
                />
              </TabPanel>
            )}
            <TabPanel value={tabValue} index={1}>
              <Typography className={styles.titleText}>Bidding History</Typography>
              <Box className={styles.infoRow}>
                <Typography className={styles.rowText}>Winning Bid:</Typography>
                {/* <Typography className={styles.rowText} sx={{ color: theme.palette.text.secondary }}>
                  {vehicle.buyerUser
                    ? vehicle.buyerUser.firstName + ' ' + vehicle.buyerUser.lastName
                    : 'No Winner'}
                </Typography> */}
                <Typography className={styles.rowText} sx={{ color: theme.palette.text.secondary }}>
                  {vehicle.buyerDealership ? vehicle.buyerDealership.dealershipName : 'No Winner'}
                </Typography>
              </Box>
              <Box className={styles.infoRow}>
                <Typography className={styles.rowText}>Winning Bid Amount:</Typography>
                <Typography className={styles.rowText} sx={{ color: theme.palette.text.secondary }}>
                  ${vehicle.auction.currentPrice ? vehicle.auction.currentPrice : '0'}
                </Typography>
              </Box>
              {vehicle.auction.previousBids && vehicle.auction.previousBids !== null && (
                <Box className={styles.infoRow}>
                  <Typography className={styles.rowText}>Total Bids:</Typography>
                  <Typography
                    className={styles.rowText}
                    sx={{ color: theme.palette.text.secondary }}
                  >
                    {vehicle.auction.previousBids.length}
                  </Typography>
                </Box>
              )}
              <Divider
                sx={{
                  marginTop: '20px',
                  borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
                }}
              />
              <Typography className={styles.titleText} sx={{ marginTop: '20px' }}>
                Bids
              </Typography>
              {vehicle.auction.negotiationHistory && (
                <>
                  <Typography className={styles.headerText} sx={{ marginTop: '20px' }}>
                    in negotiations
                  </Typography>
                  {vehicle.auction.negotiationHistory !== null &&
                    vehicle.auction.negotiationHistory.length > 0 &&
                    vehicle.auction.negotiationHistory
                      .sort((a, b) => b.dateTime.toMillis() - a.dateTime.toMillis())
                      .filter((item) => item.type !== 'Closing Bid')
                      .map((item, index) => {
                        return (
                          <Box
                            className={styles.infoRow}
                            key={index}
                            sx={{
                              // color: index === 0 ? '#21BA45' : theme.palette.text.secondary
                              color: '#21BA45'
                            }}
                          >
                            <Typography className={styles.rowText}>
                              {currencyFormat.format(parseInt(item.price.toString()))}
                            </Typography>
                            <Typography className={styles.rowText}>
                              {moment(item.dateTime.toDate()).format('ll') +
                                ' ' +
                                moment(item.dateTime.toDate()).format('hh:mm a')}
                            </Typography>
                            <Typography
                              className={styles.rowText}
                              sx={{
                                // color: index === 0 ? '#21BA45' : theme.palette.text.secondary
                                color: '#21BA45'
                              }}
                            >
                              {item.dealership ? item.dealership : item.user}
                            </Typography>
                          </Box>
                        );
                      })}
                  <Divider
                    sx={{
                      marginTop: '10px',
                      marginBottom: '10px',
                      borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
                    }}
                  />
                </>
              )}
              <Typography className={styles.headerText} sx={{ marginTop: '20px' }}>
                active auction
              </Typography>
              {vehicle.auction.previousBids &&
                vehicle.auction.previousBids !== null &&
                vehicle.auction.previousBids
                  .sort((a, b) => b.dateTime.toMillis() - a.dateTime.toMillis())
                  .map((item, index) => {
                    return (
                      <Box className={styles.infoRow} key={index}>
                        <Typography className={styles.rowText}>
                          {currencyFormat.format(parseInt(item.price.toString()))}
                        </Typography>
                        <Typography className={styles.rowText}>
                          {moment(item.dateTime.toDate()).format('ll') +
                            ' ' +
                            moment(item.dateTime.toDate()).format('hh:mm a')}
                        </Typography>
                        <Typography
                          className={styles.rowText}
                          sx={{ color: theme.palette.text.secondary }}
                        >
                          {item.bidderName}
                        </Typography>
                      </Box>
                    );
                  })}
            </TabPanel>
            {isSeller && (
              <TabPanel value={tabValue} index={2}>
                <Typography className={styles.titleText}>Capture History</Typography>
                <Box className={styles.infoRow}>
                  <Typography className={styles.rowText}>Captured By:</Typography>
                  <Typography
                    className={styles.rowText}
                    sx={{ color: theme.palette.text.secondary }}
                  >
                    {vehicle.auction.captureRepName}
                  </Typography>
                </Box>
                <Box className={styles.infoRow}>
                  <Typography className={styles.rowText}>Captured Date:</Typography>
                  {vehicle.auction.captureDate && (
                    <Typography
                      className={styles.rowText}
                      sx={{ color: theme.palette.text.secondary }}
                    >
                      {format(vehicle.auction.captureDate.toDate(), 'dd MMM yyyy')}
                    </Typography>
                  )}
                </Box>
                <Divider
                  sx={{
                    marginTop: '20px',
                    borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
                  }}
                />
                <Typography className={styles.headerText} sx={{ marginTop: '20px' }}>
                  Edit History
                </Typography>
                {vehicle.auction.captureHistory &&
                  vehicle.auction.captureHistory.map((item, index) => {
                    return (
                      <Box className={styles.infoRow} key={index}>
                        <Typography className={styles.rowText}>
                          {item.captureRepName}: Update
                        </Typography>
                        <Typography
                          className={styles.rowText}
                          sx={{ color: theme.palette.text.secondary }}
                        >
                          {format(item.date.toDate(), 'dd MMM yyyy')}
                        </Typography>
                      </Box>
                    );
                  })}
              </TabPanel>
            )}
          </Box>
        </Box>
      </Box>
    </Scrollbar>
  );
};

export default HistoryDrawerContent;
