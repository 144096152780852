import { ComponentType, Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import LoadingScreen from '../components/LoadingScreen';
import AuctionPageBuy from 'pages/auction/buyer/AuctionPageBuy';
import ManageDealersPage from 'pages/admin/ManageDealersPage';
import ManageTeamsPage from 'pages/admin/ManageTeamPage';
// import AdminReportsPage from 'pages/admin/ReportsPage';
import PreAuctionPage from 'pages/auction/seller/PreAuctionPage';
import PostAuctionPage from 'pages/auction/seller/PostAuctionPage';
import NotificationsPage from 'pages/account/NotificationsPage';
import ProfilePage from 'pages/account/ProfilePage';
import SecurityPage from 'pages/account/SecurityPage';
import AuctionPageSell from 'pages/auction/seller/AuctionPageSell';
import PostAuctionPageBuy from 'pages/auction/buyer/PostAuctionPageBuy';
import PreAuctionPageBuy from 'pages/auction/buyer/PreAuctionPageBuy';
import VehicleCapturePage from 'pages/auction/seller/VehicleCapturePage';
import CaptureWorkflowPage from 'pages/auction/seller/CaptureWorkflowPage';
// import BuyerDashboard from 'pages/dashboard/Dashboard';
import AdminGuard from 'guards/AdminGuard';
// import SuspensionGuard from 'guards/SuspensionGuard';
import SuperAdminGuard from 'guards/SuperAdminGuard';
import FindDealershipPage from 'pages/admin/FindDealershipPage';
import BuyerNetworkPage from 'pages/admin/BuyerNetworkPage';
import SellerNetworkPage from 'pages/admin/SellerNetworkPage';
import VDPPage from 'pages/auction/VDP';
import CaptureRepGuard from 'guards/CaptureRepGuard';
import ReportsPage from 'pages/admin/ReportsPage';
import ArrivedReportsPage from 'pages/admin/ArrivedReportPage';
import DeliveredReportsPage from 'pages/admin/DeliveredReportPage';
import LostVehicleHistory from 'pages/auction/buyer/LostVehicleHistory';
import LiveAuctionPageBuy from 'pages/auction/buyer/LiveAuctionPageBuy';
import RunListBuy from 'pages/auction/buyer/RunListBuy';
import RunListSell from 'pages/auction/seller/RunListSell';
import LiveAuctionPageSell from 'pages/auction/seller/LiveAuctionPageSell';
// import BuyerOnlyGuard from 'guards/BuyerOnlyGuard';
// ----------------------------------------------------------------------

const Loadable = (Component: any) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          // path: 'register/dn/:dealershipId/fn/:fName/ln/:lName/e/:email/r/:role/i/:inviteId/sid/:sendingDealershipId/nt/:networkType',
          path: 'register/id/:inviteId/',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'reset-password', element: <ResetPassword /> }
        // {
        //   path: 'register',
        //   element: (
        //     <GuestGuard>
        //       <Register />
        //     </GuestGuard>
        //   )
        // },
        // { path: 'login-unprotected', element: <Login /> },
        // { path: 'register-unprotected', element: <Register /> },
        // { path: 'verify', element: <VerifyCode /> },
        // { path: 'select-account', element: <SelectAccount /> }
      ]
    },
    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ index: true, element: <BuyerDashboard /> }]
    },
    // {
    //   path: 'vdp',
    //   element: (
    //     <AuthGuard>
    //       {/* <SuspensionGuard> */}
    //       <DashboardLayout />
    //       {/* </SuspensionGuard> */}
    //     </AuthGuard>
    //   ),
    //   children: [{ path: 'vehicle-details/:vehicleId', element: <VDPPage /> }]
    // },
    {
      path: 'buy',
      element: (
        <AuthGuard>
          {/* <SuspensionGuard> */}
          <CaptureRepGuard>
            <DashboardLayout />
          </CaptureRepGuard>
          {/* </SuspensionGuard> */}
        </AuthGuard>
      ),
      children: [
        { path: 'pre-auction', element: <PreAuctionPageBuy /> },
        { path: 'run-list', element: <RunListBuy /> },
        { path: 'active-auctions', element: <AuctionPageBuy /> },
        { path: 'live-auction', element: <LiveAuctionPageBuy /> },
        { path: 'post-auction', element: <PostAuctionPageBuy /> },
        { path: 'post-auction/:tabValue', element: <PostAuctionPageBuy /> },
        { path: 'vehicle-bidding-history', element: <LostVehicleHistory /> }
      ]
    },
    {
      path: 'sell',
      element: (
        <AuthGuard>
          {/* <SuspensionGuard> */}
          {/* <BuyerOnlyGuard> */}
          <DashboardLayout />
          {/* </BuyerOnlyGuard> */}
          {/* </SuspensionGuard> */}
        </AuthGuard>
      ),
      children: [
        {
          path: 'active-auctions',
          element: (
            <CaptureRepGuard>
              <AuctionPageSell />
            </CaptureRepGuard>
          )
        },
        { path: 'pre-auction', element: <PreAuctionPage /> },
        { path: 'run-list', element: <RunListSell /> },
        { path: 'live-auction', element: <LiveAuctionPageSell /> },
        {
          path: 'post-auction',
          element: (
            <CaptureRepGuard>
              <PostAuctionPage />
            </CaptureRepGuard>
          )
        },
        {
          path: 'post-auction/:tabValue',
          element: (
            <CaptureRepGuard>
              <PostAuctionPage />
            </CaptureRepGuard>
          )
        },
        { path: 'vehicle-capture', element: <VehicleCapturePage /> },
        { path: 'capture-workflow/:vehicleId', element: <CaptureWorkflowPage /> }
      ]
    },
    {
      path: 'community',
      element: (
        <AuthGuard>
          {/* <SuspensionGuard> */}
          {/* <AdminGuard> */}
          <DashboardLayout />
          {/* </AdminGuard> */}
          {/* </SuspensionGuard> */}
        </AuthGuard>
      ),
      children: [
        { path: 'buyer-network', element: <BuyerNetworkPage /> },
        {
          path: 'seller-network',
          element: (
            // <BuyerOnlyGuard>
            <SellerNetworkPage />
            // </BuyerOnlyGuard>
          )
        },
        { path: 'find-dealership', element: <FindDealershipPage /> }
      ]
    },
    {
      path: 'admin',
      element: (
        <AuthGuard>
          {/* <SuspensionGuard> */}
          <AdminGuard>
            <DashboardLayout />
          </AdminGuard>
          {/* </SuspensionGuard> */}
        </AuthGuard>
      ),
      children: [
        {
          path: 'manage-dealers',
          element: (
            <SuperAdminGuard>
              <ManageDealersPage />
            </SuperAdminGuard>
          )
        },
        // { path: 'dealer-network', element: <DealerNetworkPage /> },
        { path: 'manage-teams', element: <ManageTeamsPage /> },
        {
          path: 'reports',
          // element: (<ReportsPage />),
          children: [
            { index: true, element: <ReportsPage /> },
            { path: 'arrived-vehicles', element: <ArrivedReportsPage /> },
            { path: 'delivered-vehicles', element: <DeliveredReportsPage /> }
          ]
        }
      ]
    },
    {
      path: 'profile',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { index: true, element: <ProfilePage /> },
        { path: 'account-information', element: <AccountPage /> },
        { path: 'security', element: <SecurityPage /> },
        { path: 'notifications', element: <NotificationsPage /> },
        { path: 'manage-account', element: <ManagePage /> }
      ]
    },
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: <Navigate to="/auth/login" replace />
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// prettier-ignore
const lazyRetry = function (componentImport: { (): Promise<typeof import("../pages/dashboard/Dashboard")>; (): Promise<any>; }) {
  return new Promise<{ default: ComponentType<any> }>((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
    );
    // try to import the component
    componentImport().then((component) => {
      window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
      resolve(component);
    }).catch((error) => {
      if (!hasRefreshed) { // not been refreshed yet
        window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
        return window.location.reload(); // refresh the page
      }
      reject(error); // Default error behaviour as already tried refresh
    });
  });
};

// IMPORT COMPONENTS
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
// prettier-ignore
const BuyerDashboard = Loadable(lazy(() => lazyRetry(() => import('../pages/dashboard/Dashboard'))));
const AccountPage = Loadable(lazy(() => import('../pages/account/AccountPage')));
const ManagePage = Loadable(lazy(() => import('../pages/account/ManagePage')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
