import {
  Container,
  Typography,
  Grid,
  Paper,
  Box,
  MenuItem,
  Tabs,
  Tab,
  Select,
  InputBase,
  SelectChangeEvent,
  TablePagination,
  Divider,
  Alert,
  AlertTitle,
  Dialog,
  Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from '@mui/material/styles';
import * as React from 'react';
import { useState, useEffect, useRef, useMemo } from 'react';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import { DebounceInput } from 'react-debounce-input';
import ArrivedListItemBuy from 'components/buyer/postAuction/ArrivedListItemBuy';
import DeliveredListItemBuy from 'components/buyer/postAuction/DeliveredListItemBuy';
import LostListItemBuy from 'components/buyer/postAuction/LostListItemBuy';
import PendingListItemBuy from 'components/buyer/postAuction/PendingListItemBuy';
import WonListItemBuy from 'components/buyer/postAuction/WonListItemBuy';
import { VehicleInfo } from 'types/vehicleInfo';
import { useFirestore } from 'reactfire';
import {
  doc,
  DocumentReference,
  limit,
  onSnapshot,
  orderBy,
  where,
  getDoc,
  collection,
  CollectionReference,
  query,
  arrayUnion,
  collectionGroup,
  DocumentSnapshot,
  arrayRemove
} from 'firebase/firestore';
import useAuth from 'hooks/useAuth';
import { useLocation, useNavigate } from 'react-router-dom';
import Tour from 'reactour';
import { User } from 'constants/user';
import { useFirestoreByPath } from 'hooks/useFirestoreByPath';
import EmptyStateMessage from 'components/shared/EmptyStateMessage';
import { sortNegotiationVehiclesArray } from 'utils/searchHelper';
import { ViewportList } from 'react-viewport-list';
import SharedDrawer from 'components/shared/SharedDrawer';
import HistoryDrawerContent from 'components/shared/HistoryDrawerContent';
import AuctionDrawer from 'components/auction/drawer/AuctionDrawer';
import BillofSale from 'components/admin/BillofSale';
import { useVehicleBatch } from 'utils/vehicleQuery';
import { STRIPE_PUBLISHABLE_KEY } from '../../../config';
import { Dealership } from 'types/dealership';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  id?: string;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`notifications-tabpanel-${index}`}
      aria-labelledby={`notifications-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `post-auction-tab-${index}`,
    'aria-controls': `post-auction-tabpanel-${index}`
  };
};

export default function PostAuctionPageBuy() {
  const { themeStretch } = useSettings();
  const theme = useTheme();
  const firestore = useFirestore();
  const { user, currentUser, logout } = useAuth();
  const [searchValue, setSearchValue] = useState('');
  const [, setAnchorEl] = useState(false);
  const [popupOpened] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState<any[]>();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [currentDealership, setCurrentDealership] = useState<Dealership>();

  const handleToggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const getCurrentDealership = async () => {
    const userDealershipRef = doc(firestore, `dealerships/${currentUser.currentDealershipId}`);
    const userDealerSnap = await getDoc(userDealershipRef);
    if (userDealerSnap.exists()) {
      const data = userDealerSnap.data();
      const currentDealership = { ...data, id: userDealerSnap.id } as Dealership;
      setCurrentDealership(currentDealership);
      if (currentDealership.paymentMethods && currentDealership.paymentMethods.length > 0) {
        const tempPaymentMethods: any[] = [];
        currentDealership.paymentMethods.forEach((paymentMethod: any) => {
          tempPaymentMethods.push(paymentMethod);
        });
        setPaymentMethods(tempPaymentMethods);
      }
    }
  };

  const locationState = useLocation().state as { tabValue: number };
  let navigateTabValue;
  if (locationState) {
    navigateTabValue = locationState.tabValue;
  } else {
    navigateTabValue = 0;
  }
  const [tabValue, setTabValue] = useState(navigateTabValue);

  const [orderPending, setOrderPending] = useState('newest');
  const [pagePending, setPagePending] = useState(0);
  const [rowsPerPagePending, setRowsPerPagePending] = useState(25);
  const [lastDocPendingArray, setLastDocPendingArray] = useState<any[]>([]);
  const [firstDocPendingArray, setFirstDocPendingArray] = useState<any[]>([]);
  const [backPagePending, setBackPagePending] = useState<boolean>(false);
  const [lastDataVehicleRefPending, setLastDataVehicleRefPending] = useState<DocumentReference>();

  const [collectionSizePending, setCollectionSizePending] = useState<number>(0);
  const countVehicleCollectionPending = () => {
    const vehicleRef = collection(firestore, 'vehicles') as CollectionReference;
    const vehicleQuery = query(
      vehicleRef,
      // where('networkDealerships', 'array-contains', currentUser.currentDealershipId),
      where('auction.auctionStatus', '==', 'post auction'),
      where('auction.postAuctionStatus', 'in', ['pending', 'Pending']),
      where('auction.negotiationStatusSeller', '==', 'action required'),
      where('auction.bidLeaderId', '==', user?.uid)
    );
    const unsubsribePending = onSnapshot(vehicleQuery, (querySnapshot) => {
      setCollectionSizePending(querySnapshot.size);
    });
    return unsubsribePending;
  };

  const queryConstraintsPending = [
    // where('networkDealerships', 'array-contains', currentUser.currentDealershipId),
    where('auction.auctionStatus', '==', 'post auction'),
    where('auction.postAuctionStatus', 'in', ['pending', 'Pending']),
    where('auction.negotiationStatusSeller', '==', 'action required'),
    where('auction.bidLeaderId', '==', user?.uid),
    orderBy('auction.endDate', 'desc'),
    orderBy('auction.endTime', 'desc'),
    limit(rowsPerPagePending)
  ];

  const pendingData = useVehicleBatch(
    pagePending,
    queryConstraintsPending,
    lastDocPendingArray[pagePending - 1],
    backPagePending,
    firstDocPendingArray[pagePending]
  );

  const pendingVehicles = useMemo(() => {
    const lastDoc = pendingData?.data?.docs[pendingData?.data?.docs?.length - 1];
    if (lastDoc) {
      // prettier-ignore
      const lastDocRef = pendingData.data?.docs[pendingData.data?.docs?.length - 1].ref as DocumentReference;
      setLastDataVehicleRefPending(lastDocRef);
    }
    // prettier-ignore
    return pendingData.data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id }));
  }, [pendingData.data]);

  const [orderNegotiation, setOrderNegotiation] = useState('newest');
  const [pageNegotiation, setPageNegotiation] = useState(0);
  const [rowsPerPageNegotiation, setRowsPerPageNegotiation] = useState(25);
  const [lastDocNegotiationArray, setLastDocNegotiationArray] = useState<any[]>([]);
  const [firstDocNegotiationArray, setFirstDocNegotiationArray] = useState<any[]>([]);
  const [backPageNegotiation, setBackPageNegotiation] = useState<boolean>(false);
  // prettier-ignore
  const [lastDataVehicleRefNegotiation, setLastDataVehicleRefNegotiation] = useState<DocumentReference>();

  const [collectionSizeNegotiation, setCollectionSizeNegotiation] = useState<number>(0);
  const countVehicleCollectionNegotiation = () => {
    const vehicleRef = collection(firestore, 'vehicles') as CollectionReference;
    const vehicleQuery = query(
      vehicleRef,
      // where('networkDealerships', 'array-contains', currentUser.currentDealershipId),
      where('auction.auctionStatus', '==', 'post auction'),
      where('auction.postAuctionStatus', 'in', ['pending', 'Pending']),
      where('auction.negotiationStatusSeller', '!=', 'action required'),
      where('auction.bidLeaderId', '==', user?.uid),
      orderBy('auction.negotiationStatusSeller', 'asc')
    );
    const unsubsribeNegotiation = onSnapshot(vehicleQuery, (querySnapshot) => {
      setCollectionSizeNegotiation(querySnapshot.size);
    });
    return unsubsribeNegotiation;
  };

  const queryConstraintsNegotiation = [
    // where('networkDealerships', 'array-contains', currentUser.currentDealershipId),
    where('auction.auctionStatus', '==', 'post auction'),
    where('auction.postAuctionStatus', 'in', ['pending', 'Pending']),
    where('auction.negotiationStatusSeller', '!=', 'action required'),
    where('auction.bidLeaderId', '==', user?.uid),
    orderBy('auction.negotiationStatusSeller', 'asc'),
    orderBy('auction.endDate', 'desc'),
    orderBy('auction.endTime', 'desc'),
    limit(rowsPerPageNegotiation)
  ];

  const negotiationData = useVehicleBatch(
    pageNegotiation,
    queryConstraintsNegotiation,
    lastDocNegotiationArray[pageNegotiation - 1],
    backPageNegotiation,
    firstDocNegotiationArray[pageNegotiation]
  );

  const negotiationVehicles = useMemo(() => {
    const lastDoc = negotiationData?.data?.docs[negotiationData?.data?.docs?.length - 1];
    if (lastDoc) {
      // prettier-ignore
      const lastDocRef = negotiationData.data?.docs[negotiationData.data?.docs?.length - 1].ref as DocumentReference;
      setLastDataVehicleRefNegotiation(lastDocRef);
    }
    // prettier-ignore
    return negotiationData.data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id }));
  }, [negotiationData.data]);

  const [orderWon, setOrderWon] = useState('newest');
  const [pageWon, setPageWon] = useState(0);
  const [rowsPerPageWon, setRowsPerPageWon] = useState(25);
  const [lastDocWonArray, setLastDocWonArray] = useState<any[]>([]);
  const [firstDocWonArray, setFirstDocWonArray] = useState<any[]>([]);
  const [backPageWon, setBackPageWon] = useState<boolean>(false);
  const [lastDataVehicleRefWon, setLastDataVehicleRefWon] = useState<DocumentReference>();

  const [collectionSizeWon, setCollectionSizeWon] = useState<number>(0);
  const countVehicleCollectionWon = () => {
    const vehicleRef = collection(firestore, 'vehicles') as CollectionReference;
    const vehicleQuery = query(
      vehicleRef,
      // where('networkDealerships', 'array-contains', currentUser.currentDealershipId),
      where('auction.auctionStatus', '==', 'post auction'),
      where('auction.postAuctionStatus', 'in', ['sold', 'Sold']),
      where('auction.bidLeaderId', '==', user?.uid)
    );
    const unsubsribeWon = onSnapshot(vehicleQuery, (querySnapshot) => {
      setCollectionSizeWon(querySnapshot.size);
    });
    return unsubsribeWon;
  };

  const queryConstraintsWon = [
    // where('networkDealerships', 'array-contains', currentUser.currentDealershipId),
    where('auction.auctionStatus', '==', 'post auction'),
    where('auction.postAuctionStatus', 'in', ['sold', 'Sold']),
    where('auction.bidLeaderId', '==', user?.uid),
    orderBy('auction.endDate', 'desc'),
    orderBy('auction.endTime', 'desc'),
    limit(rowsPerPageWon)
  ];

  const wonData = useVehicleBatch(
    pageWon,
    queryConstraintsWon,
    lastDocWonArray[pageWon - 1],
    backPageWon,
    firstDocWonArray[pageWon]
  );

  const wonVehicles = useMemo(() => {
    const lastDoc = wonData?.data?.docs[wonData?.data?.docs?.length - 1];
    if (lastDoc) {
      // prettier-ignore
      const lastDocRef = wonData.data?.docs[wonData.data?.docs?.length - 1].ref as DocumentReference;
      setLastDataVehicleRefWon(lastDocRef);
    }
    // prettier-ignore
    return wonData.data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id }));
  }, [wonData.data]);

  const [orderArrived, setOrderArrived] = useState('newest');
  const [pageArrived, setPageArrived] = useState(0);
  const [rowsPerPageArrived, setRowsPerPageArrived] = useState(25);
  const [lastDocArrivedArray, setLastDocArrivedArray] = useState<any[]>([]);
  const [firstDocArrivedArray, setFirstDocArrivedArray] = useState<any[]>([]);
  const [backPageArrived, setBackPageArrived] = useState<boolean>(false);
  const [lastDataVehicleRefArrived, setLastDataVehicleRefArrived] = useState<DocumentReference>();

  const [collectionSizeArrived, setCollectionSizeArrived] = useState<number>(0);
  const countVehicleCollectionArrived = () => {
    const vehicleRef = collection(firestore, 'vehicles') as CollectionReference;
    const vehicleQuery = query(
      vehicleRef,
      // where('networkDealerships', 'array-contains', currentUser.currentDealershipId),
      where('auction.auctionStatus', '==', 'post auction'),
      where('auction.postAuctionStatus', 'in', ['arrived', 'Arrived']),
      where('auction.bidLeaderId', '==', user?.uid)
    );
    const unsubsribeArrived = onSnapshot(vehicleQuery, (querySnapshot) => {
      setCollectionSizeArrived(querySnapshot.size);
    });
    return unsubsribeArrived;
  };

  const queryConstraintsArrived = [
    // where('networkDealerships', 'array-contains', currentUser.currentDealershipId),
    where('auction.auctionStatus', '==', 'post auction'),
    where('auction.postAuctionStatus', 'in', ['arrived', 'Arrived']),
    where('auction.bidLeaderId', '==', user?.uid),
    orderBy('auction.endDate', 'desc'),
    orderBy('auction.endTime', 'desc'),
    limit(rowsPerPageArrived)
  ];

  const arrivedData = useVehicleBatch(
    pageArrived,
    queryConstraintsArrived,
    lastDocArrivedArray[pageArrived - 1],
    backPageArrived,
    firstDocArrivedArray[pageArrived]
  );

  const arrivedVehicles = useMemo(() => {
    const lastDoc = arrivedData?.data?.docs[arrivedData?.data?.docs?.length - 1];
    if (lastDoc) {
      // prettier-ignore
      const lastDocRef = arrivedData.data?.docs[arrivedData.data?.docs?.length - 1].ref as DocumentReference;
      setLastDataVehicleRefArrived(lastDocRef);
    }
    // prettier-ignore
    return arrivedData.data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id }));
  }, [arrivedData.data]);

  const [orderDelivered, setOrderDelivered] = useState('newest');
  const [pageDelivered, setPageDelivered] = useState(0);
  const [rowsPerPageDelivered, setRowsPerPageDelivered] = useState(25);
  const [lastDocDeliveredArray, setLastDocDeliveredArray] = useState<any[]>([]);
  const [firstDocDeliveredArray, setFirstDocDeliveredArray] = useState<any[]>([]);
  const [backPageDelivered, setBackPageDelivered] = useState<boolean>(false);
  // prettier-ignore
  const [lastDataVehicleRefDelivered, setLastDataVehicleRefDelivered] = useState<DocumentReference>();

  const [collectionSizeDelivered, setCollectionSizeDelivered] = useState<number>(0);
  const countVehicleCollectionDelivered = () => {
    const vehicleRef = collection(firestore, 'vehicles') as CollectionReference;
    const vehicleQuery = query(
      vehicleRef,
      // where('networkDealerships', 'array-contains', currentUser.currentDealershipId),
      where('auction.auctionStatus', '==', 'post auction'),
      where('auction.postAuctionStatus', 'in', ['delivered', 'Delivered']),
      where('auction.bidLeaderId', '==', user?.uid)
    );
    const unsubsribeDelivered = onSnapshot(vehicleQuery, (querySnapshot) => {
      setCollectionSizeDelivered(querySnapshot.size);
    });
    return unsubsribeDelivered;
  };

  const queryConstraintsDelivered = [
    // where('networkDealerships', 'array-contains', currentUser.currentDealershipId),
    where('auction.auctionStatus', '==', 'post auction'),
    where('auction.postAuctionStatus', 'in', ['delivered', 'Delivered']),
    where('auction.bidLeaderId', '==', user?.uid),
    orderBy('auction.dateDelivered', 'desc'),
    // orderBy('auction.endTime', 'desc'),
    limit(rowsPerPageDelivered)
  ];

  const deliveredData = useVehicleBatch(
    pageDelivered,
    queryConstraintsDelivered,
    lastDocDeliveredArray[pageDelivered - 1],
    backPageDelivered,
    firstDocDeliveredArray[pageDelivered]
  );

  const deliveredVehicles = useMemo(() => {
    const lastDoc = deliveredData?.data?.docs[deliveredData?.data?.docs?.length - 1];
    if (lastDoc) {
      // prettier-ignore
      const lastDocRef = deliveredData.data?.docs[deliveredData.data?.docs?.length - 1].ref as DocumentReference;
      setLastDataVehicleRefDelivered(lastDocRef);
    }
    // prettier-ignore
    return deliveredData.data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id }));
  }, [deliveredData.data]);

  const [orderLost, setOrderLost] = useState('newest');
  const [pageLost, setPageLost] = useState(0);
  const [rowsPerPageLost, setRowsPerPageLost] = useState(25);
  const [lastDocLostArray, setLastDocLostArray] = useState<any[]>([]);
  const [firstDocLostArray, setFirstDocLostArray] = useState<any[]>([]);
  const [backPageLost, setBackPageLost] = useState<boolean>(false);
  const [lastDataVehicleRefLost, setLastDataVehicleRefLost] = useState<DocumentReference>();

  const [collectionSizeLost, setCollectionSizeLost] = useState<number>(0);
  const countVehicleCollectionLost = () => {
    const vehicleRef = collection(firestore, 'vehicles') as CollectionReference;
    const vehicleQuery = query(
      vehicleRef,
      // where('auction.previousBidders', 'array-contains', user?.uid),
      where('auction.bidLeaderId', '==', user?.uid),
      where('auction.auctionStatus', '==', 'post auction'),
      where('auction.postAuctionStatus', '==', 'no sale')
    );
    const unsubsribeLost = onSnapshot(vehicleQuery, (querySnapshot) => {
      setCollectionSizeLost(querySnapshot.size);
    });
    return unsubsribeLost;
  };

  const queryConstraintsLost = [
    // where('auction.previousBidders', 'array-contains', user?.uid),
    where('auction.bidLeaderId', '==', user?.uid),
    where('auction.auctionStatus', '==', 'post auction'),
    where('auction.postAuctionStatus', '==', 'no sale'),
    orderBy('auction.endDate', 'desc'),
    orderBy('auction.endTime', 'desc'),
    limit(rowsPerPageLost)
  ];

  const lostData = useVehicleBatch(
    pageLost,
    queryConstraintsLost,
    lastDocLostArray[pageLost - 1],
    backPageLost,
    firstDocLostArray[pageLost]
  );

  const lostVehicles = useMemo(() => {
    const lastDoc = lostData?.data?.docs[lostData?.data?.docs?.length - 1];
    if (lastDoc) {
      // prettier-ignore
      const lastDocRef = lostData.data?.docs[lostData.data?.docs?.length - 1].ref as DocumentReference;
      setLastDataVehicleRefLost(lastDocRef);
    }
    // prettier-ignore
    return lostData.data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id }));
  }, [lostData.data]);

  useEffect(() => {
    const unsubscribePending = countVehicleCollectionPending();
    const unsubscribeNegotiation = countVehicleCollectionNegotiation();
    const unsubscribeWon = countVehicleCollectionWon();
    const unsubscribeArrived = countVehicleCollectionArrived();
    const unsubscribeDelivered = countVehicleCollectionDelivered();
    const unsubscribeLost = countVehicleCollectionLost();
    return () => {
      unsubscribePending();
      unsubscribeNegotiation();
      unsubscribeWon();
      unsubscribeArrived();
      unsubscribeDelivered();
      unsubscribeLost();
    };
  }, []);

  ///-------------------------------------------->

  const [tourOpen, setTourOpen] = useState(false);
  const { update: updateUser } = useFirestoreByPath<User>('users');
  const [, setCurUser] = useState<User>();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [openBidDrawer, setOpenBidDrawer] = useState(false);

  const pendingRef = useRef(null);
  const negotiationRef = useRef(null);
  const wonRef = useRef(null);
  const arrivedRef = useRef(null);
  const deliveredRef = useRef(null);
  const lostRef = useRef(null);

  const [userDeleted, setUserDeleted] = useState<boolean>(false);
  const snapshotUser = () => {
    const userDoc = doc(firestore, `users/${user?.uid}`) as DocumentReference<User>;
    onSnapshot(userDoc, (doc: any) => {
      const userObj = { ...doc.data(), id: doc.id } as User;
      setCurUser(userObj);
      if (!userObj.email) {
        logout?.().then(() => {
          navigate('.');
          window.location.reload();
        });
      }
      if (userObj.showPostAuctionBuyTour && !tourOpen) {
        setTourOpen(true);
      }
      if (userObj && userObj.status.toLowerCase() === 'suspended') {
        setUserSuspended(true);
      } else {
        setUserSuspended(false);
      }
      if (userObj && userObj.status.toLowerCase() === 'disabled') {
        setUserDisabled(true);
      } else {
        setUserDisabled(false);
      }
    });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      snapshotUser();
      getCurrentDealership();
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseTour = async () => {
    await updateUser(user?.uid as string, { showPostAuctionBuyTour: false });
    setTourOpen(false);
  };

  const steps = [
    {
      selector: '',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Buy: Post-Auction</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              Post-auction is where vehicles land after their active auction time has expired.
            </Typography>
          </Box>
        </Box>
      )
    },
    {
      selector: '#postAuctionBuy2',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Buy: Post-Auction</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              Post-auction is where vehicles land after their active auction time has expired. Vehicles move through a number of statues during the closing process.
            </Typography>
          </Box>
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              <span style={{ fontWeight: '900' }}>Pending</span> is where vehicles that have not hit the floor price sit until the Seller Accepts, Declines or begins a negotiation on your offer. Vehicles will only sit in this status until the end of that business day.
            </Typography>
          </Box>
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              <span style={{ fontWeight: '900' }}>In Negotiation</span> is when a seller's floor has not been achieved and the seller would like to continue through negotiations. Once an offer is sent by the seller, you will be able to Accept, Counter or Decline their offer.
            </Typography>
          </Box>
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              <span style={{ fontWeight: '900' }}>Won</span> vehicles will land here if the floor price has been met and you were the top bidder! If the vehicle is 'in stock' it will immediately move to arrived. If the vehicle is an appraisal it will sit in Won until the seller moves it to arrived.
            </Typography>
          </Box>
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              <span style={{ fontWeight: '900' }}>Arrived</span> vehicles that have been won will move to arrived status once available. At this point arrange payment, ownership and transport details.
            </Typography>
          </Box>
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              <span style={{ fontWeight: '900' }}>Delivered</span> The seller will move the vehicle from arrived to delivered, once completed, closing out the transaction.
            </Typography>
          </Box>
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              <span style={{ fontWeight: '900' }}>Lost</span> This is a historical list of vehicles bid on but not won.
            </Typography>
          </Box>
        </Box>
      )
    },
    {
      selector: '#postAuctionBuy3',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Buy: Post-Auction</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          {/* prettier-ignore */}
          <Typography sx={{ marginTop: '10px', color: theme.palette.text.secondary }}>
            Response buttons are only triggered during negotiation 
          </Typography>
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              <span style={{ fontWeight: '900' }}>Awaiting Response</span> This is to notify that you are waiting for the seller to make a decision on your counter offer. 
            </Typography>
          </Box>
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              <span style={{ fontWeight: '900' }}>Response Required</span> This is to notify you that you need to either accept, counter or decline the seller's offer.
            </Typography>
          </Box>
        </Box>
      )
    }
  ];

  const handleChangePagePending = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > pagePending) {
      const firstDoc = pendingData?.data?.docs[0];
      const firstDocRef = firstDoc.ref as DocumentReference;
      const docRef = doc(firestore, `vehicles/${firstDocRef.id}`);
      const docSnap = await getDoc(docRef);
      const tempFirstDocs = firstDocPendingArray;
      if (docSnap.exists()) {
        tempFirstDocs.push(docSnap);
        setFirstDocPendingArray(tempFirstDocs);
      }
      if (lastDataVehicleRefPending) {
        const docRef = doc(firestore, `vehicles/${lastDataVehicleRefPending.id}`);
        const docSnap = await getDoc(docRef);
        const tempLastDocs = lastDocPendingArray;
        if (docSnap.exists()) {
          tempLastDocs.push(docSnap);
          setLastDocPendingArray(tempLastDocs);
          setPagePending(newPage);
          setBackPagePending(false);
        }
      }
    } else {
      setPagePending(newPage);
      setBackPagePending(true);
    }
  };

  const handleChangePageNegotiation = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > pageNegotiation) {
      const firstDoc = negotiationData?.data?.docs[0];
      const firstDocRef = firstDoc.ref as DocumentReference;
      const docRef = doc(firestore, `vehicles/${firstDocRef.id}`);
      const docSnap = await getDoc(docRef);
      const tempFirstDocs = firstDocNegotiationArray;
      if (docSnap.exists()) {
        tempFirstDocs.push(docSnap);
        setFirstDocNegotiationArray(tempFirstDocs);
      }
      if (lastDataVehicleRefNegotiation) {
        const docRef = doc(firestore, `vehicles/${lastDataVehicleRefNegotiation.id}`);
        const docSnap = await getDoc(docRef);
        const tempLastDocs = lastDocNegotiationArray;
        if (docSnap.exists()) {
          tempLastDocs.push(docSnap);
          setLastDocNegotiationArray(tempLastDocs);
          setPageNegotiation(newPage);
          setBackPageNegotiation(false);
        }
      }
    } else {
      setPageNegotiation(newPage);
      setBackPageNegotiation(true);
    }
  };

  const handleChangePageWon = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > pageWon) {
      const firstDoc = wonData?.data?.docs[0];
      const firstDocRef = firstDoc.ref as DocumentReference;
      const docRef = doc(firestore, `vehicles/${firstDocRef.id}`);
      const docSnap = await getDoc(docRef);
      const tempFirstDocs = firstDocWonArray;
      if (docSnap.exists()) {
        tempFirstDocs.push(docSnap);
        setFirstDocWonArray(tempFirstDocs);
      }
      if (lastDataVehicleRefWon) {
        const docRef = doc(firestore, `vehicles/${lastDataVehicleRefWon.id}`);
        const docSnap = await getDoc(docRef);
        const tempLastDocs = lastDocWonArray;
        if (docSnap.exists()) {
          tempLastDocs.push(docSnap);
          setLastDocWonArray(tempLastDocs);
          setPageWon(newPage);
          setBackPageWon(false);
        }
      }
    } else {
      setPageWon(newPage);
      setBackPageWon(true);
    }
  };

  const handleChangePageArrived = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > pageArrived) {
      const firstDoc = arrivedData?.data?.docs[0];
      const firstDocRef = firstDoc.ref as DocumentReference;
      const docRef = doc(firestore, `vehicles/${firstDocRef.id}`);
      const docSnap = await getDoc(docRef);
      const tempFirstDocs = firstDocArrivedArray;
      if (docSnap.exists()) {
        tempFirstDocs.push(docSnap);
        setFirstDocArrivedArray(tempFirstDocs);
      }
      if (lastDataVehicleRefArrived) {
        const docRef = doc(firestore, `vehicles/${lastDataVehicleRefArrived.id}`);
        const docSnap = await getDoc(docRef);
        const tempLastDocs = lastDocArrivedArray;
        if (docSnap.exists()) {
          tempLastDocs.push(docSnap);
          setLastDocArrivedArray(tempLastDocs);
          setPageArrived(newPage);
          setBackPageArrived(false);
        }
      }
    } else {
      setPageArrived(newPage);
      setBackPageArrived(true);
    }
  };

  const handleChangePageDelivered = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > pageDelivered) {
      const firstDoc = deliveredData?.data?.docs[0];
      const firstDocRef = firstDoc.ref as DocumentReference;
      const docRef = doc(firestore, `vehicles/${firstDocRef.id}`);
      const docSnap = await getDoc(docRef);
      const tempFirstDocs = firstDocDeliveredArray;
      if (docSnap.exists()) {
        tempFirstDocs.push(docSnap);
        setFirstDocDeliveredArray(tempFirstDocs);
      }
      if (lastDataVehicleRefDelivered) {
        const docRef = doc(firestore, `vehicles/${lastDataVehicleRefDelivered.id}`);
        const docSnap = await getDoc(docRef);
        const tempLastDocs = lastDocDeliveredArray;
        if (docSnap.exists()) {
          tempLastDocs.push(docSnap);
          setLastDocDeliveredArray(tempLastDocs);
          setPageDelivered(newPage);
          setBackPageDelivered(false);
        }
      }
    } else {
      setPageDelivered(newPage);
      setBackPageDelivered(true);
    }
  };

  const handleChangePageLost = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > pageLost) {
      const firstDoc = lostData?.data?.docs[0];
      const firstDocRef = firstDoc.ref as DocumentReference;
      const docRef = doc(firestore, `vehicles/${firstDocRef.id}`);
      const docSnap = await getDoc(docRef);
      const tempFirstDocs = firstDocLostArray;
      if (docSnap.exists()) {
        tempFirstDocs.push(docSnap);
        setFirstDocLostArray(tempFirstDocs);
      }
      if (lastDataVehicleRefLost) {
        const docRef = doc(firestore, `vehicles/${lastDataVehicleRefLost.id}`);
        const docSnap = await getDoc(docRef);
        const tempLastDocs = lastDocLostArray;
        if (docSnap.exists()) {
          tempLastDocs.push(docSnap);
          setLastDocLostArray(tempLastDocs);
          setPageLost(newPage);
          setBackPageLost(false);
        }
      }
    } else {
      setPageLost(newPage);
      setBackPageLost(true);
    }
  };

  const handleChangeRowsPerPagePending = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPagePending(parseInt(event.target.value, 10));
    setPagePending(0);
    setFirstDocPendingArray([]);
    setLastDocPendingArray([]);
    setBackPagePending(false);
  };

  const handleChangeRowsPerPageNegotiation = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPageNegotiation(parseInt(event.target.value, 10));
    setPageNegotiation(0);
    setFirstDocNegotiationArray([]);
    setLastDocNegotiationArray([]);
    setBackPageNegotiation(false);
  };

  const handleChangeRowsPerPageWon = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPageWon(parseInt(event.target.value, 10));
    setPageWon(0);
    setFirstDocWonArray([]);
    setLastDocWonArray([]);
    setBackPageWon(false);
  };

  const handleChangeRowsPerPageArrived = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPageArrived(parseInt(event.target.value, 10));
    setPageArrived(0);
    setFirstDocArrivedArray([]);
    setLastDocArrivedArray([]);
    setBackPageArrived(false);
  };

  const handleChangeRowsPerPageDelivered = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPageDelivered(parseInt(event.target.value, 10));
    setPageDelivered(0);
    setFirstDocDeliveredArray([]);
    setLastDocDeliveredArray([]);
    setBackPageDelivered(false);
  };

  const handleChangeRowsPerPageLost = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPageLost(parseInt(event.target.value, 10));
    setPageLost(0);
    setFirstDocLostArray([]);
    setLastDocLostArray([]);
    setBackPageLost(false);
  };

  const handleOrderByChangePending = (event: SelectChangeEvent) => {
    setOrderPending(event.target.value);
  };

  const handleOrderByChangeNegotiation = (event: SelectChangeEvent) => {
    setOrderNegotiation(event.target.value);
  };

  const handleOrderByChangeWon = (event: SelectChangeEvent) => {
    setOrderWon(event.target.value);
  };

  const handleOrderByChangeArrived = (event: SelectChangeEvent) => {
    setOrderArrived(event.target.value);
  };

  const handleOrderByChangeDelivered = (event: SelectChangeEvent) => {
    setOrderDelivered(event.target.value);
  };

  const handleOrderByChangeLost = (event: SelectChangeEvent) => {
    setOrderLost(event.target.value);
  };

  const handleChangeTab = async (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleCloseBidDrawer = () => {
    setOpenBidDrawer(!openBidDrawer);
  };

  const [openVDPDrawer, setOpenVDPDrawer] = useState<boolean>(false);
  const [currentVehicle, setCurrentVehicle] = useState<VehicleInfo>();
  const [openBillofSale, setOpenBillofSale] = useState(false);
  const [stripeLoading, setStripeLoading] = useState<boolean>(false);

  const { update: updateCustomer } = useFirestoreByPath<any>('customers');
  const payForVehicle = async (vehicleId: string, checkoutSession: string) => {
    if (paymentMethods) {
      setStripeLoading(true);
      await updateCustomer(user?.uid as string, {
        checkoutVehicleIds: arrayUnion(vehicleId)
      });
      await getCheckoutSession(vehicleId);
    } else {
      handleToggleModal();
    }
  };

  const getCheckoutSession = async (vehicleId: string) => {
    const sessionRef = collectionGroup(firestore, 'checkout_sessions') as CollectionReference;
    const sessionQuery = query(sessionRef, where('vehicleId', '==', vehicleId));
    onSnapshot(sessionQuery, (querySnapshot) => {
      querySnapshot.forEach(async (document: DocumentSnapshot) => {
        if (document.exists()) {
          const session = { ...document.data(), id: document.id } as any;
          const sessionId = session.sessionId;
          if (sessionId) {
            redirectToStripe(sessionId as string);
            setStripeLoading(false);
            await updateCustomer(user?.uid as string, {
              checkoutVehicleIds: arrayRemove(vehicleId)
            });
          }
        }
      });
    });
  };

  const SetDrawerVehicle = (
    index: number,
    vehicle: VehicleInfo
    // isDeliveredBuy?: boolean,
    // checkoutSession?: string
  ) => {
    setCurrentVehicle(vehicle);
    if (index === 0) {
      setOpenDrawer(!openDrawer);
    } else if (index === 1) {
      setOpenBidDrawer(!openBidDrawer);
    } else if (index === 2) {
      setOpenVDPDrawer(!openVDPDrawer);
    } else if (index === 3) {
      setOpenBillofSale(!openBillofSale);
    }
    // else if (index === 4 && isDeliveredBuy) {
    //   if (paymentMethods) {
    //     redirectToStripe(checkoutSession as string);
    //   } else {
    //     handleToggleModal();
    //   }
    // }
  };

  const redirectToStripe = async (checkoutSessionId: string) => {
    const sessionId = checkoutSessionId;
    if (window.Stripe && checkoutSessionId) {
      window.Stripe(STRIPE_PUBLISHABLE_KEY).redirectToCheckout({ sessionId });
    }
  };

  const StyledTabs = styled((props: StyledTabsProps) => (
    <Tabs
      {...props}
      variant="scrollable"
      sx={{
        minHeight: 'unset !important'
      }}
      // scrollButtons
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />
      }}
    />
  ))(({ theme }) => ({
    '& .MuiTab-root': {
      color:
        theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
      backgroundColor:
        theme.palette.mode === 'dark'
          ? theme.palette.background.neutral
          : theme.palette.background.paper
    },
    '& .MuiTab-root:not(:last-of-type)': {
      marginRight: '10px'
    },
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent !important',
      padding: '5px'
    },
    '& .Mui-selected': {
      backgroundColor: theme.palette.primary.lighter,
      color: `${theme.palette.primary.main} !important`
    },
    '& .MuiTabs-indicatorSpan': {
      display: 'none',
      maxWidth: 40,
      width: '100%',
      backgroundColor: theme.palette.chart.violet[2]
    }
  }));

  useEffect(() => {
    setAnchorEl(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupOpened]);

  const [pendingEmptyState, setPendingEmptyState] = useState<boolean>(false);
  const [negotiationEmptyState, setNegotiationEmptyState] = useState<boolean>(false);
  const [wonEmptyState, setWonEmptyState] = useState<boolean>(false);
  const [arrivedEmptyState, setArrivedEmptyState] = useState<boolean>(false);
  const [deliveredEmptyState, setDeliveredEmptyState] = useState<boolean>(false);
  const [lostEmptyState, setLostEmptyState] = useState<boolean>(false);

  useEffect(() => {
    if (!pendingVehicles || pendingVehicles.length < 1) {
      setPendingEmptyState(true);
    } else {
      setPendingEmptyState(false);
    }
    if (!negotiationVehicles || negotiationVehicles.length < 1) {
      setNegotiationEmptyState(true);
    } else {
      setNegotiationEmptyState(false);
    }
    if (!wonVehicles || wonVehicles.length < 1) {
      setWonEmptyState(true);
    } else {
      setWonEmptyState(false);
    }
    if (!arrivedVehicles || arrivedVehicles.length < 1) {
      setArrivedEmptyState(true);
    } else {
      setArrivedEmptyState(false);
    }
    if (!deliveredVehicles || deliveredVehicles.length < 1) {
      setDeliveredEmptyState(true);
    } else {
      setDeliveredEmptyState(false);
    }
    if (!lostVehicles || lostVehicles.length < 1) {
      setLostEmptyState(true);
    } else {
      setLostEmptyState(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pendingVehicles,
    negotiationVehicles,
    wonVehicles,
    arrivedVehicles,
    deliveredVehicles,
    lostVehicles
  ]);

  function sortPostAuctionBuy(a: any, b: any, order: any) {
    const endDateA = moment(a.auction.endDate.toDate()).format('YYYY/MM/DD');
    const endTimeA = moment(a.auction.endTime.toDate()).format('hh:mm:ss a');
    const finalEndDateA = new Date(endDateA + ' ' + endTimeA);

    const endDateB = moment(b.auction.endDate.toDate()).format('YYYY/MM/DD');
    const endTimeB = moment(b.auction.endTime.toDate()).format('hh:mm:ss a');
    const finalEndDateB = new Date(endDateB + ' ' + endTimeB);

    if (order === 'oldest') {
      return finalEndDateA.valueOf() > finalEndDateB.valueOf() ? 1 : -1;
    } else if (order === 'newest') {
      return finalEndDateA.valueOf() > finalEndDateB.valueOf() ? -1 : 1;
    } else {
      return a.details.make > b.details.make ? 1 : -1;
    }
  }

  const useStyles = makeStyles({
    declineButton: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
      marginBottom: '10px',
      '&:hover': {
        backgroundColor: theme.palette.error.dark
      }
    },
    counterButton: {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.common.black,
      marginBottom: '10px',
      '&:hover': {
        backgroundColor: theme.palette.grey[300]
      }
    },
    cancelButton: {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.info.light,
      '&:hover': {
        backgroundColor: theme.palette.grey[300]
      }
    },
    paper: {
      minWidth: '800px'
    },
    listV: {
      height: '100vh',
      width: '100vw',
      padding: '0 1rem',
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'hidden',
      overflowY: 'auto'
    },
    item: {
      flex: '0 0 auto',
      padding: '1rem',
      borderRadius: '1rem',
      backgroundColor: 'rgb(44, 44, 46)',
      overflow: 'hidden',
      maxWidth: '75%',
      minHeight: '42px',
      marginBottom: '16px'
    },
    tourContainer: {
      maxWidth: '500px !important',
      borderRadius: '10px !important'
    },
    pagination: {
      borderTopStyle: 'none',
      fontWeight: '700',
      borderTop: 'none'
    },
    inviteButton: {
      backgroundColor: theme.palette.primary.main
    },
    searchContainer: {
      width: '100%',
      maxWidth: '400px',
      height: 'auto',
      padding: '5px 0px',
      // marginBottom: '23px',
      // marginRight: '40px'
      marginBottom: '0px',
      marginRight: '20px'
    },
    search: {
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.background.neutral : 'transparent !important',
      border: theme.palette.mode === 'dark' ? 'unset' : `1px solid ${theme.palette.divider}`,
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: '100%'
    },
    searchInput: {
      fontSize: '14px',
      width: '100%',
      height: '34px',
      margin: '0 10px',
      border: 'none',
      padding: '5px 10px',
      outline: 'none !important',
      marginLeft: 2,
      flex: 1,
      backgroundColor: 'transparent !important',
      color:
        theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
      '&::placeholder': {
        color:
          theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
        fontSize: '14px'
      }
    },
    searchIcon: {
      fill: theme.palette.primary.main,
      marginRight: '5px'
    },
    secondaryButton: {
      backgroundColor: theme.palette.grey[50],
      color: theme.palette.info.light
    },
    subtitle: {
      fontWeight: 'bold',
      color: theme.palette.secondary.darker
    },
    subtitle2: {
      color: theme.palette.info.light,
      fontWeight: 'normal'
    },
    list: {
      '& li:first-child, & li:last-child': {
        '& button': {
          border: `1px solid ${theme.palette.grey[100]}`,
          color: theme.palette.info.light,
          width: '100px'
        }
      },
      '& li': {
        paddingLeft: 3,
        paddingRight: 3,
        '& button': {
          color: theme.palette.info.main,
          minWidth: '40px'
        }
      }
    },
    forwardIcon: {
      fontSize: '12px !important'
    },
    backwardIcon: {
      fontSize: '12px !important'
    },
    divider: {
      width: '93%',
      [theme.breakpoints.down(1524)]: {
        width: '90%'
      },
      [theme.breakpoints.down(863)]: {
        width: '87%'
      }
    },
    gridAvatarTitle: {
      [theme.breakpoints.down(1486)]: {
        flexBasis: '40%',
        maxWidth: '40%'
      },
      [theme.breakpoints.down(1299)]: {
        flexBasis: '45%',
        maxWidth: '45%'
      }
    },
    gridDateTitle: {
      marginRight: '64px',
      [theme.breakpoints.down(1330)]: {
        marginRight: '24px'
      }
    },
    menuPopover: {
      boxShadow: '0 0 2px 0 rgba(139, 148, 156, 0.24),0 10px 20px -4px rgba(139, 148, 156, 0.24)',
      // backgroundColor: theme.palette.primary.contrastText,
      paddingTop: '20px',
      paddingBottom: '20px',
      '@global': {
        '.MuiMenuItem-root': {
          paddingBottom: '6px'
        },
        '.MuiMenuItem-root:last-child': {
          color: theme.palette.error.dark
        }
      }
    },
    dataGrid: {
      '@global': {
        '.MuiDataGrid-columnHeaders': {
          visibility: 'hidden'
        },
        '.MuiDataGrid-virtualScroller': {
          marginTop: '0 !important'
        },
        '.flexEndCell': {
          justifyContent: 'flex-end',
          padding: '0'
        },
        '.flexEndCellPopup': {
          justifyContent: 'flex-end',
          padding: '0',
          '& .MuiTouchRipple-root': {
            display: 'none'
          }
        },
        '.MuiDataGrid-row': {
          minHeight: '88px !important',
          maxHeight: '88px !important',
          '&:hover': {
            backgroundColor: 'transparent !important'
          }
        },
        '.MuiDataGrid-cell': {
          minHeight: '88px !important',
          maxHeight: '88px !important',
          borderBottom: '0'
        },
        '.imageCell': {
          padding: '0'
        }
      }
    },
    headerTextContainer: {
      width: '100%',
      height: 'auto',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '0px'
    },
    controlsContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'flex-end'
    },
    headerText: {
      fontSize: '14px',
      marginRight: '18px',
      marginLeft: '0px'
    },
    inputText: {
      fontSize: '14px'
    }
  });

  const styles = useStyles();

  const [userDisabled, setUserDisabled] = useState<boolean>(false);
  const [userSuspended, setUserSuspended] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/dashboard');
  };

  return (
    <>
      {!userDeleted && !userSuspended && !userDisabled ? (
        <Page title="Post Auction | DealerGavel">
          <Container maxWidth={themeStretch ? false : 'xl'}>
            <Typography
              variant="h4"
              component="h1"
              sx={{
                marginTop: '0px',
                marginBottom: '0px',
                fontSize: '1rem !important',
                color:
                  theme.palette.mode === 'dark'
                    ? theme.palette.common.white
                    : theme.palette.text.primary
              }}
            >
              Post Auction: Buying
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'wrap'
              }}
            >
              <StyledTabs
                id="postAuctionBuy2"
                value={tabValue}
                onChange={handleChangeTab}
                aria-label="Post Auction Tabs"
              >
                <Tab
                  sx={{
                    padding: '0px 10px',
                    borderRadius: '5px',
                    minHeight: '40px',
                    height: '40px'
                  }}
                  label={
                    <Typography sx={{ fontSize: '12px' }}>
                      PENDING ({collectionSizePending})
                    </Typography>
                  }
                  {...a11yProps(0)}
                />
                <Tab
                  sx={{
                    padding: '0px 10px',
                    borderRadius: '5px',
                    minHeight: '40px',
                    height: '40px'
                  }}
                  label={
                    <Typography sx={{ fontSize: '12px' }}>
                      IN NEGOTIATION ({collectionSizeNegotiation})
                    </Typography>
                  }
                  {...a11yProps(1)}
                />
                <Tab
                  sx={{
                    padding: '0px 10px',
                    borderRadius: '5px',
                    minHeight: '40px',
                    height: '40px'
                  }}
                  label={
                    <Typography sx={{ fontSize: '12px' }}>WON ({collectionSizeWon})</Typography>
                  }
                  {...a11yProps(2)}
                />
                <Tab
                  sx={{
                    padding: '0px 10px',
                    borderRadius: '5px',
                    minHeight: '40px',
                    height: '40px'
                  }}
                  label={
                    <Typography sx={{ fontSize: '12px' }}>
                      ARRIVED ({collectionSizeArrived})
                    </Typography>
                  }
                  {...a11yProps(3)}
                />
                <Tab
                  sx={{
                    padding: '0px 10px',
                    borderRadius: '5px',
                    minHeight: '40px',
                    height: '40px'
                  }}
                  label={
                    <Typography sx={{ fontSize: '12px' }}>
                      DELIVERED ({collectionSizeDelivered})
                    </Typography>
                  }
                  {...a11yProps(4)}
                />
                <Tab
                  sx={{
                    padding: '0px 10px',
                    borderRadius: '5px',
                    minHeight: '40px',
                    height: '40px'
                  }}
                  label={
                    <Typography sx={{ fontSize: '12px' }}>
                      NO SALE ({collectionSizeLost})
                    </Typography>
                  }
                  {...a11yProps(5)}
                />
              </StyledTabs>
              <Box className={styles.searchContainer}>
                <Paper component="form" className={styles.search}>
                  <DebounceInput
                    className={styles.searchInput}
                    placeholder="Search Vehicles by Make, Model, Year, etc."
                    minLength={2}
                    debounceTimeout={300}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                  <SearchIcon className={styles.searchIcon} sx={{ width: '20px' }} />
                </Paper>
              </Box>
            </Box>
            {/* PENDING */}
            <TabPanel value={tabValue} index={0}>
              <Box sx={{ flexGrow: 1 }}>
                <Box className={styles.headerTextContainer}>
                  {pendingVehicles && (
                    <Typography
                      component="p"
                      className={styles.headerText}
                      sx={{
                        textTransform: 'uppercase',
                        fontWeight: '700',
                        color: theme.palette.info.light
                      }}
                    >
                      {collectionSizePending} vehicles found
                    </Typography>
                  )}
                  <Box className={styles.controlsContainer}>
                    <Typography component="p" className={styles.headerText}>
                      Sort By:
                    </Typography>
                    <Select
                      label="Sort By"
                      value={orderPending}
                      onChange={handleOrderByChangePending}
                      className={styles.inputText}
                      inputProps={{ 'aria-label': 'order vehicles' }}
                      input={<InputBase />}
                    >
                      <MenuItem value={'oldest'}>Oldest</MenuItem>
                      <MenuItem value={'newest'}>Newest</MenuItem>
                      {/* <MenuItem value={'vehicleName'}>Name</MenuItem> */}
                    </Select>
                    {pendingVehicles && (
                      <TablePagination
                        className={styles.pagination}
                        count={collectionSizePending}
                        page={pagePending}
                        onPageChange={handleChangePagePending}
                        rowsPerPage={rowsPerPagePending}
                        onRowsPerPageChange={handleChangeRowsPerPagePending}
                        rowsPerPageOptions={[5, 10, 25]}
                      />
                    )}
                  </Box>
                </Box>
                <Grid
                  // container
                  rowSpacing={1}
                  sx={{
                    overflowX: 'auto',
                    height: 'calc(100vh - 200px)',
                    overflowY: 'auto'
                  }}
                  ref={pendingRef}
                >
                  {pendingVehicles && (
                    <ViewportList
                      viewportRef={pendingRef}
                      initialPrerender={8}
                      items={pendingVehicles
                        .sort((a, b) => sortPostAuctionBuy(a, b, orderPending))
                        // .sort((a: VehicleInfo, b: VehicleInfo) => {
                        //   if (orderPending === 'oldest') {
                        //     return a.auction.captureDate > b.auction.captureDate ? 1 : -1;
                        //   } else if (orderPending === 'newest') {
                        //     return a.auction.captureDate > b.auction.captureDate ? -1 : 1;
                        //   } else {
                        //     return a.details.make > b.details.make ? 1 : -1;
                        //   }
                        // })
                        .filter(
                          (v: VehicleInfo) =>
                            v.details.make.toLowerCase().includes(searchValue.toLowerCase()) ||
                            v.details.model.toLowerCase().includes(searchValue.toLowerCase()) ||
                            v.auction.floorPrice
                              .toString()
                              .toLowerCase()
                              .includes(searchValue.toLowerCase()) ||
                            v.auction.captureRepName
                              .toLowerCase()
                              .includes(searchValue.toLowerCase()) ||
                            v.details.year
                              ?.toString()
                              .toLowerCase()
                              .includes(searchValue.toLowerCase()) ||
                            v.details.trim.toLowerCase().includes(searchValue.toLowerCase())
                        )}
                    >
                      {(vehicle: VehicleInfo) => (
                        <>
                          {currentDealership && (
                            <PendingListItemBuy
                              key={vehicle.id}
                              vehicle={vehicle}
                              dealershipName={currentDealership.dealershipName}
                              initialOpen={false}
                              sendVehicleToParent={SetDrawerVehicle}
                            />
                          )}
                        </>
                      )}
                    </ViewportList>
                  )}
                  {pendingEmptyState && (
                    <EmptyStateMessage
                      boldMessage="You have no vehicles pending."
                      message="You may have to wait for the seller to accept or start a negotiation."
                    />
                  )}
                </Grid>
              </Box>
            </TabPanel>
            {/* IN NEGOTIATION */}
            <TabPanel value={tabValue} index={1}>
              <Box sx={{ flexGrow: 1 }}>
                <Box className={styles.headerTextContainer}>
                  {negotiationVehicles && (
                    <Typography
                      component="p"
                      className={styles.headerText}
                      sx={{
                        textTransform: 'uppercase',
                        fontWeight: '700',
                        color: theme.palette.info.light
                      }}
                    >
                      {collectionSizeNegotiation} vehicles found
                    </Typography>
                  )}
                  <Box className={styles.controlsContainer}>
                    <Typography component="p" className={styles.headerText}>
                      Sort By:
                    </Typography>
                    <Select
                      label="Sort By"
                      value={orderNegotiation}
                      onChange={handleOrderByChangeNegotiation}
                      className={styles.inputText}
                      inputProps={{ 'aria-label': 'order vehicles' }}
                      input={<InputBase />}
                    >
                      <MenuItem value={'oldest'}>Oldest</MenuItem>
                      <MenuItem value={'newest'}>Newest</MenuItem>
                      {/* <MenuItem value={'vehicleName'}>Name</MenuItem> */}
                    </Select>
                    {negotiationVehicles && (
                      <TablePagination
                        className={styles.pagination}
                        count={collectionSizeNegotiation}
                        page={pageNegotiation}
                        onPageChange={handleChangePageNegotiation}
                        rowsPerPage={rowsPerPageNegotiation}
                        onRowsPerPageChange={handleChangeRowsPerPageNegotiation}
                        rowsPerPageOptions={[5, 10, 25]}
                      />
                    )}
                  </Box>
                </Box>
                <Grid
                  // container
                  rowSpacing={1}
                  sx={{
                    overflowX: 'auto',
                    height: 'calc(100vh - 200px)',
                    overflowY: 'auto'
                  }}
                  ref={negotiationRef}
                >
                  {negotiationVehicles && (
                    <ViewportList
                      initialPrerender={8}
                      viewportRef={negotiationRef}
                      items={sortNegotiationVehiclesArray(
                        negotiationVehicles,
                        orderNegotiation,
                        searchValue,
                        pageNegotiation,
                        rowsPerPageNegotiation
                      )}
                    >
                      {(vehicle: VehicleInfo) => (
                        <>
                          {currentDealership && (
                            <PendingListItemBuy
                              key={vehicle.id}
                              vehicle={vehicle}
                              dealershipName={currentDealership.dealershipName}
                              initialOpen={false}
                              sendVehicleToParent={SetDrawerVehicle}
                            />
                          )}
                        </>
                      )}
                    </ViewportList>
                  )}
                  {negotiationEmptyState && (
                    <EmptyStateMessage
                      boldMessage="You have no vehicles in negotiation."
                      message=""
                    />
                  )}
                </Grid>
              </Box>
            </TabPanel>
            {/* WON */}
            <TabPanel value={tabValue} index={2}>
              <Box sx={{ flexGrow: 1 }}>
                <Box className={styles.headerTextContainer}>
                  {wonVehicles && (
                    <Typography
                      component="p"
                      className={styles.headerText}
                      sx={{
                        textTransform: 'uppercase',
                        fontWeight: '700',
                        color: theme.palette.info.light
                      }}
                    >
                      {collectionSizeWon} vehicles found
                    </Typography>
                  )}
                  <Box className={styles.controlsContainer}>
                    <Typography component="p" className={styles.headerText}>
                      Sort By:
                    </Typography>
                    <Select
                      label="Sort By"
                      value={orderWon}
                      onChange={handleOrderByChangeWon}
                      className={styles.inputText}
                      inputProps={{ 'aria-label': 'order vehicles' }}
                      input={<InputBase />}
                    >
                      <MenuItem value={'oldest'}>Oldest</MenuItem>
                      <MenuItem value={'newest'}>Newest</MenuItem>
                      {/* <MenuItem value={'vehicleName'}>Name</MenuItem> */}
                    </Select>
                    {wonVehicles && (
                      <TablePagination
                        className={styles.pagination}
                        count={collectionSizeWon}
                        page={pageWon}
                        onPageChange={handleChangePageWon}
                        rowsPerPage={rowsPerPageWon}
                        onRowsPerPageChange={handleChangeRowsPerPageWon}
                        rowsPerPageOptions={[5, 10, 25]}
                      />
                    )}
                  </Box>
                </Box>
                <Grid
                  // container
                  spacing={1}
                  sx={{
                    overflowX: 'auto',
                    height: 'calc(100vh - 200px)',
                    overflowY: 'auto'
                  }}
                  ref={wonRef}
                >
                  {wonVehicles && (
                    <ViewportList
                      viewportRef={wonRef}
                      initialPrerender={8}
                      items={wonVehicles
                        .sort((a, b) => sortPostAuctionBuy(a, b, orderWon))
                        // .sort((a: VehicleInfo, b: VehicleInfo) => {
                        //   if (orderWon === 'oldest') {
                        //     return a.auction.captureDate > b.auction.captureDate ? 1 : -1;
                        //   } else if (orderWon === 'newest') {
                        //     return a.auction.captureDate > b.auction.captureDate ? -1 : 1;
                        //   } else {
                        //     return a.details.make > b.details.make ? 1 : -1;
                        //   }
                        // })
                        .filter(
                          (v: VehicleInfo) =>
                            v.details.make.toLowerCase().includes(searchValue.toLowerCase()) ||
                            v.details.model.toLowerCase().includes(searchValue.toLowerCase()) ||
                            v.auction.floorPrice
                              .toString()
                              .toLowerCase()
                              .includes(searchValue.toLowerCase()) ||
                            v.auction.captureRepName
                              .toLowerCase()
                              .includes(searchValue.toLowerCase()) ||
                            v.details.year
                              ?.toString()
                              .toLowerCase()
                              .includes(searchValue.toLowerCase()) ||
                            v.details.trim.toLowerCase().includes(searchValue.toLowerCase())
                        )}
                    >
                      {(vehicle: VehicleInfo) => (
                        <WonListItemBuy
                          key={vehicle.id}
                          vehicle={vehicle}
                          initialOpen={false}
                          sendVehicleToParent={SetDrawerVehicle}
                        />
                      )}
                    </ViewportList>
                  )}
                  {wonEmptyState && (
                    <EmptyStateMessage boldMessage="You have won no vehicles." message="" />
                  )}
                </Grid>
              </Box>
            </TabPanel>
            {/* ARRIVED */}
            <TabPanel value={tabValue} index={3}>
              <Box sx={{ flexGrow: 1 }}>
                <Box className={styles.headerTextContainer}>
                  {arrivedVehicles && (
                    <Typography
                      component="p"
                      className={styles.headerText}
                      sx={{
                        textTransform: 'uppercase',
                        fontWeight: '700',
                        color: theme.palette.info.light
                      }}
                    >
                      {collectionSizeArrived} vehicles found
                    </Typography>
                  )}
                  <Box className={styles.controlsContainer}>
                    <Typography component="p" className={styles.headerText}>
                      Sort By:
                    </Typography>
                    <Select
                      label="Sort By"
                      value={orderArrived}
                      onChange={handleOrderByChangeArrived}
                      className={styles.inputText}
                      inputProps={{ 'aria-label': 'order vehicles' }}
                      input={<InputBase />}
                    >
                      <MenuItem value={'oldest'}>Oldest</MenuItem>
                      <MenuItem value={'newest'}>Newest</MenuItem>
                      {/* <MenuItem value={'vehicleName'}>Name</MenuItem> */}
                    </Select>
                    {arrivedVehicles && (
                      <TablePagination
                        className={styles.pagination}
                        count={collectionSizeArrived}
                        page={pageArrived}
                        onPageChange={handleChangePageArrived}
                        rowsPerPage={rowsPerPageArrived}
                        onRowsPerPageChange={handleChangeRowsPerPageArrived}
                        rowsPerPageOptions={[5, 10, 25]}
                      />
                    )}
                  </Box>
                </Box>
                <Grid
                  // container
                  rowSpacing={1}
                  sx={{
                    overflowX: 'auto',
                    height: 'calc(100vh - 200px)',
                    overflowY: 'auto'
                  }}
                  ref={arrivedRef}
                >
                  {arrivedVehicles && (
                    <ViewportList
                      initialPrerender={8}
                      viewportRef={arrivedRef}
                      items={arrivedVehicles
                        .sort((a, b) => sortPostAuctionBuy(a, b, orderArrived))
                        // .sort((a: VehicleInfo, b: VehicleInfo) => {
                        //   if (orderArrived === 'oldest') {
                        //     return a.auction.captureDate > b.auction.captureDate ? 1 : -1;
                        //   } else if (orderArrived === 'newest') {
                        //     return a.auction.captureDate > b.auction.captureDate ? -1 : 1;
                        //   } else {
                        //     return a.details.make > b.details.make ? 1 : -1;
                        //   }
                        // })
                        .filter(
                          (v: VehicleInfo) =>
                            v.details.make.toLowerCase().includes(searchValue.toLowerCase()) ||
                            v.details.model.toLowerCase().includes(searchValue.toLowerCase()) ||
                            v.auction.floorPrice
                              .toString()
                              .toLowerCase()
                              .includes(searchValue.toLowerCase()) ||
                            v.auction.captureRepName
                              .toLowerCase()
                              .includes(searchValue.toLowerCase()) ||
                            v.details.year
                              ?.toString()
                              .toLowerCase()
                              .includes(searchValue.toLowerCase()) ||
                            v.details.trim.toLowerCase().includes(searchValue.toLowerCase())
                        )}
                    >
                      {(vehicle: VehicleInfo) => (
                        <ArrivedListItemBuy
                          key={vehicle.id}
                          vehicle={vehicle}
                          initialOpen={false}
                          sendVehicleToParent={SetDrawerVehicle}
                        />
                      )}
                    </ViewportList>
                  )}
                  {arrivedEmptyState && (
                    <EmptyStateMessage
                      boldMessage="You have no arrived vehicles."
                      message="Once a vehicle is in-stock, it will appear here."
                    />
                  )}
                </Grid>
              </Box>
            </TabPanel>
            {/* DELIVERED */}
            <TabPanel value={tabValue} index={4}>
              <Box sx={{ flexGrow: 1 }}>
                <Box className={styles.headerTextContainer}>
                  {deliveredVehicles && (
                    <Typography
                      component="p"
                      className={styles.headerText}
                      sx={{
                        textTransform: 'uppercase',
                        fontWeight: '700',
                        color: theme.palette.info.light
                      }}
                    >
                      {collectionSizeDelivered} vehicles found
                    </Typography>
                  )}
                  <Box className={styles.controlsContainer}>
                    <Typography component="p" className={styles.headerText}>
                      Sort By:
                    </Typography>
                    <Select
                      label="Sort By"
                      value={orderDelivered}
                      onChange={handleOrderByChangeDelivered}
                      className={styles.inputText}
                      inputProps={{ 'aria-label': 'order vehicles' }}
                      input={<InputBase />}
                    >
                      <MenuItem value={'oldest'}>Oldest</MenuItem>
                      <MenuItem value={'newest'}>Newest</MenuItem>
                      {/* <MenuItem value={'vehicleName'}>Name</MenuItem> */}
                    </Select>
                    {deliveredVehicles && (
                      <TablePagination
                        className={styles.pagination}
                        count={collectionSizeDelivered}
                        page={pageDelivered}
                        onPageChange={handleChangePageDelivered}
                        rowsPerPage={rowsPerPageDelivered}
                        onRowsPerPageChange={handleChangeRowsPerPageDelivered}
                        rowsPerPageOptions={[5, 10, 25]}
                      />
                    )}
                  </Box>
                </Box>
                <Grid
                  // container
                  rowSpacing={1}
                  sx={{
                    overflowX: 'auto',
                    height: 'calc(100vh - 200px)',
                    overflowY: 'auto'
                  }}
                  ref={deliveredRef}
                >
                  {deliveredVehicles && (
                    <ViewportList
                      initialPrerender={8}
                      viewportRef={deliveredRef}
                      items={deliveredVehicles
                        // .sort((a, b) => sortPostAuctionBuy(a, b, orderDelivered))
                        .sort((a: VehicleInfo, b: VehicleInfo) => {
                          if (orderDelivered === 'oldest') {
                            return a.auction.dateDelivered > b.auction.dateDelivered ? 1 : -1;
                          } else if (orderDelivered === 'newest') {
                            return a.auction.dateDelivered > b.auction.dateDelivered ? -1 : 1;
                          } else {
                            return a.details.make > b.details.make ? 1 : -1;
                          }
                        })
                        .filter(
                          (v: VehicleInfo) =>
                            v.details.make.toLowerCase().includes(searchValue.toLowerCase()) ||
                            v.details.model.toLowerCase().includes(searchValue.toLowerCase()) ||
                            v.auction.floorPrice
                              .toString()
                              .toLowerCase()
                              .includes(searchValue.toLowerCase()) ||
                            v.auction.captureRepName
                              .toLowerCase()
                              .includes(searchValue.toLowerCase()) ||
                            v.details.year
                              ?.toString()
                              .toLowerCase()
                              .includes(searchValue.toLowerCase()) ||
                            v.details.trim.toLowerCase().includes(searchValue.toLowerCase())
                        )}
                    >
                      {(vehicle: VehicleInfo) => (
                        <DeliveredListItemBuy
                          key={vehicle.id}
                          stripeLoading={stripeLoading}
                          vehicle={vehicle}
                          initialOpen={false}
                          sendVehicleToParent={SetDrawerVehicle}
                          payForVehicle={payForVehicle}
                        />
                      )}
                    </ViewportList>
                  )}
                  {deliveredEmptyState && (
                    <EmptyStateMessage
                      boldMessage="You have no delivered vehicles."
                      message="Once a vehicle is paid for and the sale is closed, it will appear here."
                    />
                  )}
                </Grid>
              </Box>
            </TabPanel>
            {/* LOST */}
            <TabPanel value={tabValue} index={5}>
              <Box sx={{ flexGrow: 1 }}>
                <Box className={styles.headerTextContainer}>
                  {lostVehicles && (
                    <Typography
                      component="p"
                      className={styles.headerText}
                      sx={{
                        textTransform: 'uppercase',
                        fontWeight: '700',
                        color: theme.palette.info.light
                      }}
                    >
                      {collectionSizeLost} vehicles found
                    </Typography>
                  )}
                  <Box className={styles.controlsContainer}>
                    <Typography component="p" className={styles.headerText}>
                      Sort By:
                    </Typography>
                    <Select
                      label="Sort By"
                      value={orderLost}
                      onChange={handleOrderByChangeLost}
                      className={styles.inputText}
                      inputProps={{ 'aria-label': 'order vehicles' }}
                      input={<InputBase />}
                    >
                      <MenuItem value={'oldest'}>Oldest</MenuItem>
                      <MenuItem value={'newest'}>Newest</MenuItem>
                      {/* <MenuItem value={'vehicleName'}>Name</MenuItem> */}
                    </Select>
                    {lostVehicles && (
                      <TablePagination
                        className={styles.pagination}
                        count={collectionSizeLost}
                        page={pageLost}
                        onPageChange={handleChangePageLost}
                        rowsPerPage={rowsPerPageLost}
                        onRowsPerPageChange={handleChangeRowsPerPageLost}
                        rowsPerPageOptions={[5, 10, 25]}
                      />
                    )}
                  </Box>
                </Box>
                <Grid
                  // container
                  rowSpacing={1}
                  sx={{
                    overflowX: 'auto',
                    height: 'calc(100vh - 200px)',
                    overflowY: 'auto'
                  }}
                  ref={lostRef}
                >
                  {lostVehicles && (
                    <ViewportList
                      initialPrerender={8}
                      viewportRef={lostRef}
                      items={lostVehicles
                        .sort((a, b) => sortPostAuctionBuy(a, b, orderLost))
                        // .sort((a: VehicleInfo, b: VehicleInfo) => {
                        //   if (orderLost === 'oldest') {
                        //     return a.auction.captureDate > b.auction.captureDate ? 1 : -1;
                        //   } else if (orderLost === 'newest') {
                        //     return a.auction.captureDate > b.auction.captureDate ? -1 : 1;
                        //   } else {
                        //     return a.details.make > b.details.make ? 1 : -1;
                        //   }
                        // })
                        .filter(
                          (v: VehicleInfo) =>
                            v.details.make.toLowerCase().includes(searchValue.toLowerCase()) ||
                            v.details.model.toLowerCase().includes(searchValue.toLowerCase()) ||
                            v.auction.floorPrice
                              .toString()
                              .toLowerCase()
                              .includes(searchValue.toLowerCase()) ||
                            v.auction.captureRepName
                              .toLowerCase()
                              .includes(searchValue.toLowerCase()) ||
                            v.details.year
                              ?.toString()
                              .toLowerCase()
                              .includes(searchValue.toLowerCase()) ||
                            v.details.trim.toLowerCase().includes(searchValue.toLowerCase())
                        )}
                    >
                      {(vehicle: VehicleInfo) => (
                        <LostListItemBuy
                          key={vehicle.id}
                          vehicle={vehicle}
                          initialOpen={false}
                          sendVehicleToParent={SetDrawerVehicle}
                        />
                      )}
                    </ViewportList>
                  )}
                  {lostEmptyState && (
                    <EmptyStateMessage boldMessage="You have no lost vehicles." message="" />
                  )}
                </Grid>
              </Box>
            </TabPanel>
          </Container>
          {pendingVehicles && pendingVehicles.length > 0 && (
            <Tour
              steps={steps}
              isOpen={tourOpen}
              onRequestClose={() => handleCloseTour()}
              className={styles.tourContainer}
            />
          )}
          {currentVehicle && (
            <>
              <SharedDrawer
                isOpenSidebar={openDrawer}
                onCloseSidebar={handleCloseDrawer}
                drawerContent={
                  <HistoryDrawerContent
                    isSeller={false}
                    onClose={handleCloseDrawer}
                    vehicle={currentVehicle as VehicleInfo}
                    index={0}
                  />
                }
              />
              <SharedDrawer
                isOpenSidebar={openBidDrawer}
                onCloseSidebar={handleCloseBidDrawer}
                drawerContent={
                  <HistoryDrawerContent
                    isSeller={false}
                    onClose={handleCloseBidDrawer}
                    vehicle={currentVehicle as VehicleInfo}
                    index={1}
                  />
                }
              />
              <AuctionDrawer
                vehicle={currentVehicle as VehicleInfo}
                tabIndex={0}
                isOpenSidebar={openVDPDrawer}
                onCloseSidebar={() => setOpenVDPDrawer(!openVDPDrawer)}
              />
              <Dialog
                classes={{ paper: styles.paper }}
                open={openBillofSale}
                onClose={() => {
                  setOpenBillofSale(false);
                }}
                sx={{
                  zIndex: '9999999'
                }}
              >
                <BillofSale vehicle={currentVehicle} />
              </Dialog>
            </>
          )}
          <Dialog open={isOpenModal} onClose={handleToggleModal}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '20px',
                position: 'relative',
                borderBottom: `1px solid ${theme.palette.divider}`
              }}
            >
              {/* prettier-ignore */}
              <Typography component="p" variant="h5">Payment Method Alert</Typography>
              <CloseIcon
                onClick={handleToggleModal}
                sx={{
                  position: 'absolute',
                  right: '10px',
                  cursor: 'pointer'
                }}
              />
            </Box>
            {/* prettier-ignore */}
            <Box>
              <Box sx={{ padding: '20px' }}>
                {/* prettier-ignore */}
                <Typography>You must create a payment method in order to do this. Payment methods can be created from the billing section in account management.</Typography>
              </Box>
            </Box>
          </Dialog>
        </Page>
      ) : (
        <>
          {userDeleted && (
            <Alert severity="error">
              <AlertTitle>Permission Denied</AlertTitle>
              Your account has been disabled.
            </Alert>
          )}
          {userSuspended && (
            <>
              <Alert severity="error">
                <AlertTitle>Thank you for joining DealerGavel</AlertTitle>
                Your account information is being reviewed by DealerGavel team, and will be
                &nbsp;authorized shortly. For now, you can return to the dashboard and await
                &nbsp;authorization.
              </Alert>
              <Box sx={{ mt: '20px' }}>
                <Button onClick={handleNavigate} variant="contained">
                  Back to Dashboard
                </Button>
              </Box>
            </>
          )}
          {userDisabled && (
            <>
              <Alert severity="error">
                <AlertTitle>Permission Denied</AlertTitle>
                Your account has been disabled.
              </Alert>
              <Box sx={{ mt: '20px' }}>
                <Button onClick={handleNavigate} variant="contained">
                  Back to Dashboard
                </Button>
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
}
