import { PATH_BUYER, PATH_COMMUNITY, PATH_DASHBOARD, PATH_SELLER } from '../../routes/paths';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import GavelIcon from '@mui/icons-material/Gavel';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import MediationIcon from '@mui/icons-material/Mediation';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import AssessmentIcon from '@mui/icons-material/Assessment';

const sidebarConfig = [
  // DASHBOARD
  {
    subheader: '',
    items: [{ title: 'Dashboard', path: PATH_DASHBOARD.root, icon: <TimelapseIcon /> }]
  },
  {
    subheader: 'buy',
    subheader2: 'timed auctions',
    items: [
      {
        title: 'Launching Soon',
        path: PATH_BUYER.preAuction,
        icon: <DirectionsCarIcon />
      },
      { title: 'Timed Auction', path: PATH_BUYER.activeAuctions, icon: <GavelIcon /> }
    ]
  },
  // {
  //   subheader: '',
  //   subheader2: 'live auctions',
  //   items: [
  //     {
  //       title: 'Run List',
  //       path: PATH_BUYER.runList,
  //       icon: <DirectionsCarIcon />
  //     },
  //     { title: 'Live Auction', path: PATH_BUYER.liveAuction, icon: <GavelIcon /> }
  //   ]
  // },
  {
    subheader: '',
    subheader2: 'Post Auction',
    items: [
      { title: 'Post Auction', path: PATH_BUYER.postAuction, icon: <AttachMoneyIcon /> },
      {
        title: 'Bidding History',
        path: PATH_BUYER.lostVehicleHistory,
        icon: <AssessmentIcon />
      }
    ]
  },
  // SELLER
  {
    subheader: 'sell',
    subheader2: 'Vehicle Capture',
    items: [
      {
        title: 'Vehicle Capture',
        path: PATH_SELLER.vehicleCapture,
        icon: <CameraAltIcon />
      }
    ]
  },
  {
    subheader: '',
    subheader2: 'Timed Auctions',
    items: [
      {
        title: 'Pre Auction',
        path: PATH_SELLER.preAuction,
        icon: <DirectionsCarIcon />
      },
      { title: 'Active Auction', path: PATH_SELLER.activeAuctions, icon: <GavelIcon /> }
    ]
  },
  // {
  //   subheader: '',
  //   subheader2: 'Live Auctions',
  //   items: [
  //     {
  //       title: 'Run List',
  //       path: PATH_SELLER.preAuction,
  //       icon: <DirectionsCarIcon />
  //     },
  //     { title: 'Live Auction', path: PATH_SELLER.liveAuction, icon: <GavelIcon /> }
  //   ]
  // },
  {
    subheader: '',
    subheader2: 'Post Auction',
    items: [{ title: 'Post Auction', path: PATH_SELLER.postAuction, icon: <AttachMoneyIcon /> }]
  },
  // DEALER NETWORK
  {
    subheader: 'dealergavel community',
    items: [
      {
        title: 'Selling Network',
        path: PATH_COMMUNITY.sellerNetwork,
        icon: <MediationIcon />
      },
      {
        title: 'Buying Network',
        path: PATH_COMMUNITY.buyerNetwork,
        icon: <MediationIcon sx={{ transform: 'rotate(180deg)' }} />
      },
      {
        title: 'Find a Dealership',
        path: PATH_COMMUNITY.findDealership,
        icon: <GroupWorkIcon />
      }
    ]
  }
];

export default sidebarConfig;
