/* eslint-disable max-len */
import {
  Container,
  Typography,
  Grid,
  Button,
  Paper,
  IconButton,
  Box,
  Drawer,
  MenuItem,
  Popover,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Divider,
  DialogActions,
  FormControl,
  FormHelperText,
  InputLabel,
  Alert,
  AlertTitle
} from '@mui/material';
import {
  collection,
  CollectionReference,
  doc,
  documentId,
  DocumentReference,
  getDoc,
  limit,
  onSnapshot,
  orderBy,
  query,
  Timestamp,
  where
} from 'firebase/firestore';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from '@mui/material/styles';
import { DrawerContent } from '../../components/auction';
import * as React from 'react';
import { useState, useEffect, useMemo } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import useSettings from '../../hooks/useSettings';
import Page from '../../components/Page';
import { DebounceInput } from 'react-debounce-input';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { Dealership } from 'types/dealership';
import { useFirestoreByPath } from '../../hooks/useFirestoreByPath';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import { DealerRequest } from 'types/request';
import { User } from 'constants/user';
import Tour from 'reactour';
import { isMobile } from 'react-device-detect';
import InputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom';
import PaginationComponent from './PginationComponent';
import { useDealershipBatch } from 'utils/dealershipQuery';
import canFlag from '../../assets/CA.svg';
import usaFlag from '../../assets/US.svg';

const NETWORK_OPTIONS = [
  { text: 'Sell to this dealership', value: 'invite' },
  { text: 'Buy from this dealership', value: 'request' }
];

export default function FindDealershipPage() {
  const { themeStretch } = useSettings();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const firestore = useFirestore();
  const { currentUser, user, logout } = useAuth();
  const [anchorEl, setAnchorEl] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [rows, setRows] = useState<Dealership[]>([]);
  const [filteredRows, setFilteredRows] = useState<Dealership[]>([]);
  // const [pageSize, setPageSize] = useState(25);
  const [anchorPopup, setAnchorPopup] = useState<HTMLButtonElement | null>(null);
  const [popupOpened, setPopupOpened] = useState(false);
  const [, setSearchValue] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [warningDialogOpen, setWarningDialogOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [userExistsDialogOpen, setUserExistsDialogOpen] = useState<boolean>(false);
  const [dealerships, setDealerships] = useState<Dealership[]>();
  // const [allDealerships, setAllDealerships] = useState<Dealership[]>([]);
  const [currentDealershipId, setCurrentDealershipId] = useState<string>();
  const [currentDealership, setCurrentDealership] = useState<Dealership>();
  const [confirmModalType, setConfirmModalType] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userDealershipName, setUserDealershipName] = useState<string>();
  const [isExistingInvite, setIsExistingInvite] = useState<boolean>(false);
  const [curUser, setCurUser] = useState<User>();
  const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

  const open = Boolean(anchorPopup);
  const id = open ? 'simple-popover' : undefined;
  const userId = user?.uid ? user.uid : '';

  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [lastDocArray, setLastDocArray] = useState<any[]>([]);
  const [firstDocArray, setFirstDocArray] = useState<any[]>([]);
  const [backPage, setBackPage] = useState<boolean>(false);
  const [lastDataDealershipRef, setLastDataDealershipRef] = useState<DocumentReference>();

  const countDealershipCollection = () => {
    const dealershipRef = collection(firestore, 'dealerships') as CollectionReference;
    const dealershipQuery = query(dealershipRef, where('status', '==', 'registered'));
    const unsubsribe = onSnapshot(dealershipQuery, (querySnapshot) => {
      setTotalDocuments(querySnapshot.size);
    });
    return unsubsribe;
  };

  useEffect(() => {
    const unsubscribe = countDealershipCollection();
    return () => {
      unsubscribe();
    };
  }, []);

  const queryConstraints = [
    where('status', '==', 'registered'),
    orderBy('dealershipNameLowerCase', 'asc'),
    limit(rowsPerPage)
  ];

  const { data } = useDealershipBatch(
    page,
    queryConstraints,
    lastDocArray[page - 2],
    backPage,
    firstDocArray[page - 1]
  );

  const allDealerships = useMemo(() => {
    const lastDoc = data?.docs[data?.docs?.length - 1];
    if (lastDoc) {
      const lastDocRef = data?.docs[data?.docs?.length - 1].ref as DocumentReference;
      setLastDataDealershipRef(lastDocRef);
    }
    // prettier-ignore
    return data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id }));
  }, [data]);

  const usersRef = collection(firestore, 'users') as CollectionReference<User>;
  const usersQuery = query(usersRef);
  const { data: allUsers } = useFirestoreCollectionData(usersQuery, { idField: 'id' });

  const invitePath = `users/${user?.uid}/invites`;
  const { data: allInvites } = useFirestoreByPath(invitePath);
  const { add: addInvite, update: updateInvite } = useFirestoreByPath<Dealership>(
    invitePath,
    where('inviteType', '==', 'dealership')
  );

  const { add: addRequest } = useFirestoreByPath<DealerRequest>('requests');

  const list = (data: Dealership) => (
    <Box sx={{ width: 400 }} role="presentation">
      <DrawerContent
        dealership={data}
        logo={data.logoUrl}
        onClose={closeDrawer}
        isFindDealer={true}
      />
    </Box>
  );

  const handleChangePage = async (newPage: number) => {
    if (newPage > page) {
      const firstDoc = data?.docs[0];
      const firstDocRef = firstDoc.ref as DocumentReference;
      const docRef = doc(firestore, `dealerships/${firstDocRef.id}`);
      const docSnap = await getDoc(docRef);
      const tempFirstDocs = firstDocArray;
      if (docSnap.exists()) {
        tempFirstDocs.push(docSnap);
        setFirstDocArray(tempFirstDocs);
      }
      if (lastDataDealershipRef) {
        const docRef = doc(firestore, `dealerships/${lastDataDealershipRef.id}`);
        const docSnap = await getDoc(docRef);
        const tempLastDocs = lastDocArray;
        if (docSnap.exists()) {
          tempLastDocs.push(docSnap);
          setLastDocArray(tempLastDocs);
          setPage(newPage);
          setBackPage(false);
        }
      }
    } else {
      setPage(newPage);
      setBackPage(true);
    }
  };

  const handleChangeRowsPerPage = (value: number) => {
    setRowsPerPage(value);
    setPage(1);
    setBackPage(false);
    setFirstDocArray([]);
    setLastDocArray([]);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
    const dealership = dealerships?.find((dealership: Dealership) => dealership.id === id);
    setSelectedRow(dealership);
    setAnchorPopup(event.currentTarget);
    setPopupOpened(true);
  };

  const handleClose = () => {
    setAnchorPopup(null);
    setPopupOpened(false);
  };

  const handleDrawer = () => {
    setAnchorEl(true);
  };

  const handleMenuItemClick = (row: any) => {
    handleDrawer();
    setAnchorPopup(null);
  };

  const closeDrawer = () => {
    setAnchorEl(false);
  };

  const handleSearchChange = (searchValue: string) => {
    const fRows = rows?.filter(
      (row: any) =>
        row.dealershipName.toLowerCase().includes(searchValue.toLowerCase()) ||
        row.location.toLowerCase().includes(searchValue.toLowerCase()) ||
        row.brand.toLowerCase().includes(searchValue.toLowerCase()) ||
        row.buySellPreference.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredRows(fRows);
  };

  const handleOpenInviteDialog = () => {
    if (
      currentUser.currentDealershipRole === 'admin' ||
      currentUser.currentDealershipRole === 'superAdmin'
    ) {
      handleDialogChange();
    } else {
      handleWarningDialogChange();
    }
  };

  const handleDialogChange = () => {
    setDialogOpen(!dialogOpen);
  };

  const handleWarningDialogChange = () => {
    setWarningDialogOpen(!warningDialogOpen);
  };

  const handleConfirmDialogChange = (id: string, type: string) => {
    setConfirmDialogOpen(!confirmDialogOpen);
    setCurrentDealershipId(id);
    setConfirmModalType(type);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(!confirmDialogOpen);
  };

  const handleUserExistsDialogClose = () => {
    setUserExistsDialogOpen(!userExistsDialogOpen);
  };

  const getDealerships = async () => {
    let tempRows: Dealership[] = [];
    const tempDealerships = allDealerships;
    setDealerships(tempDealerships);
    tempDealerships.forEach((row: Dealership) => {
      let tempBrandString = '';
      let tempNicheString = '';
      row.brands.forEach((brand: any, index: number) => {
        if (index === 0) {
          tempBrandString = brand.name;
        } else {
          tempBrandString = tempBrandString.concat(`, ${brand.name}`);
        }
      });

      if (row.niche) {
        row.niche.forEach((niche: any, index: number) => {
          if (index === 0) {
            tempNicheString = niche.name;
          } else {
            tempNicheString = tempNicheString.concat(`, ${niche.name}`);
          }
        });
      }

      const numberOfBuyerRatings =
        row.buyerRatings && row.buyerRatings.length > 0 ? row.buyerRatings.length : null;
      let buyerRunningTotal = 0;
      let averageBuyRating;
      if (numberOfBuyerRatings) {
        row.buyerRatings.forEach((rating: string) => {
          const splitRating = rating.split('+');
          const ratingNumber = splitRating[1];
          buyerRunningTotal = buyerRunningTotal + parseFloat(ratingNumber);
        });
        averageBuyRating = buyerRunningTotal / numberOfBuyerRatings;
      }

      const numberOfSellerRatings =
        row.sellerRatings && row.sellerRatings.length > 0 ? row.sellerRatings.length : null;
      let sellerRunningTotal = 0;
      let averageSellRating;
      if (numberOfSellerRatings) {
        row.sellerRatings.forEach((rating: string) => {
          const splitRating = rating.split('+');
          const ratingNumber = splitRating[1];
          sellerRunningTotal = sellerRunningTotal + parseFloat(ratingNumber);
        });
        averageSellRating = sellerRunningTotal / numberOfSellerRatings;
      }

      const newRow = {
        ...row,
        id: row.id,
        location: row.streetAddress + ', ' + row.city + ', ' + row.province,
        buyRating: averageBuyRating ? `${averageBuyRating.toFixed(1).toString()} / 5` : 'No Rating',
        sellRating: averageSellRating
          ? `${averageSellRating.toFixed(1).toString()} / 5`
          : 'No Rating',
        invite: {
          id: row.id,
          email: row.primaryContactEmail
        },
        request: {
          id: row.id,
          email: row.primaryContactEmail
        },
        actions: row.id,
        brand: tempBrandString,
        niche: tempNicheString && tempNicheString !== '' ? tempNicheString : 'None'
      };
      tempRows.push(newRow);
    });
    setRows(tempRows);
    setFilteredRows(tempRows);
  };

  const getCurrentDealership = async () => {
    const docRef = doc(firestore, `dealerships/${currentUser.currentDealershipId}`);
    onSnapshot(docRef, (doc: any) => {
      const currentDealership = { id: doc.id, ...doc.data() } as Dealership;
      setCurrentDealership(currentDealership);
    });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (currentUser) {
        getCurrentDealership();
      }
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {
    setAnchorEl(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupOpened]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (allDealerships) {
        getDealerships();
      }
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allDealerships]);

  useEffect(() => {
    if (isLoading) {
      setIsLoading(false);
    }
    if (confirmDialogOpen) {
      handleConfirmDialogClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDealership?.outgoingInvites, currentDealership?.outgoingRequests]);

  const columns: GridColumns = [
    {
      field: 'dealershipName',
      headerName: 'Dealership Name',
      renderCell: (params) => (
        <Grid>
          <Typography
            variant="subtitle2"
            component="h6"
            className={styles.subtitle}
            sx={{ textTransform: 'capitalize' }}
          >
            {params.value}
          </Typography>
        </Grid>
      ),
      flex: 3,
      minWidth: 250
    },
    {
      field: 'location',
      headerName: 'Location',
      renderCell: (params) => (
        <Grid>
          <Typography
            variant="subtitle2"
            component="h6"
            sx={{
              wordBreak: 'break-word',
              textTransform: 'capitalize'
            }}
          >
            {params.value}
          </Typography>
        </Grid>
      ),
      flex: 3,
      minWidth: 250
    },
    {
      field: 'country',
      headerName: 'Country',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Grid>
          {params.value !== 'USA' ? (
            <img src={canFlag} style={{ width: '40px' }} />
          ) : (
            <img src={usaFlag} style={{ width: '40px' }} />
          )}
          {/* <Typography
            variant="subtitle2"
            component="h6"
            sx={{
              wordBreak: 'break-word',
              textTransform: 'capitalize'
            }}
          >
            {params.value}
          </Typography> */}
        </Grid>
      ),
      flex: 2,
      minWidth: 125
    },
    {
      field: 'buyRating',
      headerName: 'Buyer Rating',
      renderCell: (params) => (
        <Grid>
          <Typography
            variant="subtitle2"
            component="h6"
            sx={{
              wordBreak: 'break-word',
              textTransform: 'capitalize'
            }}
          >
            {params.value}
          </Typography>
        </Grid>
      ),
      flex: 2,
      minWidth: 125
    },
    {
      field: 'sellRating',
      headerName: 'Seller Rating',
      renderCell: (params) => (
        <Grid>
          <Typography
            variant="subtitle2"
            component="h6"
            sx={{
              wordBreak: 'break-word',
              textTransform: 'capitalize'
            }}
          >
            {params.value}
          </Typography>
        </Grid>
      ),
      flex: 2,
      minWidth: 125
    },
    {
      field: 'brand',
      headerName: 'Brands',
      renderCell: (params) => (
        <Grid>
          <Typography
            variant="subtitle2"
            component="h6"
            sx={{
              textTransform: 'capitalize',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '200px'
            }}
          >
            {params.value}
          </Typography>
        </Grid>
      ),
      flex: 3,
      minWidth: 210
    },
    {
      field: 'buySellPreference',
      headerName: 'Dealership Type',
      renderCell: (params) => (
        <Grid>
          {params.value === 'buyer' && (
            <Typography variant="subtitle2" component="h6">
              Buyer
            </Typography>
          )}
          {params.value === 'seller' && (
            <Typography variant="subtitle2" component="h6">
              Seller
            </Typography>
          )}
          {params.value === 'buyerSeller' && (
            <Typography variant="subtitle2" component="h6">
              Buyer & Seller
            </Typography>
          )}
        </Grid>
      ),
      flex: 3,
      minWidth: 140
    },
    {
      field: 'niche',
      headerName: 'Niche',
      renderCell: (params) => (
        <Grid>
          <Typography
            variant="subtitle2"
            component="h6"
            sx={{
              textTransform: 'capitalize',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '230px'
            }}
          >
            {params.value}
          </Typography>
        </Grid>
      ),
      flex: 3,
      minWidth: 250
    },
    {
      field: 'invite',
      sortable: false,
      headerName: 'Sell To',
      cellClassName: 'flexStartCell',
      headerAlign: 'left',
      renderCell: (params) => (
        <Grid>
          <Button
            id="dealership4"
            variant="outlined"
            onClick={() => handleConfirmDialogChange(params.value.id, 'invite')}
            disabled={
              (currentDealership?.networkDealerships &&
                currentDealership?.networkDealerships.includes(params.value.id)) ||
              (currentDealership?.outgoingInvites &&
                currentDealership?.outgoingInvites.includes(params.value.id)) ||
              (curUser && curUser.status === 'buyerOnly') ||
              params.value.id === currentUser.currentDealershipId ||
              params.value.email === 'matt+prodAdmin@devlift.io' ||
              params.value.email === 'drew@dealergavel.com'
            }
          >
            {currentDealership?.networkDealerships &&
            currentDealership?.networkDealerships.includes(params.value.id)
              ? 'In Network'
              : currentDealership?.outgoingInvites &&
                currentDealership?.outgoingInvites.includes(params.value.id)
              ? 'Invited'
              : params.value.email === 'matt+prodAdmin@devlift.io' ||
                params.value.email === 'drew@dealergavel.com'
              ? 'N/A'
              : 'Invite'}
          </Button>
        </Grid>
      ),
      flex: 2,
      minWidth: 125
    },
    {
      field: 'request',
      sortable: false,
      headerName: 'Buy From',
      cellClassName: 'flexStartCell',
      headerAlign: 'left',
      renderCell: (params) => (
        <Grid>
          <Button
            id="dealership5"
            variant="contained"
            onClick={() => handleConfirmDialogChange(params.value.id, 'request')}
            disabled={
              (currentDealership?.joinedDealerships &&
                currentDealership?.joinedDealerships.includes(params.value.id)) ||
              (currentDealership?.outgoingRequests &&
                currentDealership?.outgoingRequests.includes(params.value.id)) ||
              params.value.id === currentUser.currentDealershipId ||
              params.value.email === 'matt+prodAdmin@devlift.io' ||
              params.value.email === 'drew@dealergavel.com'
            }
            sx={{ ml: '10px' }}
          >
            {currentDealership?.joinedDealerships &&
            currentDealership?.joinedDealerships.includes(params.value.id)
              ? 'Joined'
              : currentDealership?.outgoingRequests &&
                currentDealership?.outgoingRequests.includes(params.value.id)
              ? 'Request Sent'
              : params.value.email === 'matt+prodAdmin@devlift.io' ||
                params.value.email === 'drew@dealergavel.com'
              ? 'N/A'
              : 'Request To Join'}
          </Button>
        </Grid>
      ),
      flex: 2,
      minWidth: 150
    },
    {
      field: 'actions',
      sortable: false,
      headerName: 'Actions',
      cellClassName: 'flexEndCell',
      headerAlign: 'center',
      renderCell: (params) => (
        <Grid>
          <IconButton
            id="dealership6"
            aria-describedby={id}
            onClick={(e: any) => handleClick(e, params.value)}
            sx={{
              ml: 1,
              backgroundColor:
                theme.palette.mode === 'dark' ? '#2D2F3D' : theme.palette.background.paper
            }}
          >
            <MoreVertIcon sx={{ color: theme.palette.mode === 'dark' ? '#989DB1' : '' }} />
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorPopup}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            sx={{ width: 220 }}
            PaperProps={{ classes: { root: styles.menuPopover } }}
          >
            <MenuItem
              onClick={() => handleMenuItemClick(params.value)}
              sx={{ typography: 'body2', py: 1, px: 2.5 }}
            >
              View Dealership
            </MenuItem>
          </Popover>
        </Grid>
      ),
      flex: 1,
      minWidth: 50
    }
  ];

  const sendInvite = async (
    firstName: string,
    lastName: string,
    email: string,
    networkType: string,
    phone: string
  ) => {
    for (let user of allUsers as User[]) {
      if (user.email.toLowerCase() === email.toLowerCase()) {
        const docRef = doc(firestore, `dealerships/${user.currentDealershipId}`);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const userDealership = { id: docSnap.id, ...docSnap.data() } as Dealership;
          setUserDealershipName(userDealership.dealershipName);
          setUserExistsDialogOpen(true);
          setIsExistingInvite(false);
          enqueueSnackbar('User already exists!', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right'
            }
          });
          return;
        }
      }
    }

    const userDealershipRef = doc(firestore, `dealerships/${currentUser?.currentDealershipId}`);
    const userDealerSnap = await getDoc(userDealershipRef);
    let currentDealership: Dealership = {} as Dealership;
    if (userDealerSnap.exists()) {
      const data = userDealerSnap.data();
      currentDealership = { ...data, id: userDealerSnap.id } as Dealership;
    }

    const tmpInvites = {
      id: userId,
      firstName: firstName,
      lastName: lastName,
      email: email,
      inviteDateSent: Timestamp.now(),
      inviteStatus: 'invite sent',
      inviteName: currentUser ? `${currentUser.firstName} ${currentUser.lastName}` : ' ',
      inviteRole: 'admin',
      inviteDealershipId: 'NoId',
      status: 'incomplete',
      primaryContact: email,
      dealership: 'Not Set By User',
      inviteDealershipName: currentDealership.dealershipName || 'No Dealership',
      inviteType: 'dealership',
      role: 'admin',
      type: 'sent',
      sendingDealershipId: currentUser.currentDealershipId,
      networkType: networkType,
      phone: phone,
      isExpired: false
    };

    if (allInvites) {
      const emailNotFound = allInvites.every((invite: any) => invite.email !== email);
      if (emailNotFound) {
        const { id } = await addInvite(tmpInvites);
        const updatedInvite = { ...tmpInvites, inviteId: id };
        await updateInvite(id, updatedInvite);
      } else {
        setUserExistsDialogOpen(true);
        setIsExistingInvite(true);
        enqueueSnackbar('User already exists!', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          }
        });
      }
    } else {
      const { id } = await addInvite(tmpInvites);
      const updatedInvite = { ...tmpInvites, inviteId: id };
      await updateInvite(id, updatedInvite);
    }
  };

  const handleInviteDealer = async (inviteType: string) => {
    const receivingDealership = dealerships?.find(
      (dealership: Dealership) => dealership.id === currentDealershipId
    );
    // const userDealership = allDealerships?.find(
    //   (dealership: Dealership) => dealership.id === currentUser.currentDealershipId
    // );
    const userDealership = currentDealership;
    const request = {
      receivingDealershipId: receivingDealership?.id,
      receivingDealershipName: receivingDealership?.dealershipName as string,
      receivingDealershipPhone: receivingDealership?.phone as string,
      receivingDealershipLocation: receivingDealership?.province as string,
      receivingDealershipBrands: receivingDealership?.brands,
      receivingDealershipCountry: receivingDealership?.country,
      requestingUsername: currentUser.firstName + ' ' + currentUser.lastName,
      requestingUserrole: currentUser.currentDealershipRole,
      requestingDealershipId: userDealership?.id,
      requestingDealershipName: userDealership?.dealershipName,
      requestingDealershipLocation: userDealership?.province as string,
      requestingDealershipBrands: userDealership?.brands,
      requestingDealershipCountry: userDealership?.country,
      requestDateSent: Timestamp.now(),
      requestStatus: 'pending',
      requestingType: inviteType === 'invite' ? 'buy-from-me' : 'buy-from-them',
      receivingType: inviteType === 'invite' ? 'buy-from-them' : 'buy-from-me',
      emailType: inviteType,
      isExpired: false
    };

    if (
      inviteType === 'invite' &&
      userDealership?.networkDealerships &&
      userDealership?.networkDealerships.includes(receivingDealership?.id)
    ) {
      handleConfirmDialogClose();
      enqueueSnackbar('Dealership already networked!', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      });
    } else if (
      inviteType === 'request' &&
      receivingDealership?.networkDealerships &&
      receivingDealership?.networkDealerships.includes(userDealership?.id)
    ) {
      handleConfirmDialogClose();
      enqueueSnackbar('Dealership already joined!', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      });
    } else {
      setIsLoading(true);
      await addRequest(request);
    }
  };

  const useStyles = makeStyles({
    tourContainer: {
      maxWidth: '500px !important',
      borderRadius: '10px !important'
    },
    expandIcon: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      borderRadius: '100%',
      width: '25px',
      height: '25px',
      padding: '5px'
    },
    headerText: {
      fontSize: '14px',
      marginRight: '18px',
      marginLeft: '5px'
    },
    inputText: {
      fontSize: '14px'
    },
    dialogContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      position: 'relative'
    },
    emailRow: {
      marginTop: '10px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    inviteRow: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      marginTop: '20px'
    },
    inviteButton: {
      backgroundColor: theme.palette.primary.main
    },
    buttonRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      width: '100%',
      marginTop: '60px'
    },
    toolbar: {
      margin: 1,
      width: '200px',
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      right: '380px',
      top: '-35px'
    },
    dropdownStyle: {
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '12px',
      lineHeight: '18px',
      color: theme.palette.info.light
    },
    search: {
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.background.neutral : 'transparent !important',
      border: theme.palette.mode === 'dark' ? 'unset' : `1px solid ${theme.palette.divider}`,
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1
    },
    searchInput: {
      fontSize: '14px',
      width: '100%',
      height: '34px',
      margin: '0 10px',
      border: 'none',
      padding: '5px 10px',
      outline: 'none !important',
      marginLeft: 2,
      flex: 1,
      backgroundColor: 'transparent !important',
      color:
        theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
      '&::placeholder': {
        color:
          theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
        fontSize: '14px'
      }
    },
    searchIcon: {
      fill: theme.palette.primary.main,
      marginRight: '5px'
    },
    secondaryButton: {
      backgroundColor: theme.palette.grey[50],
      color: theme.palette.info.light,
      '&:hover': {
        backgroundColor: theme.palette.grey[100]
      }
    },
    subtitle: {
      fontWeight: 'bold'
      // color: theme.palette.secondary.darker
    },
    subtitle2: {
      color: theme.palette.info.light,
      fontWeight: 'normal'
    },
    list: {
      '& li:first-child, & li:last-child': {
        '& button': {
          border: `1px solid ${theme.palette.grey[100]}`,
          color: theme.palette.info.light,
          width: '100px',
          '&:hover': {
            backgroundColor: theme.palette.grey[100]
          }
        }
      },
      '& li': {
        paddingLeft: 3,
        paddingRight: 3,
        '& button': {
          color: theme.palette.info.main,
          minWidth: '40px'
        }
      }
    },
    divider: {
      width: '93%'
    },
    gridDateTitle: {
      marginRight: '64px'
    },
    menuPopover: {
      boxShadow: '0 0 2px 0 rgba(139, 148, 156, 0.24),0 10px 20px -4px rgba(139, 148, 156, 0.24)',
      // backgroundColor: theme.palette.primary.contrastText,
      paddingTop: '20px',
      paddingBottom: '20px',
      '@global': {
        '.MuiMenuItem-root': {
          paddingBottom: '6px'
        },
        '.MuiMenuItem-root:last-child': {
          // color: theme.palette.error.dark
        }
      }
    },
    dataGrid: {
      '@global': {
        '.MuiDataGrid-selectedRowCount': {
          display: 'none'
        },
        '.MuiDataGrid-footerContainer': {
          //position: 'absolute',
          //top: '-53px',
          //right: '0',
          //borderTop: '0'
          display: 'none'
        },
        '.css-h4goux-MuiInputBase-root-MuiTablePagination-select': {
          marginRight: '0 !important'
        },
        '.MuiDataGrid-columnHeaders': {
          // visibility: 'hidden'
          position: 'relative',
          color: theme.palette.info.light
        },
        '.MuiDataGrid-virtualScroller': {
          marginTop: '0 !important'
        },
        '.flexEndCell': {
          justifyContent: 'flex-end',
          padding: '0'
        },
        '.flexStartCell': {
          justifyContent: 'flex-start',
          padding: '0'
        },
        '.flexEndCellPopup': {
          justifyContent: 'flex-end',
          padding: '0',
          '& .MuiTouchRipple-root': {
            display: 'none'
          },
          '& .MuiButtonBase-root': {
            '&:hover': {
              backgroundColor: 'transparent !important'
            }
          }
        },
        '.MuiDataGrid-row': {
          // minHeight: '88px !important'
          // maxHeight: '88px !important'
          minHeight: '65px !important',
          maxHeight: '65px !important'
        },
        '.MuiDataGrid-cell': {
          minHeight: '65px !important',
          maxHeight: '65px !important',
          // minHeight: '88px !important',
          // maxHeight: '88px !important',
          borderBottom: '0',
          whiteSpace: 'normal !important',
          wordWrap: 'break-word !important'
        },
        '.imageCell': {
          padding: '0'
        }
      }
    }
  });

  const styles = useStyles();

  const [tourOpen, setTourOpen] = useState(false);
  const { update: updateUser } = useFirestoreByPath<User>('users');
  const [userDeleted, setUserDeleted] = useState<boolean>(false);
  const [userDisabled, setUserDisabled] = useState<boolean>(false);
  const [userSuspended, setUserSuspended] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/dashboard');
  };

  const snapshotUser = () => {
    const userDoc = doc(firestore, `users/${user?.uid}`) as DocumentReference<User>;
    onSnapshot(userDoc, (doc: any) => {
      const userObj = { ...doc.data(), id: doc.id } as User;
      setCurUser(userObj);
      if (!userObj.email) {
        logout?.().then(() => {
          navigate('.');
          window.location.reload();
        });
      }
      if (userObj.showFindDealershipTour && !tourOpen) {
        setTourOpen(true);
      }
      if (userObj && userObj.status.toLowerCase() === 'suspended') {
        setUserSuspended(true);
      } else {
        setUserSuspended(false);
      }
      if (userObj && userObj.status.toLowerCase() === 'disabled') {
        setUserDisabled(true);
      } else {
        setUserDisabled(false);
      }
    });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      snapshotUser();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const handleCloseTour = async () => {
    await updateUser(user?.uid as string, { showFindDealershipTour: false });
    setTourOpen(false);
  };

  const steps = [
    {
      selector: '',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Community: Find a Dealership</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              Start by inviting dealerships where you have established relationships buying and selling. Then browse the DealerGavel Community listings and start to invite or request to join other networks in the community.
            </Typography>
          </Box>
        </Box>
      )
    },
    {
      selector: '#dealership3',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Community: Find a Dealership</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          {/* prettier-ignore */}
          <Typography sx={{  marginTop: '10px', color: theme.palette.text.secondary }}>
            To send an invite to a dealership not in this list, click this button and fill out the information. An invite link will be sent to the provided email.
          </Typography>
        </Box>
      )
    },
    {
      selector: '[data-rowindex="0"]',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Community: Find a Dealership</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          {/* prettier-ignore */}
          <Typography sx={{  marginTop: '10px', color: theme.palette.text.secondary }}>
            Browse through the list of community members identifying dealers of interest. 
          </Typography>
        </Box>
      )
    },
    {
      selector: '#dealership4',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Community: Find a Dealership</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          {/* prettier-ignore */}
          <Typography sx={{  marginTop: '10px', color: theme.palette.text.secondary }}>
            Want to sell vehicles to a Dealership? Invite them to your Dealership network. Once they accept your invite, they will see your available vehicles in their active auction.
          </Typography>
        </Box>
      )
    },
    {
      selector: '#dealership5',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Community: Find a Dealership</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          {/* prettier-ignore */}
          <Typography sx={{ marginTop: '10px', color: theme.palette.text.secondary }}>
            Want to buy vehicles from a Dealership? Request to join their Dealership network. Once they accept your request, you’ll see their available vehicles in your active auction.
          </Typography>
        </Box>
      )
    },
    {
      selector: '#dealership6',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Community: Find a Dealership</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          {/* prettier-ignore */}
          <Typography sx={{ marginTop: '10px', color: theme.palette.text.secondary }}>
            Click here for contact information and other relevant dealership information.
          </Typography>
        </Box>
      )
    }
  ];

  return (
    <>
      {!userDeleted && !userSuspended && !userDisabled ? (
        <Page title="Dealer Networks | DealerGavel">
          <Container
            maxWidth={themeStretch ? false : 'xl'}
            sx={{
              width: '100%',
              marginRight: 'auto'
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ mb: 0 }}
            >
              <Box sx={{ display: 'flex', mt: '0px' }}>
                <Typography
                  variant="h4"
                  component="h1"
                  paragraph
                  sx={{
                    fontSize: '1rem !important',
                    color:
                      theme.palette.mode === 'dark'
                        ? theme.palette.common.white
                        : theme.palette.text.primary
                  }}
                >
                  Find A Dealership
                </Typography>
              </Box>
              <Button
                id="dealership3"
                onClick={handleOpenInviteDialog}
                className={styles.inviteButton}
                variant="contained"
                sx={{ p: '7px 36px 7px 36px', marginTop: '-24px' }}
                disabled={curUser && curUser.status === 'buyerOnly'}
              >
                Send an Invite
              </Button>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ mb: 4 }}
            >
              <Paper component="form" className={styles.search}>
                <DebounceInput
                  className={styles.searchInput}
                  placeholder="Search Dealerships"
                  minLength={2}
                  debounceTimeout={300}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    handleSearchChange(e.target.value);
                  }}
                />
                <SearchIcon className={styles.searchIcon} sx={{ width: '20px' }} />
              </Paper>
            </Grid>
            <Grid
              container
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            >
              <Typography
                component="p"
                className={styles.headerText}
                sx={{
                  textTransform: 'uppercase',
                  fontWeight: '700',
                  color: theme.palette.info.light
                }}
              >
                DEALERSHIPS ({totalDocuments /* filteredRows && filteredRows.length */})
              </Typography>
              <PaginationComponent
                // currentPage={currentPage}
                currentPage={page}
                totalPages={Math.ceil(totalDocuments / rowsPerPage)}
                rowsPerPageOptions={[5, 10, 25]}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={(value: number) => handleChangeRowsPerPage(value)}
                onNextPage={() => {
                  // setCurrentPage((prevPage) => prevPage + 1);
                  handleChangePage(page + 1);
                }}
                onPrevPage={() => {
                  // setCurrentPage((prevPage) => prevPage - 1);
                  handleChangePage(page - 1);
                }}
              />
              <Grid xs={12} sx={{ height: '60vh', marginTop: '10px', oveflowX: 'scroll' }}>
                <DataGrid
                  rows={filteredRows}
                  columns={columns}
                  pageSize={rowsPerPage}
                  disableColumnMenu
                  disableSelectionOnClick={true}
                  className={styles.dataGrid}
                  components={{
                    ColumnSortedAscendingIcon: UnfoldMoreIcon,
                    ColumnSortedDescendingIcon: UnfoldMoreIcon
                  }}
                ></DataGrid>
              </Grid>
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'row',
                  justifyContent: 'flex-end'
                }}
              >
                <Typography sx={{ fontSize: '13px', color: theme.palette.primary.main }}>
                  Scroll for More
                </Typography>
              </Box>
            </Grid>
            <Drawer
              anchor="right"
              open={anchorEl}
              PaperProps={{
                sx: {
                  top: isMobile ? '64px' : '83px',
                  width: '400px',
                  bgcolor: 'background.default',
                  height: isMobile ? 'calc(100vh - 64px)' : 'calc(100vh - 83px)'
                }
              }}
              variant="persistent"
            >
              {selectedRow !== null ? list(selectedRow as Dealership) : null}
            </Drawer>
          </Container>
          <Dialog open={dialogOpen} onClose={handleDialogChange}>
            <Box className={styles.dialogContainer}>
              <DialogTitle>Invite Dealership</DialogTitle>
              <CloseIcon
                onClick={handleDialogChange}
                sx={{
                  cursor: 'pointer',
                  position: 'absolute',
                  right: '15px',
                  top: '24px'
                }}
              />
            </Box>
            <DialogContent>
              <Formik
                initialValues={{
                  firstName: '',
                  lastName: '',
                  email: '',
                  networkType: '',
                  phone: ''
                }}
                validationSchema={Yup.object().shape({
                  firstName: Yup.string()
                    .required('First Name is required')
                    .matches(/^[a-zA-Z0-9 ]+$/, 'Cannot contain special characters'),
                  lastName: Yup.string()
                    .required('Last Name is required')
                    .matches(/^[a-zA-Z0-9 ]+$/, 'Cannot contain special characters'),
                  email: Yup.string()
                    .required('Email is required')
                    .matches(
                      /^[a-zA-Z0-9.!$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      'Email must be a valid email address'
                    ),
                  phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
                  // .required('Phone is required'),
                  networkType: Yup.string().required('Invitation type is required')
                })}
                onSubmit={async (values, { setErrors, setSubmitting }) => {
                  sendInvite(
                    values.firstName,
                    values.lastName,
                    values.email,
                    values.networkType,
                    values.phone
                  );
                  handleDialogChange();
                }}
              >
                {({
                  getFieldProps,
                  isSubmitting,
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  isValid,
                  touched,
                  errors,
                  dirty,
                  values
                }): JSX.Element => (
                  <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                    <Typography variant="h6">Primary Contact</Typography>
                    <Box className={styles.emailRow}>
                      <TextField
                        label="Enter First Name"
                        type="text"
                        sx={{ minWidth: '350px', marginTop: '10px' }}
                        {...getFieldProps('firstName')}
                        error={Boolean(touched.firstName && errors.firstName)}
                        helperText={touched.firstName && errors.firstName}
                      ></TextField>
                      <TextField
                        label="Enter Last Name"
                        type="text"
                        sx={{ minWidth: '350px', marginTop: '10px' }}
                        {...getFieldProps('lastName')}
                        error={Boolean(touched.lastName && errors.lastName)}
                        helperText={touched.lastName && errors.lastName}
                      ></TextField>
                      <TextField
                        label="Enter Email"
                        type="text"
                        sx={{ minWidth: '350px', marginTop: '10px' }}
                        {...getFieldProps('email')}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                      ></TextField>
                      <InputMask
                        mask="(999) 999-9999"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phone}
                      >
                        {() => (
                          <TextField
                            sx={{ minWidth: '350px', marginTop: '10px' }}
                            label="Phone Number"
                            {...getFieldProps('phone')}
                            error={Boolean(touched.phone && errors.phone)}
                            helperText={touched.phone && errors.phone}
                          />
                        )}
                      </InputMask>
                    </Box>
                    <Divider
                      sx={{
                        mt: 2,
                        mb: 2,
                        borderColor:
                          theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
                      }}
                    />
                    <Typography variant="h6">Invitation</Typography>
                    <Box className={styles.emailRow}>
                      <FormControl sx={{ mb: '10px' }} fullWidth>
                        <InputLabel id="select-network">You would like to...</InputLabel>
                        <Select
                          {...getFieldProps('networkType')}
                          id="select-network"
                          label="You would like to..."
                          error={Boolean(touched.networkType && errors.networkType)}
                        >
                          {NETWORK_OPTIONS &&
                            NETWORK_OPTIONS.map((option: any, index: number) => {
                              return (
                                <MenuItem key={index} value={option.value}>
                                  {option.text}
                                </MenuItem>
                              );
                            })}
                        </Select>
                        <FormHelperText error={Boolean(touched.networkType && errors.networkType)}>
                          {errors.networkType}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                    <Box className={styles.buttonRow}>
                      <LoadingButton size="large" type="submit" variant="contained">
                        Send Invite
                      </LoadingButton>
                    </Box>
                  </form>
                )}
              </Formik>
            </DialogContent>
          </Dialog>
          <Dialog open={confirmDialogOpen} onClose={handleConfirmDialogClose}>
            <Box className={styles.dialogContainer}>
              <DialogTitle>
                {confirmModalType === 'invite' ? 'Send Invite' : 'Send Request'}
              </DialogTitle>
              <CloseIcon
                onClick={handleConfirmDialogClose}
                sx={{
                  cursor: 'pointer',
                  position: 'absolute',
                  right: '15px',
                  top: '24px'
                }}
              />
            </Box>
            <DialogContent sx={{ textAlign: 'center' }}>
              <Typography variant="h5">
                {confirmModalType === 'invite' ? 'Invite Dealership' : 'Request to Join'}
              </Typography>
              {confirmModalType === 'invite' ? (
                <Typography>
                  Expand your Selling Network by inviting this dealership to buy from you.
                </Typography>
              ) : (
                <Typography>
                  Expand your Buying Network by sending a Request to Join this dealership's
                  &nbsp;Selling Network.
                </Typography>
              )}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  marginTop: '20px'
                }}
              >
                <Button variant="outlined" size="large" onClick={handleConfirmDialogClose}>
                  Cancel
                </Button>
                <LoadingButton
                  loading={isLoading}
                  variant="contained"
                  size="large"
                  onClick={() =>
                    handleInviteDealer(confirmModalType === 'invite' ? 'invite' : 'request')
                  }
                >
                  {confirmModalType === 'invite' ? 'Send Invite' : 'Send Request'}
                </LoadingButton>
              </Box>
            </DialogContent>
          </Dialog>
          <Dialog open={userExistsDialogOpen} onClose={handleUserExistsDialogClose}>
            <DialogContent>
              <Box
                sx={{
                  width: 500,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-evenly'
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    mb: 2,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                  }}
                >
                  <div></div>
                  <Typography variant="h6">User Found</Typography>
                  <CloseIcon
                    sx={{ fontSize: '18px', cursor: 'pointer' }}
                    onClick={handleUserExistsDialogClose}
                  />
                </Box>
                <Divider
                  sx={{
                    width: '100%',
                    borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
                  }}
                />
                <Box
                  sx={{
                    width: '80%',
                    mt: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center'
                  }}
                >
                  <Typography variant="h4"> This user already exists! </Typography>
                  {isExistingInvite ? (
                    <>
                      {/* prettier-ignore */}
                      <Typography sx={{ fontSize: '14px', mt: 1 }}>
                        The user you are trying to invite has already been invited to the platform.
                        If you are the one who invited them, you can <span style={{ fontWeight: 'bold' }}>Resend the Invite</span>
                        &nbsp;on this page.
                      </Typography>
                    </>
                  ) : (
                    <>
                      {/* prettier-ignore */}
                      <Typography sx={{ fontSize: '14px', mt: 1 }}>
                        The user you are trying to invite belongs to the dealership <span style={{ fontWeight: 'bold' }}>{userDealershipName}</span>.
                        You can invite this dealership to your network by searching for <span style={{ fontWeight: 'bold' }}>{userDealershipName}</span> on this page.
                      </Typography>
                    </>
                  )}
                </Box>
              </Box>
            </DialogContent>
            <DialogActions
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end'
              }}
            >
              <Button type="button" variant="contained" onClick={handleUserExistsDialogClose}>
                Ok, Got It!
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={warningDialogOpen} onClose={handleWarningDialogChange}>
            <Box>
              <DialogTitle>Permissions Error</DialogTitle>
              <CloseIcon
                onClick={handleWarningDialogChange}
                sx={{
                  cursor: 'pointer',
                  position: 'absolute',
                  right: '15px',
                  top: '24px'
                }}
              />
            </Box>
            <DialogContent>
              You must be an admin to perform this action, please reach out to your admin.
            </DialogContent>
          </Dialog>
          {filteredRows && filteredRows.length > 0 && (
            <Tour
              steps={steps}
              isOpen={tourOpen}
              onRequestClose={() => handleCloseTour()}
              className={styles.tourContainer}
            />
          )}
        </Page>
      ) : (
        <>
          {userDeleted && (
            <Alert severity="error">
              <AlertTitle>Permission Denied</AlertTitle>
              Your account has been disabled.
            </Alert>
          )}
          {userSuspended && (
            <>
              <Alert severity="error">
                <AlertTitle>Thank you for joining DealerGavel</AlertTitle>
                Your account information is being reviewed by DealerGavel team, and will be
                &nbsp;authorized shortly. For now, you can return to the dashboard and await
                &nbsp;authorization.
              </Alert>
              <Box sx={{ mt: '20px' }}>
                <Button onClick={handleNavigate} variant="contained">
                  Back to Dashboard
                </Button>
              </Box>
            </>
          )}
          {userDisabled && (
            <>
              <Alert severity="error">
                <AlertTitle>Permission Denied</AlertTitle>
                Your account has been disabled.
              </Alert>
              <Box sx={{ mt: '20px' }}>
                <Button onClick={handleNavigate} variant="contained">
                  Back to Dashboard
                </Button>
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
}
