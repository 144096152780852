import {
  Container,
  Typography,
  Grid,
  Paper,
  Box,
  MenuItem,
  Tabs,
  Tab,
  Select,
  InputBase,
  SelectChangeEvent,
  TablePagination,
  Divider,
  Alert,
  AlertTitle,
  Dialog,
  Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from '@mui/material/styles';
import * as React from 'react';
import { useState, useEffect, useRef, useMemo } from 'react';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import { DebounceInput } from 'react-debounce-input';
import { fDateTimeSuffix } from 'components/shared/dateTimeFormat';
import PendingListItem from 'components/seller/postAuction/PendingListItem';
import SoldListItem from 'components/seller/postAuction/SoldListItem';
import ArrivedListItem from 'components/seller/postAuction/ArrivedListItem';
import DeliveredListItem from 'components/seller/postAuction/DeliveredListItem';
import NosaleListItem from 'components/seller/postAuction/NosaleListItem';
import { VehicleInfo } from 'types/vehicleInfo';
import useAuth from 'hooks/useAuth';
import {
  collection,
  CollectionReference,
  doc,
  DocumentReference,
  onSnapshot,
  query,
  limit,
  orderBy,
  updateDoc,
  where,
  getDoc
} from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import { useLocation, useNavigate } from 'react-router-dom';
import Tour from 'reactour';
import { User } from 'constants/user';
import { useFirestoreByPath } from 'hooks/useFirestoreByPath';
import EmptyStateMessage from 'components/shared/EmptyStateMessage';
import { sortNegotiationVehiclesArray } from 'utils/searchHelper';
import { ViewportList } from 'react-viewport-list';
import AuctionDrawer from 'components/auction/drawer/AuctionDrawer';
import SharedDrawer from 'components/shared/SharedDrawer';
import HistoryDrawerContent from 'components/shared/HistoryDrawerContent';
import BillofSale from 'components/admin/BillofSale';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import { MIconButton } from 'components/@material-extend';
import { useVehicleBatch } from 'utils/vehicleQuery';
import moment from 'moment';
import { Dealership } from 'types/dealership';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface StyledTabsProps {
  id?: string;
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`notifications-tabpanel-${index}`}
      aria-labelledby={`notifications-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `post-auction-tab-${index}`,
    'aria-controls': `post-auction-tabpanel-${index}`
  };
};

export default function PostAuctionPage() {
  const { themeStretch } = useSettings();
  const theme = useTheme();
  const { currentUser, user, logout } = useAuth();
  const firestore = useFirestore();
  const [searchValue, setSearchValue] = useState('');
  const [, setAnchorEl] = useState(false);
  const [popupOpened] = useState(false);

  const pendingRef = useRef(null);
  const negotiationRef = useRef(null);
  const soldRef = useRef(null);
  const arrivedRef = useRef(null);
  const deliveredRef = useRef(null);
  const nosaleRef = useRef(null);

  const locationState = useLocation().state as { tabValue: number };
  let navigateTabValue;
  if (locationState) {
    navigateTabValue = locationState.tabValue;
  } else {
    navigateTabValue = 0;
  }
  const [tabValue, setTabValue] = useState(navigateTabValue);

  const [orderPending, setOrderPending] = useState('newest');
  const [pagePending, setPagePending] = useState(0);
  const [rowsPerPagePending, setRowsPerPagePending] = useState(25);
  const [lastDocPendingArray, setLastDocPendingArray] = useState<any[]>([]);
  const [firstDocPendingArray, setFirstDocPendingArray] = useState<any[]>([]);
  const [backPagePending, setBackPagePending] = useState<boolean>(false);
  const [lastDataVehicleRefPending, setLastDataVehicleRefPending] = useState<DocumentReference>();

  const [collectionSizePending, setCollectionSizePending] = useState<number>(0);
  const countVehicleCollectionPending = () => {
    const vehicleRef = collection(firestore, 'vehicles') as CollectionReference;
    const vehicleQuery = query(
      vehicleRef,
      where('dealershipId', '==', currentUser.currentDealershipId),
      where('auction.auctionStatus', '==', 'post auction'),
      where('auction.postAuctionStatus', 'in', ['pending', 'Pending']),
      where('auction.negotiationStatusSeller', '==', 'action required')
    );
    const unsubsribePending = onSnapshot(vehicleQuery, (querySnapshot) => {
      setCollectionSizePending(querySnapshot.size);
    });
    return unsubsribePending;
  };

  const queryConstraintsPending = [
    where('dealershipId', '==', currentUser.currentDealershipId),
    where('auction.auctionStatus', '==', 'post auction'),
    where('auction.postAuctionStatus', 'in', ['pending', 'Pending']),
    where('auction.negotiationStatusSeller', '==', 'action required'),
    orderBy('auction.endDate', 'desc'),
    orderBy('auction.endTime', 'desc'),
    limit(rowsPerPagePending)
  ];

  const pendingData = useVehicleBatch(
    pagePending,
    queryConstraintsPending,
    lastDocPendingArray[pagePending - 1],
    backPagePending,
    firstDocPendingArray[pagePending]
  );

  const pendingVehicles = useMemo(() => {
    const lastDoc = pendingData?.data?.docs[pendingData?.data?.docs?.length - 1];
    if (lastDoc) {
      // prettier-ignore
      const lastDocRef = pendingData.data?.docs[pendingData.data?.docs?.length - 1].ref as DocumentReference;
      setLastDataVehicleRefPending(lastDocRef);
    }
    // prettier-ignore
    return pendingData.data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id }));
  }, [pendingData.data]);

  const [orderNegotiation, setOrderNegotiation] = useState('newest');
  const [pageNegotiation, setPageNegotiation] = useState(0);
  const [rowsPerPageNegotiation, setRowsPerPageNegotiation] = useState(25);
  const [lastDocNegotiationArray, setLastDocNegotiationArray] = useState<any[]>([]);
  const [firstDocNegotiationArray, setFirstDocNegotiationArray] = useState<any[]>([]);
  const [backPageNegotiation, setBackPageNegotiation] = useState<boolean>(false);
  // prettier-ignore
  const [lastDataVehicleRefNegotiation, setLastDataVehicleRefNegotiation] = useState<DocumentReference>();

  const [collectionSizeNegotiation, setCollectionSizeNegotiation] = useState<number>(0);
  const countVehicleCollectionNegotiation = () => {
    const vehicleRef = collection(firestore, 'vehicles') as CollectionReference;
    const vehicleQuery = query(
      vehicleRef,
      where('dealershipId', '==', currentUser.currentDealershipId),
      where('auction.auctionStatus', '==', 'post auction'),
      where('auction.postAuctionStatus', 'in', ['pending', 'Pending']),
      where('auction.negotiationStatusSeller', '!=', 'action required'),
      orderBy('auction.negotiationStatusSeller', 'asc')
    );
    const unsubsribeNegotiation = onSnapshot(vehicleQuery, (querySnapshot) => {
      setCollectionSizeNegotiation(querySnapshot.size);
    });
    return unsubsribeNegotiation;
  };

  const queryConstraintsNegotiation = [
    where('dealershipId', '==', currentUser.currentDealershipId),
    where('auction.auctionStatus', '==', 'post auction'),
    where('auction.postAuctionStatus', 'in', ['pending', 'Pending']),
    where('auction.negotiationStatusSeller', '!=', 'action required'),
    orderBy('auction.negotiationStatusSeller', 'asc'),
    orderBy('auction.endDate', 'desc'),
    orderBy('auction.endTime', 'desc'),
    limit(rowsPerPageNegotiation)
  ];

  const negotiationData = useVehicleBatch(
    pageNegotiation,
    queryConstraintsNegotiation,
    lastDocNegotiationArray[pageNegotiation - 1],
    backPageNegotiation,
    firstDocNegotiationArray[pageNegotiation]
  );

  const negotiationVehicles = useMemo(() => {
    const lastDoc = negotiationData?.data?.docs[negotiationData?.data?.docs?.length - 1];
    if (lastDoc) {
      // prettier-ignore
      const lastDocRef = negotiationData.data?.docs[negotiationData.data?.docs?.length - 1].ref as DocumentReference;
      setLastDataVehicleRefNegotiation(lastDocRef);
    }
    // prettier-ignore
    return negotiationData.data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id }));
  }, [negotiationData.data]);

  const [orderSold, setOrderSold] = useState('newest');
  const [pageSold, setPageSold] = useState(0);
  const [rowsPerPageSold, setRowsPerPageSold] = useState(25);
  const [lastDocSoldArray, setLastDocSoldArray] = useState<any[]>([]);
  const [firstDocSoldArray, setFirstDocSoldArray] = useState<any[]>([]);
  const [backPageSold, setBackPageSold] = useState<boolean>(false);
  // prettier-ignore
  const [lastDataVehicleRefSold, setLastDataVehicleRefSold] = useState<DocumentReference>();

  const [collectionSizeSold, setCollectionSizeSold] = useState<number>(0);
  const countVehicleCollectionSold = () => {
    const vehicleRef = collection(firestore, 'vehicles') as CollectionReference;
    const vehicleQuery = query(
      vehicleRef,
      where('dealershipId', '==', currentUser.currentDealershipId),
      where('auction.auctionStatus', '==', 'post auction'),
      where('auction.postAuctionStatus', 'in', ['sold', 'Sold'])
    );
    const unsubsribeSold = onSnapshot(vehicleQuery, (querySnapshot) => {
      setCollectionSizeSold(querySnapshot.size);
    });
    return unsubsribeSold;
  };

  const queryConstraintsSold = [
    where('dealershipId', '==', currentUser.currentDealershipId),
    where('auction.auctionStatus', '==', 'post auction'),
    where('auction.postAuctionStatus', 'in', ['sold', 'Sold']),
    orderBy('auction.endDate', 'desc'),
    orderBy('auction.endTime', 'desc'),
    limit(rowsPerPageSold)
  ];

  const soldData = useVehicleBatch(
    pageSold,
    queryConstraintsSold,
    lastDocSoldArray[pageSold - 1],
    backPageSold,
    firstDocSoldArray[pageSold]
  );

  const soldVehicles = useMemo(() => {
    const lastDoc = soldData?.data?.docs[soldData?.data?.docs?.length - 1];
    if (lastDoc) {
      // prettier-ignore
      const lastDocRef = soldData.data?.docs[soldData.data?.docs?.length - 1].ref as DocumentReference;
      setLastDataVehicleRefSold(lastDocRef);
    }
    // prettier-ignore
    return soldData.data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id }));
  }, [soldData.data]);

  const [orderArrived, setOrderArrived] = useState('newest');
  const [pageArrived, setPageArrived] = useState(0);
  const [rowsPerPageArrived, setRowsPerPageArrived] = useState(25);
  const [lastDocArrivedArray, setLastDocArrivedArray] = useState<any[]>([]);
  const [firstDocArrivedArray, setFirstDocArrivedArray] = useState<any[]>([]);
  const [backPageArrived, setBackPageArrived] = useState<boolean>(false);
  // prettier-ignore
  const [lastDataVehicleRefArrived, setLastDataVehicleRefArrived] = useState<DocumentReference>();

  const [collectionSizeArrived, setCollectionSizeArrived] = useState<number>(0);
  const countVehicleCollectionArrived = () => {
    const vehicleRef = collection(firestore, 'vehicles') as CollectionReference;
    const vehicleQuery = query(
      vehicleRef,
      where('dealershipId', '==', currentUser.currentDealershipId),
      where('auction.auctionStatus', '==', 'post auction'),
      where('auction.postAuctionStatus', 'in', ['arrived', 'Arrived'])
    );
    const unsubsribeArrived = onSnapshot(vehicleQuery, (querySnapshot) => {
      setCollectionSizeArrived(querySnapshot.size);
    });
    return unsubsribeArrived;
  };

  const queryConstraintsArrived = [
    where('dealershipId', '==', currentUser.currentDealershipId),
    where('auction.auctionStatus', '==', 'post auction'),
    where('auction.postAuctionStatus', 'in', ['arrived', 'Arrived']),
    orderBy('auction.endDate', 'desc'),
    orderBy('auction.endTime', 'desc'),
    limit(rowsPerPageArrived)
  ];

  const arrivedData = useVehicleBatch(
    pageArrived,
    queryConstraintsArrived,
    lastDocArrivedArray[pageArrived - 1],
    backPageArrived,
    firstDocArrivedArray[pageArrived]
  );

  const arrivedVehicles = useMemo(() => {
    const lastDoc = arrivedData?.data?.docs[arrivedData?.data?.docs?.length - 1];
    if (lastDoc) {
      // prettier-ignore
      const lastDocRef = arrivedData.data?.docs[arrivedData.data?.docs?.length - 1].ref as DocumentReference;
      setLastDataVehicleRefArrived(lastDocRef);
    }
    // prettier-ignore
    return arrivedData.data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id }));
  }, [arrivedData.data]);

  const [orderDelivered, setOrderDelivered] = useState('newest');
  const [pageDelivered, setPageDelivered] = useState(0);
  const [rowsPerPageDelivered, setRowsPerPageDelivered] = useState(25);
  const [lastDocDeliveredArray, setLastDocDeliveredArray] = useState<any[]>([]);
  const [firstDocDeliveredArray, setFirstDocDeliveredArray] = useState<any[]>([]);
  const [backPageDelivered, setBackPageDelivered] = useState<boolean>(false);
  // prettier-ignore
  const [lastDataVehicleRefDelivered, setLastDataVehicleRefDelivered] = useState<DocumentReference>();

  const [collectionSizeDelivered, setCollectionSizeDelivered] = useState<number>(0);
  const countVehicleCollectionDelivered = () => {
    const vehicleRef = collection(firestore, 'vehicles') as CollectionReference;
    const vehicleQuery = query(
      vehicleRef,
      where('dealershipId', '==', currentUser.currentDealershipId),
      where('auction.auctionStatus', '==', 'post auction'),
      where('auction.postAuctionStatus', 'in', ['delivered', 'Delivered'])
    );
    const unsubsribeDelivered = onSnapshot(vehicleQuery, (querySnapshot) => {
      setCollectionSizeDelivered(querySnapshot.size);
    });
    return unsubsribeDelivered;
  };

  const queryConstraintsDelivered = [
    where('dealershipId', '==', currentUser.currentDealershipId),
    where('auction.auctionStatus', '==', 'post auction'),
    where('auction.postAuctionStatus', 'in', ['delivered', 'Delivered']),
    orderBy('auction.endDate', 'desc'),
    orderBy('auction.endTime', 'desc'),
    limit(rowsPerPageDelivered)
  ];

  const deliveredData = useVehicleBatch(
    pageDelivered,
    queryConstraintsDelivered,
    lastDocDeliveredArray[pageDelivered - 1],
    backPageDelivered,
    firstDocDeliveredArray[pageDelivered]
  );

  const deliveredVehicles = useMemo(() => {
    const lastDoc = deliveredData?.data?.docs[deliveredData?.data?.docs?.length - 1];
    if (lastDoc) {
      // prettier-ignore
      const lastDocRef = deliveredData.data?.docs[deliveredData.data?.docs?.length - 1].ref as DocumentReference;
      setLastDataVehicleRefDelivered(lastDocRef);
    }
    // prettier-ignore
    return deliveredData.data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id }));
  }, [deliveredData.data]);

  const [orderNosale, setOrderNosale] = useState('newest');
  const [pageNosale, setPageNosale] = useState(0);
  const [rowsPerPageNosale, setRowsPerPageNosale] = useState(25);
  const [lastDocNosaleArray, setLastDocNosaleArray] = useState<any[]>([]);
  const [firstDocNosaleArray, setFirstDocNosaleArray] = useState<any[]>([]);
  const [backPageNosale, setBackPageNosale] = useState<boolean>(false);
  const [lastDataVehicleRefNosale, setLastDataVehicleRefNosale] = useState<DocumentReference>();

  const [collectionSizeNosale, setCollectionSizeNosale] = useState<number>(0);
  const countVehicleCollectionNosale = () => {
    const vehicleRef = collection(firestore, 'vehicles') as CollectionReference;
    const vehicleQuery = query(
      vehicleRef,
      where('dealershipId', '==', currentUser.currentDealershipId),
      where('auction.auctionStatus', '==', 'post auction'),
      where('auction.postAuctionStatus', 'in', ['no sale', 'No Sale'])
    );
    const unsubsribeNosale = onSnapshot(vehicleQuery, (querySnapshot) => {
      setCollectionSizeNosale(querySnapshot.size);
    });
    return unsubsribeNosale;
  };

  const queryConstraintsNosale = [
    where('dealershipId', '==', currentUser.currentDealershipId),
    where('auction.auctionStatus', '==', 'post auction'),
    where('auction.postAuctionStatus', 'in', ['no sale', 'No Sale']),
    orderBy('auction.endDate', 'desc'),
    orderBy('auction.endTime', 'desc'),
    limit(rowsPerPageNosale)
  ];

  const noSaleData = useVehicleBatch(
    pageNosale,
    queryConstraintsNosale,
    lastDocNosaleArray[pageNosale - 1],
    backPageNosale,
    firstDocNosaleArray[pageNosale]
  );

  const nosaleVehicles = useMemo(() => {
    const lastDoc = noSaleData?.data?.docs[noSaleData?.data?.docs?.length - 1];
    if (lastDoc) {
      const lastDocRef = lastDoc.ref as DocumentReference;
      setLastDataVehicleRefNosale(lastDocRef);
    }
    // prettier-ignore
    return noSaleData.data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id }));
  }, [noSaleData.data]);

  useEffect(() => {
    const unsubscribePending = countVehicleCollectionPending();
    const unsubscribeNegotiation = countVehicleCollectionNegotiation();
    const unsubscribeSold = countVehicleCollectionSold();
    const unsubscribeArrived = countVehicleCollectionArrived();
    const unsubscribeDelivered = countVehicleCollectionDelivered();
    const unsubscribeNosale = countVehicleCollectionNosale();
    return () => {
      unsubscribePending();
      unsubscribeNegotiation();
      unsubscribeSold();
      unsubscribeArrived();
      unsubscribeDelivered();
      unsubscribeNosale();
    };
  }, []);

  const [tourOpen, setTourOpen] = useState(false);
  const { update: updateUser } = useFirestoreByPath<User>('users');

  const [userDeleted, setUserDeleted] = useState<boolean>(false);
  const [curUser, setCurUser] = useState<User>();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [userDisabled, setUserDisabled] = useState<boolean>(false);
  const [userSuspended, setUserSuspended] = useState<boolean>(false);
  const [buyerOnly, setBuyerOnly] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/dashboard');
  };

  const snapshotUser = () => {
    const userDoc = doc(firestore, `users/${user?.uid}`) as DocumentReference<User>;
    onSnapshot(userDoc, (doc: any) => {
      const userObj = { ...doc.data(), id: doc.id } as User;
      setCurUser(userObj);
      if (!userObj.email) {
        logout?.().then(() => {
          navigate('.');
          window.location.reload();
        });
      }
      if (userObj.showPostAuctionSellTour && !tourOpen) {
        setTourOpen(true);
      }
      if (userObj && userObj.status.toLowerCase() === 'suspended') {
        setUserSuspended(true);
      } else {
        setUserSuspended(false);
      }
      if (userObj && userObj.status.toLowerCase() === 'disabled') {
        setUserDisabled(true);
      } else {
        setUserDisabled(false);
      }
      if (userObj && userObj.status === 'buyerOnly') {
        setBuyerOnly(true);
      } else {
        setBuyerOnly(false);
      }
    });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      snapshotUser();
      getCurrentDealership();
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseTour = async () => {
    await updateUser(user?.uid as string, { showPostAuctionSellTour: false });
    setTourOpen(false);
  };

  const steps = [
    {
      selector: '#postAuctionSell2',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Sell: Post-Auction</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          {/* prettier-ignore */}
          <Typography sx={{ marginTop: '10px', color: theme.palette.text.secondary }}>
            <span style={{ fontWeight: '900' }}>Post Auction</span> is where vehicles land after their active auction time has expired. Vehicles move through a number of statues during the closing process.
          </Typography>
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              <span style={{ fontWeight: '900' }}>Pending</span> is where vehicles that have not hit the floor price sit until you as the seller Accepts, Declines or begins a negotiation on the Buyer's offer. Vehicles will only sit in this status until the end of that business day.
            </Typography>
          </Box>
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              <span style={{ fontWeight: '900' }}>In Negotiation</span> is when your floor has not been achieved and you have chosen to continue negotiations. Once your offer is sent the Buyer will be able to Accept, Counter or Decline their offer.
            </Typography>
          </Box>
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              <span style={{ fontWeight: '900' }}>Sold</span> vehicles will arrive in the sold status, identifying the winning bidder, if the floor price was met and/or surpassed during Active Auction. If the vehicle is 'in stock' it will immediately move to arrived. If the vehicle is an appraisal it will sit in Sold until you as the seller move it to an arrived status. 
            </Typography>
          </Box>
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              <span style={{ fontWeight: '900' }}>Arrived</span> vehicles that have been Sold will move to arrived status once available. At this point arrange payment, ownership and transport details.
            </Typography>
          </Box>
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              <span style={{ fontWeight: '900' }}>Delivered</span>  It is here you move the vehicle from arrived to delivered, once completed, closing out the transaction.
            </Typography>
          </Box>
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              <span style={{ fontWeight: '900' }}>No Sale</span> This is a historical list of vehicles uploaded into the Active Auction but not Sold. You can move vehicles here back into Parked and/or Launching Soon.
            </Typography>
          </Box>
        </Box>
      )
    },
    {
      selector: '#postAuctionSell3',
      content: () => (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography variant="h5">Sell: Post-Auction</Typography>
          </Box>
          <Divider
            sx={{
              borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : ''
            }}
          />
          {/* prettier-ignore */}
          <Typography sx={{ marginTop: '10px', color: theme.palette.text.secondary }}>
            Response buttons are only triggered during negotiation.
          </Typography>
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              <span style={{ fontWeight: '900' }}>Awaiting Response</span> This is to notify that you are waiting for the Buyer to make a decision on your counter offer.
            </Typography>
          </Box>
          <Box sx={{ marginTop: '10px' }}>
            {/* prettier-ignore */}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              <span style={{ fontWeight: '900' }}>Response Required</span> This is to notify you that you need to either accept, counter or decline the Buyer's offer. 
            </Typography>
          </Box>
        </Box>
      )
    }
  ];

  const handleChangePagePending = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > pagePending) {
      const firstDoc = pendingData?.data?.docs[0];
      const firstDocRef = firstDoc.ref as DocumentReference;
      const docRef = doc(firestore, `vehicles/${firstDocRef.id}`);
      const docSnap = await getDoc(docRef);
      const tempFirstDocs = firstDocPendingArray;
      if (docSnap.exists()) {
        tempFirstDocs.push(docSnap);
        setFirstDocPendingArray(tempFirstDocs);
      }
      if (lastDataVehicleRefPending) {
        const docRef = doc(firestore, `vehicles/${lastDataVehicleRefPending.id}`);
        const docSnap = await getDoc(docRef);
        const tempLastDocs = lastDocPendingArray;
        if (docSnap.exists()) {
          tempLastDocs.push(docSnap);
          setLastDocPendingArray(tempLastDocs);
          setPagePending(newPage);
          setBackPagePending(false);
        }
      }
    } else {
      setPagePending(newPage);
      setBackPagePending(true);
    }
  };

  const handleChangePageNegotiation = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > pageNegotiation) {
      const firstDoc = negotiationData?.data?.docs[0];
      const firstDocRef = firstDoc.ref as DocumentReference;
      const docRef = doc(firestore, `vehicles/${firstDocRef.id}`);
      const docSnap = await getDoc(docRef);
      const tempFirstDocs = firstDocNegotiationArray;
      if (docSnap.exists()) {
        tempFirstDocs.push(docSnap);
        setFirstDocNegotiationArray(tempFirstDocs);
      }
      if (lastDataVehicleRefNegotiation) {
        const docRef = doc(firestore, `vehicles/${lastDataVehicleRefNegotiation.id}`);
        const docSnap = await getDoc(docRef);
        const tempLastDocs = lastDocNegotiationArray;
        if (docSnap.exists()) {
          tempLastDocs.push(docSnap);
          setLastDocNegotiationArray(tempLastDocs);
          setPageNegotiation(newPage);
          setBackPageNegotiation(false);
        }
      }
    } else {
      setPageNegotiation(newPage);
      setBackPageNegotiation(true);
    }
  };

  const handleChangePageSold = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > pageSold) {
      const firstDoc = soldData?.data?.docs[0];
      const firstDocRef = firstDoc.ref as DocumentReference;
      const docRef = doc(firestore, `vehicles/${firstDocRef.id}`);
      const docSnap = await getDoc(docRef);
      const tempFirstDocs = firstDocSoldArray;
      if (docSnap.exists()) {
        tempFirstDocs.push(docSnap);
        setFirstDocSoldArray(tempFirstDocs);
      }
      if (lastDataVehicleRefSold) {
        const docRef = doc(firestore, `vehicles/${lastDataVehicleRefSold.id}`);
        const docSnap = await getDoc(docRef);
        const tempLastDocs = lastDocSoldArray;
        if (docSnap.exists()) {
          tempLastDocs.push(docSnap);
          setLastDocSoldArray(tempLastDocs);
          setPageSold(newPage);
          setBackPageSold(false);
        }
      }
    } else {
      setPageSold(newPage);
      setBackPageSold(true);
    }
  };

  const handleChangePageArrived = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > pageArrived) {
      const firstDoc = arrivedData?.data?.docs[0];
      const firstDocRef = firstDoc.ref as DocumentReference;
      const docRef = doc(firestore, `vehicles/${firstDocRef.id}`);
      const docSnap = await getDoc(docRef);
      const tempFirstDocs = firstDocArrivedArray;
      if (docSnap.exists()) {
        tempFirstDocs.push(docSnap);
        setFirstDocArrivedArray(tempFirstDocs);
      }
      if (lastDataVehicleRefArrived) {
        const docRef = doc(firestore, `vehicles/${lastDataVehicleRefArrived.id}`);
        const docSnap = await getDoc(docRef);
        const tempLastDocs = lastDocArrivedArray;
        if (docSnap.exists()) {
          tempLastDocs.push(docSnap);
          setLastDocArrivedArray(tempLastDocs);
          setPageArrived(newPage);
          setBackPageArrived(false);
        }
      }
    } else {
      setPageArrived(newPage);
      setBackPageArrived(true);
    }
  };

  const handleChangePageDelivered = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > pageDelivered) {
      const firstDoc = deliveredData?.data?.docs[0];
      const firstDocRef = firstDoc.ref as DocumentReference;
      const docRef = doc(firestore, `vehicles/${firstDocRef.id}`);
      const docSnap = await getDoc(docRef);
      const tempFirstDocs = firstDocDeliveredArray;
      if (docSnap.exists()) {
        tempFirstDocs.push(docSnap);
        setFirstDocDeliveredArray(tempFirstDocs);
      }
      if (lastDataVehicleRefDelivered) {
        const docRef = doc(firestore, `vehicles/${lastDataVehicleRefDelivered.id}`);
        const docSnap = await getDoc(docRef);
        const tempLastDocs = lastDocDeliveredArray;
        if (docSnap.exists()) {
          tempLastDocs.push(docSnap);
          setLastDocDeliveredArray(tempLastDocs);
          setPageDelivered(newPage);
          setBackPageDelivered(false);
        }
      }
    } else {
      setPageDelivered(newPage);
      setBackPageDelivered(true);
    }
  };

  const handleChangePageNosale = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > pageNosale) {
      const firstDoc = noSaleData?.data?.docs[0];
      const firstDocRef = firstDoc.ref as DocumentReference;
      const docRef = doc(firestore, `vehicles/${firstDocRef.id}`);
      const docSnap = await getDoc(docRef);
      const tempFirstDocs = firstDocNosaleArray;
      if (docSnap.exists()) {
        tempFirstDocs.push(docSnap);
        setFirstDocNosaleArray(tempFirstDocs);
      }
      if (lastDataVehicleRefNosale) {
        const docRef = doc(firestore, `vehicles/${lastDataVehicleRefNosale.id}`);
        const docSnap = await getDoc(docRef);
        const tempLastDocs = lastDocNosaleArray;
        if (docSnap.exists()) {
          tempLastDocs.push(docSnap);
          setLastDocNosaleArray(tempLastDocs);
          setPageNosale(newPage);
          setBackPageNosale(false);
        }
      }
    } else {
      setPageNosale(newPage);
      setBackPageNosale(true);
    }
  };

  const handleChangeRowsPerPagePending = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPagePending(parseInt(event.target.value, 10));
    setPagePending(0);
    setFirstDocPendingArray([]);
    setLastDocPendingArray([]);
    setBackPagePending(false);
  };

  const handleChangeRowsPerPageNegotiation = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPageNegotiation(parseInt(event.target.value, 10));
    setPageNegotiation(0);
    setFirstDocNegotiationArray([]);
    setLastDocNegotiationArray([]);
    setBackPageNegotiation(false);
  };

  const handleChangeRowsPerPageSold = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPageSold(parseInt(event.target.value, 10));
    setPageSold(0);
    setFirstDocSoldArray([]);
    setLastDocSoldArray([]);
    setBackPageSold(false);
  };

  const handleChangeRowsPerPageArrived = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPageArrived(parseInt(event.target.value, 10));
    setPageArrived(0);
    setFirstDocArrivedArray([]);
    setLastDocArrivedArray([]);
    setBackPageArrived(false);
  };

  const handleChangeRowsPerPageDelivered = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPageDelivered(parseInt(event.target.value, 10));
    setPageDelivered(0);
    setFirstDocDeliveredArray([]);
    setLastDocDeliveredArray([]);
    setBackPageDelivered(false);
  };

  const handleChangeRowsPerPageNosale = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPageNosale(parseInt(event.target.value, 10));
    setPageNosale(0);
    setFirstDocNosaleArray([]);
    setLastDocNosaleArray([]);
    setBackPageNosale(false);
  };

  const handleOrderByChangePending = (event: SelectChangeEvent) => {
    setOrderPending(event.target.value);
  };

  const handleOrderByChangeNegotiation = (event: SelectChangeEvent) => {
    setOrderNegotiation(event.target.value);
  };

  const handleOrderByChangeSold = (event: SelectChangeEvent) => {
    setOrderSold(event.target.value);
  };

  const handleOrderByChangeArrived = (event: SelectChangeEvent) => {
    setOrderArrived(event.target.value);
  };

  const handleOrderByChangeDelivered = (event: SelectChangeEvent) => {
    setOrderDelivered(event.target.value);
  };

  const handleOrderByChangeNosale = (event: SelectChangeEvent) => {
    setOrderNosale(event.target.value);
  };

  const handleChangeTab = async (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleCloseBidHistory = () => {
    setOpenBidHistory(!openBidHistory);
  };

  const handleCloseVehicleHistory = () => {
    setOpenVehicleHistory(!openVehicleHistory);
  };

  const handleCloseBillofSale = () => {
    setOpenBillofSale(!openBillofSale);
  };

  const handleCancelSale = async () => {
    const docRef = doc(firestore, `vehicles/${currentVehicle?.id}`);
    await updateDoc(docRef, {
      'auction.postAuctionStatus': 'no sale',
      'auction.reasonLost': 'Sale Cancelled'
    });
    enqueueSnackbar('Sale Cancelled', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      },
      action: (key) => (
        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
          <CloseIcon />
        </MIconButton>
      )
    });
  };

  const [currentVehicle, setCurrentVehicle] = useState<VehicleInfo>();
  const [openBillofSale, setOpenBillofSale] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openBidHistory, setOpenBidHistory] = useState(false);
  const [openVehicleHistory, setOpenVehicleHistory] = useState(false);

  const SetDrawerVehicle = (index: number, vehicle: VehicleInfo, isArrivedItem?: boolean) => {
    setCurrentVehicle(vehicle);
    if (index === 0) {
      setOpenVehicleHistory(!openVehicleHistory);
    } else if (index === 1) {
      setOpenBidHistory(!openBidHistory);
    } else if (index === 2) {
      setOpenDrawer(!openDrawer);
    } else if (index === 3 && !isArrivedItem) {
      setOpenBillofSale(!openBillofSale);
    }
  };

  const toggleBillOfSale = (vehicle: VehicleInfo) => {
    setCurrentVehicle(vehicle);
    setOpenBillofSale(!openBillofSale);
  };

  const StyledTabs = styled((props: StyledTabsProps) => (
    <Tabs
      {...props}
      variant="scrollable"
      sx={{
        minHeight: 'unset !important'
      }}
      // scrollButtons
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />
      }}
    />
  ))(({ theme }) => ({
    '& .MuiTab-root': {
      color:
        theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
      backgroundColor:
        theme.palette.mode === 'dark'
          ? theme.palette.background.neutral
          : theme.palette.background.paper
    },
    '& .MuiTab-root:not(:last-of-type)': {
      marginRight: '10px'
    },
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent !important',
      padding: '5px'
    },
    '& .Mui-selected': {
      backgroundColor: theme.palette.primary.lighter,
      color: `${theme.palette.primary.main} !important`
    },
    '& .MuiTabs-indicatorSpan': {
      display: 'none',
      maxWidth: 40,
      width: '100%',
      backgroundColor: theme.palette.chart.violet[2]
    }
  }));

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setAnchorEl(false);
    }
    return () => {
      isMounted = false;
    };
  }, [popupOpened]);

  const useStyles = makeStyles({
    paper: {
      minWidth: '800px'
    },
    tourContainer: {
      maxWidth: '500px !important',
      borderRadius: '10px !important'
    },
    pagination: {
      borderTopStyle: 'none',
      fontWeight: '700',
      borderTop: 'none'
    },
    inviteButton: {
      backgroundColor: theme.palette.primary.main
    },
    searchContainer: {
      width: '100%',
      maxWidth: '400px',
      height: 'auto',
      padding: '5px 0px',
      // marginBottom: '23px',
      // marginRight: '40px'
      marginBottom: '0px',
      marginRight: '20px'
    },
    search: {
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.background.neutral : 'transparent !important',
      border: theme.palette.mode === 'dark' ? 'unset' : `1px solid ${theme.palette.divider}`,
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: '100%'
    },
    searchInput: {
      fontSize: '14px',
      width: '100%',
      height: '34px',
      margin: '0 10px',
      border: 'none',
      padding: '5px 10px',
      outline: 'none !important',
      marginLeft: 2,
      flex: 1,
      backgroundColor: 'transparent !important',
      color:
        theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
      '&::placeholder': {
        color:
          theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.secondary,
        fontSize: '14px'
      }
    },
    searchIcon: {
      fill: theme.palette.primary.main,
      marginRight: '5px'
    },
    secondaryButton: {
      backgroundColor: theme.palette.grey[50],
      color: theme.palette.info.light
    },
    subtitle: {
      fontWeight: 'bold',
      color: theme.palette.secondary.darker
    },
    subtitle2: {
      color: theme.palette.info.light,
      fontWeight: 'normal'
    },
    list: {
      '& li:first-child, & li:last-child': {
        '& button': {
          border: `1px solid ${theme.palette.grey[100]}`,
          color: theme.palette.info.light,
          width: '100px'
        }
      },
      '& li': {
        paddingLeft: 3,
        paddingRight: 3,
        '& button': {
          color: theme.palette.info.main,
          minWidth: '40px'
        }
      }
    },
    forwardIcon: {
      fontSize: '12px !important'
    },
    backwardIcon: {
      fontSize: '12px !important'
    },
    divider: {
      width: '93%',
      [theme.breakpoints.down(1524)]: {
        width: '90%'
      },
      [theme.breakpoints.down(863)]: {
        width: '87%'
      }
    },
    gridAvatarTitle: {
      [theme.breakpoints.down(1486)]: {
        flexBasis: '40%',
        maxWidth: '40%'
      },
      [theme.breakpoints.down(1299)]: {
        flexBasis: '45%',
        maxWidth: '45%'
      }
    },
    gridDateTitle: {
      marginRight: '64px',
      [theme.breakpoints.down(1330)]: {
        marginRight: '24px'
      }
    },
    menuPopover: {
      boxShadow: '0 0 2px 0 rgba(139, 148, 156, 0.24),0 10px 20px -4px rgba(139, 148, 156, 0.24)',
      // backgroundColor: theme.palette.primary.contrastText,
      paddingTop: '20px',
      paddingBottom: '20px',
      '@global': {
        '.MuiMenuItem-root': {
          paddingBottom: '6px'
        },
        '.MuiMenuItem-root:last-child': {
          color: theme.palette.error.dark
        }
      }
    },
    dataGrid: {
      '@global': {
        '.MuiDataGrid-columnHeaders': {
          visibility: 'hidden'
        },
        '.MuiDataGrid-virtualScroller': {
          marginTop: '0 !important'
        },
        '.flexEndCell': {
          justifyContent: 'flex-end',
          padding: '0'
        },
        '.flexEndCellPopup': {
          justifyContent: 'flex-end',
          padding: '0',
          '& .MuiTouchRipple-root': {
            display: 'none'
          }
        },
        '.MuiDataGrid-row': {
          minHeight: '88px !important',
          maxHeight: '88px !important',
          '&:hover': {
            backgroundColor: 'transparent !important'
          }
        },
        '.MuiDataGrid-cell': {
          minHeight: '88px !important',
          maxHeight: '88px !important',
          borderBottom: '0'
        },
        '.imageCell': {
          padding: '0'
        }
      }
    },
    headerTextContainer: {
      width: '100%',
      height: 'auto',
      minHeight: '65px',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'space-between',
      // marginBottom: '25px'
      marginBottom: '0px'
    },
    controlsContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'flex-end'
    },
    headerText: {
      fontSize: '14px',
      marginRight: '18px',
      // marginLeft: '5px'
      marginLeft: '0px'
    },
    inputText: {
      fontSize: '14px'
    }
  });

  const styles = useStyles();

  const [pendingEmptyState, setPendingEmptyState] = useState<boolean>(false);
  const [negotiationEmptyState, setNegotiationEmptyState] = useState<boolean>(false);
  const [soldEmptyState, setSoldEmptyState] = useState<boolean>(false);
  const [arrivedEmptyState, setArrivedEmptyState] = useState<boolean>(false);
  const [deliveredEmptyState, setDeliveredEmptyState] = useState<boolean>(false);
  const [noSaleEmptyState, setNoSaleEmptyState] = useState<boolean>(false);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (!pendingVehicles || pendingVehicles.length < 1) {
        setPendingEmptyState(true);
      } else {
        setPendingEmptyState(false);
      }
      if (!negotiationVehicles || negotiationVehicles.length < 1) {
        setNegotiationEmptyState(true);
      } else {
        setNegotiationEmptyState(false);
      }
      if (!soldVehicles || soldVehicles.length < 1) {
        setSoldEmptyState(true);
      } else {
        setSoldEmptyState(false);
      }
      if (!arrivedVehicles || arrivedVehicles.length < 1) {
        setArrivedEmptyState(true);
      } else {
        setArrivedEmptyState(false);
      }
      if (!deliveredVehicles || deliveredVehicles.length < 1) {
        setDeliveredEmptyState(true);
      } else {
        setDeliveredEmptyState(false);
      }
      if (!nosaleVehicles || nosaleVehicles.length < 1) {
        setNoSaleEmptyState(true);
      } else {
        setNoSaleEmptyState(false);
      }
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pendingVehicles,
    negotiationVehicles,
    soldVehicles,
    arrivedVehicles,
    deliveredVehicles,
    nosaleVehicles
  ]);

  function sortPostAuctionSell(a: any, b: any, order: any) {
    const endDateA = moment(a.auction.endDate.toDate()).format('YYYY/MM/DD');
    const endTimeA = moment(a.auction.endTime.toDate()).format('hh:mm:ss a');
    const finalEndDateA = new Date(endDateA + ' ' + endTimeA);

    const endDateB = moment(b.auction.endDate.toDate()).format('YYYY/MM/DD');
    const endTimeB = moment(b.auction.endTime.toDate()).format('hh:mm:ss a');
    const finalEndDateB = new Date(endDateB + ' ' + endTimeB);

    if (order === 'oldest') {
      return finalEndDateA.valueOf() > finalEndDateB.valueOf() ? 1 : -1;
    } else if (order === 'newest') {
      return finalEndDateA.valueOf() > finalEndDateB.valueOf() ? -1 : 1;
    } else {
      return a.details.make > b.details.make ? 1 : -1;
    }
  }

  const [currentDealership, setCurrentDealership] = useState<Dealership>();

  const getCurrentDealership = async () => {
    const userDealershipRef = doc(firestore, `dealerships/${currentUser.currentDealershipId}`);
    const userDealerSnap = await getDoc(userDealershipRef);
    if (userDealerSnap.exists()) {
      const data = userDealerSnap.data();
      const currentDealership = { ...data, id: userDealerSnap.id } as Dealership;
      setCurrentDealership(currentDealership);
    }
  };

  return (
    <>
      {!userDeleted && !userSuspended && !userDisabled && !buyerOnly ? (
        <Page title="Post Auction | DealerGavel">
          <Container maxWidth={themeStretch ? false : 'xl'}>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexWrap: 'wrap'
              }}
            >
              <Box>
                <Typography
                  variant="h4"
                  component="h1"
                  sx={{
                    fontSize: '1rem !important',
                    marginTop: '0px',
                    marginBottom: '0px',
                    color:
                      theme.palette.mode === 'dark'
                        ? theme.palette.common.white
                        : theme.palette.text.primary
                  }}
                >
                  Post Auction: Selling
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'wrap'
              }}
            >
              <StyledTabs
                id="postAuctionSell2"
                value={tabValue}
                onChange={handleChangeTab}
                aria-label="Post Auction Tabs"
              >
                <Tab
                  sx={{
                    padding: '0px 10px',
                    borderRadius: '5px',
                    minHeight: '40px',
                    height: '40px'
                  }}
                  label={
                    <Typography sx={{ fontSize: '12px' }}>
                      PENDING ({collectionSizePending})
                    </Typography>
                  }
                  {...a11yProps(0)}
                />
                <Tab
                  sx={{
                    padding: '0px 10px',
                    borderRadius: '5px',
                    minHeight: '40px',
                    height: '40px'
                  }}
                  label={
                    <Typography sx={{ fontSize: '12px' }}>
                      IN NEGOTIATION ({collectionSizeNegotiation})
                    </Typography>
                  }
                  {...a11yProps(1)}
                />
                <Tab
                  sx={{
                    padding: '0px 10px',
                    borderRadius: '5px',
                    minHeight: '40px',
                    height: '40px'
                  }}
                  label={
                    <Typography sx={{ fontSize: '12px' }}>SOLD ({collectionSizeSold})</Typography>
                  }
                  {...a11yProps(2)}
                />
                <Tab
                  sx={{
                    padding: '0px 10px',
                    borderRadius: '5px',
                    minHeight: '40px',
                    height: '40px'
                  }}
                  label={
                    <Typography sx={{ fontSize: '12px' }}>
                      ARRIVED ({collectionSizeArrived})
                    </Typography>
                  }
                  {...a11yProps(3)}
                />
                <Tab
                  sx={{
                    padding: '0px 10px',
                    borderRadius: '5px',
                    minHeight: '40px',
                    height: '40px'
                  }}
                  label={
                    <Typography sx={{ fontSize: '12px' }}>
                      DELIVERED ({collectionSizeDelivered})
                    </Typography>
                  }
                  {...a11yProps(4)}
                />
                <Tab
                  sx={{
                    padding: '0px 10px',
                    borderRadius: '5px',
                    minHeight: '40px',
                    height: '40px'
                  }}
                  label={
                    <Typography sx={{ fontSize: '12px' }}>
                      NO SALE ({collectionSizeNosale})
                    </Typography>
                  }
                  {...a11yProps(5)}
                />
              </StyledTabs>
              <Box className={styles.searchContainer}>
                <Paper component="form" className={styles.search}>
                  <DebounceInput
                    className={styles.searchInput}
                    placeholder="Search Vehicles by Make, Model, Year, etc."
                    minLength={2}
                    debounceTimeout={300}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                  <SearchIcon className={styles.searchIcon} sx={{ width: '20px' }} />
                </Paper>
              </Box>
            </Box>
            {/* PENDING */}
            <TabPanel value={tabValue} index={0}>
              <Box sx={{ flexGrow: 1 }}>
                <Box className={styles.headerTextContainer}>
                  {pendingVehicles && (
                    <Typography
                      component="p"
                      className={styles.headerText}
                      sx={{
                        textTransform: 'uppercase',
                        fontWeight: '700',
                        color: theme.palette.info.light
                      }}
                    >
                      {collectionSizePending} vehicles found
                    </Typography>
                  )}
                  {pendingVehicles && (
                    <Box className={styles.controlsContainer}>
                      <Typography component="p" className={styles.headerText}>
                        Sort By:
                      </Typography>
                      <Select
                        label="Sort By"
                        value={orderPending}
                        onChange={handleOrderByChangePending}
                        className={styles.inputText}
                        inputProps={{ 'aria-label': 'order vehicles' }}
                        input={<InputBase />}
                      >
                        <MenuItem value={'oldest'}>Oldest</MenuItem>
                        <MenuItem value={'newest'}>Newest</MenuItem>
                        {/* <MenuItem value={'vehicleName'}>Name</MenuItem> */}
                      </Select>
                      {pendingVehicles && (
                        <TablePagination
                          className={styles.pagination}
                          count={collectionSizePending}
                          page={pagePending}
                          onPageChange={handleChangePagePending}
                          rowsPerPage={rowsPerPagePending}
                          onRowsPerPageChange={handleChangeRowsPerPagePending}
                          rowsPerPageOptions={[5, 10, 25]}
                        />
                      )}
                    </Box>
                  )}
                </Box>
                <Grid
                  // container
                  rowSpacing={1}
                  sx={{
                    overflowX: 'auto',
                    height: 'calc(100vh - 200px)',
                    overflowY: 'auto'
                  }}
                  ref={pendingRef}
                >
                  {pendingVehicles && (
                    <ViewportList
                      initialPrerender={8}
                      viewportRef={pendingRef}
                      items={pendingVehicles
                        // .slice()
                        .sort((a, b) => sortPostAuctionSell(a, b, orderPending))
                        .filter(
                          (v: VehicleInfo) =>
                            v.details.make.toLowerCase().includes(searchValue.toLowerCase()) ||
                            v.details.model.toLowerCase().includes(searchValue.toLowerCase()) ||
                            fDateTimeSuffix(v.auction.captureDate.toDate())
                              .toString()
                              .toLowerCase()
                              .includes(searchValue.toLowerCase()) ||
                            v.auction.floorPrice
                              .toString()
                              .toLowerCase()
                              .includes(searchValue.toLowerCase()) ||
                            v.auction.captureRepName
                              .toLowerCase()
                              .includes(searchValue.toLowerCase()) ||
                            v.details.year
                              ?.toString()
                              .toLowerCase()
                              .includes(searchValue.toLowerCase()) ||
                            v.details.trim.toLowerCase().includes(searchValue.toLowerCase())
                        )}
                    >
                      {(vehicle: VehicleInfo) => (
                        <>
                          {currentDealership && (
                            <PendingListItem
                              key={vehicle.id}
                              vehicle={vehicle}
                              dealershipName={currentDealership.dealershipName}
                              initialOpen={false}
                              sendVehicleToParent={SetDrawerVehicle}
                            />
                          )}
                        </>
                      )}
                    </ViewportList>
                  )}
                  {pendingEmptyState && (
                    <EmptyStateMessage
                      boldMessage="You have no vehicles in pending."
                      message="Vehicles that don't hit the floor price appear here and provide the opportunity to accept, decline or start a negotiation."
                    />
                  )}
                </Grid>
              </Box>
            </TabPanel>
            {/* NEGOTIATION */}
            <TabPanel value={tabValue} index={1}>
              <Box sx={{ flexGrow: 1 }}>
                <Box className={styles.headerTextContainer}>
                  {negotiationVehicles && (
                    <Typography
                      component="p"
                      className={styles.headerText}
                      sx={{
                        textTransform: 'uppercase',
                        fontWeight: '700',
                        color: theme.palette.info.light
                      }}
                    >
                      {collectionSizeNegotiation} vehicles found
                    </Typography>
                  )}
                  {negotiationVehicles && (
                    <Box className={styles.controlsContainer}>
                      <Typography component="p" className={styles.headerText}>
                        Sort By:
                      </Typography>
                      <Select
                        label="Sort By"
                        value={orderNegotiation}
                        onChange={handleOrderByChangeNegotiation}
                        className={styles.inputText}
                        inputProps={{ 'aria-label': 'order vehicles' }}
                        input={<InputBase />}
                      >
                        <MenuItem value={'oldest'}>Oldest</MenuItem>
                        <MenuItem value={'newest'}>Newest</MenuItem>
                        {/* <MenuItem value={'vehicleName'}>Name</MenuItem> */}
                      </Select>
                      {negotiationVehicles && (
                        <TablePagination
                          className={styles.pagination}
                          count={collectionSizeNegotiation}
                          page={pageNegotiation}
                          onPageChange={handleChangePageNegotiation}
                          rowsPerPage={rowsPerPageNegotiation}
                          onRowsPerPageChange={handleChangeRowsPerPageNegotiation}
                          rowsPerPageOptions={[5, 10, 25]}
                        />
                      )}
                    </Box>
                  )}
                </Box>
                <Grid
                  // container
                  rowSpacing={1}
                  sx={{
                    overflowX: 'auto',
                    height: 'calc(100vh - 200px)',
                    overflowY: 'auto'
                  }}
                  ref={negotiationRef}
                >
                  {negotiationVehicles && (
                    <ViewportList
                      initialPrerender={8}
                      viewportRef={negotiationRef}
                      items={sortNegotiationVehiclesArray(
                        negotiationVehicles,
                        orderNegotiation,
                        searchValue,
                        pageNegotiation,
                        rowsPerPageNegotiation
                      )}
                    >
                      {(vehicle: VehicleInfo) => (
                        <>
                          {currentDealership && (
                            <PendingListItem
                              key={vehicle.id}
                              vehicle={vehicle}
                              dealershipName={currentDealership.dealershipName}
                              initialOpen={false}
                              sendVehicleToParent={SetDrawerVehicle}
                            />
                          )}
                        </>
                      )}
                    </ViewportList>
                  )}
                  {negotiationEmptyState && (
                    <EmptyStateMessage
                      boldMessage="You have no vehicles in negotiation."
                      message="You can start a negotiation with a buyer on any pending vehicles."
                    />
                  )}
                </Grid>
              </Box>
            </TabPanel>
            {/* SOLD */}
            <TabPanel value={tabValue} index={2}>
              <Box sx={{ flexGrow: 1 }}>
                <Box className={styles.headerTextContainer}>
                  {soldVehicles && (
                    <Typography
                      component="p"
                      className={styles.headerText}
                      sx={{
                        textTransform: 'uppercase',
                        fontWeight: '700',
                        color: theme.palette.info.light
                      }}
                    >
                      {collectionSizeSold} vehicles found
                    </Typography>
                  )}
                  {soldVehicles && (
                    <Box className={styles.controlsContainer}>
                      <Typography component="p" className={styles.headerText}>
                        Sort By:
                      </Typography>
                      <Select
                        label="Sort By"
                        value={orderSold}
                        onChange={handleOrderByChangeSold}
                        className={styles.inputText}
                        inputProps={{ 'aria-label': 'order vehicles' }}
                        input={<InputBase />}
                      >
                        <MenuItem value={'oldest'}>Oldest</MenuItem>
                        <MenuItem value={'newest'}>Newest</MenuItem>
                        {/* <MenuItem value={'vehicleName'}>Name</MenuItem> */}
                      </Select>
                      {soldVehicles && (
                        <TablePagination
                          className={styles.pagination}
                          count={collectionSizeSold}
                          page={pageSold}
                          onPageChange={handleChangePageSold}
                          rowsPerPage={rowsPerPageSold}
                          onRowsPerPageChange={handleChangeRowsPerPageSold}
                          rowsPerPageOptions={[5, 10, 25]}
                        />
                      )}
                    </Box>
                  )}
                </Box>
                <Grid
                  // container
                  spacing={1}
                  sx={{
                    overflowX: 'auto',
                    height: 'calc(100vh - 200px)',
                    overflowY: 'auto'
                  }}
                  ref={soldRef}
                >
                  {soldVehicles && (
                    <ViewportList
                      viewportRef={soldRef}
                      initialPrerender={8}
                      items={soldVehicles
                        // .slice()
                        .sort((a, b) => sortPostAuctionSell(a, b, orderSold))
                        .filter(
                          (v: VehicleInfo) =>
                            v.details.make.toLowerCase().includes(searchValue.toLowerCase()) ||
                            v.details.model.toLowerCase().includes(searchValue.toLowerCase()) ||
                            v.auction.floorPrice
                              .toString()
                              .toLowerCase()
                              .includes(searchValue.toLowerCase()) ||
                            v.auction.captureRepName
                              .toLowerCase()
                              .includes(searchValue.toLowerCase()) ||
                            v.details.year
                              ?.toString()
                              .toLowerCase()
                              .includes(searchValue.toLowerCase()) ||
                            v.details.trim.toLowerCase().includes(searchValue.toLowerCase())
                        )}
                    >
                      {(vehicle: VehicleInfo) => (
                        <SoldListItem
                          key={vehicle.id}
                          vehicle={vehicle}
                          sendVehicleToParent={SetDrawerVehicle}
                        />
                      )}
                    </ViewportList>
                  )}
                  {soldEmptyState && (
                    <EmptyStateMessage
                      boldMessage="You have no vehicles in sold."
                      message="Vehicles that have met the floor price but are not in-stock will appear here."
                    />
                  )}
                </Grid>
              </Box>
            </TabPanel>
            {/* ARRIVED */}
            <TabPanel value={tabValue} index={3}>
              <Box sx={{ flexGrow: 1 }}>
                <Box className={styles.headerTextContainer}>
                  {arrivedVehicles && (
                    <Typography
                      component="p"
                      className={styles.headerText}
                      sx={{
                        textTransform: 'uppercase',
                        fontWeight: '700',
                        color: theme.palette.info.light
                      }}
                    >
                      {collectionSizeArrived} vehicles found
                    </Typography>
                  )}
                  {arrivedVehicles && (
                    <Box className={styles.controlsContainer}>
                      <Typography component="p" className={styles.headerText}>
                        Sort By:
                      </Typography>
                      <Select
                        label="Sort By"
                        value={orderArrived}
                        onChange={handleOrderByChangeArrived}
                        className={styles.inputText}
                        inputProps={{ 'aria-label': 'order vehicles' }}
                        input={<InputBase />}
                      >
                        <MenuItem value={'oldest'}>Oldest</MenuItem>
                        <MenuItem value={'newest'}>Newest</MenuItem>
                        {/* <MenuItem value={'vehicleName'}>Name</MenuItem> */}
                      </Select>
                      {arrivedVehicles && (
                        <TablePagination
                          className={styles.pagination}
                          count={collectionSizeArrived}
                          page={pageArrived}
                          onPageChange={handleChangePageArrived}
                          rowsPerPage={rowsPerPageArrived}
                          onRowsPerPageChange={handleChangeRowsPerPageArrived}
                          rowsPerPageOptions={[5, 10, 25]}
                        />
                      )}
                    </Box>
                  )}
                </Box>
                <Grid
                  // container
                  rowSpacing={1}
                  sx={{
                    overflowX: 'auto',
                    height: 'calc(100vh - 200px)',
                    overflowY: 'auto'
                  }}
                  ref={arrivedRef}
                >
                  {arrivedVehicles && (
                    <ViewportList
                      initialPrerender={8}
                      viewportRef={arrivedRef}
                      items={arrivedVehicles
                        // .slice()
                        .sort((a, b) => sortPostAuctionSell(a, b, orderArrived))
                        .filter(
                          (v: VehicleInfo) =>
                            v.details.make.toLowerCase().includes(searchValue.toLowerCase()) ||
                            v.details.model.toLowerCase().includes(searchValue.toLowerCase()) ||
                            v.auction.floorPrice
                              .toString()
                              .toLowerCase()
                              .includes(searchValue.toLowerCase()) ||
                            v.auction.captureRepName
                              .toLowerCase()
                              .includes(searchValue.toLowerCase()) ||
                            v.details.year
                              ?.toString()
                              .toLowerCase()
                              .includes(searchValue.toLowerCase()) ||
                            v.details.trim.toLowerCase().includes(searchValue.toLowerCase())
                        )}
                    >
                      {(vehicle: VehicleInfo) => (
                        <ArrivedListItem
                          key={vehicle.id}
                          vehicle={vehicle}
                          initialOpen={false}
                          openBillOfSale={toggleBillOfSale}
                          sendVehicleToParent={SetDrawerVehicle}
                        />
                      )}
                    </ViewportList>
                  )}
                  {arrivedEmptyState && (
                    <EmptyStateMessage
                      boldMessage="You have no vehicles in arrived."
                      message="Vehicles that have met the price and are in-stock will appear here."
                    />
                  )}
                </Grid>
              </Box>
            </TabPanel>
            {/* DELIVERED */}
            <TabPanel value={tabValue} index={4}>
              <Box sx={{ flexGrow: 1 }}>
                <Box className={styles.headerTextContainer}>
                  {deliveredVehicles && (
                    <Typography
                      component="p"
                      className={styles.headerText}
                      sx={{
                        textTransform: 'uppercase',
                        fontWeight: '700',
                        color: theme.palette.info.light
                      }}
                    >
                      {collectionSizeDelivered} vehicles found
                    </Typography>
                  )}
                  {deliveredVehicles && (
                    <Box className={styles.controlsContainer}>
                      <Typography component="p" className={styles.headerText}>
                        Sort By:
                      </Typography>
                      <Select
                        label="Sort By"
                        value={orderDelivered}
                        onChange={handleOrderByChangeDelivered}
                        className={styles.inputText}
                        inputProps={{ 'aria-label': 'order vehicles' }}
                        input={<InputBase />}
                      >
                        <MenuItem value={'oldest'}>Oldest</MenuItem>
                        <MenuItem value={'newest'}>Newest</MenuItem>
                        {/* <MenuItem value={'vehicleName'}>Name</MenuItem> */}
                      </Select>
                      {deliveredVehicles && (
                        <TablePagination
                          className={styles.pagination}
                          count={collectionSizeDelivered}
                          page={pageDelivered}
                          onPageChange={handleChangePageDelivered}
                          rowsPerPage={rowsPerPageDelivered}
                          onRowsPerPageChange={handleChangeRowsPerPageDelivered}
                          rowsPerPageOptions={[5, 10, 25]}
                        />
                      )}
                    </Box>
                  )}
                </Box>
                <Grid
                  // container
                  rowSpacing={1}
                  sx={{
                    overflowX: 'auto',
                    height: 'calc(100vh - 200px)',
                    overflowY: 'auto'
                  }}
                  ref={deliveredRef}
                >
                  {deliveredVehicles && (
                    <ViewportList
                      initialPrerender={8}
                      viewportRef={deliveredRef}
                      items={deliveredVehicles
                        // .slice()
                        .sort((a, b) => sortPostAuctionSell(a, b, orderDelivered))
                        .filter(
                          (v: VehicleInfo) =>
                            v.details.make.toLowerCase().includes(searchValue.toLowerCase()) ||
                            v.details.model.toLowerCase().includes(searchValue.toLowerCase()) ||
                            v.auction.floorPrice
                              .toString()
                              .toLowerCase()
                              .includes(searchValue.toLowerCase()) ||
                            v.auction.captureRepName
                              .toLowerCase()
                              .includes(searchValue.toLowerCase()) ||
                            v.details.year
                              ?.toString()
                              .toLowerCase()
                              .includes(searchValue.toLowerCase()) ||
                            v.details.trim.toLowerCase().includes(searchValue.toLowerCase())
                        )}
                    >
                      {(vehicle: VehicleInfo) => (
                        <DeliveredListItem
                          key={vehicle.id}
                          vehicle={vehicle}
                          initialOpen={false}
                          sendVehicleToParent={SetDrawerVehicle}
                        />
                      )}
                    </ViewportList>
                  )}
                  {deliveredEmptyState && (
                    <EmptyStateMessage
                      boldMessage="You have no vehicles in delivered."
                      message="Once a vehicle is paid for and the sale is closed, it will appear here."
                    />
                  )}
                </Grid>
              </Box>
            </TabPanel>
            {/* NO SALE */}
            <TabPanel value={tabValue} index={5}>
              <Box sx={{ flexGrow: 1 }}>
                <Box className={styles.headerTextContainer}>
                  {nosaleVehicles && (
                    <Typography
                      component="p"
                      className={styles.headerText}
                      sx={{
                        textTransform: 'uppercase',
                        fontWeight: '700',
                        color: theme.palette.info.light
                      }}
                    >
                      {collectionSizeNosale} vehicles found
                    </Typography>
                  )}
                  {nosaleVehicles && (
                    <Box className={styles.controlsContainer}>
                      <Typography component="p" className={styles.headerText}>
                        Sort By:
                      </Typography>
                      <Select
                        label="Sort By"
                        value={orderNosale}
                        onChange={handleOrderByChangeNosale}
                        className={styles.inputText}
                        inputProps={{ 'aria-label': 'order vehicles' }}
                        input={<InputBase />}
                      >
                        <MenuItem value={'oldest'}>Oldest</MenuItem>
                        <MenuItem value={'newest'}>Newest</MenuItem>
                        {/* <MenuItem value={'vehicleName'}>Name</MenuItem> */}
                      </Select>
                      {nosaleVehicles && (
                        <TablePagination
                          className={styles.pagination}
                          count={collectionSizeNosale}
                          page={pageNosale}
                          onPageChange={handleChangePageNosale}
                          rowsPerPage={rowsPerPageNosale}
                          onRowsPerPageChange={handleChangeRowsPerPageNosale}
                          rowsPerPageOptions={[5, 10, 25]}
                        />
                      )}
                    </Box>
                  )}
                </Box>
                <Grid
                  // container
                  rowSpacing={1}
                  sx={{
                    overflowX: 'auto',
                    height: 'calc(100vh - 200px)',
                    overflowY: 'auto'
                  }}
                  ref={nosaleRef}
                >
                  {nosaleVehicles && (
                    <ViewportList
                      viewportRef={nosaleRef}
                      initialPrerender={8}
                      items={nosaleVehicles
                        // .slice()
                        .sort((a, b) => sortPostAuctionSell(a, b, orderNosale))
                        .filter(
                          (v: VehicleInfo) =>
                            v.details.make.toLowerCase().includes(searchValue.toLowerCase()) ||
                            v.details.model.toLowerCase().includes(searchValue.toLowerCase()) ||
                            v.auction.floorPrice
                              .toString()
                              .toLowerCase()
                              .includes(searchValue.toLowerCase()) ||
                            v.auction.captureRepName
                              .toLowerCase()
                              .includes(searchValue.toLowerCase()) ||
                            v.details.year
                              ?.toString()
                              .toLowerCase()
                              .includes(searchValue.toLowerCase()) ||
                            v.details.trim.toLowerCase().includes(searchValue.toLowerCase())
                        )}
                    >
                      {(vehicle: VehicleInfo) => (
                        <NosaleListItem
                          key={vehicle.id}
                          curUser={curUser}
                          vehicle={vehicle}
                          initialOpen={false}
                          sendVehicleToParent={SetDrawerVehicle}
                        />
                      )}
                    </ViewportList>
                  )}
                  {noSaleEmptyState && (
                    <EmptyStateMessage
                      boldMessage="You have no vehicles in No Sale"
                      message="Vehicles that do not reach a deal or sell in an active auction will appear here."
                    />
                  )}
                </Grid>
              </Box>
            </TabPanel>
          </Container>
          {currentVehicle && (
            <>
              <AuctionDrawer
                tabIndex={0}
                isOpenSidebar={openDrawer}
                onCloseSidebar={handleCloseDrawer}
                vehicle={currentVehicle as VehicleInfo}
              />
              <SharedDrawer
                isOpenSidebar={openVehicleHistory}
                onCloseSidebar={handleCloseVehicleHistory}
                drawerContent={
                  <HistoryDrawerContent
                    isSeller={true}
                    onClose={handleCloseVehicleHistory}
                    vehicle={currentVehicle as VehicleInfo}
                    index={0}
                  />
                }
              />
              <SharedDrawer
                isOpenSidebar={openBidHistory}
                onCloseSidebar={handleCloseBidHistory}
                drawerContent={
                  <HistoryDrawerContent
                    isSeller={true}
                    onClose={handleCloseBidHistory}
                    vehicle={currentVehicle as VehicleInfo}
                    index={1}
                  />
                }
              />
              <Dialog
                classes={{ paper: styles.paper }}
                open={openBillofSale}
                onClose={handleCloseBillofSale}
                sx={{
                  zIndex: '9999999'
                }}
              >
                <BillofSale vehicle={currentVehicle as VehicleInfo} />
              </Dialog>
            </>
          )}
          {pendingVehicles && pendingVehicles.length > 0 && (
            <Tour
              steps={steps}
              isOpen={tourOpen}
              onRequestClose={() => handleCloseTour()}
              className={styles.tourContainer}
            />
          )}
        </Page>
      ) : (
        <>
          {userDeleted && (
            <Alert severity="error">
              <AlertTitle>Permission Denied</AlertTitle>
              Your account has been disabled.
            </Alert>
          )}
          {userSuspended && (
            <>
              <Alert severity="error">
                <AlertTitle>Thank you for joining DealerGavel</AlertTitle>
                Your account information is being reviewed by DealerGavel team, and will be
                &nbsp;authorized shortly. For now, you can return to the dashboard and await
                &nbsp;authorization.
              </Alert>
              <Box sx={{ mt: '20px' }}>
                <Button onClick={handleNavigate} variant="contained">
                  Back to Dashboard
                </Button>
              </Box>
            </>
          )}
          {userDisabled && (
            <>
              <Alert severity="error">
                <AlertTitle>Permission Denied</AlertTitle>
                Your account has been disabled.
              </Alert>
              <Box sx={{ mt: '20px' }}>
                <Button onClick={handleNavigate} variant="contained">
                  Back to Dashboard
                </Button>
              </Box>
            </>
          )}
          {buyerOnly && (
            <>
              <Alert severity="error">
                <AlertTitle>Permission Denied</AlertTitle>
                You do not have permissions for this page.
              </Alert>
              <Box sx={{ mt: '20px' }}>
                <Button onClick={handleNavigate} variant="contained">
                  Back to Dashboard
                </Button>
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
}
