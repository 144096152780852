import { Grid, Box, BoxProps, MenuItem, Popover, IconButton, Checkbox } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import LaunchingButton from './LaunchingButton';
import { Link as RouterLink } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { fDateTimeSuffix } from 'components/shared/dateTimeFormat';
import ListItemBidPrice from '../../shared/ListItemPriceBid';
import ListItemPersonDate from '../../shared/ListItemPersonDate';
import ListItemDetails from '../../shared/ListItemDetails';
import { VehicleInfo, VehiclePhotos } from 'types/vehicleInfo';
import moment from 'moment';
import ListItemStatusPill from 'components/shared/ListItemStatusPill';
import ListItemBidPriceEdit from 'components/shared/ListItemBidPriceEdit';
import { User } from 'constants/user';

interface Props extends BoxProps {
  vehicle: VehicleInfo;
  handleChecked: any;
  curUser?: User;
  sendVehicleToParent: (index: number, vehicle: VehicleInfo) => void;
  toggleFloorPriceModal: (vehicleId: string) => void;
  isChecked: boolean;
  initialOpen?: boolean;
  optionToDisable?: string;
  isRunList?: boolean;
}

const MENU_OPTIONS = [
  { label: 'Capture History', linkTo: '#' },
  { label: 'Edit Vehicle', linkTo: '#' },
  { label: 'View VDP', linkTo: '#' }
];

export default function PreAuctionListItem({
  vehicle,
  handleChecked,
  sendVehicleToParent,
  toggleFloorPriceModal,
  curUser,
  isChecked,
  optionToDisable,
  isRunList,
  initialOpen
}: Props) {
  const theme = useTheme();
  const [anchorPopup, setAnchorPopup] = useState<HTMLButtonElement | null>(null);
  const [popupOpened, setPopupOpened] = useState(false);
  const [, setAnchorEl] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openHistoryDrawer, setOpenHistoryDrawer] = useState(false);
  const [, setSelectedIndex] = useState(0);
  const [runtime, setRuntime] = useState<string>();
  // const [vehiclePhoto, setVehiclePhoto] = useState<VehiclePhotos>();

  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogChange = () => {
    setDialogOpen(!dialogOpen);
  };

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });

  let vehiclePhoto;
  if (vehicle.photos) {
    const photos = vehicle.photos.filter(
      (v: any) => v.url && v.location && v.location.toLowerCase().includes('3/4 shot')
    );
    if (photos[0]) {
      vehiclePhoto = photos[0];
    }
  }

  const open = Boolean(anchorPopup);
  const id = open ? 'simple-popover' : undefined;

  const useStyles = makeStyles({
    menuPopover: {
      boxShadow: '0 0 2px 0 rgba(139, 148, 156, 0.24),0 10px 20px -4px rgba(139, 148, 156, 0.24)',
      paddingTop: '20px',
      paddingBottom: '20px',
      '@global': {
        '.MuiMenuItem-root': {
          paddingBottom: '6px'
        }
      }
    },
    cancelButton: {
      backgroundColor: theme.palette.divider,
      color: theme.palette.info.light,
      '&:hover': {
        color: theme.palette.common.white
      }
    }
  });

  const styles = useStyles();

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setSelectedIndex(index);
    sendVehicleToParent(index, vehicle);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorPopup(event.currentTarget);
    setPopupOpened(true);
  };

  const handleClose = () => {
    setAnchorPopup(null);
    setPopupOpened(false);
  };

  const handleCloseModal = () => {
    setOpenModal(!openModal);
    handleClose();
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(!openDrawer);
    handleClose();
  };

  const handleCloseHistoryDrawer = () => {
    setOpenHistoryDrawer(!openHistoryDrawer);
    handleClose();
  };

  const [launchDate, setLaunchDate] = useState<Date>();

  useEffect(() => {
    if (vehicle.auction.auctionStatus.toLowerCase() === 'launching soon') {
      const date = moment(vehicle.auction.launchDate.toDate()).format('YYYY/MM/DD');
      const time = moment(vehicle.auction.launchTime.toDate()).format('hh:mm:ss a');
      const launchDate = new Date(date + ' ' + time);
      setLaunchDate(launchDate);
    }
    // prettier-ignore
    if (vehicle.auction.launchDate && vehicle.auction.launchTime && vehicle.auction.endDate && vehicle.auction.endTime && vehicle.auction.auctionStatus === 'launching soon') {
      const launchDate = moment(vehicle.auction.launchDate.toDate()).format('YYYY/MM/DD');
      const launchTime = moment(vehicle.auction.launchTime.toDate()).format('hh:mm:ss a');
      const finalLaunchDate = new Date(launchDate + ' ' + launchTime);
      const endDate = moment(vehicle.auction.endDate.toDate()).format('YYYY/MM/DD');
      const endTime = moment(vehicle.auction.endTime.toDate()).format('hh:mm:ss a');
      const finalEndDate = new Date(endDate + ' ' + endTime);
      const duration = moment.duration(moment(finalEndDate).diff(finalLaunchDate));
      const daysRemaining = duration.days();
      const hoursRemaining = duration.hours();
      const minutesRemaining = duration.minutes();
      const finalRunTime = `${daysRemaining}D ${hoursRemaining}H ${minutesRemaining}M`;
      console.log('final run time', finalRunTime);
      setRuntime(finalRunTime);
    }
  }, [vehicle]);

  useEffect(() => {
    setAnchorEl(false);
  }, [popupOpened]);

  return (
    <Grid
      xs={12}
      sx={{
        marginTop: '5px',
        paddingTop: '2px !important',
        paddingBottom: '2px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        maxWidth: 'unset !important',
        minHeight: '100px'
      }}
    >
      <Grid
        sx={{
          display: 'flex',
          justifyContent: 'center',
          minWidth: '50px',
          minHeight: '100px',
          borderTopLeftRadius: '10px',
          borderBottomLeftRadius: '10px',
          borderTop:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderLeft:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderBottom:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`
        }}
      >
        <Checkbox
          checked={isChecked}
          onChange={(event, checked) => {
            handleChecked(checked, vehicle.id);
          }}
        />
      </Grid>
      <Grid
        sx={{
          display: 'flex',
          minHeight: '100px',
          minWidth: '450px',
          borderTop:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderBottom:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`
        }}
      >
        <ListItemDetails
          vehicle={vehicle}
          isSellerName={true}
          imageUrl={vehiclePhoto?.url}
          minWidth={'400px'}
          hasStatusPill={false}
        ></ListItemDetails>
      </Grid>
      <Grid
        xs={2}
        sx={{
          padding: '0 5px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minWidth: '100px',
          minHeight: '100px',
          borderTop:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderBottom:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`
        }}
      >
        <ListItemStatusPill
          label={''}
          value={vehicle.auction.isInStock ? 'In Stock' : 'Appraisal'}
          color={vehicle.auction.isInStock ? theme.palette.success.main : '#FD7E14'}
        ></ListItemStatusPill>
      </Grid>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          minHeight: '100px',
          borderTop:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderBottom:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`
        }}
        id="preAuctionSell3"
      >
        <Grid
          xs={2}
          sx={{
            padding: '0 5px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '150px'
          }}
        >
          <ListItemBidPriceEdit
            label={'floor price'}
            value={currencyFormat.format(vehicle.auction.floorPrice)}
            color={theme.palette.primary.main}
            toggleFloorPriceModal={() => toggleFloorPriceModal(vehicle.id)}
          ></ListItemBidPriceEdit>
        </Grid>
        {vehicle.auction.captureDate &&
          vehicle.auction.auctionStatus.toLowerCase() !== 'launching soon' && (
            <Grid xs={2} sx={{ padding: '0 5px', minWidth: '175px' }}>
              <ListItemPersonDate
                label={'Capture Date'}
                value={fDateTimeSuffix(vehicle.auction.captureDate.toDate())}
              ></ListItemPersonDate>
            </Grid>
          )}
        {vehicle.auction.captureDate &&
          launchDate &&
          vehicle.auction.auctionStatus.toLowerCase() === 'launching soon' && (
            <Grid xs={2} sx={{ padding: '0 5px', minWidth: '175px' }}>
              <ListItemPersonDate
                label={'Launch Date'}
                value={fDateTimeSuffix(launchDate)}
              ></ListItemPersonDate>
            </Grid>
          )}
        {vehicle.auction.auctionStatus.toLowerCase() !== 'launching soon' && (
          <Grid xs={2} sx={{ padding: '0 5px', minWidth: '175px' }}>
            <ListItemPersonDate
              label={'capture rep'}
              value={vehicle.auction.captureRepName}
            ></ListItemPersonDate>
          </Grid>
        )}
        {/* {vehicle.auction.auctionStatus.toLowerCase() === 'launching soon' &&
          vehicle.auction.runTime && (
            <Grid xs={2} sx={{ padding: '0 5px', minWidth: '175px' }}>
              <ListItemPersonDate
                label={'Run Time'}
                value={vehicle.auction.runTime.toString()}
              ></ListItemPersonDate>
            </Grid>
          )} */}
        {vehicle.auction.auctionStatus.toLowerCase() === 'launching soon' && runtime && (
          <Grid xs={2} sx={{ padding: '0 5px', minWidth: '175px' }}>
            <ListItemPersonDate label={'Run Time'} value={runtime}></ListItemPersonDate>
          </Grid>
        )}
        {vehicle.auction.auctionStatus.toLowerCase() === 'launching soon' && (
          <Grid xs={2} sx={{ padding: '0 5px', minWidth: '175px' }}>
            <ListItemPersonDate
              label={'Last Edited By'}
              value={vehicle.auction.captureRepName}
            ></ListItemPersonDate>
          </Grid>
        )}
      </Box>
      <Grid
        id="preAuctionSell4"
        xs={2}
        sx={{
          padding: '0 5px',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          minWidth: '250px',
          minHeight: '100px',
          borderTopRightRadius: '10px',
          borderBottomRightRadius: '10px',
          borderTop:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderRight:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`,
          borderBottom:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.text.secondary}`
              : `1px solid ${theme.palette.divider}`
        }}
      >
        <LaunchingButton
          vehicle={vehicle}
          curUser={curUser}
          optionToDisable={optionToDisable}
          isRunList={isRunList}
        />
        <IconButton
          aria-describedby={id}
          onClick={handleClick}
          sx={{
            backgroundColor: theme.palette.background.paper,
            padding: '5px',
            borderRadius: '5px',
            marginLeft: '10px'
          }}
        >
          <MoreVertIcon />
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorPopup}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          sx={{ width: 220 }}
          PaperProps={{ classes: { root: styles.menuPopover } }}
        >
          {MENU_OPTIONS.map((option, index) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={(event: any) => handleMenuItemClick(event, index)}
              sx={{ typography: 'body2', py: 1, px: 2.5 }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Popover>
      </Grid>
    </Grid>
  );
}
