import {
  Box,
  Button,
  ButtonGroup,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent
} from '@mui/material';
import { useState, useRef } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import LaunchSoonContent from '../preAuction/LaunchSoonContent';
import ActiveContent from '../preAuction/ActiveContent';
import { useFirestore } from 'reactfire';
import { doc, Timestamp, updateDoc } from 'firebase/firestore';
import { VehicleInfo } from 'types/vehicleInfo';
import useAuth from 'hooks/useAuth';
import { User } from 'constants/user';

type Props = {
  vehicleId: string;
  isNoSale?: boolean;
  vehicle?: VehicleInfo;
  daysUntilNextRun?: number;
  curUser?: User;
};

export default function RelaunchButton({
  vehicleId,
  isNoSale,
  vehicle,
  daysUntilNextRun,
  curUser
}: Props) {
  const theme = useTheme();
  const firestore = useFirestore();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const { currentUser } = useAuth();
  const [adminOmvicDialogOpen, setAdminOmvicDialogOpen] = useState<boolean>(false);

  let options: string[];
  if (isNoSale) {
    // options = ['parked', 'launching soon', 'active'];
    options = ['parked'];
  } else {
    options = ['arrived', 'no sale'];
  }

  const handleToggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const handleMenuItemClick = async (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    setOpen(false);
    const docRef = doc(firestore, `vehicles/${vehicleId}`);
    const option = options[index];
    if (isNoSale) {
      if (option.toLowerCase() === 'parked') {
        await updateDoc(docRef, {
          liveAuctionIndex: null,
          'auction.startDateTime': null,
          'auction.liveAuctionStatus': 'Inactive',
          'auction.isLiveAuctionActive': false,
          'auction.auctionStatus': 'parked',
          billOfSalelink: null,
          buyerVehicleHistory: [],
          sellerMarkAsSold: false,
          bidExtension: false,
          bidExtensionTime: null,
          'auction.launchDate': null,
          'auction.launchTime': null,
          'auction.endDate': null,
          'auction.endTime': null,
          'auction.timesRun': 0,
          'auction.isAutobid': [],
          'auction.isWatchlist': [],
          'auction.isFavourite': [],
          'auction.isExpanded': [],
          'auction.autobids': [],
          'auction.postAuctionStatus': null,
          'auction.negotiationStatusSeller': 'action required',
          'auction.negotiationStatusBuyer': 'awaiting response',
          'auction.buyerName': null,
          'auction.currentPrice': null,
          'auction.negotiationPrice': null,
          'auction.bidLeaderId': null,
          'auction.runTime': null,
          buyerDealership: null,
          sellerDealership: null,
          buyerUser: null,
          'auction.negotiationHistory': [],
          'auction.previousBids': [],
          'auction.previousBidders': []
          // 'auction.vehicleLaunchUser': {
          //   name: currentUser.firstName + ' ' + currentUser.lastName,
          //   email: currentUser.email,
          //   phone: currentUser.phone
          // }
        });
      } else {
        handleToggleModal();
        // if (curUser && curUser.omvicNumber) {
        //   handleToggleModal();
        // } else {
        //   handleCloseAdminOmvicDialog();
        // }
      }
    } else {
      await updateDoc(docRef, {
        'auction.postAuctionStatus': option,
        'auction.dateArrived': Timestamp.now()
      });
    }
  };

  const handleCloseAdminOmvicDialog = () => {
    setAdminOmvicDialogOpen(!adminOmvicDialogOpen);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const useStyles = makeStyles({
    primaryButton: {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.common.white} !important`,
      '&:hover': {
        backgroundColor: `${theme.palette.primary.dark} !important`
      },
      '&:disabled': {
        backgroundColor: `${theme.palette.primary.light} !important`
      }
    },
    primaryButtonLarge: {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.common.white} !important`,
      '&:hover': {
        backgroundColor: `${theme.palette.primary.dark} !important`
      }
    }
  });

  const styles = useStyles();

  return (
    <Box>
      <ButtonGroup
        sx={{ boxShadow: 'none !Important' }}
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
      >
        <Button disabled className={isNoSale ? styles.primaryButton : styles.primaryButtonLarge}>
          Move To
        </Button>
        <Button
          disabled={daysUntilNextRun && daysUntilNextRun > 0 ? true : false}
          size="small"
          className={styles.primaryButton}
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select vehicle status"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        style={{ zIndex: '9999' }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      sx={{ textTransform: 'capitalize' }}
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <Dialog open={isOpenModal} onClose={handleToggleModal}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '20px',
            position: 'relative',
            borderBottom: `1px solid ${theme.palette.divider}`
          }}
        >
          <Typography component="p" variant="h5">
            {options[selectedIndex].toLowerCase() === 'launching soon'
              ? 'Launching Soon'
              : 'Active Auction'}
          </Typography>
          <CloseIcon
            onClick={handleToggleModal}
            sx={{
              position: 'absolute',
              right: '10px',
              cursor: 'pointer'
            }}
          />
        </Box>
        <Box>
          {options[selectedIndex].toLowerCase() === 'launching soon' && (
            <LaunchSoonContent
              vehicleId={vehicleId}
              handleClose={handleToggleModal}
              isNoSale={true}
            />
          )}
          {options[selectedIndex].toLowerCase() === 'active' && (
            <ActiveContent
              isNoSale={true}
              vehicleId={vehicleId}
              vehicle={vehicle}
              handleClose={handleToggleModal}
            />
          )}
        </Box>
      </Dialog>
      <Dialog open={adminOmvicDialogOpen} onClose={handleCloseAdminOmvicDialog}>
        <Box sx={{ textAlign: 'center' }}>
          <DialogTitle>Permission Error</DialogTitle>
          <CloseIcon
            onClick={handleCloseAdminOmvicDialog}
            sx={{
              cursor: 'pointer',
              position: 'absolute',
              right: '15px',
              top: '24px'
            }}
          />
        </Box>
        <DialogContent>
          {/* prettier-ignore */}
          <Typography>
            Your account is missing an Individual License/Registration Number (ex. OMVIC/MVDA/SAAQ etc).
            Go to Manage Account and select Account Information to update this.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'flex-end',
              mt: '15px'
            }}
          >
            <Button size="large" variant="contained" onClick={handleCloseAdminOmvicDialog}>
              Ok, Got it!
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
