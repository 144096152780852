import { Box, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { VehicleInfo } from 'types/vehicleInfo';
import { differenceInSeconds } from 'date-fns';
import moment from 'moment';
import { makeStyles } from '@mui/styles';

interface runTime {
  vehicle: VehicleInfo;
}

const PreAuctionPercentageCalc = ({ vehicle }: runTime) => {
  const [completed, setCompleted] = useState<number>(100);
  const theme = useTheme();

  const useStyles = makeStyles({
    containerStyles: {
      backgroundColor: theme.palette.primary.dark,
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '30px',
      position: 'absolute',
      zIndex: 0
    }
  });

  const styles = useStyles();

  const ProgressBar = (props: any) => {
    const { bgColor, completed } = props;

    const fillerStyles = {
      height: '100%',
      width: `${completed}%`,
      backgroundColor: bgColor,
      borderRadius: 'inherit',
      transition: 'width 1s ease-in-out'
    };

    const labelStyles = {
      padding: 5,
      color: 'white',
      fontWeight: 'bold'
    };

    return (
      <Box className={styles.containerStyles}>
        <div style={fillerStyles}>
          <span style={labelStyles}></span>
        </div>
      </Box>
    );
  };

  useEffect(() => {
    const timerId = setInterval(() => tick(), 1000);
    return () => clearInterval(timerId);
  });

  const tick = () => {
    const timezoneOffset = new Date().getTimezoneOffset();
    // const UTCEndDateMoment = moment(vehicle.auction.launchDate.toDate()).clone().add(timezoneOffset, 'minutes');
    // const UTCEndTimeMoment = moment(vehicle.auction.launchTime.toDate()).clone().add(timezoneOffset, 'minutes');
    let UTCEndDateMoment;
    let UTCEndTimeMoment;
    if (vehicle.auction.auctionStatus !== 'Live Auction') {
      // prettier-ignore
      UTCEndDateMoment = moment(vehicle.auction.launchDate.toDate()).clone().add(timezoneOffset, 'minutes');
      // prettier-ignore
      UTCEndTimeMoment = moment(vehicle.auction.launchTime.toDate()).clone().add(timezoneOffset, 'minutes');
    } else {
      const date = new Date();
      date.setHours(12, 0, 0, 0);
      UTCEndDateMoment = moment(date).clone().add(timezoneOffset, 'minutes');
      UTCEndTimeMoment = moment(date).clone().add(timezoneOffset, 'minutes');
    }
    const UTCendDate = UTCEndDateMoment.toDate();
    const UTCendTime = UTCEndTimeMoment.toDate();
    UTCendDate.setHours(UTCendTime.getHours());
    UTCendDate.setMinutes(UTCendTime.getMinutes());
    UTCendDate.setSeconds(UTCendTime.getSeconds());
    UTCendDate.setMilliseconds(UTCendTime.getMilliseconds());
    const now = moment().clone().add(timezoneOffset, 'minutes');
    const durationSeconds = 3600;
    let timeLeft = differenceInSeconds(UTCendDate, now.toDate());
    const percentage = Math.round((timeLeft / durationSeconds) * 100);
    if (percentage < 100) {
      setCompleted(percentage);
    } else {
      setCompleted(100);
    }
  };

  return <ProgressBar bgColor={theme.palette.primary.main} completed={completed} />;
};

export default PreAuctionPercentageCalc;
